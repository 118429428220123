/* General imports */
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { devices, config } from '../../app/config'
import axios from 'axios'
import  { authHeader } from '../../app/auth-header';

/* Components imports */
import NoImage from '../../images/no-image.png'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Spinner from '../../spinners/LoaderSpinner';


/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}
const HeightHeader = '70px'

/* Styled components */
const Root = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: calc(100% - ${HeightHeader});
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const TitleBox = styled.div`
    position: relative;
    display: flex;
    height: 300px;
    width: 90%;
    box-sizing: border-box;
    border-radius: 10px;
    transition: all .5s ease-in-out;
    box-shadow: 5px 5px 15px black;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
`

const ImageBox = styled.div`
    position: relative;
    display: flex;
    height: 100%;
    width: 35%;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`


const InputsTitleBox = styled.div`
    position: relative;
    display: flex;
    height: 100%;
    width: 65%;
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
`

const ContentBox = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 400px;
    align-items: center;
    justify-content: center;
`

const Header = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: ${HeightHeader};
    padding: 0 25px;
    box-sizing: border-box;
    background: #234;
`

const HeaderTitle = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 60%;
    height: 100%;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    text-shadow: 1px 1px 2px black;
`

const HeaderButtons = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 40%;
    height: 100%;
`

const WrapBox = styled.div`
    position: relative;
    display: flex;
    height: 100%;
    width: 22.5%;
    box-sizing: border-box;
    border-radius: 10px;
    transition: all .5s ease-in-out;
    box-shadow: 5px 5px 15px black;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 5px;
`

const ZoneSubButtons = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
`

const Box = styled.div`
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    border-radius: ${(props) => props.borderRadius ? props.borderRadius : 0};
    h4 {
        margin: 20px 0 0 0;
    }
`

const ButtonHeader = styled.button`
    margin: 0 5px;
`

const Inputbox = styled.div`
    width: ${(props) => props.columWidth ? props.columWidth : '100%'};
    position: relative;
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 16px;
    box-sizing: border-box;
    height: 44px;
    border-bottom: 1px solid black;
    ${(props) => props.error ? 'border-color: red;' : ''};
    margin: ${(props) => props.margin ? '10px 10px 0 10px' : 0};
    label {
        position: absolute;
        top: ${(props) => props.type === 'date' ? '-10px' : props.effect ? '0px' : '20px' };
        left: 0;
        color: #ccc;
        transition: .5s;
        pointer-events: none;
        font-size: ${(props) => props.type === 'date' ? '14px' : props.effect ? '14px' : '16px'};
        margin-left: ${(props) => props.icon ? '40px' : 0};
        height: 16px;
    }

    input {
        border: none;
        outline: none;
        background: none;
        color: black;
        font-size: 16px;
        width: 100%;
        padding-left: ${(props) => props.icon ? '5px' : 0};
        margin-top: 14px;
        box-sizing: border-box;
        height: 24px;
    }

    input:focus + label {
        top: 0px;
        color: ${(props) => props.effectColor ? props.effectColor : 'black'};
        font-size: 14px;
    }

    select:focus + label {
        top: 0px;
        color: ${(props) => props.effectColor ? props.effectColor : 'black'};
        font-size: 14px;
    }
`

const CancelImg = styled.div`
    position: absolute;
    display: none;
    width: 30px;
    height: 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 50%;
    background-color: #ff000080;
    align-items: center;
    justify-content: center;
    opacity: 0;
    user-select: none;
    cursor: pointer;
`

const ImageContainer = styled.div`
    position: relative;
    display: flex;
    width: 150px;
    height: 150px;
    margin-top: 20px;
    margin-bottom: 10px;
    img {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    ${(props) => props.active ?
    `&:hover {
        ${CancelImg} {
            display: flex;
            opacity: 1;
        }
        img {
            opacity: 0.4;
        }
    }`
    :
    ''
    }
`

const UploadImage = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    box-sizing: border-box;
`

const CheckOneButton = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    width: ${(props) => props.width ? props.width : '100%'};
    margin: ${(props) => props.margin ? props.margin : 0};
    label {
        margin-left: 5px;
    }
`

/* ----- Principal function ----- */
function ObjetivesSettings({match, history, user}) {

    let configHeader = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    const { idPage, idSection } = match.params

    const [ image, setImage ] = React.useState('')

    const [data, setData] = React.useState(null)

    const onBack = () => {
        history.push(config.webeditorpath+'/page/'+idPage)
    }

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        })
    }

    const onFileChange = (e) => {
        setImage(e.target.files[0])
    }

    const handleFileUpload = (e) => {
        e.preventDefault()
        const { name } = e.currentTarget.dataset;
        if(image !== '' ){
            if(!data[name]){
                const formData = new FormData()
                formData.append('image', image)
                axios.post("/api/images/upload", formData, configHeader)
                .then(res => {
                    const url_img = '/uploads/'+res.data.filename
                    setData({
                        ...data,
                        [name]: url_img
                    })
                })
                .catch(error => {
                    alert('Error al cargar la imagen. Sólo se soporta hasta 2MB y los tipos jpeg-jpg-png-gif-webp.')
                })
            } else {
                // eslint-disable-next-line no-restricted-globals
                if(confirm('¿Desea subir esta nueva imagen y reemplazar la anterior?')){
                    const formData = new FormData()
                    formData.append('image', image)
                    axios.post("/api/images/upload", formData, configHeader)
                    .then(res => {
                        const url_img = '/uploads/'+res.data.filename
                        setData({
                            ...data,
                            [name]: url_img
                        })   
                    })
                    .catch(error => {
                        alert('Error al cargar la imagen. Sólo se soporta hasta 2MB y los tipos jpeg-jpg-png-gif-webp.')
                    })  
                }
            }
        }else{
            alert("Debe selecionar una imagen...")
        }
    }

    const onSave = () => {
        const saveData = {
            data: data,
            updatedUser: user.idUser
        }
        axios.put("/api/updatedatasection/"+idSection, saveData, configHeader)
        .then(res => {
            history.push(config.webeditorpath+'/page/'+idPage)
        })
        .catch(error => {
            alert('Hubo un error...')
        })
    }

    const cancelImage = (e) => {
        e.preventDefault()
        const { name } = e.currentTarget.dataset;
        setData({
            ...data,
            [name]:''
        })
    }

    const handleCheckParallax = (e) => {
        const { name, checked } = e.target;
        setData({
            ...data,
            [name]: checked ? 1 : 0
        })
    }

    React.useEffect(() => {
        if(!data){
            axios.get('/api/sections/' + idSection,configHeader)
            .then(sectionData => {
                if(parseInt(Object.keys(sectionData.data.section.data).length) > 0){
                    setData(sectionData.data.section.data);
                } else {
                    setData({
                        title: '',
                        text1: '',
                        img1: '',
                        text2: '',
                        img2: '',
                        text3: '',
                        img3: '',
                        text4: '',
                        img4: '',
                        parallax: 0
                    })
                }
            })
            .catch(err => {
                alert('Hubo un error...');
            })
        }
    }, [data, idSection])

    return(
        <React.Fragment>
            <Header>
                {
                    <HeaderTitle>CONFIGURACIÓN THREE SERVICES</HeaderTitle>
                }
                <HeaderButtons><ButtonHeader onClick={onBack}>VOLVER</ButtonHeader> <ButtonHeader onClick={onSave}>GUARDAR</ButtonHeader> </HeaderButtons>
            </Header>
            <Desktop>
                {
                    !data ? <Spinner spinnerType='ThreeDots' />
                    :
                    <Root>
                        <TitleBox>
                            <ImageBox>
                                <ImageContainer active={data.img ? true : false}>
                                    <img src={data.img ? data.img : NoImage} alt="Imagen subida" />
                                    {
                                        data.img && <CancelImg data-name='img' onClick={cancelImage}> X </CancelImg>
                                    }
                                </ImageContainer>
                                <UploadImage>
                                    <input style={{fontSize: '0.75rem', width: '80%'}} id="img" name="img" type="file" onChange={onFileChange} /><CloudUploadIcon data-name='img' onClick={handleFileUpload} />
                                </UploadImage>
                            </ImageBox>
                            <InputsTitleBox>
                                <Inputbox type='text' effect={ data.title !== '' ? true : false} columWidth='75%' margin={true} >
                                    <input id='title' required={true} name='title' value={data.title} type='text' onChange={handleChange} />
                                    <label>Título</label>
                                </Inputbox>
                                <CheckOneButton width='90%' margin='20px 0'>
                                    <input id='parallax' name='parallax' checked={data.parallax === 0 ? false : true} type='checkbox' onChange={handleCheckParallax} />
                                    <label>Efecto Parallax</label>
                                </CheckOneButton>
                            </InputsTitleBox>
                        </TitleBox>
                        <ContentBox>
                            <WrapBox borderRadius='left'>
                                <ZoneSubButtons>
                                    <Box borderRadius='0 0 10px 10px'>
                                        <h4>OBJETIVO 1</h4>
                                        <Inputbox type='text' effect={ data.text1 !== '' ? true : false} columWidth='85%' margin={true} >
                                            <input id='text1' required={true} name='text1' value={data.text1} type='text' onChange={handleChange} />
                                            <label>Texto</label>
                                        </Inputbox>
                                        <ImageContainer active={data.img1 ? true : false}>
                                            <img src={data.img1 ? data.img1 : NoImage} alt="Imagen subida" />
                                            {
                                                data.img1 && <CancelImg data-name='img1' onClick={cancelImage}> X </CancelImg>
                                            }
                                        </ImageContainer>
                                        <UploadImage>
                                            <input style={{fontSize: '0.75rem', width: '100%'}} id="img1" name="img1" type="file" onChange={onFileChange} /><CloudUploadIcon data-name='img1' onClick={handleFileUpload} />
                                        </UploadImage>
                                    </Box>
                                </ZoneSubButtons>
                            </WrapBox>
                            <WrapBox>
                                <ZoneSubButtons>
                                    <Box borderRadius='0 0 10px 10px'>
                                        <h4>OBJETIVO 2</h4>
                                        <Inputbox type='text' effect={ data.text2 !== '' ? true : false} columWidth='85%' margin={true} >
                                            <input id='text2' required={true} name='text2' value={data.text2} type='text' onChange={handleChange} />
                                            <label>Texto</label>
                                        </Inputbox>
                                        <ImageContainer active={data.img2 ? true : false}>
                                            <img src={data.img2 ? data.img2 : NoImage} alt="Imagen subida" />
                                            {
                                                data.img2 && <CancelImg data-name='img2' onClick={cancelImage}> X </CancelImg>
                                            }
                                        </ImageContainer>
                                        <UploadImage>
                                            <input style={{fontSize: '0.75rem', width: '100%'}} id="img2" name="img2" type="file" onChange={onFileChange} /><CloudUploadIcon data-name='img2' onClick={handleFileUpload} />
                                        </UploadImage>
                                    </Box>
                                </ZoneSubButtons>
                            </WrapBox>
                            <WrapBox borderRadius='right'>
                                <ZoneSubButtons>
                                    <Box borderRadius='0 0 10px 10px'>
                                        <h4>OBJETIVO 3</h4>
                                        <Inputbox type='text' effect={ data.text3 !== '' ? true : false} columWidth='85%' margin={true} >
                                            <input id='text3' required={true} name='text3' value={data.text3} type='text' onChange={handleChange} />
                                            <label>Texto</label>
                                        </Inputbox>
                                        <ImageContainer active={data.img3 ? true : false}>
                                            <img src={data.img3 ? data.img3 : NoImage} alt="Imagen subida" />
                                            {
                                                data.img3 && <CancelImg data-name='img3' onClick={cancelImage}> X </CancelImg>
                                            }
                                        </ImageContainer>
                                        <UploadImage>
                                            <input style={{fontSize: '0.75rem', width: '100%'}} id="img3" name="img3" type="file" onChange={onFileChange} /><CloudUploadIcon data-name='img3' onClick={handleFileUpload} />
                                        </UploadImage>
                                    </Box>
                                </ZoneSubButtons>
                            </WrapBox>
                            <WrapBox borderRadius='right'>
                                <ZoneSubButtons>
                                    <Box borderRadius='0 0 10px 10px'>
                                        <h4>OBJETIVO 4</h4>
                                        <Inputbox type='text' effect={ data.text4 !== '' ? true : false} columWidth='85%' margin={true} >
                                            <input id='text4' required={true} name='text4' value={data.text4} type='text' onChange={handleChange} />
                                            <label>Texto</label>
                                        </Inputbox>
                                        <ImageContainer active={data.img4 ? true : false}>
                                            <img src={data.img4 ? data.img4 : NoImage} alt="Imagen subida" />
                                            {
                                                data.img4 && <CancelImg data-name='img4' onClick={cancelImage}> X </CancelImg>
                                            }
                                        </ImageContainer>
                                        <UploadImage>
                                            <input style={{fontSize: '0.75rem', width: '100%'}} id="img4" name="img4" type="file" onChange={onFileChange} /><CloudUploadIcon data-name='img4' onClick={handleFileUpload} />
                                        </UploadImage>
                                    </Box>
                                </ZoneSubButtons>
                            </WrapBox>
                        </ContentBox>
                    </Root>
                }
            </Desktop>
            <Tablet>
                <ContentBox>
                    TABLET OBJETIVES SETTINGS 
                </ContentBox>
            </Tablet>
            <Mobile>
                <ContentBox>
                    MOBILE OBJETIVES SETTINGS  
                </ContentBox>
            </Mobile>
        </React.Fragment>
    );
}

function mapState(state) {
    const { user } = state.login;
    return { user };
}

export default connect(mapState)(ObjetivesSettings);