/* General imports */
import React from 'react';
import styled from 'styled-components';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ModalAddFeature from './ModalAddFeature';

const Root = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 200px;
    box-sizing: border-box;
    padding: 10px;
`

const ContentFeatures = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100px;
    overflow-y: auto;
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: flex-start;
`

const Feature = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: auto;
`

const StyledDelete = styled(DeleteOutlineIcon)`
    color: red;
    cursor: pointer;
`

const Features = ({features, handleConfirmAddFeature, deleteFeature, alertMessage}) => {

    const [ newFeature, setNewFeature ] = React.useState(false);

    const handlenewFeature = () => {
        setNewFeature(true);
    }
    
    const handleCloseFeature = () => {
        setNewFeature(false);
    };  

    return (
        <Root>
            <ContentFeatures>
                {
                    features && features.length > 0 ?
                    features.map((feature, index) => 
                        <Feature key={`feature_${index}`}>
                            <span data-index={index}>{feature.name}</span>
                            <StyledDelete data-name={feature.name} onClick={deleteFeature} />
                        </Feature>
                    )
                    :
                    <h6>No existen características en esta categoría</h6>
                }
            </ContentFeatures>
            <button onClick={handlenewFeature}>Nueva característica</button>
            {
                newFeature && <ModalAddFeature title='AGREGAR CARACTERÍSTICA' textButton='AGREGAR' handleAction={handleConfirmAddFeature} openModal={newFeature} closeModal={handleCloseFeature} alertMessage={alertMessage} />
            }
        </Root>
    );
}

export default Features;