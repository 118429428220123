/* General imports */
import React from 'react'
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

/* Components imports */


/* Styled Components */

const Root = styled.div`
    width: 100%;
    height: 25vh;
    display: flex;
    position:relative;
    align-items: center;
    justify-content: space-around;
    font-family: 'IBM Plex Sans';
    background: ${(props) => props.invertColor ? '#2196F3' : '#001554'};
    margin: 50px 0;
    box-sizing: border-box;
    padding: 0 100px;
    margin: 30px 0;
    @media (max-width: 600px) {
        flex-direction: column;
        height: auto;
        padding: 50px 20px;
    }
`

const Feature = styled.span`
    font-size: 1.25rem;
    color: ${(props) => props.invertColor ? '#001554' : '#FFF'};;
    width: 20%;
    text-align: center;
    @media (max-width: 600px) {
        width: 75%;
        font-size: 1.5rem;
    }
`

const Divider = styled.div`
    position: relative;
    display: flex;
    background: ${(props) => props.invertColor ? '#FFF' : '#2196F3'};
    width: 1px;
    height: 60%;
    @media (max-width: 600px) {
        width: 60%;
        height: 1px;
        margin: 25px 0;
    }
`

const SpecialFeatures = (props) => {

    console.log("FEATURES")
    console.log(props.features)

    return (
        <Root invertColor={props.invertColor}>
            {
                props.features && props.features.aptitude1 &&
                <Fade bottom>
                    <Feature invertColor={props.invertColor}>{props.features.aptitude1}</Feature>
                </Fade>
            }
            {
                props.features && props.features.aptitude2 &&
                <Divider invertColor={props.invertColor} />
            }
            {
                props.features && props.features.aptitude2 &&
                <Fade bottom>
                    <Feature invertColor={props.invertColor}>{props.features.aptitude2}</Feature>
                </Fade>
            }
            {
                props.features && props.features.aptitude3 &&
                <Divider invertColor={props.invertColor} />
            }
            {
                props.features && props.features.aptitude3 &&
                <Fade bottom>
                    <Feature invertColor={props.invertColor}>{props.features.aptitude3}</Feature>
                </Fade>
            }
        </Root>
    );
}

export default SpecialFeatures;