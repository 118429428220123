/* General imports */
import React from 'react'
import { connect } from 'react-redux'
import { loginActions } from './LoginActions'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { theme, devices } from '../app/config'

/* Components imports */
import Form from '../form/Form'
import LoginBackground from '../images/LoginBackground.jpg'
import Spinner from '../spinners/LoaderSpinner'

/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled components */
const Root = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`

const LeftContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: ${(props) => props.width ? props.width : '100%'};
    height: 100%;
    background-image: url(${LoginBackground});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`

const RightContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 300px;
    width: ${(props) => props.width ? props.width : '100%'};
    height: 100%;
`
const Title = styled.div`
    width: 100%;
    color: ${theme.default.sidebarBackground};
    font-family: 'Montserrat';
    font-size: 37px;
    font-size: ${(props) => props.fontSize ? props.fontSize : '37px'};
    font-weight: 700;
    text-align: center;
    margin-top: 25px;
    margin-bottom: 25px;
`


const TextRegister = styled(Title)`
    font-weight: 400;
    font-size: ${(props) => props.fontSize ? props.fontSize : '14px'};;
    color: ${theme.default.greyText};
    margin-top: 10px;
`

const RegisterLink = styled.a`
    color: ${theme.default.redText};
    font-family: 'Montserrat';
    font-size: ${(props) => props.fontSize ? props.fontSize : '14px'};;
    font-weight: 600;
    text-decoration: none;
`

const HorizontalSeparator = styled.div`
    display: flex;
    width: ${(props) => props.width ? props.width : '400px'};
    align-items: center;
    justify-content: center;
    opacity: .3;
    &::before {
        content: '';
        position: relative;
        width: 40%;
        height: 1.5px;
        display: flex;
        margin-right: 5%;
        background-color: black;
    }
    &::after {
        content: '';
        position: relative;
        width: 40%;
        height: 1.5px;
        display: flex;
        margin-left: 5%;
        background-color: black;
    }
`



/* ----- Principal function ----- */
function Login({ history, login, loginFields, getFields }) {

    React.useEffect(() => {
        if(!loginFields){
            getFields()
        }
    }, [ loginFields, getFields ])

    const submit = (data) => {
        login(data, history, '/admin')
    }

    return(
        (!loginFields) ? 
            <Spinner spinnerType='ThreeDots' />
        : 
            <React.Fragment>
                <Desktop>
                    <Root>
                        <LeftContainer width='60%'>

                        </LeftContainer>
                        <RightContainer width='40%'>
                            <Title>Inicio de sesión</Title>
                            <Form width='400px' height='400px' fields={loginFields} submitButton='Iniciar sesión' submitFunction={submit} />
                            <HorizontalSeparator>o</HorizontalSeparator>
                            <TextRegister>¿No tienes cuenta? <RegisterLink href='/registration'>Registrarse</RegisterLink></TextRegister>
                        </RightContainer>
                    </Root>
                </Desktop>
                <Tablet>
                    <Root>
                        <RightContainer>
                            <Title>Inicio de sesión</Title>
                            <Form width='300px' height='400px' fields={loginFields} submitButton='Iniciar sesión' submitFunction={submit} />
                            <HorizontalSeparator width='300px'>o</HorizontalSeparator>
                            <TextRegister>¿No tienes cuenta? <RegisterLink href='/registration'>Registrarse</RegisterLink></TextRegister>
                        </RightContainer>
                    </Root>
                </Tablet>
                <Mobile>
                    <Root>
                        <RightContainer>
                            <Title fontSize='28px'>Inicio de sesión</Title>
                            <Form width='250px' height='350px' fields={loginFields} submitButton='Iniciar sesión' submitFunction={submit} />
                            <HorizontalSeparator width='250px'>o</HorizontalSeparator>
                            <TextRegister>¿No tienes cuenta? <RegisterLink href='/registration'>Registrarse</RegisterLink></TextRegister>
                        </RightContainer>
                    </Root>
                </Mobile>
            </React.Fragment>
    );
}

function mapState(state) {
    const { loggingIn, loginFields } = state.login;
    return { loggingIn, loginFields };
}

const actionCreators = {
    login: loginActions.login,
    logout: loginActions.logout,
    getFields: loginActions.getFields
};

export default connect(mapState, actionCreators)(Login);