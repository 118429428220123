/* General imports */
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { devices, config } from '../../app/config'
import { carouselActions } from './CarouselActions'
import { alertActions } from '../../alert/AlertActions'
import axios from 'axios'
import  { authHeader } from '../../app/auth-header';

/* Components imports */
import Table from '../../tables/ClassicTable'
import ModalCreateUpdateSlide from './ModalCreateUpdateSlide'
import ModalViewSlide from './ModalCreateViewSlide'
import Spinner from '../../spinners/LoaderSpinner'
import Input from '../../form/Input'

/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

const HeightHeader = '70px'

/* Styled components */
const ContentBox = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
`

const Header = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: ${HeightHeader};
    padding: 0 25px;
    box-sizing: border-box;
    background: #234;
`

const HeaderTitle = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 60%;
    height: 100%;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    text-shadow: 1px 1px 2px black;
`

const HeaderButtons = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 40%;
    height: 100%;
`

const BackButton = styled.button`
    margin: 0 5px;
`

const GralConfigs = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 150px;
    align-items: center;
    justify-content: flex-start;
    button {
        position: absolute;
        right: 30px;
    }
`

/* ----- Principal function ----- */
function CarouselSettings({ match, user, error, isFetching, carousels, getCarousels, createCarousel, updateCarousel, deleteCarousel, alertMessage, history }) {
    const { idPage, idSection } = match.params

    const columsTable = [
        { title: 'Slide', field: 'name' },
        { title: 'Título', field: 'title', width: 300 },
        { title: 'Orden', field: 'order' },
    ]

    let configHeader = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    const [data, setData ] = React.useState(null);

    const tableRef = React.createRef()

    const [open, setOpen] = React.useState(false);

    const [ view, setView ] = React.useState(false)

    const [ viewData, setViewData ] = React.useState(null);

    const [ edit, setEdit ] = React.useState(false)

    const [ editData, setEditData ] = React.useState(null);

    const refreshData = () => getData() && tableRef.current.onQueryChange()

    const handleView = (rowData) => {
        setViewData(rowData)
        setView(true);
    };

    const handleCloseView = () => {
        setView(false)
        refreshData()
    };

    const handleNew = () => {
        setOpen(true);
    };

    const handleConfirmNew = async(data) => {
        const newData = {
            ...data,
            idSection: idSection,
            createdUser: user.idUser
        }
        await createCarousel(newData)
        handleClose()
    }
    
    const handleClose = () => {
        setOpen(false)
        refreshData()
    };

    const handleEdit = (rowData) => {
        setEditData(rowData)
        setEdit(true)
    }

    const handleConfirmEdit = async (data) => {
        const newData = {
            ...data,
            updatedUser: user.idUser
        }
        await updateCarousel(newData)
        handleCloseEdit()
    }

    const handleCloseEdit = () => {
        setEdit(false)
        setEditData(null)
        refreshData()
    }

    const handleDelete = async (rowData) => {
        // eslint-disable-next-line no-restricted-globals
        if(confirm('¿Desea eliminar este slide?')){
            await deleteCarousel(rowData.idCarousel)
            refreshData()
        }
    }

    const getData = () => {
        getCarousels(idSection)
    }

    const updateGralSettings = () => {
        

    }

    const onBack = () => {
        history.push(config.webeditorpath+'/page/'+idPage)
    }

    const handleChange = (name, value) => {
        setData({ 
            ...data,
            [name]: value 
        });
    }

    const onApplyChanges = () => {
        const saveData = {
            data: data,
            updatedUser: user.idUser
        }
        axios.put("/api/updatedatasection/"+idSection, saveData, configHeader)
        .then(res => {
            alertMessage('Cambios aplicados');
        })
        .catch(error => {
            alert('Hubo un error...');
        })
    }

    React.useEffect(() => {
        if(!carousels){
            getCarousels(idSection)
            
        }
        if(!data){
            axios.get('/api/sections/' + idSection,configHeader)
            .then(sectionData => {
                if(parseInt(Object.keys(sectionData.data.section.data).length) > 0){
                    setData(sectionData.data.section.data);
                } else {
                    setData({
                        background: ''
                    })
                }
            })
            .catch(err => {
                alert('Hubo un error...');
            })
        }
    }, [carousels, getCarousels, idSection, data])

    return(
        <React.Fragment>
            <Header>
                {
                    <HeaderTitle>CONFIGURACIÓN CAROUSEL</HeaderTitle>
                }
                <HeaderButtons><BackButton onClick={onBack}>VOLVER</BackButton></HeaderButtons>
            </Header>
            <Desktop>
                <ContentBox>
                    {
                        data && 
                        <GralConfigs>
                            <Input name='background' data={data} width='200px' onHandleChange={handleChange} type='color' required={true} placeholder='COLOR DE FONDO' />
                            <button onClick={onApplyChanges}>APLICAR CAMBIOS</button>
                        </GralConfigs>
                    }
                    {
                        isFetching ? <Spinner spinnerType='ThreeDots' /> : 
                        <Table tableRef={tableRef}  title="SLIDERS" columnsTable={columsTable} data={carousels} getData={getData} handleView={handleView} handleNew={handleNew} handleEdit={handleEdit} handleDelete={handleDelete} />   
                    }
                    {
                        open && <ModalCreateUpdateSlide title='NUEVO SLIDE' textButton='CREAR' handleAction={handleConfirmNew} openModal={open} closeModal={handleClose} alertMessage={alertMessage} />
                    }
                    {
                        editData && <ModalCreateUpdateSlide title='EDITAR SLIDE' textButton='EDITAR' handleAction={handleConfirmEdit} rowData={editData} openModal={edit} closeModal={handleCloseEdit} />
                    }
                    {
                        view && <ModalViewSlide title='PREVIEW SLIDE' openModal={view} rowData={viewData} closeModal={handleCloseView} />
                    }
                </ContentBox>
            </Desktop>
            <Tablet>
                <ContentBox>
                    TABLET CAROUSEL SETTINGS - SECTION {idSection}
                </ContentBox>
            </Tablet>
            <Mobile>
                <ContentBox>
                    MOBILE CAROUSEL SETTINGS - SECTION {idSection}
                </ContentBox>
            </Mobile>
        </React.Fragment>
    );
}

function mapState(state) {
    const { error, isFetching, carousels } = state.carousel;
    const { user } = state.login;
    return { user, error, isFetching, carousels };
}

const actionCreators = {
    getCarousels: carouselActions.getCarousels,
    createCarousel: carouselActions.createCarousel,
    updateCarousel: carouselActions.updateCarousel,
    deleteCarousel: carouselActions.deleteCarousel,
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(CarouselSettings);