import { combineReducers } from 'redux';

import { login } from '../login/LoginReducer';
import { register } from '../register/RegisterReducer';
import { user } from '../users/UsersReducer';
import { invoice } from '../invoices/InvoicesReducer';
import { professional } from '../professionals/ProfessionalsReducer';
import { patient } from '../patients/PatientsReducer';
import { chatbot } from '../chatbot/ChatbotReducer';
import { alert } from '../alert/AlertReducer';
import { page } from '../page/PageReducer';
import { section } from '../section/SectionReducer';
import { pageEditor } from '../pageEditor/PageEditorReducer';
import { carousel } from '../sectionsTypes/Carousel/CarouselReducer';
import { account } from '../accounts/AccountsReducer';
import { category } from '../stockSystem/category/CategoryReducer';
import { product } from '../stockSystem/product/ProductReducer';
import { stockReducer } from '../stockSystem/stock/StockReducer';
import { pointsale } from '../pointSale/PointSaleReducer';
import { pointpurchase } from '../pointPurchase/PointPurchaseReducer';
import { sidebarcart } from '../SidebarCart/SidebarCartReducer';

const rootReducer = combineReducers({
  login,
  register,
  user,
  invoice,
  professional,
  patient,
  alert,
  chatbot,
  page,
  section,
  pageEditor,
  carousel,
  account,
  category,
  product,
  stock: stockReducer,
  pointsale,
  pointpurchase,
  sidebarcart
});

export default rootReducer;