/* General imports */
import React from 'react'
import { Route } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { devices } from '../../app/config'
import { categoryActions } from './CategoryActions'
import { alertActions } from '../../alert/AlertActions'

/* Components imports */
import Table from '../../tables/Table'
import Spinner from '../../spinners/LoaderSpinner'
import NewCategory from './NewCategory'
import ProfessionalsIcon from '../../icons/professionalsWhite.png'
import NoImage from '../../images/no-image.png'

/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled components */
const Root = styled.div`
    width: ${(props) => props.width ? props.width : '100%'};
    height: 100%;
    padding: ${(props) => props.padding ? props.padding : 0};
    box-sizing: border-box;
`

const Header = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 150px;
    background: linear-gradient(to left, #122230 0%, #192d3e 100%);
    align-items: flex-start;
    padding: 35px 35px 70px 35px;
    box-sizing: border-box;
`

const Title = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img {
        position: relative;
        display: flex;
        width: 30px;
        height: 30px;
    }
    h6 {
        position: relative;
        display: flex;
        margin: 0 20px;
        color: white;
        font-size: 1.5rem;
        font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
        font-weight: 400;
    }
`

const Content = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: calc(100% - 150px);
    padding: 0 35px;
    box-sizing: border-box;
    top: -64px;
`

/* ----- Principal function ----- */
function Category({ user, categories, getCategories, deleteCategory, isFetching, alertMessage, history }) {

    const columsTable = [
        { title: 'Image', field: 'img', render: rowData => <img src={rowData.img ? rowData.img : NoImage } alt='SinImagen' style={{width: 40, height:40, borderRadius: '50%', boxShadow: '1px 1px 2px rgba(0,0,0,0.5)'}}/>, width: 50, align: 'center' },
        { title: 'ID', field: 'idCategory', width: 50 },
        { title: 'Orden', field: 'order', width: 50 },
        { 
            title: 'Nombre', 
            field: 'name', 
            width: 300
        },
        { 
            title: 'Descripción', 
            field: 'description'
        },
        { title: 'Características', field: 'features', hidden: true },
        { title: 'Superior', field: 'parent_idCategory', width: 50 },
    ]

    const tableRef = React.createRef()

    const [ editData, setEditData ] = React.useState(null);

    const handleNew = () => {
        history.push('/admin/stocksystem/category/new')
    };

    const handleEdit = (rowData) => {
        setEditData(rowData)
        history.push('/admin/stocksystem/category/update/'+rowData.idCategory)
    }

    const handleDelete = (rowData) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("¿Desea eliminar la categoría "+rowData.idCategory+"?")) {
            deleteCategory(rowData.idCategory);
        }
    }

    React.useEffect(() => {
        if(!categories){
            getCategories()
        }
    }, [getCategories, categories])

    const RouteComponent = ({ component: Component, rowData, ...rest }) => (
        <Route
            {...rest}
            render={(props) => {
                return <Component rowData={rowData} {...props} />
            }}
        />
    )

    return(
        <React.Fragment>
            <Desktop>
                <Root>
                    <Header>
                        <Title>
                            <img src={ProfessionalsIcon} alt='Icon' />
                            <h6>Categorías</h6>
                        </Title>
                    </Header>
                    <Content>
                        <Route path={'/admin/stocksystem/category'} exact={true} >
                            {
                                isFetching ? <Spinner spinnerType='ThreeDots' /> : 
                                <Table tableRef={tableRef} autoExport={true} hiddenView={true}  title="LISTADO DE CATEGORÍAS" columnsTable={columsTable} data={categories} getData={getCategories} handleNew={handleNew} handleEdit={handleEdit} handleDelete={handleDelete} />   
                            }
                        </Route>
                        <Route path={'/admin/stocksystem/category/new'} component={NewCategory} />
                        <RouteComponent path={'/admin/stocksystem/category/update'} rowData={editData} component={NewCategory} />
                    </Content>
                </Root>
            </Desktop>
            <Tablet>VERSIÓN TABLET EN CONSTRUCCIÓN</Tablet>
            <Mobile>VERSIÓN MOBILE EN CONSTRUCCIÓN</Mobile>
        </React.Fragment>
    );
}

function mapState(state) {
    const { error, isFetching, categories } = state.category;
    const { user } = state.login;
    return { user, error, isFetching, categories};
}

const actionCreators = {
    getFields: categoryActions.getFields,
    getCategories: categoryActions.getCategories,
    deleteCategory: categoryActions.deleteCategory,
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(Category);