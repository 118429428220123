import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import  { authHeader } from '../app/auth-header';
import AsyncCreatableSelect from 'react-select/async-creatable';

const Root = styled(AsyncCreatableSelect)`
    width: ${(props) => props.width ? props.width : '100%'};
    margin: ${(props) => props.margin ? props.margin : 0};
    font-size: 12px;
`

const TagSelect = ({handleChange, value, url, limit, isMulti, cacheOptions, margin, width, placeholder}) => {

    // Promesa que retorna los valores correpondientes, tienen que venir con formato {value: "valor", label: "etiqueta"}
    const promiseOptions = inputValue =>
        new Promise(resolve => {
            let configHeader = {
                headers: { ...authHeader(), 'Content-Type': 'application/json' }
            }
            if(inputValue.length > 1){
                axios.get(`${url}?search=${inputValue}&limit=${limit}`,configHeader)
                .then(tagsData => {
                    resolve(tagsData.data.tagsList)
                })
                .catch(err => {
                    console.log(err)
                })
            } else {
                resolve([])
            }
        });

    return (
        <Root
            isMulti={isMulti}
            cacheOptions={cacheOptions}
            onChange={handleChange}
            loadOptions={promiseOptions}
            value={value}
            margin={margin}
            width={width}
            placeholder={placeholder}
        />
    );
}

export default TagSelect;