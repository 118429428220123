/* General imports */
import React from 'react'
import styled from 'styled-components'

/* Components imports */
import { Dot } from 'pure-react-carousel';


/* Styled components */
const StyledDot = styled(Dot)`
    width: 100%;
    height: calc(100% * 0.25);
    outline: none;
    box-sizing: border-box;
    ${(props) => props.active ? 'padding: 5px' : 'padding: 0'};
`

const ImageDot = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    box-sizing: border-box;
    ${(props) => props.active ? 'border: black 1px solid' : ''};
    ${(props) => props.active ? 'box-shadow: 1px 1px 5px black' : ''};
`


/* ----- Principal function ----- */
function DotCarousel({index, currentSlide, handleClick, src, alt }) {

    const [ active, setActive ] = React.useState(index === currentSlide)

    React.useEffect(() => {
        setActive(parseInt(index) === parseInt(currentSlide))
    }, [index, currentSlide])

    return(
        <StyledDot slide={currentSlide} active={active} > <ImageDot data-num={index} onClick={handleClick} src={src} alt={alt} active={active} /> </StyledDot>
    )
}

export default DotCarousel;