/* General imports */
import React from 'react'
import styled from 'styled-components'
import axios from 'axios'

/* Components imports */
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { theme } from '../../app/config'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import NoImage from '../../images/no-image.png'

/* Styled components */
const ModalStyled = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 10px 10px!important;
    z-index: 99999;
    outline: none;
`

const ModalContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    color: black;
    box-sizing: border-box;
    border-radius: 10px 10px 10px 10px;
    width: 450px;
    min-height: 550px;
    align-items: center;
    justify-content: center;
    overflow: auto;
    outline: none;
`

const ModalTitle = styled.div`
    position: absolute;
    display: flex;
    top: 0;
    width: 100%;
    height: 70px;
    color: white;
    font-size: 24px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    align-items: center;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
    box-sizing: border-box;
    background: linear-gradient(to left,#122230 0%,#192d3e 100%);
`

const ModalBody = styled.form`
    position: absolute;
    display: flex;
    top: 70px;
    width: 100%;
    height: calc(100% - 70px);
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    box-sizing: border-box;
    flex-direction: column;
`

const Inputbox = styled.div`
    width: 90%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 20px;
    margin: 10px 0;
    box-sizing: border-box;
    height: 48px;
    border-bottom: 1px solid black;
    ${(props) => props.error ? 'border-color: red;' : ''};
    label {
        position: absolute;
        top: ${(props) => props.effect ? '-10px' : '18px' };
        left: 0;
        color: #ccc;
        transition: .5s;
        pointer-events: none;
        font-size: ${(props) => props.effect ? '16px' : '20px'};
        height: 16px;
    }

    input {
        border: none;
        outline: none;
        background: none;
        color: black;
        font-size: 18px;
        width: calc(100% - 35px);
        padding-bottom: 12px;
        margin-top: 16px;
        box-sizing: border-box;
        height: 32px;
    }

    input:focus + label {
        top: -10px;
        color: black;
        font-size: 16px;
    }

    select:focus + label {
        top: -10px;
        color: black;
        font-size: 16px;
    }
`

const Select = styled.select`
    background: transparent;
    border: none;
    font-size: 18px;
    height: 30px;
    width: 100%;
    padding-bottom: 12px;
    margin-top: 16px;
    height: 32px;
`

const Option = styled.option`
    font-size: 18px;
`

const ImageContainer = styled.div`
    position: relative;
    display: flex;
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
    img {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`

const UploadImage = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    box-sizing: border-box;
`

const StyledButton = styled.button`
    width: calc(${(props) => props.columWidth ? props.columWidth : '100%'} * 0.75);
    height: 44px;
    background-color: ${theme.default.primaryButtonBg};
    color: ${theme.default.primaryButtonText};
    font-family: 'Montserrat';
    font-size: 18px;
    font-weight: 600;
    border-radius: 80px;
    border-color: transparent;
    border-style: solid;
    margin-top: 25px;
    margin-bottom: 25px;
`

/* ----- Principal function ----- */
function ModalNewFeature({ title, textButton, openModal, closeModal, handleAction, rowData }) {

    const [data, setData ] = React.useState(rowData ? rowData : {
        field: '',
        name: '',
        type: ''
    })

    const [ image, setImage ] = React.useState(rowData ? rowData.img : '')

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        })
    }

    const onFileChange = (e) => {
        setImage(e.target.files[0])
    }

    const handleFileUpload = (e) => {
        if(image !== '' ){
            if(!data.img){
                const formData = new FormData()
                formData.append('image', image)
                axios.post("/api/images/upload", formData, {
                }).then(res => {
                    const url_img = '/uploads/'+res.data.filename
                    setData({
                        ...data,
                        img: url_img,
                        img_error:false
                    })
                })
                .catch(error => {
                    alert('Error al cargar la imagen. Sólo se soporta hasta 2MB y los tipos jpeg-jpg-png-gif-webp.')
                })
            } else {
                // eslint-disable-next-line no-restricted-globals
                if(confirm('¿Desea subir esta nueva imagen y reemplazar la anterior?')){
                    const formData = new FormData()
                    formData.append('image', image)
                    axios.post("/api/images/upload", formData, {
                    }).then(res => {
                        const url_img = '/uploads/'+res.data.filename
                        setData({
                            ...data,
                            img: url_img,
                            img_error:false
                        })    
                    })
                    .catch(error => {
                        alert('Error al cargar la imagen. Sólo se soporta hasta 2MB y los tipos jpeg-jpg-png-gif-webp.')
                    })  
                }
            }
        }else{
            alert("Debe selecionar una imagen...")
        }
    }

    const submit = (e) => {
        e.preventDefault();
        handleAction(data)
    }

    return(
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openModal}
            onClose={closeModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={openModal}>
                <ModalContent>
                    <ModalTitle>
                        {title}
                    </ModalTitle>
                    <ModalBody onSubmit={submit}>
                        <Inputbox  type='text' effect={ data.field !== '' ? true : false} >
                            <input id='field' required={true} autoFocus={true} readOnly={rowData ? true : false} name='field' type='text' value={data.field}  onChange={handleChange} />
                            <label>Campo*</label>
                        </Inputbox>
                        <Inputbox  type='text' effect={ data.name !== '' ? true : false} >
                            <input id='name' required={true} name='name' type='text' value={data.name}  onChange={handleChange} />
                            <label>Nombre*</label>
                        </Inputbox>
                        <Inputbox  type='select' effect={ data.type !== '' ? true : false} >
                            <Select id='type' value={data.type} required={true} name='type' onChange={handleChange}>
                                <Option value="" hidden></Option>
                                <Option value='text'>Texto</Option>
                                <Option value='number'>Número</Option>
                                <Option value='mm'>Milímetros</Option>
                                <Option value='cm'>Centímetros</Option>
                                <Option value='m'>Metros</Option>
                                <Option value='m'>Metros</Option>
                                <Option value='m2'>Metros cuadrados</Option>
                                <Option value='m3'>Metros cúbicos</Option>
                                <Option value='color'>Color</Option>
                            </Select>
                            <label>Tipo*</label>
                        </Inputbox>
                        <ImageContainer>
                            <img src={data.img ? data.img : NoImage} alt="Imagen subida" />
                        </ImageContainer>
                        <UploadImage>
                            <input style={{fontSize: '0.75rem', width: '300px', color: data['img_error'] ? 'red' : 'black'}} id="image" name="image" type="file" onChange={onFileChange} error={data['img_error']} /><CloudUploadIcon onClick={handleFileUpload} />
                        </UploadImage>
                        <StyledButton > {textButton} </StyledButton>
                    </ModalBody>
                </ModalContent>
            </Fade>
        </ModalStyled>
    );
}

export default ModalNewFeature;