/* General imports */
import React from 'react'
import styled from 'styled-components';
import axios from 'axios'
import  { authHeader } from '../../app/auth-header';

/* Components imports */
import Fade from 'react-reveal/Fade';
import NoImage from '../../images/no-image.png'
import Background from '../../images/Backgorund_Bluedots_TemplateDestacado.png'
import Deco1 from '../../images/Nosotros_Img muestra1.jpg'
import Deco2 from '../../images/Nosotros_Img muestra2.jpg'
import Deco3 from '../../images/Nosotros_Img muestra3.jpg'
import IconCV from '../../icons/RRHH.png'

/* Styled Components */

const Root = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    position:relative;
    align-items: center;
    justify-content: flex-start;
    font-family: 'IBM Plex Sans';
    padding: 50px 100px;
    box-sizing: border-box;
    background: url(${Background});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 100px 0;
    @media (max-width: 600px) {
        padding: 50px 20px;
    }
`

const Title = styled.h2`
    width: 100%;
    font-family: 'IBM Plex Sans';
    font-size: 2.5rem;
    color: #2196F3;
`

const Team = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const Member = styled.div`
    width: 180px;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`

const MemberName = styled.span`
    width: 100%;
    color: #FFF;
    font-size: 1rem;
    font-weight: 500;
    margin: 0.5rem 0;
`

const MemberRole = styled.span`
    width: 100%;
    color: #2196F3;
    font-size: 0.875rem;
`

const MemberImage = styled.img`
    width: 180px;
    height: 180px;
    object-fit: contain;
    object-position: center;
`

const ContentCV = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 600px) {
        flex-direction: column;
    }
`

const AddCV = styled.a`
    width: 185px;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background: #2196F3;
    box-sizing: border-box;
    padding: 20px;
    cursor: pointer;
    user-select: none;
    text-decoration: none;
    margin-right: 20px;
    img {
        width: 50px;
        height: 50px;
    }
    p {
        margin: 1rem 0;
        font-size: 1.15rem;
        font-weight: 700;
        color: #FFF;
    }
    span {
        font-size: 1rem;
        color: #001554;
    }
    @media (max-width: 600px) {
        width: 100%;
        margin: 0;
    }
`

const CVDecorations = styled.div`
    @media (max-width: 600px) {
        display: flex;
        flex-direction: column;
    }
`

const Decoration = styled.img`
    width: 310px;
    height: 250px;
    object-fit: cover;
    object-position: center;
`

function OurTeam({idsection}) {

    const [data, setData] = React.useState(null);

    const team = [
        {
            firstname: "Nombre",
            lastname: "Apellido",
            role: "Puesto",
            img: null
        },
        {
            firstname: "Nombre",
            lastname: "Apellido",
            role: "Puesto",
            img: null
        },
        {
            firstname: "Nombre",
            lastname: "Apellido",
            role: "Puesto",
            img: null
        },
        {
            firstname: "Nombre",
            lastname: "Apellido",
            role: "Puesto",
            img: null
        },
        {
            firstname: "Nombre",
            lastname: "Apellido",
            role: "Puesto",
            img: null
        },
        {
            firstname: "Nombre",
            lastname: "Apellido",
            role: "Puesto",
            img: null
        }
    ]

    React.useEffect(() => {
        if(!data){
            let configHeader = {
                headers: { ...authHeader(), 'Content-Type': 'application/json' }
            }
            axios.get('/api/sections/' + idsection,configHeader)
            .then(sectionData => {
                setData(sectionData.data.section.data);
            })
            .catch(err => {
                alert('Hubo un error...');
            })
        }
    }, [data, idsection])

    return (
        data && 
        <Root>
            {/* <Title>Nuestro equipo</Title>
            <Team>
                {
                    team.map((member, index) => 
                    <Fade bottom>
                        <Member key={`member_${index}`}>
                            <MemberImage src={member.img ? member.img : NoImage} alt={`Member image ${index}`} />
                            <MemberName>{`${member.firstname} ${member.lastname}`}</MemberName>
                            <MemberRole>{member.role}</MemberRole>
                        </Member>
                    </Fade>
                    )
                }
            </Team> */}
            <ContentCV>
                <Fade left>
                    <AddCV href={data ? data.link : "#"} target="_blank">
                        <img src={IconCV} alt="cv icon" />
                        <p>¿Querés trabajar con nosotros?</p>
                        <span>Dejanos tu CV</span>
                    </AddCV>
                </Fade>
                <Fade right>
                    <CVDecorations>
                        <Decoration src={Deco1} alt="decoration 1" />
                        <Decoration src={Deco2} alt="decoration 2" />
                        <Decoration src={Deco3} alt="decoration 3" />
                    </CVDecorations>
                </Fade>
            </ContentCV>
        </Root>
    )
}

export default OurTeam;