import { patientsConstants } from './PatientsConstants';
import { professionalsConstants } from '../professionals/ProfessionalsConstants';
import { alertActions } from '../alert/AlertActions';
import axios from 'axios';
import  { authHeader } from '../app/auth-header';
import PersonIcon from '../icons/userCircle.svg';
import LockIcon from '../icons/lock.svg';

export const patientsActions = {
    createPatient,
    updatePatient,
    getPatients,
    deletePatient,
    getFields,
    getUpdateFields,
    assignProfessional,
    unassignProfessional,
    getProfessionalList,
    getActivitiesList,
    getAssignProfessionals
};

function createPatient(patient) {
    return dispatch => {
        axios
        .post( '/api/users/register' , {
            firstname: patient.firstname,
            lastname: patient.lastname,
            documentType: patient.documentType,
            documentNumber: patient.documentNumber,
            birthday: patient.birthday || null,
            tel: patient.tel,
            email: patient.email,
            username: patient.username,
            password: patient.password,
            createUser: patient.createUser,
            enabled: patient.enabled,
            idRole: patientsConstants.IDROLE,
            img: patient.img || null,
            roleData: {
                diagnosis: patient.diagnosis || null,
                socialWork: patient.socialWork || null,
                orderNumber: patient.orderNumber || null,
                affiliateNumber: patient.affiliateNumber || null,
                admisionDate: patient.admisionDate || null,
                dueDate: patient.dueDate || null,
                renovationDate: patient.renovationDate || null,
                egressDate: patient.egressDate || null,
                assignedProfessionals: []
            },
            invoiceData: patient.invoiceData || null
            })
        .then(response => {
            dispatch(alertActions.success('Paciente creado'));
        })
        .catch(error => {
            dispatch(alertActions.error(error.toString()));
        })
    };
}

function updatePatient(idUser, patient) {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return dispatch => {
        axios
        .put( '/api/users/' + idUser + '/' + patientsConstants.IDROLE, {
            firstname: patient.firstname,
            lastname: patient.lastname,
            documentType: patient.documentType,
            documentNumber: patient.documentNumber,
            birthday: patient.birthday  || null,
            tel: patient.tel,
            email: patient.email,
            username: patient.username,
            password: patient.password,
            updatedUser: patient.updatedUser,
            enabled: patient.enabled,
            img: patient.img,
            roleData: {
                diagnosis: patient.diagnosis,
                socialWork: patient.socialWork,
                orderNumber: patient.orderNumber,
                affiliateNumber: patient.affiliateNumber,
                admisionDate: patient.admisionDate,
                dueDate: patient.dueDate,
                renovationDate: patient.renovationDate,
                egressDate: patient.egressDate,
                assignedProfessionals: patient.assignedProfessionals
            },
            invoiceData: patient.invoiceData
            }, config)
        .then(response => {
            dispatch(alertActions.success('Paciente actualizado'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}


function getPatients() {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return dispatch => {
        const idRole = patientsConstants.IDROLE;
        dispatch({ type: patientsConstants.FETCHING });
        axios.get('/api/users/role/'+idRole,config)
        .then(patientsData => {
            const patients = patientsData.data.users
            patients.forEach(element => {
                if(element.roles[0].userRole.roleData){
                    element['diagnosis'] = element.roles[0].userRole.roleData.diagnosis;
                    element['socialWork'] = element.roles[0].userRole.roleData.socialWork;
                    element['orderNumber'] = element.roles[0].userRole.roleData.orderNumber;
                    element['affiliateNumber'] = element.roles[0].userRole.roleData.affiliateNumber;
                    element['admisionDate'] = element.roles[0].userRole.roleData.admisionDate;
                    element['dueDate'] = element.roles[0].userRole.roleData.dueDate;
                    element['renovationDate'] = element.roles[0].userRole.roleData.renovationDate;
                    element['egressDate'] = element.roles[0].userRole.roleData.egressDate;
                }
                if(element.roles[0].userRole.invoiceData){
                    element['invoiceData'] = element.roles[0].userRole.invoiceData
                } else {
                    element['invoiceData'] = null
                }
                if(element.address[0]){
                    element['street'] = element.address[0].street;
                    element['number'] = element.address[0].number;
                }
            });
            dispatch({ type: patientsConstants.SUCCESS, patients });
        })
        .catch(err => {
            dispatch({ type: patientsConstants.ERROR });
            dispatch(alertActions.error('Hubo un error...'));
        })
        
    }
}

function getAssignProfessionals(idPatient) {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return dispatch => {
        axios
        .get('/api/assignprofessionals/'+idPatient, config)
        .then(response => {
            const assignProfessionals = response.data.assignProfessionals
            dispatch({ type: patientsConstants.SUCCESS_ASSIGNPROFESSIONALS, assignProfessionals });
        })
        .catch(error => {
            dispatch(alertActions.error(error.toString()));
        })
    };
}

function assignProfessional(assign) {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return dispatch => {
        axios
        .post('/api/assignprofessionals', {
                idPatient: assign.idPatient,
                idProfessional: assign.idProfessional,
                activity: assign.activity,
                activityName: assign.activityName,
                createdUser: assign.idUser
            }, config)
        .then(response => {
            dispatch(alertActions.success('Asignación realizada'));
        })
        .catch(error => {
            dispatch(alertActions.error(error.toString()));
        })
    };
}

function unassignProfessional(assign) {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return dispatch => {
        axios
        .delete( '/api/assignprofessionals/' + assign.idPatient + '/' + assign.idProfessional + '/' + assign.activity, config)
        .then(response => {
            dispatch(alertActions.success('Asignación eliminada'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}

function deletePatient(id) {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return dispatch => {
        axios
        .delete( '/api/users/' + id, config)
        .then(response => {
            dispatch(alertActions.success('Paciente eliminado'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}


function getFields() {

    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    return dispatch => {
        dispatch({ type: patientsConstants.FETCHING_FORMFIELDS });
        axios.get('/api/users/role/'+ professionalsConstants.IDROLE, config)
        .then(professionalsData => {
            const professionals = professionalsData.data.users
            let professionalsList = []
            professionals.forEach(option => {
                professionalsList.push({
                    name: option.firstname + " " + option.lastname + "(" + option.roles[0].userRole.roleData.specialization + ")",
                    value: option.idUser
                })
            });

            axios.get('/api/registerFields', config)
            .then(response => {
                const patientFields = response.data.registerFields.filter(element => element.name !== 'idRole');
                patientFields.forEach((element, index) => {
                    if(element.icon === 'PersonIcon'){
                        element.icon = PersonIcon
                    }
                    if(element.icon === 'LockIcon'){
                        element.icon = LockIcon
                    }
                });
                patientFields.push(
                    {
                        name: 'diagnosis',
                        type: 'text',
                        placeholder: 'Diagnóstico',
                        required: false
                    },
                    {
                        name: 'socialWork',
                        type: 'text',
                        placeholder: 'Obra Social',
                        required: false
                    },
                    {
                        name: 'orderNumber',
                        type: 'text',
                        placeholder: 'Nro. de Orden',
                        required: false
                    },
                    {
                        name: 'affiliateNumber',
                        type: 'text',
                        placeholder: 'Nro. de Afiliado',
                        required: false
                    },
                    {
                        name: 'admisionDate',
                        type: 'date',
                        placeholder: 'Ingreso',
                        required: true
                    },
                    {
                        name: 'dueDate',
                        type: 'date',
                        placeholder: 'Vencimiento',
                        required: false
                    },
                    {
                        name: 'renovationDate',
                        type: 'date',
                        placeholder: 'Renovación',
                        required: false
                    },
                    {
                        name: 'egressDate',
                        type: 'date',
                        placeholder: 'Egreso',
                        required: false
                    },
/*                     {
                        name: 'doctor',
                        type: 'select',
                        placeholder: 'Médico clínico',
                        options: professionalsList,
                        required: false
                    },
                    {
                        name: 'kinesiologist',
                        type: 'select',
                        placeholder: 'Kinesiólogo/a',
                        options: professionalsList,
                        required: false
                    },
                    {
                        name: 'occupationalTherapy',
                        type: 'select',
                        placeholder: 'Terapia Ocupacional',
                        options: professionalsList,
                        required: false
                    },
                    {
                        name: 'speechTherapist',
                        type: 'select',
                        placeholder: 'Fonoaudiologo/a',
                        options: professionalsList,
                        required: false
                    },
                    {
                        name: 'nurse',
                        type: 'select',
                        placeholder: 'Enfermero/a',
                        options: professionalsList,
                        required: false
                    },
                    {
                        name: 'caretaker',
                        type: 'select',
                        placeholder: 'Cuidador/a',
                        options: professionalsList,
                        required: false
                    } */
                )
                dispatch({ type: patientsConstants.SUCCESS_FORMFIELDS, patientFields });
            })
            .catch(error => {
                dispatch(alertActions.error(error));
            })
        })
        .catch(err => {
            dispatch({ type: professionalsConstants.ERROR });
            dispatch(alertActions.error('Hubo un error...'));
        })
        
    }
}

function getProfessionalList() {

    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    return dispatch => {
        axios.get('/api/users/role/'+ professionalsConstants.IDROLE, config)
        .then(professionalsData => {
            const professionals = professionalsData.data.users
            let professionalsList = []
            professionals.forEach(option => {
                professionalsList.push({
                    name: option.firstname + " " + option.lastname + "(" + option.roles[0].userRole.roleData.specialization + ")",
                    value: option.idUser
                })
            });
            dispatch({ type: patientsConstants.SUCCESS_PROFESSIONALSLIST, professionalsList });
        })
        .catch(err => {
            dispatch({ type: professionalsConstants.ERROR });
        })
        
    }
}

function getActivitiesList() {

    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    return dispatch => {
        axios.get('/api/valuelist/group/professionalActivity', config)
        .then(activitiesData => {
            const activities = activitiesData.data.valueslist
            let activitiesList = []
            activities.forEach(option => {
                activitiesList.push({
                    name: option.value,
                    value: option.idValueList
                })
            });
            dispatch({ type: patientsConstants.SUCCESS_ACTIVITIESLIST, activitiesList });
        })
        .catch(err => {
            dispatch({ type: professionalsConstants.ERROR });
        })
        
    }
}

function getUpdateFields() {

    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    return dispatch => {
        dispatch({ type: patientsConstants.FETCHING_FORMFIELDS });
        axios.get('/api/registerFields', config)
        .then(response => {
            const patientUpdateFields = response.data.registerFields.filter(element => ((element.name !== 'idRole') && (element.name !== 'username') && (element.type !== 'password')));
            patientUpdateFields.push(
                {
                    name: 'diagnosis',
                    type: 'text',
                    placeholder: 'Diagnóstico',
                    required: false
                },
                {
                    name: 'socialWork',
                    type: 'text',
                    placeholder: 'Obra Social',
                    required: false
                },
                {
                    name: 'orderNumber',
                    type: 'text',
                    placeholder: 'Nro. de Orden',
                    required: false
                },
                {
                    name: 'affiliateNumber',
                    type: 'text',
                    placeholder: 'Nro. de Afiliado',
                    required: false
                },
                {
                    name: 'admisionDate',
                    type: 'date',
                    placeholder: 'Ingreso',
                    required: true
                },
                {
                    name: 'dueDate',
                    type: 'date',
                    placeholder: 'Vencimiento',
                    required: false
                },
                {
                    name: 'renovationDate',
                    type: 'date',
                    placeholder: 'Renovación',
                    required: false
                },
                {
                    name: 'egressDate',
                    type: 'date',
                    placeholder: 'Egreso',
                    required: false
                },
/*                 {
                    name: 'doctor',
                    type: 'text',
                    placeholder: 'Doctor',
                    required: false
                },
                {
                    name: 'kinesiologist',
                    type: 'text',
                    placeholder: 'Kinesiólogo/a',
                    required: false
                },
                {
                    name: 'occupationalTherapy',
                    type: 'text',
                    placeholder: 'Terapia Ocupacional',
                    required: false
                },
                {
                    name: 'speechTherapist',
                    type: 'text',
                    placeholder: 'Fonoaudiologo/a',
                    required: false
                },
                {
                    name: 'nurse',
                    type: 'text',
                    placeholder: 'Enfermero/a',
                    required: false
                },
                {
                    name: 'caretaker',
                    type: 'text',
                    placeholder: 'Cuidador/a',
                    required: false
                } */
            )

            dispatch({ type: patientsConstants.SUCCESS_UPDATEFORMFIELDS, patientUpdateFields });
        })
        .catch(error => {
            dispatch(alertActions.error(error));
        })
    }
}