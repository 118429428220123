/* General imports */
import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { theme, devices, layoutConfig } from '../app/config'
import { AuthNameAccess } from '../utils/AuthNameAccess' 

/* Components imports */
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import UserCircle from '../icons/userCircle.svg'
import Logout from '../icons/logout.svg'



/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled components */
const MenuOpen = styled(MenuIcon)`
    position: ${(props) => props.mobile ? 'fixed' : 'absolute' };
    cursor: pointer;
    border-radius: 3px;
    z-index: 100;
    ${(props) => props.mobile ? '' : 'right: 20px' };
    ${(props) => props.mobile ? 'left: 20px' : '' };
    opacity: ${(props) => props.open ? 0 : 100};
    pointer-events: ${(props) => props.open ? 'none' : 'initial'};
    top: ${(props) => props.mobile ? '20px' : '50%' };
    ${(props) => props.mobile ? '' : 'transform: translateY(-50%)' };
    font-size: 25px;
    color: ${(props) => props.mobile ? 'black' : 'white' };
    transition: all 200ms ease;
`

const MenuClose = styled(CloseIcon)`
    position: absolute;
    cursor: pointer;
    border-radius: 3px;
    z-index: 100;
    right: 20px;
    opacity: ${(props) => props.open ? 100 : 0};
    top: 50%;
    transform: translateY(-50%);
    color: white;
    transition: all 200ms ease;
    font-size: 25px;
`

const SidebarMenu = styled.div`
    position: fixed;
    left: 0%;
    width: ${(props) => props.open ? '250px' : props.mobile ? '0' : '65px'};
    height: 100%;
    background-color: white;
    transition: all 200ms ease;
    z-index: 99;
    overflow: hidden;
    box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
    & > header {
        position: relative;
        display: flex;
        padding-left: 20px;
        height: ${layoutConfig.header};
        align-items: center;
        box-sizing: border-box;
        background-color: ${theme.default.sidebarHeaderBackground};
        user-select: none;
        h4 {
            font-size: 16px;
            color: ${theme.default.whiteText};
            text-align: left;
            text-shadow: 1px 1px 2px white;
            display: ${(props) => props.open ? 'flex' : 'none'};
        }
    }
`

const ContentMenu = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
    height: calc(100% - ${layoutConfig.header});
    header {
        position: relative;
        display: flex;
        box-sizing: border-box;
        width: 100%;
        height: 120px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 1rem 0 ${layoutConfig.header} 0;
        margin-bottom: 50px;
        color: ${theme.default.whiteText};
        background-color: ${theme.default.sidebarHeaderBackground};
        h4 {
            position: relative;
            display: flex;
            opacity: ${(props) => props.open ? 1 : 0};
            /* display: ${(props) => props.open ? 'flex' : 'none'}; */
            font-size: 1rem;
            margin: 0;
            font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
            font-weight: 400;
            line-height: 1.5;
        }
        p {
            position: relative;
            display: flex;
            opacity: ${(props) => props.open ? .5 : 0};
            /* display: ${(props) => props.open ? 'flex' : 'none'}; */
            font-size: .8rem;
            margin: 0.2rem 0 0 0;
        }
    }
    ul {
        margin: 0;
        padding: ${(props) => props.open ? '0 20px 0 0' : 0};
        display: block;
        box-sizing: border-box;
        /* flex-direction: column;
        align-items: center;
        justify-content: center; */
        height: calc(100% - 120px);
        overflow-y: auto;
        h3 {
            display: ${(props) => props.open ? 'flex' : 'none'};
            margin: 10px 0 10px 0;
            width: 100%;
            padding: 0 0 0 24px;
            text-transform: uppercase;
            cursor: default;
            color: rgba(0, 0, 0, 0.54);
            font-size: 15px;
            box-sizing: border-box;
        }
        hr {
            display: ${(props) => props.open ? 'none' : 'flex'};
            width: 50%;
            cursor: default;
            opacity: .5;
        }
    }

    a{
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: ${(props) => props.open ? 'flex-start' : 'center'};
        height: 40px;
        width: ${(props) => props.open ? '100%' : '40px'};
        line-height: 40px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.87);
        box-sizing: border-box;
        padding: ${(props) => props.open ? '0 12px 0 24px' : 0};
        border-radius: ${(props) => props.open ? '0px 20px 20px 0px' : '50%'};
        transition: all 200ms ease;
        margin: auto;
        span {
            font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
            font-weight: 400;
            line-height: 1.5;
            display: ${(props) => props.open ? 'flex' : 'none'};
        }
        img {
            width: 20px;
            height: 20px;
            margin-right: ${(props) => props.open ? '16px' : 0};
        }
    }

    a:hover {
        background-color: rgba(0,0,0,.025);
    }

    a:active {
        background-color: blue;
    }

    li {
        list-style: none;
        &:hover {
            a {
                padding-left: 50px;
            }
        }
    }

    svg {
        margin-right: 16px;
    }
`

const Avatar = styled.div`
    position: absolute;
    bottom: ${(props) => props.open ? '-40px' : '50%'};
    display: flex;
    width: ${(props) => props.open ? '85px' : '40px'};
    height: ${(props) => props.open ? '85px' : '40px'};
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.open ? 'white' : 'transparent'};
    box-sizing: border-box;
    z-index: 99;
    border-radius: 50%;
    transition: all 200ms ease;
    img {
        width: ${(props) => props.open ? '70px' : '40px'};
        height: ${(props) => props.open ? '70px' : '40px'};
        border-radius: 50%;
        transition: all 200ms ease;
    }
`

const ShadowMenu = styled.div`
    position: fixed;
    right: ${(props) => props.open ? 0 : '-250px' };
    width: calc(100% - 250px);
    height: 100%;
    background-color: black;
    opacity: ${(props) => props.open ? .8 : 0 };
    transition: all 200ms ease;
    z-index: 100;
`

/* ----- Principal function ----- */
function Sidebar(props) {

    const handleClose = () => {
        props.handleClose()
    }

    return(
        <React.Fragment>
            <Desktop>
                <React.Fragment>
                    <SidebarMenu open={props.open}>
                        <header>
                            <h4>{props.title}</h4>
                            <MenuOpen open={props.open} left='40px' onClick={props.handleAction}/>
                            <MenuClose open={props.open} onClick={props.handleAction}/>
                        </header>
                        <ContentMenu open={props.open}>
                            <header>
                                <h4> {props.user.username} </h4>
                                <p> {props.user.email} </p>
                                <Avatar open={props.open}>
                                    <img src={props.user.img ? props.user.img : UserCircle} alt='Profile' />
                                </Avatar>
                            </header>
                            <ul>
                                {
                                    props.menuItems.map( (item, index) => 
                                        {   
                                            if(!AuthNameAccess(props.user, item.name_access)){
                                                return null
                                            } else {
                                                return (
                                                
                                                    <React.Fragment key={`Menuitem${index}`}>
                                                        <h3>{item.title}</h3>
                                                        <hr></hr>
                                                        {
                                                            item.menus.map((subItem, subIndex) => {
                                                                if(!AuthNameAccess(props.user, subItem.name_access)){
                                                                    return null
                                                                } else {
                                                                    return (
                                                                        <NavLink key={ subIndex } exact to={ subItem.link } activeStyle={{ background: '#61dafb' }} >
                                                                            <img src={subItem.icon} alt='Dashboard Icon' />
                                                                            <span>{subItem.name}</span>
                                                                        </NavLink>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </React.Fragment>
                                                )
                                            }
                                        }
                                    )
                                }
                                <hr></hr>
                                <NavLink exact to='/login' activeStyle={{ background: '#61dafb' }} onClick={props.logout} >
                                    <img src={Logout} alt='Log out' />
                                    <span>Cerrar Sesión</span>
                                </NavLink>
                            </ul>
                        </ContentMenu>
                    </SidebarMenu>
                </React.Fragment>
            </Desktop>
            <Tablet>
                <React.Fragment>
                    <MenuOpen open={props.open} left='35px' onClick={props.handleAction}/>
                    <MenuClose open={props.open} onClick={props.handleAction}/>
                    <SidebarMenu open={props.open}>
                    <header>
                            <h4>{props.title}</h4>
                            <MenuOpen open={props.open} left='40px' onClick={props.handleAction}/>
                            <MenuClose open={props.open} onClick={props.handleAction}/>
                        </header>
                        <ContentMenu open={props.open}>
                            <header>
                                <h4> Nombre de usuario</h4>
                                <p>Correo electrónico</p>
                                <Avatar open={props.open}>
                                    <img src={UserCircle} alt='Profile' />
                                </Avatar>
                            </header>
                            <ul>
                                {
                                    props.menuItems.map( (item, index) => 
                                        {
                                            return (
                                                <React.Fragment key={`Menuitem${index}`}>
                                                    <h3>{item.title}</h3>
                                                    <hr></hr>
                                                    {
                                                        item.menus.map((subItem, subIndex) => {
                                                            return (
                                                                <NavLink key={ subIndex } exact to={ subItem.link } activeStyle={{ background: '#61dafb' }} >
                                                                    <img src={subItem.icon} alt='Dashboard Icon' />
                                                                    <span>{subItem.name}</span>
                                                                </NavLink>
                                                            )
                                                        })
                                                    }
                                                </React.Fragment>
                                            )
                                        }
                                    )
                                }
                                <hr></hr>
                                <NavLink exact to='/login' activeStyle={{ background: '#61dafb' }} onClick={props.logout} >
                                    <img src={Logout} alt='Log out' />
                                    <span>Cerrar Sesión</span>
                                </NavLink>
                            </ul>
                        </ContentMenu>
                    </SidebarMenu>
                </React.Fragment>
            </Tablet>
            <Mobile>
                <React.Fragment>
                    <MenuOpen open={props.open} left='15px' onClick={props.handleAction} mobile={true}/>
                    <MenuClose open={props.open} onClick={props.handleAction}/>
                    <SidebarMenu open={props.open} mobile={true}>
                        <header>
                            <h4>{props.title}</h4>
                            <MenuOpen open={props.open} left='40px' onClick={props.handleAction}/>
                            <MenuClose open={props.open} onClick={props.handleAction}/>
                        </header>
                        <ContentMenu open={props.open}>
                            <header>
                                <h4> {props.user.username} </h4>
                                <p> {props.user.email} </p>
                                <Avatar open={props.open}>
                                    <img src={props.user.img ? props.user.img : UserCircle} alt='Profile' />
                                </Avatar>
                            </header>
                            <ul>
                                {
                                    props.menuItems.map( (item, index) => 
                                        {
                                            return (
                                                <React.Fragment key={`Menuitem${index}`}>
                                                    <h3>{item.title}</h3>
                                                    <hr></hr>
                                                    {
                                                        item.menus.map((subItem, subIndex) => {
                                                            return (
                                                                <NavLink key={ subIndex } exact to={ subItem.link } activeStyle={{ background: '#61dafb' }} onClick={handleClose} >
                                                                    <img src={subItem.icon} alt='Dashboard Icon' />
                                                                    <span>{subItem.name}</span>
                                                                </NavLink>
                                                            )
                                                        })
                                                    }
                                                </React.Fragment>
                                            )
                                        }
                                    )
                                }
                                <hr></hr>
                                <NavLink exact to='/login' activeStyle={{ background: '#61dafb' }} onClick={props.logout} >
                                    <img src={Logout} alt='Log out' />
                                    <span>Cerrar Sesión</span>
                                </NavLink>
                            </ul>
                        </ContentMenu>
                    </SidebarMenu>
                    <ShadowMenu open={props.open} />
                </React.Fragment>
            </Mobile>
        </React.Fragment>
    );
}

export default Sidebar;