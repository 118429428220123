export const productConstants = {
    FETCHING_FORMFIELDS: 'PRODUCT_FETCHING_FORMFIELDS',
    SUCCESS_FORMFIELDS: 'PRODUCT_SUCCESS_FORMFIELDS',
    SUCCESS_UPDATEFORMFIELDS: 'PRODUCT_SUCCESS_UPDATEFORMFIELDS',
    ERROR_FORMFIELDS: 'PRODUCT_ERROR_FORMFIELDS',
    SUCCESS: 'PRODUCT_SUCCESS',
    ERROR: 'PRODUCT_ERROR',
    FETCHING: 'PRODUCT_FETCHING',
    CURRENCY_GROUP: 'unitsCurrency',
    SUCCESS_CURRENCYUNITS: 'CURRENCY_UNITS_SUCCESS',
    SUCCESS_QUANTITYUNITS: 'QUANTITY_UNITS_SUCCESS',
    SUCCESS_TRADEMARKS: 'TRADEMARKS_SUCCESS',
    QUANTITY_GROUP: 'unitsMeasurement',
    SUPPLIER_IDROLE: 4
};
