/* General imports */
import React from 'react'
import styled from 'styled-components'
import { categoryActions } from './CategoryActions'
import { alertActions } from '../../alert/AlertActions'
import { connect } from 'react-redux'
import axios from 'axios'
import { theme } from '../../app/config'

/* Components imports */
import Spinner from '../../spinners/LoaderSpinner'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import NoImage from '../../images/no-image.png'
import FormStep from '../../form/FormStep'
import ModalNewFeature from './ModalNewFeature'
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import RemoveIcon from '../../icons/remove.svg'
import EditIcon from '../../icons/edit.svg'


/* Styled components */
const Root = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 20px;
    background-color: white;
`

const Box = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: ${(props) => props.width ? props.width : '100%'};
    height: 100%;
    align-items: ${(props) => props.alignX ? props.alignX : 'center'};
    justify-content: ${(props) => props.alignY ? props.alignY : 'flex-start'};
`

const ImageContainer = styled.div`
    position: relative;
    display: flex;
    width: 300px;
    height: 300px;
    margin-bottom: 25px;
    img {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`

const UploadImage = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    box-sizing: border-box;
`

const FeaturesBox = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    justify-content: flex-start;
    width: 90%;
    height: 40%;
    margin-top: 15px;
    border-radius: 10px;
    border: 0.5px solid black;
    ul {
        width: 100%;
        list-style: none;
        margin: 0;
        padding: 0;
        max-height: 250px;
        overflow-y: auto;
    }
`

const RowHeader = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    background-color: gray;
    border-radius: 10px 10px 0 0;
    h4 {
        font-weight: bold;
        width: 10%;
        text-align: center;
        margin: 0;
        padding: 10px 0;
    }
    h5 {
        font-weight: bold;
        width: 26.5%;
        text-align: center;
        margin: 0;
        padding: 10px 0;
    }
`

const RowLi = styled.li`
    display: flex;
    width: 100%;
    height: 50px;
    align-items: center;
    justify-content: flex-start;
    div {
        position: relative;
        display: flex;
        width: 10%;
        height: 100%;
        align-items: center;
        justify-content: center;
        img {
            position: relative;
            display: flex;
            width: 45px;
            height: 45px;
            border-radius: 50%;
            box-shadow: 1px 1px 2px rgba(0,0,0,0.5);
        }
    }
    img {
        position: relative;
        display: flex;
        width: 5%;
        height: 30px;
        border-radius: 50%;
    }
    span {
        width: 26.5%;
        text-align: center;
        margin: 0;
        padding: 5px 0;
    }
`

const StyledButton = styled.button`
    width: 35%;
    height: 30px;
    background-color: ${theme.default.primaryButtonBg};
    color: ${theme.default.primaryButtonText};
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 600;
    border-radius: 80px;
    border-color: transparent;
    border-style: solid;
    margin: auto;
    outline: none;
`

const IconAction = styled.div`
    position: absolute;
    display: flex;
    width: 40px;
    height: 40px;
    top: 5px;
    right: ${(props) => props.right ? props.right : '10px'};
    align-items: center;
    justify-content: center;
    
    svg {
        font-size: 35px;
        cursor: pointer;
        z-index: 999;
    }
`

/* ----- Principal function ----- */
function NewCategory({user, rowData, error, categoryFields, getFields, getCategories, createCategory, updateCategory, alertMessage, history }) {

    const [ dataForm, setDataForm ] = React.useState(rowData ? rowData : { 
        name: '',
        description: '',
        features: '',
        parent_idCategory: '',
        order: 0
    })

    const [ image, setImage ] = React.useState(rowData ? rowData.img : '')

    const [ features, setFeatures ] = React.useState(rowData ? rowData.features : [])

    const [open, setOpen] = React.useState(false);

    const [edit, setEdit] = React.useState(false);

    const [editData, setEditData] = React.useState(null);

    const checkForm = () => {
        let flag = true
        let message = 'Faltan completar campos'
        categoryFields.forEach(element => {
            if(element.required){
                if(dataForm[element.name] === null || dataForm[element.name] === '' || dataForm[element.name] === undefined){
                    flag = false;
                    dataForm[`${element.name}_error`] = true;
                } else {
                    dataForm[`${element.name}_error`] = false;
                }
            }
        });
        return { flag, message }
    }

    const handleChange = (name, value) => {
        setDataForm({ 
            ...dataForm,
            [name]: value 
        });
    }

    const onFileChange = (e) => {
        setImage(e.target.files[0])
    }

    const handleFileUpload = (e) => {
        if(image !== '' ){
            if(!dataForm.img){
                const formData = new FormData()
                formData.append('image', image)
                axios.post("/api/images/upload", formData, {
                }).then(res => {
                    const url_img = '/uploads/'+res.data.filename
                    setDataForm({
                        ...dataForm,
                        img: url_img,
                        img_error:false
                    })
                })
                .catch(error => {
                    alert('Error al cargar la imagen. Sólo se soporta hasta 2MB y los tipos jpeg-jpg-png-gif-webp.')
                })
            } else {
                // eslint-disable-next-line no-restricted-globals
                if(confirm('¿Desea subir esta nueva imagen y reemplazar la anterior?')){
                    const formData = new FormData()
                    formData.append('image', image)
                    axios.post("/api/images/upload", formData, {
                    }).then(res => {
                        const url_img = '/uploads/'+res.data.filename
                        setDataForm({
                            ...dataForm,
                            img: url_img,
                            img_error:false
                        })    
                    })
                    .catch(error => {
                        alert('Error al cargar la imagen. Sólo se soporta hasta 2MB y los tipos jpeg-jpg-png-gif-webp.')
                    })  
                }
            }
        }else{
            alert("Debe selecionar una imagen...")
        }
    }

    const addFeature = () => {
        setOpen(true);
    };

    const editFeature = (e) => {
        const {idfeature} = e.target.dataset;
        setEditData(features[idfeature]);
        setEdit(true);
    }

    const handleConfirmNew = async (data) => {
        let newData = features;
        if(features.filter((value) => value.field === data.field).length === 0){
            newData.push(data)
            setFeatures(newData)
            handleClose()
        } else {
            alertMessage('ERROR: Ya existe una característica con este campo.')
        }
        
    }

    const handleConfirmEdit = async (data) => {
        let newFeatures = features;
        newFeatures[features.findIndex((value) => value.field === data.field)] = data;
        setFeatures(newFeatures);
        handleCloseEdit();
    }
    
    const handleClose = () => {
        setOpen(false)
    };

    const handleCloseEdit = () => {
        setEdit(false)
        setEditData(null);
    };

    const cancelCategory = () => {
        history.push('/admin/stocksystem/category')
    }

    const saveCategory = () => {
        const {flag, message} = checkForm();
        if(flag){
            if(rowData){
                updateCategory(rowData.idCategory, {
                    ...dataForm,
                    features: features,
                    updatedUser: user.idUser
                })
            } else {
                createCategory({
                    ...dataForm,
                    features: features,
                    createdUser: user.idUser
                })
            }
            getCategories()
            history.push('/admin/stocksystem/category')
        } else {
            alertMessage(message)
        }
        
    }

    const removeFeature = (e) => {
        const {idfeature, name} = e.target.dataset;
        // eslint-disable-next-line no-restricted-globals
        if(confirm('¿Desea remover la característica '+name+'?')){
            setFeatures(features.filter((value, index) => index != parseInt(idfeature)));   
        }
    }

    React.useEffect(() => {
        if(!categoryFields){
            getFields()
        }
    }, [getFields, categoryFields])

    return(
            !categoryFields ? <Spinner spinnerType='ThreeDots' />
            :
            <Root>
                <IconAction right='60px'><SaveIcon onClick={saveCategory} /></IconAction>
                <IconAction ><CancelIcon onClick={cancelCategory} /></IconAction>
                <Box width='40%' alignY='center' >
                    <ImageContainer>
                        <img src={dataForm.img ? dataForm.img : NoImage} alt="Imagen subida" />
                        </ImageContainer>
                    <UploadImage>
                        <input style={{fontSize: '0.75rem', width: '300px', color: dataForm['img_error'] ? 'red' : 'black'}} id="image" name="image" type="file" onChange={onFileChange} error={dataForm['img_error']} required /><CloudUploadIcon onClick={handleFileUpload} />
                    </UploadImage>
                </Box>
                <Box width='60%' alignX='flex-start' >
                    <FormStep fields={categoryFields} width='100%' height='50%' inputMargin={'10px'} data={dataForm} onHandleChange={handleChange} columWidth='90%' noWrap={true} alignV='flex-start'  />
                    <FeaturesBox>
                        <RowHeader><h4>IMAGEN</h4><h5>CAMPO</h5><h5>NOMBRE</h5><h5>TIPO</h5></RowHeader>
                        {
                            features && features.length > 0 &&
                            <ul>
                                {
                                    features.map((feature, index) => 
                                        <RowLi key={`feature${index}`}>
                                            <div>
                                                <img src={feature.img ? feature.img : NoImage} alt='SinImagen' />
                                            </div>
                                            <span>{feature.field}</span>
                                            <span>{feature.name}</span>
                                            <span>{feature.type}</span>
                                            <img src={EditIcon} alt='IconEdit' data-idfeature={index} data-name={feature.name} onClick={editFeature} />
                                            <img src={RemoveIcon} alt='IconRemove' data-idfeature={index} data-name={feature.name} onClick={removeFeature} />
                                        </RowLi>
                                    )
                                }
                            </ul>
                        }
                        <StyledButton onClick={addFeature}> AGREGAR CARACTERÍSTICA </StyledButton>
                    </FeaturesBox>
                </Box>
                {
                    open && <ModalNewFeature title='NUEVA CARACTERÍSTICA' textButton='AGREGAR' handleAction={handleConfirmNew} openModal={open} closeModal={handleClose} alertMessage={alertMessage} />
                }
                {
                    editData && <ModalNewFeature title='EDITAR CARACTERÍSTICA' textButton='ACTUALIZAR' rowData={editData} handleAction={handleConfirmEdit} openModal={edit} closeModal={handleCloseEdit} alertMessage={alertMessage} />
                }
            </Root>
    );
}

function mapState(state) {
    const { error, categoryFields } = state.category;
    const { user } = state.login;
    return { user, error, categoryFields};
}

const actionCreators = {
    getFields: categoryActions.getFields,
    getCategories: categoryActions.getCategories,
    createCategory: categoryActions.createCategory,
    updateCategory: categoryActions.updateCategory,
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(NewCategory);