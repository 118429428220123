/* General imports */
import React from 'react'
import styled from 'styled-components';
import Gallery from '../ImageGallery/Gallery';

/* Components imports */


/* Styled Components */

const Root = styled.div`
    display: grid;
    grid-template-columns: 0.6fr 0.4fr;
    grid-template-rows: 250px 250px;
    gap: 20px;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    padding: 0 100px;
    align-items: flex-end;
    justify-content: center;
    background: linear-gradient(180deg, #FFF 0%, #FFF 40%, #001554 40%, #001554 100%);
    @media (max-width: 600px) {
        margin: 50px 0 0 0;
        padding: 0 50px;
        grid-template-columns: 1fr;
        grid-template-rows: 350px 350px 350px;
        background: linear-gradient(180deg, #FFF 0%, #FFF 90%, #001554 90%, #001554 100%);
        gap: 10px;
    }
`
const ContentImage1 = styled.img`
    position: relative;
    display: flex;
    width: 100%;
    height: 80%;
    object-position: center;
    object-fit: cover;
    grid-row: 1/3;
    @media (max-width: 600px) {
        grid-row: unset;
        width: 300px;
        height: 300px;
    }
`

const ContentImage2 = styled.img`
    position: relative;
    display: flex;
    width: 250px;
    height: 250px;
    object-position: center;
    object-fit: cover;
    justify-self: center;
    @media (max-width: 600px) {
        width: 300px;
        height: 300px;
    }
`

const ThreeImagesV2 = (props) => {

    return (
        props.images && props.images.length > 3 && props.images.length === 4 ?
        <Root>
            {
                props.images[1] &&
                <ContentImage1 src={props.images[1]} alt="Image 1" />
            }
            {
                props.images[2] &&
                <ContentImage2 src={props.images[2]} alt="Image 1" />
            }
            {
                props.images[3] &&
                <ContentImage2 src={props.images[3]} alt="Image 1" />
            }
        </Root>
        :
        props.images && props.images.length > 1 &&
        <Gallery images={props.images.slice(1)} isDesktop={props.isDesktop} />
    );
}

export default ThreeImagesV2;