/* General imports */
import React from 'react'
import styled from 'styled-components'
import { config } from '../../app/config'

/* Components imports */
import NoImage from '../../images/no-image.png'

/* Styled components */
const Root = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 280px;
    height: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0 20px;
`

const ImageLink = styled.a`
    width: 100%;
    height: 280px;
    margin-bottom: 10px;
`

const ProducImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: fill;
    object-position: center;
`

const Name = styled.a`
    text-decoration: none;
    font-size: 1rem;
    color: ${(props) => props.color ? props.color : '#001554'};
    font-weight: 500;
`

const Data = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 300;
    font-size: 1rem;
    color: ${(props) => props.color ? props.color : '#001554'};
`


/* ----- Principal function ----- */
function ProductCard({productInfo, colorName, colorData}) {

    return(
        productInfo &&
        <Root>
            <ImageLink href={`${config.url}/product/${productInfo.idProduct}`}>
                <ProducImage src={productInfo.img && productInfo.img.thumbnail ? productInfo.img.thumbnail : productInfo.img.images.length > 0 ? productInfo.img.images[0] : NoImage} alt={productInfo.name} />
            </ImageLink>
            <Name color={colorName} href={`${config.url}/product/${productInfo.idProduct}`}><strong>{productInfo.name}</strong></Name>
            <Data color={colorData}><span>{productInfo.trademark ? productInfo.trademark.name : 'Marca'}</span>&nbsp;|&nbsp;<span>{productInfo.id_Category && productInfo.id_Category.name}</span></Data>
        </Root>
    );
}

export default ProductCard;