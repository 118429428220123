/* General imports */
import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { devices, config } from '../app/config'
import { alertActions } from '../alert/AlertActions'
import { pageActions } from '../page/PageActions'

/* Components imports */
import Table from '../tables/ClassicTable'
import ModalCreateUpdatePage from './ModalCreateUpdatePage'

/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled components */
const ContentBox = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    justify-content: flex-start;
`

/* ----- Principal function ----- */
function PageConfig({ history, user, error, isFetching, pages, pageFields, getPages, getFields, createPage, updatePage, deletePage }) {

    const columsTable = {
        desktop: [
            { title: 'ID', field: 'idPage',width: 50 },
            { title: 'Orden', field: 'order',width: 50 },
            { 
                title: 'Título', 
                field: 'title', 
                width: 400
            },
            { 
                title: 'Descripción', 
                field: 'description', 
                width: 500
            }
        ],
        mobile: [
            { title: 'ID', field: 'idPage',width: 25 },
            { title: 'Orden', field: 'order',width: 25 },
            { 
                title: 'Título', 
                field: 'title', 
                width: 300
            }
        ]
    }

    const tableRef = React.createRef()

    const [open, setOpen] = React.useState(false);

    const [ edit, setEdit ] = React.useState(false);

    const [ editData, setEditData ] = React.useState(null);

    const refreshData = () => getPages() && tableRef.current.onQueryChange()

    const handleView = (data) => {
        history.push(config.webeditorpath+'/page/'+ data.idPage)
    };

    const handleNew = () => {
        setOpen(true);
    };

    const handleConfirmNew = async(data) => {
        data = {
            ...data,
            createdUser: user.idUser
        }
        await createPage(data)
        handleClose()
    }

    const handleClose = () => {
        setOpen(false)
        refreshData()
    };

    const handleEdit = (rowData) => {
        setEditData(rowData)
        setEdit(true)
    }

    const handleConfirmEdit = async (data) => {
        data = {
            ...data,
            updatedUser: user.idUser
        }
        await updatePage(data.idPage, data)
        handleCloseEdit()
    }

    const handleCloseEdit = () => {
        setEdit(false)
        setEditData(null)
        refreshData()
    }

    const handleDelete = (rowData) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("¿Desea eliminar el profesional "+rowData.idPage+"?")) {
            deletePage(rowData.idPage)
        }
    }

    React.useEffect(() => {
        if(!pages){
            getPages()
        }
    }, [getPages, pages])

    return(
        <React.Fragment>
            <Desktop>
                <ContentBox>
                    {
                        isFetching ? <h1>CARGANDO...</h1> :
                        <Table tableRef={tableRef}  title="" columnsTable={columsTable.desktop} data={pages} getData={getPages} handleView={handleView} handleNew={handleNew} handleEdit={handleEdit} handleDelete={handleDelete} />
                    }
                    {
                        open && <ModalCreateUpdatePage title='CREAR PÁGINA' textButton='CREAR' handleAction={handleConfirmNew} getFields={getFields} fields={pageFields} openModal={open} closeModal={handleClose} />
                    }
                    {
                        editData && <ModalCreateUpdatePage title='EDITAR PÁGINA' textButton='EDITAR' handleAction={handleConfirmEdit} rowData={editData} getFields={getFields} fields={pageFields} openModal={edit} closeModal={handleCloseEdit} />
                    }
                </ContentBox>
            </Desktop>
            <Tablet>
                <ContentBox>
                    <Table tableRef={tableRef}  title="" columnsTable={columsTable.desktop} data={pages} getData={getPages} handleView={handleView} handleNew={handleNew} handleEdit={handleEdit} handleDelete={handleDelete} />   
                </ContentBox>
            </Tablet>
            <Mobile>
                <ContentBox>
                    <Table tableRef={tableRef}  title="" columnsTable={columsTable.mobile} data={pages} getData={getPages} handleView={handleView} handleNew={handleNew} handleEdit={handleEdit} handleDelete={handleDelete} />   
                </ContentBox>
            </Mobile>
        </React.Fragment>
    );
}

function mapState(state) {
    const { error, isFetching, pages, pageFields } = state.page;
    const { user } = state.login;
    return { user, error, isFetching, pages, pageFields };
}

const actionCreators = {
    getFields: pageActions.getFields,
    createPage: pageActions.createPage,
    getPages: pageActions.getPages,
    updatePage: pageActions.updatePage,
    deletePage: pageActions.deletePage,
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(PageConfig);