/* General imports */
import React from 'react'
import styled, {keyframes} from 'styled-components';
import axios from 'axios'
import  { authHeader } from '../../app/auth-header';

/* Components imports */
import NoImage from '../../images/no-image.png';
import Zoom from 'react-reveal/Zoom';


/* Styled Components */
const moveSlideshow = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-50%, 0, 0);
  }
`;

const Root = styled.div`
    width: 100%;
    height: 50vh;
    background: #FFF;
    display: flex;
    box-sizing: border-box;
    padding: 50px;
    flex-direction: column;
    position:relative;
    font-family: 'IBM Plex Sans';
    overflow-x: hidden;
    @media (max-width: 600px) {
        padding: 20px 10px;
        height: auto;
        min-height: 30vh;
    }
`

const Title = styled.h2`
    width: 100%;
    text-align: center;
    font-weight: 300;
    font-size: 2.5rem;
    color: #001554;
    margin: auto 0;
    @media (max-width: 600px) {
        font-size: 1.8rem;
        margin: 0;
    }
`

const SuppliersList = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* width: ${(props) => props.amount ? `calc(300px * ${props.amount})` : '100%'}; */
    width: fit-content;
    height: 200px;
    animation: ${moveSlideshow} ${props => props.amount * 3}s linear infinite;
`

const SupImage = styled.img`
    width: auto;
    height: 150px;
    margin: 0 25px;
    object-fit: contain;
    object-position: center;
    /* @media (max-width: 600px) {
        width: 80px;
        height: 80px;
    } */
`


function Suppliers({idsection}) {

    const [data, setData] = React.useState(null);
    const [suppliers, setSuppliers] = React.useState(null);

    React.useEffect(() => {
        if(!suppliers){
            let configHeader = {
                headers: { ...authHeader(), 'Content-Type': 'application/json' }
            }
            axios.get('/api/trademarks',configHeader)
            .then(suppliersData => {
                setSuppliers(suppliersData.data.rows);
            })
            .catch(err => {
                alert('Hubo un error...');
            })
        }
    }, [suppliers])

    React.useEffect(() => {
        if(!data){
            let configHeader = {
                headers: { ...authHeader(), 'Content-Type': 'application/json' }
            }
            axios.get('/api/sections/' + idsection,configHeader)
            .then(sectionData => {
                setData(sectionData.data.section.data);
            })
            .catch(err => {
                alert('Hubo un error...');
            })
        }
    }, [data, idsection])

    return (
        data && suppliers &&
        <Root>
            <Zoom delay={300}>
                <Title>{data.title}</Title>
            </Zoom>
            <SuppliersList amount={suppliers.length}>
            {
                suppliers && suppliers.map((sup, index) => 
                <SupImage total={suppliers.length} key={`sup_${index}`} src={sup.img ? sup.img : NoImage} alt={`image_sup_${index}`} />
                )
            }
            {
                suppliers && suppliers.map((sup, index) => 
                <SupImage total={suppliers.length} key={`sup_${index}`} src={sup.img ? sup.img : NoImage} alt={`image_sup_${index}`} />
                )
            }
            </SuppliersList>
        </Root>
    )
}

export default Suppliers;