/* General imports */
import React from 'react'
import styled from 'styled-components'

const Inputbox = styled.div`
    width: ${(props) => props.width ? props.width : '100%'};
    position: relative;
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: ${(props) => props.fontSize ? props.fontSize : '20px'};
    margin: ${(props) => props.inputMargin ? `${props.inputMargin} ${props.inputMargin} calc(${props.inputMargin}*0.5) ${props.inputMargin}` : '18px 18px 9px 18px'};
    box-sizing: border-box;
    height: ${(props) => props.height ? props.height : props.fontSize ? `calc(${props.fontSize}*2.2)` : '40px'};
    border-bottom: ${(props) => props.color ? `1px solid ${props.color}` : '1px solid black'};
    ${(props) => props.error ? 'border-color: red;' : ''};
    ${(props) => props.border ? '' : 'border: none'};
    outline: none;
    label {
        position: absolute;
        top: ${(props) => props.type === 'date' || props.type === 'color' ? '-10px' : props.effect ? '-10px' : '12px' };
        left: 0;
        color: #ccc;
        transition: .5s;
        pointer-events: none;
        font-size: ${(props) => props.type === 'date' ? '16px' : props.effect ? props.fontSize ? `calc(${props.fontSize}*0.8)` : '16px' : props.fontSize ? props.fontSize : '20px'};
        margin-left: ${(props) => props.icon ? '40px' : 0};
        height:${(props)=> props.fontSize ? `calc(${props.fontSize}*0.8)` : '16px'};
        outline: none;
    }

    input {
        border: none;
        outline: none;
        background: none;
        color: ${(props) => props.color ? props.color : 'black'};
        font-size: ${(props) => props.fontSize ? `calc(${props.fontSize}*0.9)` : '18px'};
        width: calc(100% - 35px);
        padding-bottom: 12px;
        padding-left: ${(props) => props.icon ? '5px' : 0};
        margin-top: 16px;
        box-sizing: border-box;
        height: ${(props) => props.fontSize ? `calc(${props.fontSize}*2)` : '32px'};
        outline: none;
    }

    textarea {
        border: none;
        outline: none;
        background: none;
        color: ${(props) => props.color ? props.color : 'black'};
        font-size: ${(props) => props.fontSize ? `calc(${props.fontSize}*0.9)` : '18px'};
        width: calc(100% - 35px);
        padding-bottom: 12px;
        padding-left: ${(props) => props.icon ? '5px' : 0};
        margin-top: 16px;
        box-sizing: border-box;
        /* height: ${(props) => props.fontSize ? `calc(${props.fontSize}*2)` : '80px'}; */
        height: ${(props) => props.height ? `calc(${props.height} - 10px)` : props.fontSize ? `calc(${props.fontSize}*2)` : '80px'};
        outline: none;
        resize: none;
    }

    input:focus + label {
        top: -10px;
        color: ${(props) => props.effectColor ? props.effectColor : 'black'};
        font-size: 16px;
    }

    select:focus + label {
        top: -10px;
        color: ${(props) => props.effectColor ? props.effectColor : 'black'};
        font-size: 16px;
    }

    textarea:focus + label {
        top: -10px;
        color: ${(props) => props.effectColor ? props.effectColor : 'black'};
        font-size: 16px;
    }
`

const Select = styled.select`
    background: transparent;
    border: none;
    font-size: ${(props) => props.fontSize ? `calc(${props.fontSize}*0.9)` : '18px'};
    height: 30px;
    width: 100%;
    padding-bottom: 12px;
    margin-top: 16px;
    height: ${(props) => props.fontSize ? `calc(${props.fontSize}*2)` : '32px'};
    outline: none;
`

const Option = styled.option`
    font-size: 18px;
`

const Icon = styled.img`
    font-size: 26px;
    width: 30px;
    height: 30px;
    margin: 5px 5px 5px 0;
`

/* ----- Principal function ----- */
function Input({name, error, width, height, onHandleChange, icon, type, effectColor, placeholder, data, required, options, autoFocus, fontSize, inputMargin, rows, border, color}) {
    
    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        onHandleChange(name, value)
    }

    const renderInput = (type) => {
        switch (type) {
            case 'select':
                return (
                (data && data[name]) ?
                <Select id={name} value={data[name]} required={required} name={name} fontSize={fontSize} onChange={handleChange}>
                    <Option value="" hidden>{placeholder}</Option>
                    {
                        options && options.map((option, index) => 
                            <Option key={`${name}_option${index}`} value={option.value}>{option.key ? `${option.key}(${option.name})` : option.name}</Option>
                        )
                    }
                </Select>
                :
                <Select id={name} required={required} name={name} onChange={handleChange}>
                    <Option value="" hidden></Option>
                    {
                        options && options.map((option, index) => 
                            <Option key={`${name}_option${index}`} value={option.value}>{option.key ? `${option.key}(${option.name})` : option.name}</Option>
                        )
                    }
                </Select>
                );
            case 'text':
                return (
                (data && data[name]) ? 
                <input id={name} value={data[name]} required={required} autoFocus={autoFocus} name={name} type={type} placeholder={placeholder} onChange={handleChange} />
                :
                <input id={name} required={required} autoFocus={autoFocus} name={name} type={type}  onChange={handleChange} />
                );
            case 'number':
                    return (
                    (data && data[name]) ? 
                    <input id={name} value={data[name]} required={required} autoFocus={autoFocus} name={name} type={type} placeholder={placeholder} onChange={handleChange} />
                    :
                    <input id={name} required={required} autoFocus={autoFocus} name={name} type={type}  onChange={handleChange} />
                    );
            case 'textarea':
                return (
                (data && data[name]) ? 
                <textarea id={name} value={data[name]} style={{width: '100%', verticalAlign: 'top'}} required={required} autoFocus={autoFocus} name={name} type={type} placeholder={placeholder} rows={rows}  onChange={handleChange} />
                :
                <textarea id={name} style={{width: '100%', verticalAlign: 'top'}} required={required} autoFocus={autoFocus} name={name} type={type}  onChange={handleChange}  />
                );
            case 'color':
                return (
                (data && data[name]) ? 
                <input id={name} value={data[name]} required={required} name={name} type={type} onChange={handleChange} />
                :
                <input id={name} required={required} name={name} type={type}  onChange={handleChange} />
                );
            default:
                return <h6>No se reconoce el tipo de input</h6>;
        }
    }

    return(
        <Inputbox error={error} width={width} height={height} icon={icon} type={type} effect={ data[name] ? true : false} effectColor={effectColor} fontSize={fontSize} inputMargin={inputMargin} border={border} color={color}>
            { icon && <Icon src={icon} alt={placeholder} /> }
            { renderInput(type) }
            <label htmlFor={name}>{required ? `${placeholder}*` :  placeholder }</label>
        </Inputbox>
    );
}

export default Input;