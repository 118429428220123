import { usersConstants } from './UsersConstants';

let users = JSON.parse(localStorage.getItem('users'));
const initialState = users ? 
        { 
            error: false,
            isFetching: false,
            users: users
        }
    : 
        { 
            error: false,
            isFetching: true,
        };

export function user(state = initialState, action) {
    switch (action.type) {
        case usersConstants.FETCHING:
            return { 
                ...state,
                isFetching: true
            };
        case usersConstants.SUCCESS:
            return { 
                ...state,
                isFetching: false,
                users: action.users
            };
        case usersConstants.ERROR:
            return { 
                ...state,
                error: true
            };
        default:
            return state
    }
}