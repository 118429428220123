import { pageEditorConstants } from './PageEditorConstants';
import { alertActions } from '../alert/AlertActions';
import axios from 'axios';
import  { authHeader } from '../app/auth-header';

export const pageEditorActions = {
    createSection,
    updateSection,
    updateDataSection,
    getSections,
    deleteSection,
    getFields,
    getUpdateFields,
    changeOrder
};

function createSection(data) {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return dispatch => {
        
        axios
        .post( '/api/sectionspage' , data)
        .then(response => {
            dispatch({ type: pageEditorConstants.FETCHING });
            axios.get('/api/sectionspage/page/'+response.data.result.idPage,config)
            .then(sectionsData => {
                const sections = sectionsData.data.sectionsPage
                dispatch({ type: pageEditorConstants.SUCCESS, sections });
                dispatch(alertActions.success('Sección creada'));
            })
            .catch(err => {
                dispatch({ type: pageEditorConstants.ERROR });
                dispatch(alertActions.error('Hubo un error...'));
            })  
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}

function changeOrder(sectionsPage, updatedUser) {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const today = new Date()
    return dispatch => {
        let changes = []

        sectionsPage.forEach((element, index) => {
            changes.push({
                idSectionPage: element.idSectionPage,
                idPage: element.idPage,
                idSection: element.idSection,
                order: index+1,
                updatedDate: today,
                updatedUser
            })
        });
        axios.put( '/api/changeordersections', {changes}, config)
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}

function updateSection(idPage, idSection, section) {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return dispatch => {
        axios
        .put( '/api/sections/' + idSection, section)
        .then(response => {
            dispatch({ type: pageEditorConstants.FETCHING });
            axios.get('/api/sectionspage/page/'+idPage,config)
            .then(sectionsData => {
                const sections = sectionsData.data.sectionsPage
                dispatch({ type: pageEditorConstants.SUCCESS, sections });
                dispatch(alertActions.success('Sección actualizada'));
            })
            .catch(err => {
                dispatch({ type: pageEditorConstants.ERROR });
                dispatch(alertActions.error('Hubo un error...'));
            })  
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}

function updateDataSection(idPage, idSection, dataSection) {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return dispatch => {
        axios
        .put( '/api/updatedatasection/' + idSection, dataSection, config)
        .then(response => {
            dispatch({ type: pageEditorConstants.FETCHING });
            axios.get('/api/sectionspage/page/'+idPage,config)
            .then(sectionsData => {
                const sections = sectionsData.data.sectionsPage
                dispatch({ type: pageEditorConstants.SUCCESS, sections });
                dispatch(alertActions.success('Sección actualizada'));
            })
            .catch(err => {
                dispatch({ type: pageEditorConstants.ERROR });
                dispatch(alertActions.error('Hubo un error...'));
            })
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}

function getSections(idPage) {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return dispatch => {
        dispatch({ type: pageEditorConstants.FETCHING });
        axios.get('/api/sectionspage/page/'+idPage,config)
        .then(sectionsData => {
            const sections = sectionsData.data.sectionsPage
            dispatch({ type: pageEditorConstants.SUCCESS, sections });
        })
        .catch(err => {
            dispatch({ type: pageEditorConstants.ERROR });
            dispatch(alertActions.error('Hubo un error...'));
        })        
    }
}

function deleteSection(idPage, idSection) {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return dispatch => {
        axios
        .delete( '/api/sectionspage/' + idPage + '/' + idSection )
        .then(response => {
            dispatch({ type: pageEditorConstants.FETCHING });
            axios.get('/api/sectionspage/page/'+idPage,config)
            .then(sectionsData => {
                const sections = sectionsData.data.sectionsPage
                dispatch({ type: pageEditorConstants.SUCCESS, sections });
                dispatch(alertActions.success('Sección eliminada'));
            })
            .catch(err => {
                dispatch({ type: pageEditorConstants.ERROR });
                dispatch(alertActions.error('Hubo un error...'));
            }) 
            
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}

function getFields() {

    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    return dispatch => {
        dispatch({ type: pageEditorConstants.FETCHING_FORMFIELDS });

        axios.get('/api/sectionfields', config)
        .then(response => {
            const sectionFields = response.data.fields;
            dispatch({ type: pageEditorConstants.SUCCESS_FORMFIELDS, sectionFields });
        })
        .catch(error => {
            dispatch(alertActions.error(error));
        })
    }
}

function getUpdateFields() {

    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    return dispatch => {
        dispatch({ type: pageEditorConstants.FETCHING_FORMFIELDS });

        axios.get('/api/sectionupdatefields', config)
        .then(response => {
            const sectionUpdateFields = response.data.fields;
            dispatch({ type: pageEditorConstants.SUCCESS_UPDATEFORMFIELDS, sectionUpdateFields });
        })
        .catch(error => {
            dispatch(alertActions.error(error));
        })
    }
}