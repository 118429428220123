import { invoicesConstants } from './InvoicesConstants';

let invoices = JSON.parse(localStorage.getItem('invoices'));
const initialState = invoices ? 
        { 
            error: false,
            isFetching: false,
            invoices: invoices
        }
    : 
        { 
            error: false,
            isFetching: true,
        };

export function invoice(state = initialState, action) {
    switch (action.type) {
        case invoicesConstants.FETCHING:
            return { 
                ...state,
                isFetching: true
            };
        case invoicesConstants.SUCCESS:
            return { 
                ...state,
                isFetching: false,
                invoices: action.invoices
            };
        case invoicesConstants.ERROR:
            return { 
                ...state,
                error: true
            };
        case invoicesConstants.FETCHING_FORMFIELDS:
            return { 
                ...state,
                isFetchingFields: true
            };
        case invoicesConstants.SUCCESS_FORMFIELDS:
            return { 
                ...state,
                isFetchingFields: false,
                invoicesFields: action.invoicesFields,
                error: false
        };
        case invoicesConstants.SUCCESS_UPDATEFORMFIELDS:
            return { 
                ...state,
                isFetchingFields: false,
                invoicesUpdateFields: action.invoicesUpdateFields,
                error: false
        };
        case invoicesConstants.ERROR_FORMFIELDS:
            return {
                ...state,
                error: true
        };
        default:
            return state
    }
}