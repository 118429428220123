/* General imports */
import React from 'react'
import styled from 'styled-components'

/* Components imports */
import TextField from './TextField'


/* Styled components */
const Root = styled.div`
    width: ${(props) => props.width ? props.width : '100%'};
    height: ${(props) => props.height ? props.height : '100%'};
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: ${(props) => props.alignH ? props.alignH : 'center'};
    justify-content: ${(props) => props.alignV ? props.alignV : 'center'};
    flex-wrap: ${(props) => props.noWrap ? 'nowrap' : 'wrap' };
    ${(props) => props.overflowY ? `overflow-y: ${props.overflowY}` : '' };
    ${(props) => props.overflowX ? `overflow-x: ${props.overflowX}` : '' };
`

const Inputbox = styled.div`
    width: ${(props) => props.columWidth ? props.columWidth : '100%'};
    position: relative;
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: ${(props) => props.fontSize ? props.fontSize : '20px'};
    margin: ${(props) => props.inputMargin ? `${props.inputMargin} ${props.inputMargin} calc(${props.inputMargin}*0.5) ${props.inputMargin}` : '18px 18px 9px 18px'};
    box-sizing: border-box;
    height: ${(props) => props.height ? props.height : props.fontSize ? `calc(${props.fontSize}*2.2)` : '48px'};
    border-bottom: ${(props) => props.color ? `1px solid ${props.color}` : '1px solid black'};
    ${(props) => props.error ? 'border-color: red;' : ''};
    ${(props) => props.border ? '' : 'border: none'};
    label {
        position: absolute;
        top: ${(props) => props.type === 'date' ? '-10px' : props.effect ? '-10px' : '10px' };
        left: 0;
        color: #ccc;
        transition: .5s;
        pointer-events: none;
        font-size: ${(props) => props.type === 'date' ? '16px' : props.effect ? '16px' : '20px'};
        margin-left: ${(props) => props.icon ? '40px' : 0};
        height:${(props)=> props.fontSize ? `calc(${props.fontSize}*0.8)` : '16px'};
        outline: none;
    }

    input {
        border: none;
        outline: none;
        background: none;
        color: ${(props) => props.color ? props.color : 'black'};
        font-size: ${(props) => props.fontSize ? `calc(${props.fontSize}*0.9)` : '18px'};
        width: calc(100% - 35px);
        padding-bottom: 12px;
        padding-left: ${(props) => props.icon ? '5px' : 0};
        margin-top: 16px;
        box-sizing: border-box;
        height: ${(props) => props.fontSize ? `calc(${props.fontSize}*2)` : '32px'};
        outline: none;
    }

    input:focus + label {
        top: -10px;
        color: ${(props) => props.effectColor ? props.effectColor : 'black'};
        font-size: 16px;
    }

    select:focus + label {
        top: -10px;
        color: ${(props) => props.effectColor ? props.effectColor : 'black'};
        font-size: 16px;
    }
`

const Select = styled.select`
    background: transparent;
    border: none;
    font-size: ${(props) => props.fontSize ? `calc(${props.fontSize}*0.9)` : '18px'};
    height: ${(props) => props.fontSize ? `calc(${props.fontSize}*2)` : '32px'};
    width: 100%;
    padding-bottom: 12px;
    margin-top: 16px;
    outline: none;
`

const Option = styled.option`
    font-size: 18px;
`

const Icon = styled.img`
    font-size: 26px;
    width: 25px;
    height: 25px;
    margin: 5px 5px 5px 0;
`

/* ----- Principal function ----- */
function Form(props) {
    
    const handleChange = (name, value) => {
        /* e.preventDefault();
        const { name, value } = e.target; */
        props.onHandleChange(name, value)
    }

    return(
        <Root width={props.width} height={props.height} alignV={props.alignV} alignH={props.alignH} noWrap={props.noWrap} overflowX={props.overflowX} overflowY={props.overflowY} >
            {
                props.fields.map(field => 
                    <TextField key={field.name} id={field.name} value={props.data[field.name]} required={field.required} name={field.name} placeholder={field.placeholder} options={field.options} margin={props.inputMargin} error={props.data[`${field.name}_error`]} width={props.columWidth} icon={field.icon} type={field.type} fontSize={props.fontSize} autoFocus={field.autoFocus} onChange={handleChange}>
                        
                    </TextField>    
                )
            }
            {/* {
                props.fields.map(field => 
                    <Inputbox key={field.name} inputMargin={props.inputMargin} error={props.data[`${field.name}_error`]} columWidth={props.columWidth} icon={field.icon} type={field.type} effect={ props.data[field.name] ? true : false} effectColor={props.effectColor} border={props.border} fontSize={props.fontSize} >
                        
                        {
                            field.icon && <Icon src={field.icon} alt={field.placeholder} />
                        }
                        {
                            field.type === 'select' ?
                                (props.data && props.data[field.name]) ?
                                    <Select id={field.name} value={props.data[field.name]} required={field.required} name={field.name} onChange={handleChange}>
                                        <Option value="" hidden>{field.placeholder}</Option>
                                        {
                                            field.options.map((option, index) => 
                                                <Option key={index} value={option.value}>{option.key ? `${option.key}(${option.name})` : option.name}</Option>
                                            )
                                        }
                                    </Select>
                                :
                                    <Select id={field.name} required={field.required} name={field.name} onChange={handleChange}>
                                        <Option value="" hidden></Option>
                                        {
                                            field.options.map((option, index) => 
                                                <Option key={index} value={option.value}>{option.key ? `${option.key}(${option.name})` : option.name}</Option>
                                            )
                                        }
                                    </Select>
                            :
                                (props.data && props.data[field.name]) ? 
                                    <input id={field.name} value={props.data[field.name]} required={field.required} autoFocus={field.autoFocus} name={field.name} type={field.type} placeholder={field.placeholder} onChange={handleChange} />
                                :
                                    <input id={field.name} required={field.required} autoFocus={field.autoFocus} name={field.name} type={field.type}  onChange={handleChange} />
                        }
                        <label>{field.required ? `${field.placeholder}*` :  field.placeholder }</label>
                    </Inputbox>
                )
            } */}
        </Root>
    );
}

export default Form;