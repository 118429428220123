/* General imports */
import React from 'react'
import styled from 'styled-components'
import InvoiceAData from './InvoiceAData'
import InvoiceBData from './InvoiceBData'
import ReceiptAData from './ReceiptAData'

/* Components imports */
import TicketData from './TicketData'

const ContainerStep = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`

const VoucherGeneralData = ({idForm, rowData, onBack, handleOnFinishStep}) => {

    const getFormData = (idFormData) => {
        switch (parseInt(idFormData)) {
            case 19:
                return <TicketData rowData={rowData} onBack={onBack} handleFinish={handleOnFinishStep} />
                break;
            case 29:
                return <InvoiceAData rowData={rowData} onBack={onBack} handleFinish={handleOnFinishStep} />
                break;
            case 30:
                return <InvoiceBData rowData={rowData} onBack={onBack} handleFinish={handleOnFinishStep} />
                break;
            case 6:
                return <h2>FACTURA C</h2>
                break;
            case 31:
                return <h2>NOTA DE CRÉDITO</h2>
                break;
            case 32:
                return <h2>ORDEN DE COMPRE</h2>
                break;
            case 33:
                return <ReceiptAData rowData={rowData} onBack={onBack} handleFinish={handleOnFinishStep} />
                break;
            case 34:
                return <h2>REMITO</h2>
                break;
            case 35:
                return <h2>PRESUPUESTO</h2>
                break;
            case 36:
                return <h2>RECIBO A</h2>
                break;
            default:
                return <h2>Tipo de comprobante desconocido...</h2>
                break;
        }
    }

    return (
        <ContainerStep>
            {getFormData(idForm)}
        </ContainerStep>
    );
}

export default VoucherGeneralData;