import { professionalsConstants } from './ProfessionalsConstants';

let professionals = JSON.parse(localStorage.getItem('professionals'));
const initialState = professionals ? 
        { 
            error: false,
            isFetching: false,
            professionals: professionals
        }
    : 
        { 
            error: false,
            isFetching: true,
        };

export function professional(state = initialState, action) {
    switch (action.type) {
        case professionalsConstants.FETCHING:
            return { 
                ...state,
                isFetching: true
            };
        case professionalsConstants.SUCCESS:
            return { 
                ...state,
                isFetching: false,
                professionals: action.professionals
            };
        case professionalsConstants.ERROR:
            return { 
                ...state,
                error: true
            };
        case professionalsConstants.FETCHING_FORMFIELDS:
            return { 
                ...state,
                isFetchingFields: true
            };
        case professionalsConstants.SUCCESS_FORMFIELDS:
            return { 
                ...state,
                isFetchingFields: false,
                professionalFields: action.professionalFields,
                error: false
        };
        case professionalsConstants.SUCCESS_UPDATEFORMFIELDS:
            return { 
                ...state,
                isFetchingFields: false,
                professionalUpdateFields: action.professionalUpdateFields,
                error: false
        };
        case professionalsConstants.ERROR_FORMFIELDS:
            return {
                ...state,
                error: true
        };
        default:
            return state
    }
}