/* General imports */
import React from 'react'
import styled from 'styled-components'
import axios from 'axios';
import DolarBox from './DolarBox';
import ExampleChart from './ExampleChart';

const Root = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 200px 200px 300px 300px auto;
  width: 100%;
  height: auto;
  gap: 2rem;
  box-sizing: border-box;
  padding: 20px;
  @media (max-width: 450px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
`

const Box = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  box-shadow: ${(props) => props.boxShadow ? props.boxShadow : 'unset'};
  grid-row: ${(props) => props.gridRow ? props.gridRow : 'unset' };
  grid-column: ${(props) => props.gridColumn ? props.gridColumn : 'unset' };
  padding: ${(props) => props.padding ? props.padding : 'none'};
  @media (max-width: 450px) {
    ${(props) => props.height ? `height: ${props.height}` : ''};
    max-height: 400px;
    ${(props) => props.hideMobile ? 'display: none;' : ''};
    grid-column: inherit;
    grid-row: inherit;
  }
`



function GridLayout(props) {

  const [dolar, setDolar] = React.useState(null);
  const [dolarBlue, setDolarBlue] = React.useState(null);
  const [dolarLiqui, setDolarLiqui] = React.useState(null);

  React.useEffect(() => {
    if(!dolar){
      axios.get('https://api-contenidos.lanacion.com.ar/json/V3/economia/cotizacion/DBNA')
      .then(response => {
          setDolar(response.data)
      })
      .catch(err => {
          alert('Hubo un error al obtener dólar');
      })
    }
    if(!dolarBlue){
      axios.get('https://api-contenidos.lanacion.com.ar/json/V3/economia/cotizacionblue/DBLUE')
      .then(response => {
        setDolarBlue(response.data)
      })
      .catch(err => {
          alert('Hubo un error al obtener dólar blue');
      })
    }
    if(!dolarLiqui){
      axios.get('https://api-contenidos.lanacion.com.ar/json/V3/economia/cotizacionblue/DCCL')
      .then(response => {
        setDolarLiqui(response.data)
      })
      .catch(err => {
          alert('Hubo un error al obtener dólar liqui');
      })
    }
  
}, [ dolar, dolarBlue, dolarLiqui ])

  

    return (
      <Root>
        <Box hideMobile={true} gridColumn="1/5" boxShadow="1px 1px 5px rgba(0,0,0,0.25)">
          <ExampleChart />
        </Box>
        <Box boxShadow="1px 1px 5px rgba(0,0,0,0.25)">
          <DolarBox title="Dólar Banco Nación" dolar={dolar} />
        </Box>
        <Box boxShadow="1px 1px 5px rgba(0,0,0,0.25)">
          <DolarBox title="Dólar Blue Banco Nación" dolar={dolarBlue} />
        </Box>
        <Box boxShadow="1px 1px 5px rgba(0,0,0,0.25)">
          <DolarBox title="Dólar Liqui Banco Nación" dolar={dolarLiqui} />
        </Box>
        <Box boxShadow="1px 1px 5px rgba(0,0,0,0.25)">
        </Box>
        <Box height="400px" gridColumn="1/3" gridRow="3/4" boxShadow="1px 1px 5px rgba(0,0,0,0.25)">
        </Box>
        <Box height="400px" gridColumn="3/5" gridRow="3/4" boxShadow="1px 1px 5px rgba(0,0,0,0.25)">
        </Box>
        <Box gridColumn="1/5" gridRow="4/5" boxShadow="1px 1px 5px rgba(0,0,0,0.25)">
        </Box>
      </Root>
    )
}
export default GridLayout;