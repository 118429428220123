/* General imports */
import React from 'react'
import styled from 'styled-components'

/* Components imports */
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Form from '../form/Form'
/* import { theme } from '../app/config' */

/* Styled components */
const ModalStyled = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 10px 10px!important;
    z-index: 99999;
    outline: none;
`

const ModalContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    color: black;
    box-sizing: border-box;
    border-radius: 10px 10px 10px 10px;
    width: 500px;
    min-height: 450px;
    align-items: center;
    justify-content: center;
    overflow: auto;
    outline: none;
`

const ModalTitle = styled.div`
    position: absolute;
    display: flex;
    top: 0;
    width: 100%;
    height: 70px;
    color: white;
    font-size: 24px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    align-items: center;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
    box-sizing: border-box;
    background: linear-gradient(to left,#122230 0%,#192d3e 100%);
`

const ModalBody = styled.div`
    position: absolute;
    display: flex;
    top: 70px;
    width: 100%;
    height: calc(100% - 70px);
    align-items: center;
    justify-content: center;
`

/* ----- Principal function ----- */
function ModalAddAssign({ user, title, textButton, professionalsList, activitiesList, rowData, openModal, closeModal, handleAction }) {

    const fields = [
        {
            name: 'idProfessional',
            type: 'select',
            placeholder: 'Profesional',
            options: professionalsList,
            required: true
        },
        {
            name: 'activity',
            type: 'select',
            placeholder: 'Actividad',
            options: activitiesList,
            required: true
        }
    ]

    const submit = async (data) => {
        data = {
            ...data,
            idPatient: rowData.idUser,
            idUser: user.idUser,
            activityName: activitiesList.filter(element => element.value === parseInt(data.activity))[0].name
        }
        await handleAction(data)
    }

    return(
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openModal}
            onClose={closeModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={openModal}>
                <ModalContent>
                    <ModalTitle>
                        {title}
                    </ModalTitle>
                    <ModalBody>
                        {
                            (!fields) ? 
                                <h1>CARGANDO...</h1> 
                            : 
                            <Form width='450px' height='300px' columWidth='300px' alignV='flex-start' fields={fields} submitButton={textButton} submitFunction={submit}/>
                        }
                    </ModalBody>
                </ModalContent>
            </Fade>
        </ModalStyled>
    );
}

export default ModalAddAssign;