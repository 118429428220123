import { registerConstants } from './RegisterConstants';

const initialState = {
  registering: false,
  isFetching: false
}

export function register(state = initialState, action) {
  switch (action.type) {
    case registerConstants.REGISTER_REQUEST:
      return { 
        ...state,
        registering: true 
      };
    case registerConstants.REGISTER_SUCCESS:
      return {
        ...state,
        registering: false
      };
    case registerConstants.REGISTER_FAILURE:
      return {
        ...state,
        error: true
      };
    case registerConstants.FETCHING_FORMFIELDS:
      return { 
        ...state,
        isFetching: true
      };
    case registerConstants.SUCCESS_FORMFIELDS:
      return { 
        ...state,
        isFetching: false,
        registerFields: action.registerFields,
        error: false
      };
    case registerConstants.ERROR_FORMFIELDS:
      return {
        ...state,
        error: true
      };
    default:
      return state
  }
}


