/* General imports */
import React from 'react'
import styled from 'styled-components'
import axios from 'axios'
import  { authHeader } from '../app/auth-header';
import { DateRangePicker, createStaticRanges } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addDays, endOfDay, startOfDay, startOfMonth, endOfMonth, addMonths, startOfWeek, endOfWeek, startOfYear, endOfYear, addYears } from "date-fns";
import {es} from "react-date-range/src/locale/index";
import { CSVLink } from "react-csv";

/* Components imports */
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

/* Styled components */
const ModalStyled = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 10px 10px!important;
    z-index: 99999;
`

const ModalContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    color: black;
    box-sizing: border-box;
    border-radius: 10px 10px 10px 10px;
    width: 700px;
    min-height: 550px;
    align-items: center;
    justify-content: flex-start;
    outline: none;
`

const ModalTitle = styled.div`
    position: absolute;
    display: flex;
    top: 0;
    width: 100%;
    height: 70px;
    color: white;
    font-size: 24px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    align-items: center;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
    box-sizing: border-box;
    background: linear-gradient(to left,#122230 0%,#192d3e 100%);
    border-radius: 10px 10px 0 0;
`

const ModalBody = styled.div`
    position: absolute;
    display: flex;
    top: 70px;
    width: 100%;
    height: calc(100% - 70px);
    align-items: flex-start;
    justify-content: center;
    padding-top: 15px;
    box-sizing: border-box;
`

const defineds = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    startOfLastSevenDay: startOfDay(addDays(new Date(), -7)),
    startOfLastThirtyDay: startOfDay(addDays(new Date(), -30)),
    startOfLastNintyDay: startOfDay(addDays(new Date(), -90)),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    startOfYear: startOfYear(new Date()),
    endOfYear: endOfYear(new Date()),
    startOflastYear: startOfYear(addYears(new Date(), -1)),
    endOflastYear: endOfYear(addYears(new Date(), -1))
};

const ConfirmButton = styled.button`
    position: absolute;
    display: flex;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    height: 35px;
    width: 100px;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: #192d3e;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    border-radius: 4px;
    border: 0;
    outline: none;
    &:hover {
        background-color: #122230;
    }
`

const sideBarOptions = () => {
    const customDateObjects = [
    {
        label: "Hoy",
        range: () => ({
            startDate: defineds.startOfToday,
            endDate: defineds.endOfToday
        })
    },
    {
        label: "Últimos 7 días",
        range: () => ({
            startDate: defineds.startOfLastSevenDay,
            endDate: defineds.endOfToday
        })
    },
    {
        label: "Últimos 30 días",
        range: () => ({
            startDate: defineds.startOfLastThirtyDay,
            endDate: defineds.endOfToday
        })
    },
    {
        label: "Últimos 90 días",
        range: () => ({
            startDate: defineds.startOfLastNintyDay,
            endDate: defineds.endOfToday
        })
    },
    {
        label: "Este mes",
        range: () => ({
            startDate: defineds.startOfMonth,
            endDate: defineds.endOfMonth
        })
    },
    {
        label: "Último mes",
        range: () => ({
            startDate: defineds.startOfLastMonth,
            endDate: defineds.endOfLastMonth
        })
    },
    {
        label: "Este año",
        range: () => ({
            startDate: defineds.startOfYear,
            endDate: defineds.endOfYear
        })
    },
    {
        label: "Último año",
        range: () => ({
            startDate: defineds.startOflastYear,
            endDate: defineds.endOflastYear
        })
    }
    ];
    return customDateObjects;
};

/* ----- Principal function ----- */
function ModalExport({ title, textButton, openModal, closeModal }) {

    let [ csvData , setCSVData] = React.useState([])

    const sideBar = sideBarOptions();
    
    const staticRanges = [
        // ...defaultStaticRanges,
        ...createStaticRanges(sideBar)
    ];

    const [state, setState] = React.useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 7),
            key: 'selection'
        }
    ]);

    const buttonRef = React.useRef()

    const submit = () => {
        const paramsData = {
            startDate: state[0].startDate.getFullYear() + '-' + (state[0].startDate.getMonth()+1) + '-' + state[0].startDate.getDate(),
            endDate: state[0].endDate.getFullYear() + '-' + (state[0].endDate.getMonth()+1) + '-' + state[0].endDate.getDate()
        }
        let config = {
            headers: { ...authHeader(), 'Content-Type': 'application/json' }
        }
        axios.get('/api/invoicesreport', { params: paramsData }, config)
        .then(reportData => {
            let report = reportData.data.report;
            setCSVData(report)
            buttonRef.current.link.click()
            closeModal()
        })
        .catch(err => {
            alert('Hubo un error...');
        })
    }

    return(
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openModal}
            onClose={closeModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={openModal}>
                <ModalContent>
                    <ModalTitle>
                        {title}
                        <ConfirmButton onClick={submit}>CONFIRMAR</ConfirmButton>
                        <CSVLink data={csvData} filename={`Reporte_Facturas(${String(state[0].startDate.getDate()).padStart(2, '0') + '-' + String(state[0].startDate.getMonth()+1).padStart(2, '0') + '-' + state[0].startDate.getFullYear()}_${String(state[0].endDate.getDate()).padStart(2, '0') + '-' + String(state[0].endDate.getMonth()+1).padStart(2, '0') + '-' + state[0].endDate.getFullYear()}).csv`} ref={buttonRef} style={{display: 'none'}} ></CSVLink>
                    </ModalTitle>
                    <ModalBody>
                    <DateRangePicker
                        onChange={item => setState([item.selection])}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={1}
                        ranges={state}
                        direction="horizontal"
                        locale={es}
                        staticRanges={staticRanges}
                        inputRanges={[]}
                    />
                    </ModalBody>
                </ModalContent>
            </Fade>
        </ModalStyled>
    );
}

export default ModalExport;