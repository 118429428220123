/* General imports */
import React from 'react';
import styled from 'styled-components';
import { productActions } from './ProductActions';
import { alertActions } from '../../alert/AlertActions';
import { connect } from 'react-redux';
import axios from 'axios';
import  { authHeader } from '../../app/auth-header';
import BasicEditor from '../../textEditors/basicEditor';

/* Components imports */
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import TagSelect from '../../form/TagSelect';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import AddShoppingCartOutlinedIcon from '@material-ui/icons/AddShoppingCartOutlined';
import ImagesInput from '../../form/ImagesInput';
import ImageInput from '../../form/ImageInput';
import SupplierBox from './SupplierBox';
import Features from './Features';


/* Styled components */
const Root = styled.div`
    position: relative;
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 200px 550px 350px;
    grid-template-rows: 50px 200px 200px 70px;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 20px;
    background-color: white;
    gap: 10px 20px;
`

const GridBox = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: ${(props) => props.flexDirection ? props.flexDirection : 'column'};
    grid-column: ${(props) => props.gridColumn ? props.gridColumn : 'inherit'};
    grid-row: ${(props) => props.gridRow ? props.gridRow : 'inherit'};
    align-items: ${(props) => props.alignItems ? props.alignItems : 'center'};
    justify-content: ${(props) => props.justifyContent ? props.justifyContent : 'center'};
    padding: ${(props) => props.padding ? props.padding : 0};
    box-sizing: border-box;
    /* border: 1px solid grey; */
`

const StyledAddShoppingCartIcon = styled(AddShoppingCartIcon)`
    color: blue;
`

const StyledTextField = styled(TextField)`
    width: ${(props) => props.width ? props.width : 'inherit'};
    font-size: 0.5rem;
`

const IconAction = styled.div`
    position: absolute;
    display: flex;
    width: 25px;
    height: 25px;
    top: 5px;
    right: ${(props) => props.right ? props.right : '10px'};
    align-items: center;
    justify-content: center;
    
    svg {
        font-size: 25px;
        cursor: pointer;
        z-index: 999;
    }
`

const BoxImages = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

/* ----- Principal function ----- */
function NewProduct({user, rowData, error, getProducts, createProduct, updateProduct, currencyUnits, quantityUnits, trademarks, getTrademarks, getCurrencyUnits, getQuantityUnits, alertMessage, history }) {

    const getRowDataTags = (tags) => {
        let responseTags = []
        tags.forEach(tg => {
            responseTags.push({
                label: tg.name,
                value: tg.idTag
            })
        });
        return responseTags
    } 

    const [ images, setImages ] = React.useState(rowData ? rowData.img ? rowData.img.images : [] : []);

    const [ thumbnail, setThumbnail ] = React.useState(rowData ? rowData.img ? rowData.img.thumbnail : null : null);

    const [editorLoaded, setEditorLoaded] = React.useState(false);

    const [ dataForm, setDataForm ] = React.useState(rowData ? 
        {
            code: rowData.code,
            barcode: rowData.barcode,
            name: rowData.name,
            idQuantityUnit: rowData.idQuantityUnit,
            idCurrencyUnit: rowData.idCurrencyUnit,
            description: rowData.description,
            salePrice: rowData.salePrice,
            costPrice: rowData.costPrice,
            idCategory: rowData.idCategory,
            features: rowData.features,
            suppliers: rowData.suppliers,
            promo: rowData.promo,
            discount: rowData.discount,
            ecommerce: rowData.ecommerce,
            idTrademark: rowData.idTrademark,
            initialStock: 0,
            tags: getRowDataTags(rowData.tags),
            byproducts: rowData.byproducts,
            video: rowData.video,
            aptitudes: rowData.aptitudes ?
                {
                    aptitude1: rowData.aptitudes.aptitude1,
                    aptitude2: rowData.aptitudes.aptitude2,
                    aptitude3: rowData.aptitudes.aptitude3
                }
                :
                {
                    aptitude1: '',
                    aptitude2: '',
                    aptitude3: ''
                }
        }
        : 
        { 
            code: '',
            barcode: '',
            name: '',
            idQuantityUnit: '',
            idCurrencyUnit: '',
            description: '',
            salePrice: 0,
            costPrice: 0,
            idCategory: '',
            features: [],
            suppliers: [],
            promo: '',
            discount: 0,
            ecommerce: true,
            idTrademark: '',
            initialStock: 0,
            tags: [],
            byproducts: '',
            video: '',
            aptitudes: {
                aptitude1: '',
                aptitude2: '',
                aptitude3: ''
            }
        }
    )

    const [ categories, setCategories ] = React.useState(null);

    const handleChangeTag = (newValue, actionMeta) => {
        setDataForm({
            ...dataForm,
            tags: newValue
        });
    };

    const handleCheckEcommerce = (event) => {
        setDataForm({
            ...dataForm,
            ecommerce: event.target.checked
        });
      };

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setDataForm({
            ...dataForm,
            [name]: value
        });
    }

    const handleChangeAptitudes = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setDataForm({
            ...dataForm,
            aptitudes: {
                ...dataForm.aptitudes,
                [name]: value
            }
        });
    }

    const handleConfirmAddSupplier = async(data) => {
        let newData = dataForm.suppliers;
        newData.push(data)
        setDataForm({
            ...dataForm,
            suppliers: newData
        })
    }

    const removeSupplier = (e) => {
        const { idsupplier, name } = e.target.dataset;
        // eslint-disable-next-line no-restricted-globals
        if(confirm('¿Desea sacar de la lista al proveedor '+name+'?')){
            const newData = dataForm.suppliers.filter(sup => parseInt(sup.idSupplier) !== parseInt(idsupplier));
            setDataForm({
                ...dataForm,
                suppliers: newData
            })
        }
    }

    const handleEditor = (e, dataeditor) => {
        setDataForm({
            ...dataForm,
            description: dataeditor
        })
    }

    const handleEditorPromo = (e, dataeditor) => {
        setDataForm({
            ...dataForm,
            promo: dataeditor
        })
    }

    const requiredFields = ['name', 'idQuantityUnit', 'idCurrencyUnit', 'salePrice', 'costPrice', 'idCategory', 'suppliers']

    const checkForm = async () => {
        let flag = true
        let message = 'Faltan completar campos necesarios'
        requiredFields.forEach(element => {
            if(dataForm[element] === null || dataForm[element] === '' || dataForm[element] === undefined || dataForm[element].length === 0){
                flag = false;
            }
        });
        return { flag, message }
    }

    const handleThumbnail = (name, url) => {
        setThumbnail(url);
    }

    const saveProduct = async () => {
        const {flag, message} = await checkForm();
        if(flag){
            let savePromise = new Promise((resolve, reject) => {
                if(rowData){
                    updateProduct(rowData.idProduct, {
                        ...dataForm,
                        img: {
                            thumbnail: thumbnail,
                            images: images
                        },
                        updatedUser: user.idUser
                    });
                } else {
                    createProduct({
                        ...dataForm,
                        img: {
                            thumbnail: thumbnail,
                            images: images
                        },
                        createdUser: user.idUser
                    });
                }
                resolve("Producto guardado");    
            });
            savePromise.then(()=> {
                getProducts();
                history.push('/admin/stocksystem/product');    
            });
        } else {
            alertMessage(message);
        } 
    } 

    const cancelProduct = () => {
        history.push('/admin/stocksystem/product')
    }

    const handleConfirmAddFeature = (data) => {
        let newData = dataForm.features;
        newData.push(data)
        setDataForm({
            ...dataForm,
            features: newData
        });
    }

    const deleteFeature = (e) => {
        const { name } = e.target.dataset;
        // eslint-disable-next-line no-restricted-globals
        if(confirm('¿Desea sacar de la lista la característica '+name+'?')){
            const newData = dataForm.features.filter(fea => fea.name !== name);
            setDataForm({
                ...dataForm,
                features: newData
            })
        }
    }

    React.useEffect(() => {
        let configHeader = {
            headers: { ...authHeader(), 'Content-Type': 'application/json' }
        }
        if(!categories){
            axios.get('/api/categoryListOptions',configHeader)
            .then(categoriesData => {
                setCategories(categoriesData.data.categoriesList)
            })
            .catch(err => {
                alertMessage('Hubo un error al cargar las categorías')
            })
        }

        if(!currencyUnits){
            getCurrencyUnits()
        }
        if(!quantityUnits){
            getQuantityUnits()
        }
        if(!trademarks){
            getTrademarks()
        }
        
    }, [])

    React.useEffect(() => {
        setEditorLoaded(true);
      }, []);

    return(
        <Root>
            <IconAction right='40px'><SaveIcon onClick={saveProduct} /></IconAction>
            <IconAction ><CancelIcon onClick={cancelProduct} /></IconAction>
            <GridBox gridColumn="1/2" gridRow="1/5" justifyContent="flex-start">
                <TextField label="CÓDIGO" id="code" name="code" value={dataForm.code} onChange={handleChange} size="small" variant="outlined" fullWidth margin="dense" />
                <TextField label="CÓDIGO DE BARRAS" id="barcode" name="barcode" value={dataForm.barcode} onChange={handleChange} size="small" variant="outlined" fullWidth margin="dense" />
                <TextField label="NOMBRE DEL PRODUCTO" id="name" name="name" value={dataForm.name} onChange={handleChange} size="small" variant="outlined" fullWidth margin="dense" required />
                <TextField label="UNIDAD DE MEDIDA" select id="idQuantityUnit" name="idQuantityUnit" value={dataForm.idQuantityUnit} onChange={handleChange} size="small" variant="outlined" fullWidth margin="dense" required>
                    {
                        quantityUnits &&
                        quantityUnits.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.key ? `${option.key}(${option.name})` : option.name}
                            </MenuItem>
                        ))
                    }
                </TextField>
                <TextField label="MONEDA" select id="idCurrencyUnit" name="idCurrencyUnit" value={dataForm.idCurrencyUnit} onChange={handleChange} size="small" variant="outlined" fullWidth margin="dense" required>
                    {
                        currencyUnits &&
                        currencyUnits.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.key ? `${option.key}(${option.name})` : option.name}
                            </MenuItem>
                        ))
                    }
                </TextField>
                <TextField label="CATEGORÍA" select id="idCategory" name="idCategory" value={dataForm.idCategory} onChange={handleChange} size="small" variant="outlined" fullWidth margin="dense" required>
                    {
                        categories &&
                        categories.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.key ? `${option.key}(${option.name})` : option.name}
                            </MenuItem>
                        ))
                    }
                </TextField>
                <TextField label="MARCA" select id="idTrademark" name="idTrademark" value={dataForm.idTrademark} onChange={handleChange} size="small" variant="outlined" fullWidth margin="dense" required>
                    {
                        trademarks &&
                        trademarks.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.key ? `${option.key}(${option.name})` : option.name}
                            </MenuItem>
                        ))
                    }
                </TextField>
                <TagSelect
                    isMulti
                    cacheOptions
                    handleChange={handleChangeTag}
                    value={dataForm.tags}
                    url="/api/tagslistoptions"
                    limit={20}
                    margin="8px 0 4px 0"
                    placeholder="ETIQUETAS"
                />
                <TextField label="SUBPRODUCTOS" helperText="IDs separados por coma" id="byproducts" name="byproducts" value={dataForm.byproducts} onChange={handleChange} size="small" variant="outlined" fullWidth margin="dense" />
                <FormControlLabel 
                    control={
                        <Checkbox 
                            checked={dataForm.ecommerce}
                            onChange={handleCheckEcommerce}
                            inputProps={{ 'aria-label': 'controlled' }} 
                            icon={<AddShoppingCartOutlinedIcon />} 
                            checkedIcon={<StyledAddShoppingCartIcon />} 
                        />} 
                    label="Ecommerce" 
                />
            </GridBox>
            <GridBox gridColumn="2/3" gridRow="1/2" flexDirection="row" justifyContent="space-between">
                <StyledTextField label="PRECIO COSTO" id="costPrice" name="costPrice" value={dataForm.costPrice} onChange={handleChange} size="small" variant="outlined" width={rowData ? '22.5%' : '18%'} margin="dense" />
                <StyledTextField label="PRECIO VENTA" id="salePrice" name="salePrice" value={dataForm.salePrice} onChange={handleChange} size="small" variant="outlined" width={rowData ? '22.5%' : '18%'} margin="dense" />
                <StyledTextField label="DESCUENTO" id="discount" name="discount" value={dataForm.discount} onChange={handleChange} size="small" variant="outlined" width={rowData ? '22.5%' : '18%'} margin="dense" />
                {
                    !rowData && <StyledTextField label="STOCK INICIAL" id="initialStock" name="initialStock" value={dataForm.initialStock} onChange={handleChange} size="small" variant="outlined" width="18%" margin="dense" />
                }
            </GridBox>
            <GridBox gridColumn="3/4" gridRow="1/5" justifyContent="space-between" padding="10px 0">
                <BoxImages>
                    <ImageInput image={thumbnail} handleImage={handleThumbnail} name='thumbnail' />
                    <ImagesInput images={images} handleImages={setImages} name='images' />
                </BoxImages>
                <TextField label="VIDEO" id="video" name="video" value={dataForm.video} onChange={handleChange} size="small" variant="outlined" fullWidth margin="dense" />
                <SupplierBox suppliers={dataForm.suppliers} removeSupplier={removeSupplier} handleConfirmAddSupplier={handleConfirmAddSupplier} alertMessage={alertMessage} />
                <Features features={dataForm.features} handleConfirmAddFeature={handleConfirmAddFeature} deleteFeature={deleteFeature} alertMessage={alertMessage} />
            </GridBox>
            <GridBox gridColumn="2/3" gridRow="2/3">
                <BasicEditor
                    name="text"
                    value={dataForm.description}
                    onChange={handleEditor}
                    editorLoaded={editorLoaded}
                    maxContentHeight="155px"
                    minContentHeight="155px"
                    placeholder="Ingrese aquí la descripción del producto..."
                />
            </GridBox>
            <GridBox gridColumn="2/3" gridRow="3/4">
                <BasicEditor
                    name="text"
                    value={dataForm.promo}
                    onChange={handleEditorPromo}
                    editorLoaded={editorLoaded}
                    maxContentHeight="155px"
                    minContentHeight="155px"
                    placeholder="Ingrese aquí la breves ventajas del producto..."
                />
            </GridBox>
            <GridBox gridColumn="2/3" gridRow="4/5" flexDirection="row" justifyContent="space-between">
                <StyledTextField width="30%" label="APTITUD 1" id="aptitude1" name="aptitude1" value={dataForm.aptitudes.aptitude1} onChange={handleChangeAptitudes} size="small" variant="outlined" margin="dense" />
                <StyledTextField width="30%" label="APTITUD 2" id="aptitude2" name="aptitude2" value={dataForm.aptitudes.aptitude2} onChange={handleChangeAptitudes} size="small" variant="outlined" margin="dense" />
                <StyledTextField width="30%" label="APTITUD 3" id="aptitude3" name="aptitude3" value={dataForm.aptitudes.aptitude3} onChange={handleChangeAptitudes} size="small" variant="outlined" margin="dense" />
            </GridBox>
        </Root>
    );
}

function mapState(state) {
    const { currencyUnits, quantityUnits, trademarks } = state.product;
    const { user } = state.login;
    return { user, currencyUnits, quantityUnits, trademarks };
}

const actionCreators = {
    getProducts: productActions.getProducts,
    createProduct: productActions.createProduct,
    updateProduct: productActions.updateProduct,
    getCurrencyUnits: productActions.getCurrencyUnits,
    getQuantityUnits: productActions.getQuantityUnits,
    getTrademarks: productActions.getTrademarks,
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(NewProduct);