/* General imports */
import React from 'react'
import styled from 'styled-components';
import axios from 'axios';
import  { authHeader } from '../../app/auth-header';

/* Components imports */
import Spinner from '../../spinners/LoaderSpinner'
import { TextField } from '@material-ui/core';
import LogoWhatsapp from '../../images/whatsapp-logo.png'
import FileInput from './FileInput';

/* Styled Components */
const Root = styled.form`
    position: relative;
    width:100%;
    height: auto;
    min-height: 100vh;
    background: ${(props) => props.linearBackground ? 'linear-gradient(135deg, #2196F3 0%, #001554 100%)' : '#001554'};
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 20px 400px 50px 50px;
    grid-template-rows: 200px 70px 70px 70px auto;
    gap: 20px 50px;
    box-sizing: border-box;
    font-family: 'IBM Plex Sans';
    @media (max-width: 600px) {
        display: flex;
        flex-direction: column;
        padding: 100px 20px;
    }
`

const BoxTitle = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    grid-column: 1/3;
    /* border: 1px solid white; */
    @media (max-width: 600px) {
        box-sizing: border-box;
        padding-right: 40px;
    }
`

const Title = styled.h2`
    font-size: 3.5rem;
    color: #FFF;
    font-weight: 300;
    margin: 0;
    @media (max-width: 600px) {
        font-size: 2.5rem;
    }
`

const Subtitle = styled.span`
    font-size: 1.5rem;
    color: #FFF;
    font-weight: 300;
    @media (max-width: 600px) {
        font-size: 1rem;
    }
`

const Box = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    ${(props) => props.gridColumn ? `grid-column: ${props.gridColumn};` : ''}
    @media (max-width: 600px) {
        grid-column: unset;
    }
    /* border: 1px solid white; */
`

const LargeBox = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column: 1/3;
    /* border: 1px solid white; */
    @media (max-width: 600px) {
        grid-column: auto;
    }
`

const StyledTextField = styled(TextField)`
    label, input {
        color: #FFF;
    }
    .MuiInput-underline:before {
        border-color: #2196F3;
    }
    .MuiOutlinedInput-notchedOutline {
        border-color: #2196F3;
    }
    .MuiInput-underline:hover:not(.Mui-disabled):before {
        border-color: #2196F3;
    }
`

const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 216px;
    height: 48px;
    font-size: 20px;
    background: #2196F3;
    border-radius: 24px;
    color: #FFF;
    margin: 50px 0;
    cursor: pointer;
    border: none;
    box-shadow: none;
    @media (max-width: 600px) {
        width: 150px;
        height: 40px;
        font-size: 16px;
        margin: 20px 0;
    }
`

const Whatsapp = styled.a`
    position: absolute;
    top: 70px;
    right: 300px;
    width: 80px;
    height: 80px;
    z-index: 9;
    img {
        object-fit: contain;
        object-position: center;
        width: 100%;
        height: 100%;
    }
    @media (max-width: 600px) {
        top: 100px;
        right: 30px;
        width: 50px;
        height: 50px;
    }
`
    

/* Principal function*/
function ContactUs({idsection, match}) {

    const [contactUsData, setContactUsData] = React.useState(null);

    const [file, setFile] = React.useState(null);

    const [dataForm, setDataForm] = React.useState({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        issue: '',
        message: ''
    })

    console.log(match)

    const [submitted, setSubmitted] = React.useState(false);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setDataForm({
            ...dataForm,
            [name]: value
        })
    }

    const onChangeFile = (e) => {
        const {files} = e.target;
        if(files.length > 0){
            setFile(files[0]);
        }
    }

    const handleForm = (e) => {

        e.preventDefault()
        if(match && match.path == '/rrhh'){
            var formData = new FormData();
            formData.append("attached", file);
            formData.append("metaData", JSON.stringify(dataForm));
            axios.post( '/api/attachedemail' , formData)
            .then(response => {
                setDataForm({
                    firstname: '',
                    lastname: '',
                    email: '',
                    phone: '',
                    issue: '',
                    message: ''
                });
                setFile(null);
                setSubmitted(true);
                alert("Mensaje enviado");
            })
            .catch(error => {
                alert('Hubo un error con el envío...')
            }) 
        } else {
            axios.post( '/api/email' , dataForm)
            .then(response => {
                setDataForm({
                    firstname: '',
                    lastname: '',
                    email: '',
                    phone: '',
                    issue: '',
                    message: ''
                });
                setFile(null);
                setSubmitted(true);
                alert("Mensaje enviado");
            })
            .catch(error => {
                alert('Hubo un error con el envío...')
            }) 
        }
        
    }

    React.useEffect(() => {
        if(!contactUsData){
            let configHeader = {
                headers: { ...authHeader(), 'Content-Type': 'application/json' }
            }
            axios.get('/api/sections/' + idsection,configHeader)
            .then(sectionData => {
                setContactUsData(sectionData.data.section.data);
            })
            .catch(err => {
                alert('Hubo un error...');
            })
        }
    }, [contactUsData, idsection])


    return (
        !contactUsData ? <Spinner spinnerType='ThreeDots' />
        :
        <Root  onSubmit={handleForm} linearBackground={contactUsData.linearBackground}>
            <Whatsapp href={`https://api.whatsapp.com/send?phone=${contactUsData.whatsapp}`} target="_blank">
                <img src={LogoWhatsapp} alt="logo_whatsapp"  />
            </Whatsapp>
            <BoxTitle>
                <Title>{contactUsData.title}</Title>
                <Subtitle>{contactUsData.subtitle}</Subtitle>
            </BoxTitle>
            <Box>
                <StyledTextField id="firstname" name="firstname" value={dataForm.firstname} label="Nombre" required fullWidth onChange={handleChange} />
            </Box>
            <Box>
                <StyledTextField id="lastname" name="lastname" value={dataForm.lastname} label="Apellido" required fullWidth onChange={handleChange} />
            </Box>
            <Box>
                <StyledTextField id="email" name="email" value={dataForm.email} label="E-mail" required fullWidth onChange={handleChange} />
            </Box>
            <Box>
                <StyledTextField id="phone" name="phone" value={dataForm.phone} label="Teléfono" required fullWidth onChange={handleChange} />
            </Box>
            <LargeBox>
                <StyledTextField id="issue" name="issue" value={dataForm.issue} label="Asunto" required fullWidth onChange={handleChange} />
            </LargeBox>
            <LargeBox>
                <StyledTextField id="message" name="message" value={dataForm.message} label="Consulta" multiline rows={10} variant="outlined" required fullWidth onChange={handleChange} />
            </LargeBox>
            {
                match && match.path == '/rrhh' &&
                <LargeBox>
                    <FileInput file={file} onChange={onChangeFile} />
                </LargeBox>
            }
            <Button type="submit">Enviar</Button>
        </Root>
    )
}

export default ContactUs;