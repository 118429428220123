/* General imports */
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { devices } from '../app/config';
import { sidebarCartActions } from '../SidebarCart/SidebarCartActions';
import axios from 'axios';
import { alertActions } from '../alert/AlertActions'
import { withRouter } from 'react-router';

/* Components imports */
import Logo from '../logos/logo.png'
import NavAnimate from '../navs/NavAnimate'
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import NoImage from '../images/no-image.png';
import LockIcon from '@material-ui/icons/Lock';
import ModalLogin from '../login/ModalLogin';

/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled components */
const Root = styled.div`
    width: 100%;
    height: auto;
    min-height: 100vh;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    box-sizing: border-box;
    padding: ${(props) => props.padding ? props.padding : "200px"};
    flex-direction: ${(props) => props.flexDirection ? props.flexDirection : "row"};
`

const Box = styled.div`
    width: ${(props) => props.width ? props.width : '100%'};
    height: auto;
    min-height: 200px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    h2 {
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        font-size: 20px;
        font-weight: 300;
        text-decoration: none;
        color: rgb(0, 0, 0);
        line-height: 50px;
        margin: 0;
    }
`

const ItemsList = styled.ul`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    height: calc(100% - 300px);
`

const BoxTitle = styled.div`
    display: flex;
    width: 100%;
    height: auto;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    h2 {
        border: none;
    }
    button {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 0px 20px;
        background: transparent;
        border: none;
        outline: none;
        background: transparent;
    }
`

const CancelItem = styled(HighlightOffOutlinedIcon)`
    display: flex;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    z-index: 9999999;
    opacity: 0;
    transition: opacity 500ms ease-in-out;
`

const Item = styled.li`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    padding: 20px 0;
    border-bottom: 1px solid  rgba(0,0,0,0.1);
    &:hover ${CancelItem} {
        opacity: 1;
    }
`

const ItemImage = styled.img`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
`

const ItemData = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: calc(100% - 100px);
    box-sizing: border-box;
    padding: 0 20px;
    span {
        margin: 2px 0;
        font-size: 12px;
    }
    input {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 5px;
        box-sizing: border-box;
        margin: 5px 0;
        width: 50px;
    }
`

const SubResume = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid  rgba(0,0,0,0.1);
`

const SubResumeRow = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: rgba(0,0,0,0.5);
    span {
        margin: 15px 0;
    }
`

const ResumeTotal = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;

`

const ResumeTotalRow = styled(SubResumeRow)`
    font-size: 15px;
    color: black;
`

const BuyButton = styled.button`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 80%;
    box-sizing: border-box;
    padding: 10px 20px;
    margin: 20px 0;
    cursor: pointer;
    background: transparent;
    border: 2px solid black;
`

const SecureIcon = styled(LockIcon)`
    display: flex;
    position: relative;
    cursor: pointer;
    width: 15px;
    height: 15px;
    margin-right: 5px;
`

const Failure = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    background: rgba(244,67,54,0.3);
    color: red;
    border-radius: 10px;
    border: 1px solid #f44336;
    font-size: 14px;
    padding: 20px;
    opacity: 0.5;
    text-align: center;
`

const MPMessage = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 14px;
    color: rgba(0,0,0,0.8);
    text-align: center;
`

/* ----- Principal function ----- */
function CheckOut({cartitems, removeToCart, setAmount, listpages, alertMessage, match, loggedIn, user, history}) {

    const [ openLogin, setOpenLogin ] = React.useState(false);

    const removeItem = (e) => {
        const { index } = e.target.dataset;
        removeToCart(index);
    }

    const setAmountItem = (e) => {
        if(e.target.value > 0){
            const { index } = e.target.dataset;
            setAmount(index, e.target.value);
        }
    }

    const getSubtotal = () => {
        let subtotal = 0;
        cartitems.forEach(element => {
            subtotal = subtotal + (element.amount * element.salePrice);
        });
        return subtotal;
    }

    const onBuy = () => {
        if(loggedIn){
            if(parseFloat(getSubtotal()) !== parseFloat(0)){
                axios.post( '/api/orders' , {user: user, utmSource: "ecommerce", items: cartitems})
                .then(orderResponse => {
                    const orderData = orderResponse.data.result[0];
                    axios.post( '/api/mp/create_preference' , {idOrder: orderData.idOrder, items: cartitems})
                    .then(response => {
                         window.location.replace(response.data.body.init_point); 
                    })
                    .catch(error => {
                        alertMessage(error.toString())
                    }); 
                    
                })
                .catch(error => {
                    alertMessage(error.toString())
                });
            } else {
                alertMessage("El monto no puede ser $0.00")
            }
        } else {
            setOpenLogin(true);
        }
        
    }

    const onHandleAction = () => {
        alert("Handleaction")
    }

    const openModal = () => {
        setOpenLogin(true);
    }

    const closeModal = () => {
        setOpenLogin(false);
    }

    const prevPage = () => {
        history.goBack();
    }

    return(
        <React.Fragment>
            <Desktop>
                <Root>
                    {
                       listpages && <NavAnimate data={{logo: Logo, items: listpages}} />
                    }
                    <Box width="65%">
                        <h2>Mi carrito</h2>
                        <ItemsList>
                            {
                                cartitems.map((cartItem, index) => 
                                <Item>
                                    <CancelItem data-index={index} onClick={removeItem}  />
                                    <ItemImage src={cartItem.img && cartItem.img.images && cartItem.img.images.length > 0 ? cartItem.img.images[0] : NoImage} alt={`item_${index}`} />
                                    <ItemData>
                                        <span>{cartItem.name}</span>
                                        <span><strong>${cartItem.salePrice}</strong></span>
                                        <span>{cartItem.description.length < 50 ? cartItem.description :  `${cartItem.description.substring(0,50)}...`}</span>
                                        <input type="number" data-index={index} value={cartItem.amount} onChange={setAmountItem} />
                                    </ItemData>
                                </Item>   
                                )
                            }
                        </ItemsList>
                    </Box>
                    <Box width="30%">
                        <h2>Resumen del pedido</h2>
                        <SubResume>
                            <SubResumeRow>
                                <span>Subtotal</span>
                                <span>${getSubtotal()}</span>
                            </SubResumeRow>
                            <SubResumeRow>
                                <span>Envío</span>
                                <span>A definir</span>
                            </SubResumeRow>
                        </SubResume>
                        <ResumeTotal>
                            <ResumeTotalRow>
                                <span>Total</span>
                                <span>${getSubtotal()}</span>
                            </ResumeTotalRow>
                        </ResumeTotal>
                        <BuyButton onClick={onBuy}><SecureIcon /> Finalizar compra</BuyButton>
                        <MPMessage>Recuerda volver al sitio cuando termine el pago con MercadoPago</MPMessage>
                        {
                            match.params.failure === "failure" && <Failure>Ha ocurrido un error o el pago fue rechazado. Intenta nuevamente.</Failure>
                        }
                    </Box>
                    {
                        openLogin && <ModalLogin openModal={openModal} closeModal={closeModal} handleAction={onHandleAction} />
                    }
                </Root>
            </Desktop>
            <Tablet>
                <Root padding="200px 100px 100px 100px">
                    <Box width="65%">
                        <h2>Mi carrito</h2>
                        <ItemsList>
                            {
                                cartitems.map((cartItem, index) => 
                                <Item>
                                    <CancelItem data-index={index} onClick={removeItem}  />
                                    <ItemImage src={cartItem.img ? cartItem.img : NoImage} alt={`item_${index}`} />
                                    <ItemData>
                                        <span>{cartItem.name}</span>
                                        <span><strong>${parseFloat(cartItem.salePrice)}</strong></span>
                                        <span>{cartItem.description.length < 50 ? cartItem.description :  `${cartItem.description.substring(0,50)}...`}</span>
                                        <input type="number" data-index={index} value={cartItem.amount} onChange={setAmountItem} />
                                    </ItemData>
                                </Item>   
                                )
                            }
                        </ItemsList>
                    </Box>
                    <Box width="30%">
                        <h2>Resumen del pedido</h2>
                        <SubResume>
                            <SubResumeRow>
                                <span>Subtotal</span>
                                <span>${parseFloat(getSubtotal())}</span>
                            </SubResumeRow>
                            <SubResumeRow>
                                <span>Envío</span>
                                <span>A definir</span>
                            </SubResumeRow>
                        </SubResume>
                        <ResumeTotal>
                            <ResumeTotalRow>
                                <span>Total</span>
                                <span>${parseFloat(getSubtotal())}</span>
                            </ResumeTotalRow>
                        </ResumeTotal>
                        <BuyButton onClick={onBuy}><SecureIcon /> Finalizar compra</BuyButton>
                    </Box>
                </Root>
            </Tablet>
            <Mobile>
                <Root flexDirection="column" padding="150px 20px 20px 20px">
                    <Box width="100%">
                        <BoxTitle>
                            <h2>Mi carrito</h2>
                            <button onClick={prevPage}>
                                Volver
                            </button>
                        </BoxTitle>
                        <ItemsList>
                            {
                                cartitems.map((cartItem, index) => 
                                <Item>
                                    <CancelItem data-index={index} onClick={removeItem}  />
                                    <ItemImage src={cartItem.img ? cartItem.img : NoImage} alt={`item_${index}`} />
                                    <ItemData>
                                        <span>{cartItem.name}</span>
                                        <span><strong>${cartItem.salePrice}</strong></span>
                                        <span>{cartItem.description.length < 50 ? cartItem.description :  `${cartItem.description.substring(0,50)}...`}</span>
                                        <input type="number" data-index={index} value={cartItem.amount} onChange={setAmountItem} />
                                    </ItemData>
                                </Item>   
                                )
                            }
                        </ItemsList>
                    </Box>
                    <Box width="100%">
                        <h2>Resumen del pedido</h2>
                        <SubResume>
                            <SubResumeRow>
                                <span>Subtotal</span>
                                <span>${getSubtotal()}</span>
                            </SubResumeRow>
                            <SubResumeRow>
                                <span>Envío</span>
                                <span>A definir</span>
                            </SubResumeRow>
                        </SubResume>
                        <ResumeTotal>
                            <ResumeTotalRow>
                                <span>Total</span>
                                <span>${getSubtotal()}</span>
                            </ResumeTotalRow>
                        </ResumeTotal>
                        <BuyButton onClick={onBuy}><SecureIcon /> Finalizar compra</BuyButton>
                    </Box>
                </Root>
                
            </Mobile>
        </React.Fragment>
    );
}

function mapState(state) {
    const { error, isFetching, cartitems} = state.sidebarcart;
    const { loggedIn, user } = state.login;
    return { error, isFetching, cartitems, loggedIn, user };
}

const actionCreators = {
    removeToCart: sidebarCartActions.removeToCart,
    setAmount: sidebarCartActions.setAmount,
    alertMessage: alertActions.error
}

export default withRouter(connect(mapState, actionCreators)(CheckOut));