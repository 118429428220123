import { registerConstants } from './RegisterConstants';
import { alertActions } from '../alert/AlertActions';
import axios from 'axios';
import  { authHeader } from '../app/auth-header';
import PersonIcon from '../icons/userCircle.svg';
import LockIcon from '../icons/lock.svg';

export const registerActions = {
    register,
    modalRegister,
    getFields
};

/* 
@params
    user (datos del usuario)
    next (path donde redirecciona luego del registro)
*/

function register(user, history, next) {
    return dispatch => {
        dispatch(request(user));
        axios
        .post( '/api/users/register' , {
            firstname: user.firstname,
            lastname: user.lastname,
            documentType: user.documentType,
            documentNumber: user.documentNumber,
            birthday: user.birthday,
            tel: user.tel,
            email: user.email,
            username: user.username,
            password: user.password,
            createUser: user.createUser,
            enabled: user.enabled,
            idRole: user.idRole,
            roleData: user.roleData,
            invoiceData: user.invoiceData
            })
        .then(response => {
            dispatch(success(user));
            history.push(next);
            dispatch(alertActions.success('Registration successful'));
        })
        .catch(error => {
            dispatch(alertActions.error(error.toString()));
        })
    };

    function request(user) { return { type: registerConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: registerConstants.REGISTER_SUCCESS, user } }
    /* function failure(error) { return { type: registerConstants.REGISTER_FAILURE, error } } */
}

/* 
@params
    user (datos del usuario)
    next (path donde redirecciona luego del registro)
*/

function modalRegister(user) {
    return dispatch => {
        dispatch(request(user));
        axios
        .post( '/api/users/register' , {
            firstname: user.firstname,
            lastname: user.lastname,
            documentType: user.documentType,
            documentNumber: user.documentNumber,
            birthday: user.birthday,
            tel: user.tel,
            email: user.email,
            username: user.username,
            password: user.password,
            createUser: user.createUser,
            enabled: user.enabled,
            idRole: user.idRole,
            roleData: user.roleData,
            invoiceData: user.invoiceData
            })
        .then(response => {
            dispatch(success(user));
            dispatch(alertActions.success('¡Registración exitosa!'));
        })
        .catch(error => {
            dispatch(alertActions.error(error.toString()));
        })
    };

    function request(user) { return { type: registerConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: registerConstants.REGISTER_SUCCESS, user } }
    /* function failure(error) { return { type: registerConstants.REGISTER_FAILURE, error } } */
}

function getFields() {

    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    return dispatch => {
        dispatch({ type: registerConstants.FETCHING_FORMFIELDS });
        axios.get('/api/registerFields', config)
        .then(response => {
            const registerFields = response.data.registerFields;
            registerFields.forEach(element => {
                if(element.icon === 'PersonIcon'){
                    element.icon = PersonIcon
                }
                if(element.icon === 'LockIcon'){
                    element.icon = LockIcon
                }
            });
            dispatch({ type: registerConstants.SUCCESS_FORMFIELDS, registerFields });
        })
        .catch(error => {
            dispatch(alertActions.error(error));
        })
    }
}

