/* General imports */
import React from 'react'
import styled from 'styled-components'
import InvoiceADetail from './InvoiceADetail'
import InvoiceBDetail from './InvoiceBDetail'
import ReceiptADetail from './ReceiptADetail'

/* Components imports */
import TicketDetail from './TicketDetail'

const ContainerStep = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`

const VoucherDetail = ({idForm, rowData, onBack, handleOnFinishStep, handleOnFinishStepTicket}) => {

    const getFormData = (idFormData) => {
        switch (parseInt(idFormData)) {
            case 19:
                return <TicketDetail rowData={rowData} onBack={onBack} handleFinish={handleOnFinishStepTicket} />
                break;
            case 29:
                return <InvoiceADetail rowData={rowData} onBack={onBack} handleFinish={handleOnFinishStep} />
                break;
            case 30:
                return <InvoiceBDetail rowData={rowData} onBack={onBack} handleFinish={handleOnFinishStep} />
                break;
            case 6:
                return <h2>FACTURA C</h2>
                break;
            case 31:
                return <h2>NOTA DE CRÉDITO</h2>
                break;
            case 32:
                return <h2>ORDEN DE COMPRE</h2>
                break;
            case 33:
                return <ReceiptADetail rowData={rowData} onBack={onBack} handleFinish={handleOnFinishStep} />
                break;
            case 34:
                return <h2>REMITO</h2>
                break;
            case 35:
                return <h2>PRESUPUESTO</h2>
                break;
            case 36:
                return <h2>RECIBO B</h2>
                break;
            default:
                return <h2>Tipo de comprobante desconocido...</h2>
                break;
        }
    }

    return (
        <ContainerStep>
            {getFormData(idForm)}
        </ContainerStep>
    );
}

export default VoucherDetail;