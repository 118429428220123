/* General imports */
import React from 'react'
import styled from 'styled-components';
import Gallery from '../ImageGallery/Gallery';

/* Components imports */


/* Styled Components */

const Root = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 400px;
    width: 100%;
    height: auto;
    margin: 50px 0;
    @media (max-width: 600px) {
        grid-template-columns: 1fr;
        grid-template-rows: 300px 300px 300px;
    }
`
const ContentImage = styled.img`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
`

const ThreeImages = (props) => {

    return (
        props.images && props.images.length > 1 && props.images.length === 4 ?
        <Root>
            {
                props.images[1] &&
                <ContentImage src={props.images[1]} alt="Image 1" />
            }
            {
                props.images[2] &&
                <ContentImage src={props.images[2]} alt="Image 1" />
            }
            {
                props.images[3] &&
                <ContentImage src={props.images[3]} alt="Image 1" />
            }
        </Root>
        :
        props.images && props.images.length > 1 &&
        <Gallery images={props.images.slice(1)} isDesktop={props.isDesktop} />
    );
}

export default ThreeImages;