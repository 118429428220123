/* General imports */
import React from 'react'
import styled from 'styled-components'
import axios from 'axios';
import  { authHeader } from '../../app/auth-header';

/* Components imports */
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

/* Styled components */
const Root = styled.section`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background: ${(props) => props.background ? props.background : "transparent"};
    @media (max-width: 600px) {
        height: 60vh;
    }
`

const Video = styled.video`
    position: absolute;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        background: linear-gradient(0deg, #001554 0%, #2196F382 100%) ;
    }
`

const Breadcrumbs = styled.div`
    position: absolute;
    top: 150px;
    left: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: auto;
    height: 50px;
    font-family: 'IBM Plex Sans';
    font-weight: 300;
    font-size: 1.2rem;
    color: #FFFFFF;
    transform: translateY(-100px);
    z-index: 9;
    svg {
        font-size: 12px;
        margin: 0 5px;
    }
    @media (max-width: 600px) {
        display: none;
    }
`

/* ----- Principal function ----- */
function OnlyVideo({idsection}) {

    const [data, setData] = React.useState(null);

    React.useEffect(() => {
        if(!data){
            let configHeader = {
                headers: { ...authHeader(), 'Content-Type': 'application/json' }
            }
            axios.get('/api/sections/' + idsection,configHeader)
            .then(sectionData => {
                setData(sectionData.data.section.data);
            })
            .catch(err => {
                alert('Hubo un error...');
            })
        }
    }, [data, idsection])

    return(
        data &&
        <Root>
            <Breadcrumbs><span>Inicio</span><ArrowForwardIosIcon /><span style={{fontWeight: 400}}>{data.title}</span></Breadcrumbs>
            <Video src='/ParqueIndRecmp4.mp4' autoPlay loop muted alt="video_nosotros" />
        </Root>
    );
}

export default OnlyVideo;