/* General imports */
import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { alertActions } from '../alert/AlertActions'
import axios from 'axios';
import  { authHeader } from '../app/auth-header';
import { pointSaleActions } from './PointSaleActions';
import ItemsTable from './ItemsTable';

/* Components imports */
import MetaSale from './MetaSale';

/* Styled components */
const Root = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    max-height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
`

const Header = styled.div`
    display: flex;
    position: relative;
    width: 95%;
    height: 70px;
    align-items: center;
    justify-content: flex-start;
    label {
        font-size: 20px;
        margin: 5px;
    }
    input {
        font-size: 20px;
        margin: 5px;
        padding: 2px 5px;
        box-sizing: border-box;
    }
    span {
        font-size: 10px;
        opacity: 0.85;
    }
`

const SaleRow = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
`

const SaleData = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 70%;
    height: auto;
    min-height: 650px;
    align-items: center;
    justify-content: flex-start;
`

const ResumeData = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 30%;
    height: auto;
    min-height: 650px;
    align-items: center;
    justify-content: flex-start;
`

const ResumeDisplay = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background: #000000;
    color: #ddd;
    width: 95%;
    height: 300px;
    box-sizing: border-box;
    padding: 20px 0;
    font-size: 24px;
    box-shadow: 1px 1px 15px rgba(0,0,0,0.5);
`

const ItemList = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 95%;
    height: 400px;
    max-height: 400px;
    overflow: auto;
    box-shadow: 1px 1px 15px rgba(0,0,0,0.5);
    box-sizing: border-box;
    padding: 10px;
`

const DisplayRow = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
    ${(props) => props.fontSize ? `font-size: ${props.fontSize};` : ''}
    ${(props) => props.color ? `color: ${props.color};` : ''}
`

const DisplayItem = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: ${(props) => props.alignH ? props.alignH : "center"};
    padding: 0 20px;
    box-sizing: border-box;
    input {
        width: 100px;
        text-align: end;
        background: black;
        color: white;
        font-size: 16px;
    }
`

const ActionsDisplay = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: space-around;
    margin: 20px 0;
`

const ButtonAction = styled.button`
    display: flex;
    position: relative;
    padding: 15px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 5px;
    background: #2e6da4;
    color: white;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.5);
    border: none;
    outline: none;
    cursor: pointer;
`

const Code = styled.div`
    display: flex;
    position: relative;
`

const ProductList = styled.ul`
    display: ${(props) => props.show ? "flex" : "none"};
    position: absolute;
    flex-direction: column;
    top: 100%;
    left: 0;
    margin: 0;
    padding: 5px;
    width: 200%;
    height: auto;
    max-height: 500px;
    overflow: auto;
    background: white;
    box-shadow: 1px 1px 15px rgba(0,0,0,0.5);
    z-index: 999;
`

const ItemProductList = styled.li`
    display: flex;
    position: relative;
    width: 100%;
    text-decoration: none;
    padding: 20px;
    margin: 0;
    box-sizing: border-box;
    border: 1px solid rgba(0,0,0,0.2);
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
    span {
        font-size: 12px;
    }
    &:hover {
        box-shadow: 1px 1px 15px rgba(0,0,0,0.8); 
    }
`

const Divider = styled.div`
    width: 80%;
    height: 1px;
    background: ${(props) => props.color ? props.color : "white"};
    opacity: 0.5;
`


/* ----- Principal function ----- */
function PointSale(props) {
    const getSubtotal = (elements) => {
        let subtotal = 0;
        elements.forEach(element => {
            subtotal = subtotal + (Math.round((element.amount * element.salePrice)*100)/100);
        });
        
        return subtotal;
    }
    const [amount, setAmount] = React.useState(1);
    const [code, setCode] = React.useState('');
    const [helpCode, setHelpCode] = React.useState(false);
    const [showProductList, setShowProductList] = React.useState(false);
    const [subtotal, setSubtotal] = React.useState(props.items.length > 0 ? getSubtotal(props.items) : 0);
    const [payment, setPayment] = React.useState(parseFloat(0.00));
    const [discount, setDiscount] = React.useState(parseFloat(0.00));
    const [saleData, setSaleData] = React.useState({
        customer: 0,
        saleDate: new Date().toISOString().slice(0, 10),
        storage: 1,
        paymentMethod: 10,
        observation: ''
    });

    const [productList, setProductList] = React.useState([])

    const onChangeCode = (e) => {
        const search = e.target.value;
        setCode(search);
        let configHeader = {
            headers: { ...authHeader(), 'Content-Type': 'application/json' }
        }
        if(search.length > 2){
            setHelpCode(false);
            axios.get(`/api/products?search=${search}`,configHeader)
            .then(productsData => {
                const products = productsData.data.rows;
                setProductList(products);
                setShowProductList(true);
            })
            .catch(err => {
                props.alertMessage('Hubo un error...');
                console.log(err);
            })
        } else {
            setHelpCode(true);
            setShowProductList(false);
        }
        
    }

    const handleChangeSaleData = (name, value) => {
        setSaleData({
            ...saleData,
            [name]: value
        })
    }

    const cancelCode = () => {
        setCode('');
        setHelpCode(false);
        setShowProductList(false);
        setAmount(1);
    }

    const deleteItem = (indexDelete) => {
        let newItems = props.items.filter(function(value, index, arr){ 
            return parseInt(index) !== parseInt(indexDelete);
        });
        setSubtotal(getSubtotal(newItems));
        props.setItems(newItems);
    }

    const insertProduct = (index) => {
        let newItems = props.items;
        let repeatedItem = props.items.findIndex((element) => parseInt(element.idProduct) === parseInt(productList[index].idProduct));
        if(repeatedItem !== -1){
            newItems[repeatedItem].amount = parseFloat(newItems[repeatedItem].amount) + parseFloat(amount);
            newItems[repeatedItem].newStock = newItems[repeatedItem].newStock - parseFloat(amount);
        } else {
            const newItem = {
                idProduct: productList[index].idProduct,
                code: productList[index].code,
                barcode: productList[index].barcode,
                name: productList[index].name,
                img: productList[index].img,
                costPrice: productList[index].costPrice,
                salePrice: productList[index].salePrice,
                category: productList[index].id_Category,
                currencyUnit: productList[index].idCurrency_Unit,
                quantityUnit: productList[index].idQuantity_Unit,
                suppliers: productList[index].suppliers,
                amount: amount,
                newStock: productList[index].stock[0].quantity - parseFloat(amount)
            }
            newItems.push(newItem);
        }
        setSubtotal(getSubtotal(newItems));
        props.setItems(newItems);
        cancelCode();
    }

    const onKeyUpCode = (e) => {
        const keycode = (e.keyCode ? e.keyCode : e.which);
        if (keycode == '13') {
            if(productList.length === 1){
                insertProduct(0);
            }
        }
        if(keycode == '27') {
            cancelCode();
        }
    }

    const addProduct = (e) => {
        const {index} = e.target.dataset;
        if(index){
            insertProduct(index)
        } else {
            props.alertMessage("No se pudo agregar el producto. Intente nuevamente");
        }
    }

    const collectOrder = async () => {
        await props.collectSale({
            ...saleData,
            idUserSeller: props.user.idUser,
            subtotal: subtotal,
            discount: discount,
            total: subtotal - (subtotal*discount/100),
            idStatus: 3, // Finished
        });
        setPayment(0);
        setSubtotal(0);
        props.setItems([]);
        cancelCode();
    }
    
    const cancel = () => {
        setPayment(0);
        setSubtotal(0);
        props.setItems([]);
        cancelCode();
    }

    return(
        <Root>
            <Header>
                <label>Cant:</label>
                <input id="amount" type="number" autoFocus style={{width: "100px", textAlign: "right"}} value={amount} onChange={(e) => setAmount(e.target.value)} ></input>
                <label>Código:</label>
                <Code >
                    <input id="code" type="text" placeholder="Ingrese el código" value={code} onChange={onChangeCode} onKeyUp={onKeyUpCode}  ></input>
                    <ProductList show={showProductList} >
                        {
                            productList.map((product, index) => 
                                <ItemProductList key={`itemproductlist_${index}`} data-index={index} onClick={addProduct}>
                                    <span><strong>{product.code ? product.code : product.barcode}</strong></span><span> { product.name}</span><span> <strong>${product.salePrice}</strong></span>
                                </ItemProductList>
                            )
                        }
                        {
                            productList && productList.length === 0 && <h4>Sin resultado</h4>
                        }
                    </ProductList>
                </Code>
                
                {
                    helpCode && <span>Debe ingresar al menos 3 caracteres...</span>
                }
            </Header>
            <SaleRow>
                <SaleData>
                    <ItemList>
                        <ItemsTable items={props.items} deleteItem={deleteItem} />
                    </ItemList>
                    <MetaSale metaData={saleData} handleChange={handleChangeSaleData} />
                </SaleData>
                <ResumeData>
                    <ResumeDisplay>
                        <DisplayRow><DisplayItem alignH="flex-start">Subtotal</DisplayItem><DisplayItem alignH="flex-end">${subtotal}</DisplayItem></DisplayRow>
                        <DisplayRow fontSize="20px"><DisplayItem alignH="flex-start">Descuento</DisplayItem><DisplayItem alignH="flex-end">%<input type="number" value={discount} onChange={(e) => {setDiscount(e.target.value)}} min="0" /></DisplayItem></DisplayRow>
                        <DisplayRow color="#fff" ><DisplayItem alignH="flex-start">TOTAL</DisplayItem><DisplayItem alignH="flex-end">${subtotal - (subtotal*discount/100)}</DisplayItem></DisplayRow>
                        <Divider />
                        <DisplayRow fontSize="20px"><DisplayItem alignH="flex-start">Pago</DisplayItem><DisplayItem alignH="flex-end">$<input type="number" value={payment} onChange={(e) => {setPayment(e.target.value)}} min="0" /></DisplayItem></DisplayRow>
                        <Divider />
                        <DisplayRow fontSize="20px" color="#9a9a9a" ><DisplayItem alignH="flex-start">Vuelto</DisplayItem><DisplayItem alignH="flex-end">${(Math.round((payment - (subtotal - (subtotal*discount/100)))*100)/100)}</DisplayItem></DisplayRow>
                    </ResumeDisplay>
                    <ActionsDisplay>
                        <ButtonAction onClick={collectOrder}>Cobrar</ButtonAction>
                        <ButtonAction onClick={cancel}>Cancelar</ButtonAction>
                    </ActionsDisplay>
                </ResumeData>
            </SaleRow>
        </Root>
    );
}

function mapState(state) {
    const { error, isFetching, items} = state.pointsale;
    const { user } = state.login;
    return { user, error, isFetching, items};
}

const actionCreators = {
    setItems: pointSaleActions.setItems,
    collectSale: pointSaleActions.collectSale,
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(PointSale);