/* General imports */
import React from 'react'
import styled from 'styled-components';
import axios from 'axios';
import  { authHeader } from '../../app/auth-header';
import { Link } from 'react-router-dom';


/* Components imports */
import Spinner from '../../spinners/LoaderSpinner'
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import Zoom from 'react-reveal/Zoom';
import LogoMax from '../../logos/LogoMaxSinBorde.png'
import LogoGamma from '../../logos/LogoGammaSinBorde.png'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { TextField } from '@material-ui/core';

/* Styled Components */
const Root = styled.div`
    width:100%;
    height: ${(props) => props.height ? props.height : '397px'};
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 150px 150px auto;
    background: #00002C;
    box-sizing: border-box;
    padding: 50px 70px 20px 70px;
    font-family: 'IBM Plex Sans';
    @media (max-width: 600px) {
        grid-template-columns: 1fr;
        grid-template-rows: 280px auto;
        padding: 70px 10px 20px 10px;
    }
`

const BoxContainer1 = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 0.3fr 0.3fr 0.4fr;
    grid-column: 1/3;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding-right: 20px;
    @media (max-width: 600px) {
        grid-template-columns: 1fr 1fr;
        grid-column: 1/2;
        padding: 0;
    }
`

const Box1 = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: #FFF;
    font-size: 1rem;
    font-weight: 300;
    box-sizing: border-box;
    padding: 30px 20px 0 50px;
    input {
        color: #FFF;
    }
    span {
        margin: 2px 0;
    }
    a {
        text-decoration: none;
        color: #FFF;
    }
    @media (max-width: 600px) {
        padding: 20px 5px 0 10px;
    }
`

const Box2 = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: none;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
    color: #FFF;
    font-size: 1rem;
    font-weight: 300;
    box-sizing: border-box;
    svg {
        font-size: 30px;
        margin: 0 30px;
        cursor: pointer;
    }
    @media (max-width: 600px) {
        display: flex;
        padding: 20px 5px 0 10px;
    }
`

const BoxContainer2 = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    grid-column: 3/4;
    grid-row: 1/3;
    justify-content: center;
    @media (max-width: 600px) {
        display: none;
    }
`

const BoxContainer3 = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column: 1/2;
    color: #FFF;
    a {
        color: #FFF;
        text-decoration: none;
    }
    svg {
        font-size: 30px;
        margin: 0 30px;
        cursor: pointer;
    }
    @media (max-width: 600px) {
        display: none;
    }
`

const Logo = styled.img`
    width: 180px;
    height: auto;
    margin: 20px 0;
`

const Arrow = styled(ArrowUpwardIcon)`
    width: 60px;
    height: 60px;
    color: #2196F3;
    cursor: pointer;
    margin: 20px;
`

const Arrow2 = styled(ArrowUpwardIcon)`
    position: absolute;
    display: none;
    top: 20px;
    right: 10px;
    width: 50px;
    height: 50px;
    color: #2196F3;
    cursor: pointer;
    @media (max-width: 600px) {
        display: flex;
    }
`

const CopyRight = styled.div`
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    color: #2196F3;
    font-weight: 300;
    font-size: 0.8rem;
    a {
        text-decoration: none;
        cursor: pointer;
        color: #2196F3;
        font-weight: 400;
    }
    @media (max-width: 600px) {
        text-align: center;
        bottom: 30px;
    }
`

const StyledTextField = styled(TextField)`
    label, input {
        color: #FFF;
    }
    .MuiInput-underline:before {
        border-color: #2196F3;
    }
    .MuiOutlinedInput-notchedOutline {
        border-color: #2196F3;
    }
    .MuiInput-underline:hover:not(.Mui-disabled):before {
        border-color: #2196F3;
    }
`

const StyledButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background: #CCCDCF;
    color: #001554;
    text-align: center;
    border-radius: 24px;
    width: 100%;
    height: 30px;
    box-shadow: none;
    border: none;
    margin: 10px 0;
    font-size: 1.2rem;
`

/* Principal function*/
function FooterSection({idsection}) {

    const [sectionData, setSectionData] = React.useState(null)

    const [ data, setData ] = React.useState({
        key: 'newsletter',
        fullname: '',
        email: ''
    })

    const [ newsLetter, setNewsLetter ] = React.useState(false);

    const onScrollUp = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
    }

    const onChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        });
    }

    const onSubmit = (e) => {
        e.preventDefault();
        let configHeader = {
            headers: { ...authHeader(), 'Content-Type': 'application/json' }
        }
        axios.post("/api/audiencies", data, configHeader)
        .then(res => {
            setNewsLetter(true);
        })
        .catch(error => {
            alert('Hubo un error...')
        })
        
    }

    React.useEffect(() => {
        if(!sectionData){
            let configHeader = {
                headers: { ...authHeader(), 'Content-Type': 'application/json' }
            }
            axios.get('/api/sections/' + idsection,configHeader)
            .then(sectionData => {
                setSectionData(sectionData.data.section.data);
            })
            .catch(err => {
                alert('Hubo un error...');
            })
        }
    }, [sectionData, idsection])

    return (
        !sectionData ? <Spinner spinnerType='ThreeDots' />
        :
        <Root>
            <Arrow2 onClick={onScrollUp} />
            <BoxContainer1>
                <Box1>
                    <span><strong>La empresa</strong></span>
                    <Link to="/ours"><span>Acerca de MAX</span></Link>
                    <Link to="/rrhh"><span>Trabaja con nosotros</span></Link>
                    <span></span>
                    <a href={`mailto:joaquinalonso@distribuidoragamma.com.ar`} target="_blank"><span>Soporte al cliente</span></a>
                </Box1>
                <Box1>
                    <span><strong>Dónde estamos</strong></span>
                    <span>EE. UU Nº 2974 Planta Alta. Capital Federal</span>
                    <span>Tel: 0249 442 8830</span>
                </Box1>
                {
                    newsLetter ?
                    <Box1>
                        <span><strong>¡Gracias por suscribirte!</strong></span>
                    </Box1>
                    :
                    <Box1>
                        <span><strong>Suscribite al newsletter</strong></span>
                        <span>Ingresá tu correo para enterarte de todas las novedades de MAX</span>
                        <StyledTextField color="#FFF" fullWidth id="fullname" name="fullname" type="text" value={data.fullname} label="Nombre y apellido" variant="standard" onChange={onChange} size="small" margin="none" required />
                        <StyledTextField fullWidth id="email" name="email" type="email" value={data.email} label="E-mail" variant="standard" onChange={onChange} size="small" margin="none" required />
                        <StyledButton onClick={onSubmit}>Suscribite</StyledButton>
                    </Box1>
                }
                <Box2>
                    <FacebookIcon />
                    <InstagramIcon />
                    <LinkedInIcon />
                </Box2>
            </BoxContainer1>
            <BoxContainer2>
                <Arrow onClick={onScrollUp} />
                <a href="https://web.maxcontinental.com"><Logo src={LogoMax} alt="logo_max" /></a>
                <a href="http://www.distribuidoragamma.com.ar" target="_blank"><Logo src={LogoGamma} alt="logo_gamma" /></a>
            </BoxContainer2>
            <BoxContainer3>
                <a href="#" target="_blank"><FacebookIcon /></a>
                <a href="#" target="_blank"><InstagramIcon /></a>
                <a href="#" target="_blank"><LinkedInIcon /></a>
            </BoxContainer3>
            <CopyRight>© 2021 MAX CONTINENTAL. <a href="https://www.molemedia.co/" target="_blank">Powered by MOLE</a></CopyRight>
        </Root>
    )
}

export default FooterSection;