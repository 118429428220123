export const patientsConstants = {
    FETCHING_FORMFIELDS: 'PATIENTS_FETCHING_FORMFIELDS',
    SUCCESS_FORMFIELDS: 'PATIENTS_SUCCESS_FORMFIELDS',
    SUCCESS_UPDATEFORMFIELDS: 'PATIENTS_SUCCESS_UPDATEFORMFIELDS',
    ERROR_FORMFIELDS: 'PATIENTS_ERROR_FORMFIELDS',
    SUCCESS_PROFESSIONALSLIST: 'SUCCESS_PROFESSIONALSLIST',
    SUCCESS_ASSIGNPROFESSIONALS: 'SUCCESS_ASSIGNPROFESSIONALS',
    SUCCESS_ACTIVITIESLIST: 'SUCCESS_ACTIVITIESLIST',
    SUCCESS: 'PATIENTS_SUCCESS',
    ERROR: 'PATIENTS_ERROR',
    FETCHING: 'PATIENTS_FETCHING',
    IDROLE: 5
};
