import { pageEditorConstants } from './PageEditorConstants';

let sections = JSON.parse(localStorage.getItem('sections'));
const initialState = sections ? 
  { 
      error: false,
      isFetching: false,
      sections: sections
  } : 
  { 
    error: false,
    isFetching: true,
  };

export function pageEditor(state = initialState, action) {
  switch (action.type) {
    case pageEditorConstants.FETCHING:
      return { 
            ...state,
            isFetching: true
        };
    case pageEditorConstants.SUCCESS:
      return { 
            ...state,
            isFetching: false,
            sections: action.sections
        };
    case pageEditorConstants.SUCCESS_FORMFIELDS:
      return { 
            ...state,
            isFetching: false,
            sectionFields: action.sectionFields
        };
    case pageEditorConstants.SUCCESS_UPDATEFORMFIELDS:
      return { 
            ...state,
            isFetching: false,
            sectionUpdateFields: action.sectionUpdateFields
        };
    case pageEditorConstants.ERROR:
      return { 
            ...state,
            error: true
        };
    default:
      return state
  }
}