/* General imports */
import React from 'react'
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { devices } from '../../app/config';
import axios from 'axios';
import  { authHeader } from '../../app/auth-header';

/* Components imports */
import Spinner from '../../spinners/LoaderSpinner'
import Zoom from 'react-reveal/Zoom';

/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled Components */
const Root = styled.div`
    width:100%;
    height:100vh;
    background: ${(props) => props.background ? props.background : 'linear-gradient(90deg, rgba(135,18,55,1) 0%, rgba(245,44,62,1) 100%)'};
    display: flex;
    position: relative;
    flex-direction:column;
`

const Header = styled.div`
    width:100%;
    height: ${(props) => props.height ? props.height : '15%'};
    display: flex;
    align-items: center;
    justify-content: center;
    h3 {
        display: flex;
        margin: 0;
        color: #FFFFFF;
        text-shadow: black 0.1em 0.1em 0.2em;
        text-align:center;
        font-size: ${(props) => props.fontSize ? `calc(${props.fontSize}*2.4)` : '3rem'};
    }
`

const Container = styled.div`
    width:100%;
    height: ${(props) => props.height ? props.height : '85%'};
    display:flex;
    box-sizing:border-box;
    align-items: center;
    justify-content: space-between;
    padding: ${(props) => props.padding ? props.padding : '20px'};
    flex-direction: ${(props) => props.flexDirection ? props.flexDirection : 'row'};
`;

const Column = styled.div`
    width: ${(props) => props.width ? props.width : '50%'};
    height:100%;
    font-family: 'Josefin Sans', sans-serif;
    box-sizing: border-box;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    padding: ${(props) => props.padding ? props.padding : 0};
    color: white;
    font-size: ${(props) => props.fontSize ? props.fontSize : '1.25rem'};
    text-align:center;
    ${(props) => props.backgroundImage ? `background-image: url(${props.backgroundImage})` : ''};
    ${(props) => props.parallax ? 'background-attachment: fixed' : ''};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    h3 {
        color: #FFFFFF;
        text-shadow: black 0.1em 0.1em 0.2em;
        text-align:center;
        font-size: ${(props) => props.fontSize ? `calc(${props.fontSize}*2.4)` : '3rem'};
    }
    h4 {
        color: #FFFFFF;
        text-align:left;
        font-size: ${(props) => props.fontSize ? `calc(${props.fontSize}*2.4)` : '1.25rem'};
        padding-left:1rem;
        transition: .5s;
    }
    ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        text-decoration: none;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
    }
    li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        list-style: none;
        width: 100%;
        height: 20%;
    }
    li:hover {
        h4{
            font-size: ${(props) => props.fontSize ? `calc(${props.fontSize}*2.4)` : '1.3rem'};
        }
        img {
            height: 55%;
        }
        
    }
`

const Image = styled.img`
    position: relative;
    display: flex;
    width: 90%;
    height: 90%;
    box-shadow: 5px 5px 5px rgba(0,0,0,0.5);
    object-fit: cover;
`;

const Icons = styled.img`
    width: auto;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: .5s;
`;

/* Principal function*/
function Objetives({idsection}) {

    const [objetivesData, setObjetivesData] = React.useState(null)

    React.useEffect(() => {
        if(!objetivesData){
            let configHeader = {
                headers: { ...authHeader(), 'Content-Type': 'application/json' }
            }
            axios.get('/api/sections/' + idsection,configHeader)
            .then(sectionData => {
                setObjetivesData(sectionData.data.section.data);
            })
            .catch(err => {
                alert('Hubo un error...');
            })
        }
    }, [objetivesData, idsection])

    return (
        !objetivesData ? <Spinner spinnerType='ThreeDots' />
        :
        <Root>
            <Desktop>
                <Header>
                    <Zoom>
                        <h3> {objetivesData.title} </h3>
                    </Zoom>
                </Header>
                <Container padding='20px 0 0 0'>
                    <Column backgroundImage={objetivesData.img} parallax={objetivesData.parallax} />
                    <Column padding='0 4rem' background='linear-gradient(90deg, rgba(135,18,55,1) 0%, rgba(245,44,62,1) 100%)'>
                        <ul>
                            <Zoom left cascade>
                                <li>
                                    <Icons src={objetivesData.img1} alt='Objetivo 1' />
                                    <h4> {objetivesData.text1} </h4>
                                </li>
                                <li>
                                    <Icons src={objetivesData.img2} alt='Objetivo 2' />
                                    <h4> {objetivesData.text2} </h4>
                                </li>
                                <li>
                                    <Icons src={objetivesData.img3} alt='Objetivo 3' />
                                    <h4> {objetivesData.text3} </h4>
                                </li>
                                <li>
                                    <Icons src={objetivesData.img4} alt='Objetivo 4' />
                                    <h4> {objetivesData.text4} </h4>
                                </li>
                            </Zoom>
                        </ul>
                    </Column>
                </Container>
            </Desktop>
            <Tablet>
                <Header height='15%' fontSize='0.75rem'>
                    <h3> {objetivesData.title} </h3>
                </Header>
                <Container height='85%' padding='20px 0 0 0'>
                    <Column backgroundImage={objetivesData.img} parallax={objetivesData.parallax} />
                    <Column padding='0 4rem' background='#A30D0D'>
                        <ul>
                            <li>
                                <Icons src={objetivesData.img1} alt='Objetivo 1' />
                                <h4> {objetivesData.text1} </h4>
                            </li>
                            <li>
                                <Icons src={objetivesData.img2} alt='Objetivo 2' />
                                <h4> {objetivesData.text2} </h4>
                            </li>
                            <li>
                                <Icons src={objetivesData.img3} alt='Objetivo 3' />
                                <h4> {objetivesData.text3} </h4>
                            </li>
                            <li>
                                <Icons src={objetivesData.img4} alt='Objetivo 4' />
                                <h4> {objetivesData.text4} </h4>
                            </li>
                        </ul>
                    </Column>
                </Container>
            </Tablet>
            <Mobile>
                <Header height='15%' fontSize='0.75rem'>
                    <h3> {objetivesData.title} </h3>
                </Header>
                <Container height='85%' padding='20px 0 0 0'>
                    <Column width='100%' padding='0 4rem' background='#A30D0D'>
                        <ul>
                            <li>
                                <Icons src={objetivesData.img1} alt='Objetivo 1' />
                                <h4> {objetivesData.text1} </h4>
                            </li>
                            <li>
                                <Icons src={objetivesData.img2} alt='Objetivo 2' />
                                <h4> {objetivesData.text2} </h4>
                            </li>
                            <li>
                                <Icons src={objetivesData.img3} alt='Objetivo 3' />
                                <h4> {objetivesData.text3} </h4>
                            </li>
                            <li>
                                <Icons src={objetivesData.img4} alt='Objetivo 4' />
                                <h4> {objetivesData.text4} </h4>
                            </li>
                        </ul>
                    </Column>
                </Container>
            </Mobile>
        </Root>
    )
}

export default Objetives;