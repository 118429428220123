import { pageConstants } from './PageConstants';
import { alertActions } from '../alert/AlertActions';
import axios from 'axios';
import  { authHeader } from '../app/auth-header';

export const pageActions = {
    createPage,
    updatePage,
    getPages,
    deletePage,
    getFields
};

function createPage(page) {
    return dispatch => {
        axios
        .post( '/api/pages' , page)
        .then(response => {
            dispatch(alertActions.success('Página creada'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}

function updatePage(id, page) {
    return dispatch => {
        axios
        .put( '/api/pages/' + id, page)
        .then(response => {
            dispatch(alertActions.success('Página actualizada'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}


function getPages() {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return dispatch => {
        dispatch({ type: pageConstants.FETCHING });
        axios.get('/api/pages',config)
        .then(pagesData => {
            const pages = pagesData.data.pages
            dispatch({ type: pageConstants.SUCCESS, pages });
        })
        .catch(err => {
            dispatch({ type: pageConstants.ERROR });
            dispatch(alertActions.error('Hubo un error...'));
        })
        
    }
}

function deletePage(id) {
    return dispatch => {
        axios
        .delete( '/api/pages/' + id)
        .then(response => {
            dispatch(alertActions.success('Página eliminada'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}

function getFields() {

    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    return dispatch => {
        dispatch({ type: pageConstants.FETCHING_FORMFIELDS });

        axios.get('/api/pagesfields', config)
        .then(response => {
            const pageFields = response.data.fields;
            dispatch({ type: pageConstants.SUCCESS_FORMFIELDS, pageFields });
        })
        .catch(error => {
            dispatch(alertActions.error(error));
        })
    }
}