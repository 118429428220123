import { pageConstants } from './PageConstants';

let pages = JSON.parse(localStorage.getItem('pages'));
const initialState = pages ? 
  { 
      error: false,
      isFetching: false,
      pages: pages
  } : 
  { 
    error: false,
    isFetching: true,
  };

export function page(state = initialState, action) {
  switch (action.type) {
    case pageConstants.FETCHING:
      return { 
            ...state,
            isFetching: true
        };
    case pageConstants.SUCCESS:
      return { 
            ...state,
            isFetching: false,
            pages: action.pages
        };
        case pageConstants.SUCCESS_FORMFIELDS:
          return { 
                ...state,
                isFetching: false,
                pageFields: action.pageFields
            };
    case pageConstants.ERROR:
      return { 
            ...state,
            error: true
        };
    default:
      return state
  }
}