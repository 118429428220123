import { pointSaleConstants } from './PointSaleConstants';

let items = JSON.parse(localStorage.getItem('items'));
const initialState = items ? 
  { 
      error: false,
      isFetching: false,
      items: items
  } : 
  { 
    error: false,
    isFetching: true,
    items: []
  };

export function pointsale(state = initialState, action) {
  switch (action.type) {
    case pointSaleConstants.FETCHING:
      return { 
            ...state,
            isFetching: true
        };
    case pointSaleConstants.ADD:
      return { 
            ...state,
            isFetching: false,
            items: action.items
        };
    case pointSaleConstants.SUCCESS:
      return { 
            ...state,
            isFetching: false
        };
    case pointSaleConstants.ERROR:
      return { 
            ...state,
            error: true
        };
    default:
      return state
  }
}