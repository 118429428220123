import { sidebarCartConstants } from './SidebarCartConstants';

let cartitems = JSON.parse(localStorage.getItem('cartitems'));
const initialState = cartitems ? 
  { 
      error: false,
      isFetching: false,
      open: false,
      cartitems: cartitems
  } : 
  { 
    error: false,
    isFetching: true,
    open: false,
    cartitems: []
  };

export function sidebarcart(state = initialState, action) {
  switch (action.type) {
    case sidebarCartConstants.FETCHING:
      return { 
            ...state,
            isFetching: true
        };
    case sidebarCartConstants.SWITCH:
      return { 
            ...state,
            open: !state.open
        };
    case sidebarCartConstants.ADD:
        return { 
            ...state,
            cartitems: action.cartitems
        };
    case sidebarCartConstants.REMOVE:
        return { 
            ...state,
            cartitems: action.cartitems
        };
    case sidebarCartConstants.SET:
        return { 
            ...state,
            cartitems: action.cartitems
        };
    case sidebarCartConstants.SUCCESS:
      return { 
            ...state,
            isFetching: false
        };
    case sidebarCartConstants.ERROR:
      return { 
            ...state,
            error: true
        };
    default:
      return state
  }
}