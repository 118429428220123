import { nameAccess } from '../app/nameAccessConstants'

export function AuthNameAccess(user, name_access) {
    /* Then the user's access permissions are confirmed */
    let access = user.role[0].scopes.split(" ")
    if(access.includes(name_access) || access.includes(nameAccess.ROOT) ){
        return true
    } else {
        return false
    }
}