/* General imports */
import React from 'react'
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { devices } from '../../app/config';
import axios from 'axios';
import  { authHeader } from '../../app/auth-header';

/* Components imports */
import Spinner from '../../spinners/LoaderSpinner'

/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled Components */
const Root = styled.div`
    width:100%;
    height: ${(props) => props.height ? props.height : '80vh'};
    min-height:600px;
    background: ${(props) => props.background ? props.background : 'transparent'};
    position: relative;
    display: flex;
    flex: auto;
    flex-direction: column;
    box-sizing: border-box;
`

/* Principal function*/
function MapSection({idsection}) {

    const [mapData, setMapData] = React.useState(null)

    React.useEffect(() => {
        if(!mapData){
            let configHeader = {
                headers: { ...authHeader(), 'Content-Type': 'application/json' }
            }
            axios.get('/api/sections/' + idsection,configHeader)
            .then(sectionData => {
                setMapData(sectionData.data.section.data);
            })
            .catch(err => {
                alert('Hubo un error...');
            })
        }
    }, [mapData, idsection])

    return (
        !mapData ? <Spinner spinnerType='ThreeDots' />
        :
        <Root>
            <Desktop>
                <iframe width="100%" height="100%" title={mapData.title} frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src={mapData.mapsrc}></iframe>
            </Desktop>
            <Tablet>
                <iframe width="100%" height="100%" title={mapData.title} frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src={mapData.mapsrc}></iframe>
            </Tablet>
            <Mobile>
                <iframe width="100%" height="100%" title={mapData.title} frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src={mapData.mapsrc}></iframe>
            </Mobile>
        </Root>
    )
}

export default MapSection;