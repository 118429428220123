/* General imports */
import React from 'react'
import styled, {keyframes} from 'styled-components';
import axios from 'axios'
import  { authHeader } from '../../app/auth-header';

/* Components imports */
import BackgroundNovelty from '../../images/Background_Novelty.png'
import Fade from 'react-reveal/Fade';


/* Styled Components */
const moveSlideshow = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-50%, 0, 0);
  }
`;

const Root = styled.div`
    width: 100%;
    height: 50vh;
    display: flex;
    position:relative;
    align-items: center;
    justify-content: center;
    font-family: 'IBM Plex Sans';
    @media (max-width: 600px) {
        height: 30vh;
    }
`

const Content = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-left: 35%;
    width: 90%;
    height: 280px;
    justify-content: center;
    @media (max-width: 600px) {
        width: 95%;
        padding: 0 10px;
    }
`

const ContentImage = styled.img`
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
`

const Pretitle = styled.span`
    color: #001554;
    font-size: 1.2rem;
`

const Title = styled.h2`
    color: #2196F3;
    font-size: 3.5rem;
    margin: 10px 0;
    @media (max-width: 600px) {
        font-size: 2rem;
    }
`

const Text = styled.span`
    color: #000A12;
    font-size: 1.4rem;
    @media (max-width: 600px) {
        font-size: 1.2rem;
    }
`

const Buttons = styled.div`
    display: flex;
    width: 100%;
    @media (max-width: 600px) {
        align-items: center;
        justify-content: center;
    }
`

const Button = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 216px;
    height: 48px;
    font-size: 20px;
    background: ${(props) => props.background ? props.background : '#001554'};
    border-radius: 24px;
    color: ${(props) => props.color ? props.color : '#FFF'};
    margin: 50px 20px 20px 0;
    cursor: pointer;
    text-decoration: none;
    ${(props) => props.border ? `border: ${props.border};` : ''}
    @media (max-width: 600px) {
        width: 150px;
        height: 40px;
        font-size: 16px;
        margin: 20px 10px;
    }
`


function Novelty({idsection}) {

    const [data, setData] = React.useState(null);

    React.useEffect(() => {
        if(!data){
            let configHeader = {
                headers: { ...authHeader(), 'Content-Type': 'application/json' }
            }
            axios.get('/api/sections/' + idsection,configHeader)
            .then(sectionData => {
                setData(sectionData.data.section.data);
            })
            .catch(err => {
                alert('Hubo un error...');
            })
        }
    }, [data, idsection])

    return (
        data && 
        <Root>
            <ContentImage src={BackgroundNovelty} alt="Background content" />
            
                <Content>
                <Fade bottom cascade>
                    <Pretitle>{`NOVEDAD EXCLUSIVA `}<strong>MAX</strong></Pretitle>
                    <Title>{data.title}</Title>
                    <Text>{data.text}</Text>
                    <Buttons>
                        <Button href={data.link1}>{data.button1}</Button>
                        <Button href={data.link2} color="#001554" background="transparent" border="1px solid #001554">{data.button2}</Button>
                    </Buttons>
                </Fade>
                </Content>
            
        </Root>
    )
}

export default Novelty;