/* General imports */
import React from 'react'
import { connect } from 'react-redux'
/* import { homeActions } from './HomeActions' */
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { theme, config, devices } from '../app/config'

/* Components imports */
// import Copyright from '../Copyright'

/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled components */
const Root = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: ${theme.default.darkBackground};
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const JumpLink = styled.a`
    color: ${theme.default.greyText};
    font-family: 'Montserrat';
    font-size: 15px;
    font-weight: 400;
    text-decoration: none;
    position: absolute;
    top: 31px;
    right: 26px;
`

const Title = styled.div`
    width: 100%;
    color: ${theme.default.whiteText};
    font-family: 'Montserrat';
    font-size: 37px;
    font-weight: 700;
`

const Subtitle = styled(Title)`
    font-size: 17px;
    font-weight: 400;
    color: ${theme.default.greyText};
    margin-bottom: 25px;
`

const StyledButton = styled.button`
    width: 304px;
    height: 44px;
    background-color: ${theme.default.redButton};
    color: ${theme.default.whiteText};
    font-family: 'Montserrat';
    font-size: 11px;
    font-weight: 600;
    border-radius: 80px;
    border-color: transparent;
    border-style: solid;
    margin-bottom: 25px;
`

const TextRegister = styled(Title)`
font-size: 11px;
font-weight: 400;
color: ${theme.default.greyText};
`

const RegisterLink = styled.a`
    color: ${theme.default.redText};
    font-family: 'Montserrat';
    font-size: 11px;
    font-weight: 600;
    text-decoration: none;
`

/* ----- Principal function ----- */
function Home(props) {

    return(
        <React.Fragment>
            <Desktop>DESKTOP</Desktop>
            <Tablet>TABLET</Tablet>
            <Mobile>
                <Root>
                    <JumpLink href='#'>Saltar</JumpLink>
                    <Title>{ config.companyName }</Title>
                    <Subtitle>ONLINE</Subtitle>
                    <StyledButton>INICIAR SESIÓN</StyledButton>
                    <TextRegister>¿No tienes cuenta? <RegisterLink href='#'>Registrarse</RegisterLink></TextRegister>
                </Root>
            </Mobile>
        </React.Fragment>
    );
}

function mapState(state) {
/*     const { loggingIn } = state.login;
    return { loggingIn }; */
}

const actionCreators = {
/*     login: homeActions.login,
    logout: homeActions.logout */
};

export default connect(mapState, actionCreators)(Home);