/* General imports */
import React from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { devices } from '../../app/config'
import axios from 'axios';
import { Link } from 'react-router-dom';
/* Components imports */
import NoImage from '../../images/no-image.png'


/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled components */
const Root = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: flex-start;
`
const NavSpace = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 150px;
    background: linear-gradient(0deg, rgba(255,255,255,0.36458333333333337) 0%, rgba(78,78,78,1) 100%);
`

const ContentBox = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    width: 100%;
    min-height: auto;
    max-width: 1366px;
    align-items: flex-start;
    justify-content: space-around;
`

const Category = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    width: ${(props) => props.width ? props.width : "30%" };
    height: auto;
    align-items: center;
    justify-content: flex-start;
    margin: 20px 0;
    a {
        text-decoration: none;
    }
    img {
        display: flex;
        position: relative;
        width: 100%;
        height: ${(props) => props.height ? props.height : "500px" };
        box-shadow: 1px 1px 5px rgba(0,0,0,0.5);
    }
    &:hover img{
        box-shadow: 1px 1px 15px rgba(0,0,0,0.5);
    }
    h2 {
        font-size: 20px;
        margin: 5px 0;
        color: black;
        font-weight: 300;
    }
`

/* ----- Principal function ----- */
function CategoriesList(props) {

    const [categories, setCategories] = React.useState(null)

    React.useEffect(() => {
        
        if(!categories){
            axios.get(`/api/category`)
            .then(categoriesData => {
                setCategories(categoriesData.data.categories);
            })
            .catch(err => {
                console.log(err);
            })
        }
    }, [ categories, setCategories ])

    return(
        <Root>
            <NavSpace />
            <Desktop>
                <ContentBox>
                    {
                        categories && categories.map((cat, index) => 
                            parseInt(cat.idCategory) !== 0 && 
                            <Category>
                                <Link to={`/tienda/categoria/${cat.idCategory}`}><img src={cat.img ? cat.img : NoImage} alt={`categoria_${cat.name}`} /></Link>
                                <Link to={`/tienda/categoria/${cat.idCategory}`}><h2>{cat.name}</h2></Link>
                            </Category>
                        )
                    }
                </ContentBox>
            </Desktop>
            <Tablet>
                <ContentBox>
                    {
                        categories && categories.map((cat, index) => 
                            parseInt(cat.idCategory) !== 0 && 
                            <Category>
                                <Link to={`/tienda/categoria/${cat.idCategory}`}><img src={cat.img ? cat.img : NoImage} alt={`categoria_${cat.name}`} /></Link>
                                <Link to={`/tienda/categoria/${cat.idCategory}`}><h2>{cat.name}</h2></Link>
                            </Category>
                        )
                    }
                </ContentBox>
            </Tablet>
            <Mobile>
                <ContentBox>
                    {
                        categories && categories.map((cat, index) => 
                            parseInt(cat.idCategory) !== 0 && 
                            <Category width="90%">
                                <Link to={`/tienda/categoria/${cat.idCategory}`}><img src={cat.img ? cat.img : NoImage} alt={`categoria_${cat.name}`} /></Link>
                                <Link to={`/tienda/categoria/${cat.idCategory}`}><h2>{cat.name}</h2></Link>
                            </Category>
                        )
                    }
                </ContentBox>
            </Mobile>
        </Root>
    );
}

export default CategoriesList;