/* General imports */
import React from 'react';
import styled from 'styled-components';

import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';

const Root = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 50px 120px 30px;
    width: 100%;
    height: 100%;
`

const Box = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  box-shadow: ${(props) => props.boxShadow ? props.boxShadow : 'unset'};
  grid-row: ${(props) => props.gridRow ? props.gridRow : 'unset' };
  box-sizing: border-box;
  grid-column: ${(props) => props.gridColumn ? props.gridColumn : 'unset' };
  padding: ${(props) => props.padding ? props.padding : 'none'};
  align-items: center;
  justify-content: center;
  background: ${(props) => props.background ? props.background : 'transparent' };
  color: ${(props) => props.color ? props.color : '#000' };
  flex-direction: ${(props) => props.flexDirection ? props.flexDirection : 'row'};
`

const Subtitle = styled.h5`
    text-transform: uppercase;
    font-size: 1rem;
    margin: 0 0 20px 0;
`

const Value = styled.span`
    font-size: 1.5rem;
`

const FooterItem = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
  span {
    color: ${(props) => props.igual ? 'black' : props.aumento ? 'green' : 'red'}
  }
`

const UpIcon = styled(TrendingUpIcon)`
  color: green;
  margin-right: 5px;
`

const DownIcon = styled(TrendingDownIcon)`
  color: red;
  margin-right: 5px;
`

const LineIcon = styled(TrendingFlatIcon)`
  margin-right: 5px;
`

const DolarBox = ({title, dolar, borderRadius}) => {
    return (
        <Root borderRadius={borderRadius}>
            <Box gridColumn="1/3" background="linear-gradient(to left,#122230 0%,#192d3e 100%)" color="#FFF">
                <strong>{title}</strong>
            </Box>
            <Box flexDirection="column" padding="10px">
                <Subtitle>Compra</Subtitle>
                {
                    dolar && <Value>${dolar.compra}</Value>
                }
            </Box>
            <Box flexDirection="column" padding="10px">
                <Subtitle>Venta</Subtitle>
                {
                    dolar && <Value>${dolar.venta}</Value>
                }
            </Box>
            <Box gridColumn="1/3">
                {
                dolar && 
                <FooterItem aumento={parseFloat(dolar.variacion.replace(',', '.')) > 0} igual={parseFloat(dolar.variacion.replace(',', '.')) === 0 ? true : false}>
                    {
                    parseFloat(dolar.variacion.replace(',', '.')) === 0 ? <LineIcon /> :  parseFloat(dolar.variacion.replace(',', '.')) > 0 ? <UpIcon /> : <DownIcon />
                    }
                    <span>{ dolar.variacion.replace('+', '').replace('-', '') }</span>
                </FooterItem>
                }
            </Box>
        </Root>
    );
}

export default DolarBox;