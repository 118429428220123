import { carouselConstants } from './CarouselConstants';

let carousels = JSON.parse(localStorage.getItem('carousels'));
const initialState = carousels ? 
        { 
            error: false,
            isFetching: false,
            carousels: carousels
        }
    : 
        { 
            error: false,
            isFetching: true,
        };

export function carousel(state = initialState, action) {
    switch (action.type) {
        case carouselConstants.FETCHING:
            return { 
                ...state,
                isFetching: true
            };
        case carouselConstants.SUCCESS:
            return { 
                ...state,
                isFetching: false,
                carousels: action.carousels
            };
        case carouselConstants.ERROR:
            return { 
                ...state,
                error: true
            };
        case carouselConstants.FETCHING_FORMFIELDS:
            return { 
                ...state,
                isFetchingFields: true
            };
        case carouselConstants.SUCCESS_FORMFIELDS:
            return { 
                ...state,
                isFetchingFields: false,
                carouselsFields: action.carouselsFields,
                error: false
        };
        case carouselConstants.SUCCESS_UPDATEFORMFIELDS:
            return { 
                ...state,
                isFetchingFields: false,
                carouselsUpdateFields: action.carouselsUpdateFields,
                error: false
        };
        case carouselConstants.ERROR_FORMFIELDS:
            return {
                ...state,
                error: true
        };
        default:
            return state
    }
}