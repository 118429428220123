/* General imports */
import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { registerActions } from '../register/RegisterActions'
import {alertActions} from '../alert/AlertActions'
import { theme } from '../app/config'
import { useMediaQuery } from 'react-responsive'
import { devices } from '../app/config'
import axios from 'axios'

/* Components imports */
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Form from '../form/Form'
import Spinner from '../spinners/LoaderSpinner'

/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled components */
const ModalStyled = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 10px 10px!important;
    z-index: 99999;
    outline: none;
`

const ModalContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    color: black;
    box-sizing: border-box;
    border-radius: 10px 10px 10px 10px;
    width: ${(props) => props.width ? props.width : '70%'};
    min-height: ${(props) => props.mobile ? '450px' : '600px'};
    align-items: ${(props) => props.mobile ? 'flex-start' : 'center'};
    justify-content: center;
    overflow: auto;
    outline: none;
`

const ModalTitle = styled.div`
    position: absolute;
    display: flex;
    top: 0;
    width: 100%;
    height: 70px;
    color: white;
    font-size: 24px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    align-items: center;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
    box-sizing: border-box;
    background: linear-gradient(to left,#F52C3E 0%,#871237 100%);
`

const ModalBody = styled.div`
    position: absolute;
    display: flex;
    top: 70px;
    width: 100%;
    height: calc(100% - 70px);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
`

const Title = styled.div`
    width: 100%;
    color: ${theme.default.sidebarBackground};
    font-family: 'Montserrat';
    font-size: 37px;
    font-size: ${(props) => props.fontSize ? props.fontSize : '37px'};
    font-weight: 700;
    text-align: center;
    margin-top: 25px;
    margin-bottom: 25px;
`

const TextRegister = styled(Title)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: ${(props) => props.fontSize ? props.fontSize : '14px'};;
    color: ${theme.default.greyText};
    margin-top: 10px;
`

const RegisterLink = styled.div`
    color: ${theme.default.redText};
    font-family: 'Montserrat';
    font-size: ${(props) => props.fontSize ? props.fontSize : '14px'};;
    font-weight: 600;
    text-decoration: none;
`

const HorizontalSeparator = styled.div`
    display: flex;
    width: ${(props) => props.width ? props.width : '400px'};
    align-items: center;
    justify-content: center;
    opacity: .3;
    &::before {
        content: '';
        position: relative;
        width: 40%;
        height: 1.5px;
        display: flex;
        margin-right: 5%;
        background-color: black;
    }
    &::after {
        content: '';
        position: relative;
        width: 40%;
        height: 1.5px;
        display: flex;
        margin-left: 5%;
        background-color: black;
    }
`


/* ----- Principal function ----- */
function ModalRegister({ openModal, closeModal, modalRegister, getFields, isFetching, registerFields, alertMessage, loginAccess }) {

    React.useEffect(() => {
        if(!registerFields){
            getFields()
        }
    }, [ registerFields, getFields ])

    const submit = async (data) => {
        if(data.password === data.repassword){
            await modalRegister(data);
        } else {
            alertMessage('Las contraseñas no coinciden')
        }
    }

    const onLogin = () => {
        closeModal();
        loginAccess()
    }

    return(
        <React.Fragment>
            <Desktop>
                <ModalStyled
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openModal}
                    onClose={closeModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openModal}>
                        {
                            (!registerFields) ? 
                            <Spinner spinnerType='ThreeDots' />
                            : 
                            <ModalContent>
                                <ModalTitle>
                                    Registrarse
                                </ModalTitle>
                                <ModalBody >
                                    <Form width='90%' height='500px' inputMargin='14px' alignV={'flex-start'} columWidth='45%' fields={registerFields} submitButton='Registrarse' submitFunction={submit} />
                                    <HorizontalSeparator>o</HorizontalSeparator>
                                    <TextRegister>¿Ya tienes cuenta? <RegisterLink onClick={onLogin}>Iniciar sesión</RegisterLink></TextRegister>
                                </ModalBody>
                            </ModalContent>
                        }
                    </Fade>
                </ModalStyled>
            </Desktop>
            <Tablet>
                <ModalStyled
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openModal}
                    onClose={closeModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openModal}>
                        <ModalContent>
                            <ModalTitle>
                                Registrarse
                            </ModalTitle>
                            <ModalBody onSubmit={submit}>
                                <Form width='90%' height='500px' inputMargin='14px' alignV={'flex-start'} columWidth='45%' fields={registerFields} submitButton='Registrarse' submitFunction={submit} />
                                <HorizontalSeparator>o</HorizontalSeparator>
                                <TextRegister>¿Ya tienes cuenta? <RegisterLink onClick={onLogin}>Iniciar sesión</RegisterLink></TextRegister>
                            </ModalBody>
                        </ModalContent>
                    </Fade>
                </ModalStyled>
            </Tablet>
            <Mobile>
                <ModalStyled
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openModal}
                    onClose={closeModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openModal}>
                        <ModalContent mobile={true} width="90%" >
                            <ModalTitle>
                                Registrarse
                            </ModalTitle>
                            <ModalBody onSubmit={submit} mobile={true} >
                                <Form width='90%' height='500px' inputMargin='14px' alignV={'flex-start'} columWidth='90%' overflowY="auto" fields={registerFields} submitButton='Registrarse' submitFunction={submit} />
                                <HorizontalSeparator>o</HorizontalSeparator>
                                <TextRegister>¿Ya tienes cuenta? <RegisterLink onClick={onLogin}>Iniciar sesión</RegisterLink></TextRegister>
                            </ModalBody>
                        </ModalContent>
                    </Fade>
                </ModalStyled>
            </Mobile>
        </React.Fragment>
    );
}

function mapState(state) {
    const { isFetching, registerFields } = state.register;
    return { isFetching, registerFields };
}

const actionCreators = {
    modalRegister: registerActions.modalRegister,
    getFields: registerActions.getFields,
    alertMessage: alertActions.error
};

export default connect(mapState, actionCreators)(ModalRegister);