/* General imports */
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { theme } from '../app/config'
import { patientsActions } from './PatientsActions'
import { alertActions } from '../alert/AlertActions'

/* Components imports */
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import PhoneIcon from '../icons/phone.svg';
import EmailIcon from '../icons/email.svg';
import LocationIcon from '../icons/location.svg';
import BirthdayIcon from '../icons/birthday.svg';
import calendarAdmission from '../icons/calendarAdmission.svg'
import calendarDue from '../icons/calendarDue.svg'
import calendarRenovation from '../icons/calendarRenovation.svg'
import calendarEgress from '../icons/calendarEgress.svg'
import addIcon from '../icons/add.svg'
import ModalAddAssign from './ModalAddAssign'
import NoImage from '../images/no-image.png';

/* Styled components */
const ModalStyled = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 10px 10px!important;
    z-index: 99999;
    outline: none;
`

const ModalContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    color: black;
    box-sizing: border-box;
    border-radius: 10px 10px 10px 10px;
    width: 900px;
    min-height: 600px;
    align-items: center;
    justify-content: center;
    overflow: auto;
    outline: none;
`

const ModalTitle = styled.div`
    position: absolute;
    display: flex;
    top: 0;
    width: 100%;
    height: 70px;
    color: white;
    box-sizing: border-box;
    background: linear-gradient(to left,#122230 0%,#192d3e 100%);
`

const ModalBody = styled.div`
    position: absolute;
    display: flex;
    top: 70px;
    width: 100%;
    height: calc(100% - 70px);
    align-items: center;
    justify-content: center;
`

const ImageProfile = styled.div`
    position: absolute;
    display: flex;
    left: 50px;
    top: 20px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: white;
    border: 1px solid black;
    box-shadow: 1px 1px 5px black;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
    }
`

const TitleProfile = styled.div`
    position: absolute;
    display: flex;
    left: 155px;
    top: 15px;
    width: auto;
    height: 25px;
    color: white;
    font-size: 20px;
    font-weight: bold;
    text-shadow: .5px .5px 5px black;
`

const SubtitleProfile = styled.div`
    position: absolute;
    display: flex;
    left: 165px;
    top: 45px;
    width: auto;
    height: 25px;
    color: white;
    font-size: 14px;
    font-weight: bold;
    text-shadow: .5px .5px 5px black;
`

const BasicInfo = styled.div`
    position: absolute;
    display: flex;
    left: 50px;
    top: 75px;
    width: 42.5%;
    height: 42.5%;
    border-radius: 10px;
    border: 1px solid black;
    padding: 20px 10px 10px 10px;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    box-sizing: border-box;
    h3 {
        position: absolute;
        display: flex;
        top: -15px;
        left: 20px;
        margin: 0;
        font-size: 20px;
        background: white;
        color: white;
        text-shadow: 1px 1px 2px black, 0 0 1em blue, 0 0 0.2em blue;
        
    }
`

const AddiotionalInfo = styled.div`
    position: absolute;
    display: flex;
    right: 50px;
    top: 75px;
    width: 42.5%;
    height: 42.5%;
    border-radius: 10px;
    border: 1px solid black;
    padding: 20px 10px 10px 10px;
    box-sizing: border-box;
    align-items: ${(props) => props.alignH ? props.alignH : 'center'};
    justify-content: center;
    flex-direction: column;
    h3 {
        position: absolute;
        display: flex;
        top: -15px;
        left: 20px;
        margin: 0;
        font-size: 20px;
        background: white;
        color: white;
        text-shadow: 1px 1px 2px black, 0 0 1em blue, 0 0 0.2em blue;
    }
    h5 {
        font-size: 14px;
        color: #ccc;
        text-shadow: 1px 1px 2px black;
    }
`
const StyledButton = styled.button`
    width: calc(55%);
    height: 35px;
    background-color: ${theme.default.primaryButtonBg};
    color: ${theme.default.primaryButtonText};
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 600;
    border-radius: 80px;
    border-color: transparent;
    border-style: solid;
    margin-top: 10px;
    ${(props) => props.alignH ? `align-self: ${props.alignH}` : ''};
`

const InvoiceAccountData = styled.div`
    position: absolute;
    display: flex;
    align-items: ${(props) => props.alignV ? props.alignV : 'center'};
    justify-content: ${(props) => props.alignH ? props.alignH : 'center'};
    flex-direction: ${(props) => props.direction ? props.direction : 'row'};
    right: 50px;
    left: 50px;
    bottom: 25px;
    width: auto;
    height: 32.5%;
    border-radius: 10px;
    border: 1px solid black;
    padding: 5px;
    h3 {
        position: absolute;
        display: flex;
        top: -15px;
        left: 20px;
        margin: 0;
        font-size: 20px;
        background: white;
        color: white;
        text-shadow: 1px 1px 2px black, 0 0 1em blue, 0 0 0.2em blue;
    }
    h5 {
        font-size: 18px;
        color: #ccc;
        text-shadow: 1px 1px 2px black;
    }
`

const ItemData = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: ${(props) => props.align ? props.align : 'flex-start'};
    font-size: 14px;
    margin-top: 2.5px;
    margin-bottom: 2.5px;
    label {
        font-weight: bold;
        margin-right: 10px;
    }
    a {
        text-decoration: none;
        display: flex;
        align-items: center;
    }
    div {
        width: 100%;
        height: 100px;
        overflow: auto;
        border: 1px solid black;
        border-radius: 10px;
        border-color: grey;
        box-sizing: border-box;
        padding: 5px;
        font-size: 10px;
        font-style: italic;
    }
`

const ProfessionalList = styled.ul`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: left;
    padding: 20px;
`

const AddProf = styled.li`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    box-sizing: border-box;
    box-shadow: 1px 1px 2px black;
    border-radius: 10px;
    width: 50px;
    height: 50px;
    padding: 10px;
    img {
        width: 90%;
        height: 90%;
    }
`

const Prof = styled.li`
    position: relative;
    display: flex;
    justify-content: center;
    list-style: none;
    box-sizing: border-box;
    box-shadow: 1px 1px 2px black;
    border-radius: 10px;
    width: 200px;
    height: 50px;
    padding: 10px;
    flex-direction: column;
    margin: 0 10px;
    font-size: 12px;
    span {
        font-weight: bold;
        font-size: 14px;
    }
    div {
        position: absolute;
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-weight: bold;
        right: 0;
        width: 25px;
        height: 100%;
        background: red;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }
`

const Icon = styled.img`
    font-size: 26px;
    width: 30px;
    height: 30px;
    margin: 5px 10px 5px 0;
`

const RowData = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
`

const DateItem = styled.div`
    position: relative;
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: flex-start;
`

const Tooltip = styled.span`
        visibility: hidden;
        width: 120px;
        background-color: rgba(0,0,0,.7);
        color: #fff;
        text-align: center;
        border-radius: 6px 6px 6px 0;
        padding: 5px;
        position: absolute;
        z-index: 1000;
        top: 0;
        right: 0;
        transform: translate(25%,-100%);
    ${DateItem}:hover & {
        visibility: visible;
    }
`

/* ----- Principal function ----- */
function ModalViewPatient({ user, rowData, openModal, closeModal, assignProfessionals, professionalsList, activitiesList, assignProfessional, unassignProfessional, getProfessionalList, getActivitiesList, getAssignProfessionals }) {

    const [ openAssign, setOpenAssign ] = React.useState(false)

    const [ assigns, setAssigns ] = React.useState(false)

    const newAssign = () => {
        setOpenAssign(true)
    }

    const closeModalAssign = () => {
        setOpenAssign(false)
        refreshData()
    }

    const addAssignProfessional = async (data) => {
        await assignProfessional(data)
        closeModalAssign()
        setAssigns(false)
    }

    const deleteAssignProfessional = async (e) => {
        e.preventDefault()
        const { patient, professional, activity } = e.target.dataset
        const data = {
            idPatient: patient,
            idProfessional: professional,
            activity: activity
        }
        await unassignProfessional(data)
        setAssigns(false)
        refreshData()
    }

    const refreshData = () => {
        getAssignProfessionals(rowData.idUser)
    }

    React.useEffect(() => {
        if(!professionalsList){
            getProfessionalList()
        }
        if(!activitiesList){
            getActivitiesList()
        }
        
    }, [ professionalsList, getProfessionalList, activitiesList, getActivitiesList])

    React.useEffect( () => {
        if(!assignProfessionals){
            getAssignProfessionals(rowData.idUser)
        } else {
            if(!assigns){
                getAssignProfessionals(rowData.idUser)
                setAssigns(true)
            }
        }
    }, [assignProfessionals, getAssignProfessionals, rowData.idUser, assigns])

    return(
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openModal}
            onClose={closeModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={openModal}>
                <ModalContent>
                    <ModalTitle />
                    <ImageProfile>
                        <img src={rowData.img ? rowData.img : NoImage} alt={'ImageProfile'} />
                    </ImageProfile>
                    <TitleProfile> { rowData.firstname + " " + rowData.lastname }</TitleProfile>
                    <SubtitleProfile> Obra Social: { rowData.socialWork } - Orden: { rowData.orderNumber} - Afiliado: { rowData.affiliateNumber} </SubtitleProfile>
                    <ModalBody>
                        <BasicInfo>
                            <h3>INFORMACIÓN BÁSICA</h3>
                            <ItemData><label>DNI:</label>  {rowData.documentNumber} </ItemData>
                            {
                                rowData.tel ? <ItemData><a href={'https://api.whatsapp.com/send?phone='+ rowData.tel + '&text=Hola%20'+rowData.firstname+'.'} rel="noopener noreferrer" target='_blank'><Icon src={PhoneIcon} alt='Teléfono' /> {rowData.tel} </a></ItemData> : ''
                            }
                            <ItemData><a href={'mailto:'+rowData.email} rel="noopener noreferrer" target='_blank'><Icon src={EmailIcon} alt='Correo electrónico' /> {rowData.email} </a></ItemData>
                            {
                                true ? <ItemData><a href={'https://www.google.com/maps/search/Bereterbide+762'} rel="noopener noreferrer" target='_blank'><Icon src={LocationIcon} alt='Dirección' /> Bereterbide 762 </a></ItemData> : ''
                            }
                            {
                                rowData.birthday ? <ItemData><Icon src={BirthdayIcon} alt='Fecha de nacimiento' /> {rowData.birthday} </ItemData> : ''
                            }
                        </BasicInfo>
                        <AddiotionalInfo alignH='left'>
                            <h3>INFORMACIÓN ADICIONAL</h3>
                            <ItemData><label>Diagnóstico:</label> </ItemData>
                            <ItemData><div>{rowData.diagnosis}</div> </ItemData>
                            <RowData>
                                <DateItem>
                                    <Icon src={calendarAdmission} alt='Ingreso' />{rowData.admisionDate}
                                    <Tooltip>Ingreso</Tooltip>
                                </DateItem>
                                <DateItem>
                                    <Icon src={calendarEgress} alt='Egreso' /> {rowData.egressDate ? rowData.egressDate : '---'}
                                    <Tooltip>Egreso</Tooltip>
                                </DateItem>
                            </RowData>
                            <RowData>
                                <DateItem>
                                    <Icon src={calendarRenovation} alt='Renovación' /> {rowData.renovationDate}
                                    <Tooltip>Renovación</Tooltip>
                                </DateItem>
                                <DateItem>
                                    <Icon src={calendarDue} alt='Vencimiento' /> {rowData.dueDate}
                                    <Tooltip>Vencimiento</Tooltip>
                                </DateItem>
                            </RowData>
                        </AddiotionalInfo>
                        {
                            (assignProfessionals && assignProfessionals.length > 0) ?
                                <InvoiceAccountData>
                                    <h3>PROFESIONALES ASIGNADOS</h3>
                                    <ProfessionalList>
                                        {
                                            assignProfessionals.map((prof, index) => 
                                                <Prof key={index}>
                                                    <span>{ prof.activityName }</span>
                                                    { prof.id_professional.firstname + ' ' + prof.id_professional.lastname }
                                                    <div data-patient={rowData.idUser} data-professional={prof.idProfessional} data-activity={prof.activity} onClick={deleteAssignProfessional}>X</div>
                                                </Prof>
                                            )
                                        }
                                        <AddProf onClick={newAssign}><img src={addIcon} alt='Asignar profesional' /></AddProf>
                                    </ProfessionalList>
                                </InvoiceAccountData>
                            :
                                <InvoiceAccountData direction='column'>
                                    <h3>PROFESIONALES ASIGNADOS</h3>
                                    <h5>NO HAY PROFESIONALES ASIGNADOS</h5>
                                    <StyledButton onClick={newAssign}> ASIGNAR </StyledButton>
                                </InvoiceAccountData>
                        }
                    </ModalBody>
                    {
                        professionalsList && <ModalAddAssign user={user} rowData={rowData} title='Asignar profesional' textButton='Asignar' professionalsList={professionalsList} activitiesList={activitiesList} openModal={openAssign} closeModal={closeModalAssign} handleAction={addAssignProfessional} />
                    }
                </ModalContent>
            </Fade>
        </ModalStyled>
    );
}

function mapState(state) {
    const { professionalsList, activitiesList, assignProfessionals } = state.patient;
    const { user } = state.login;
    return { user, professionalsList, activitiesList, assignProfessionals};
}

const actionCreators = {
    assignProfessional: patientsActions.assignProfessional,
    unassignProfessional: patientsActions.unassignProfessional,
    getProfessionalList: patientsActions.getProfessionalList,
    getActivitiesList: patientsActions.getActivitiesList,
    getAssignProfessionals: patientsActions.getAssignProfessionals,
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(ModalViewPatient);