/* General imports */
import React from 'react'
import { Route } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { devices } from '../../app/config'
import { stockActions } from './StockActions'
import { alertActions } from '../../alert/AlertActions'

/* Components imports */
import NoImage from '../../images/no-image.png';
import Table from '../../tables/AsyncTable'
import StockIcon from '../../icons/stock.png'
import ModalEditStock from './ModalEditStock'

/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled components */
const Root = styled.div`
    width: ${(props) => props.width ? props.width : '100%'};
    height: 100%;
    padding: ${(props) => props.padding ? props.padding : 0};
    box-sizing: border-box;
`

const Header = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 150px;
    background: linear-gradient(to left, #122230 0%, #192d3e 100%);
    align-items: flex-start;
    padding: 35px 35px 70px 35px;
    box-sizing: border-box;
`

const Title = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img {
        position: relative;
        display: flex;
        width: 30px;
        height: 30px;
    }
    h6 {
        position: relative;
        display: flex;
        margin: 0 20px;
        color: white;
        font-size: 1.5rem;
        font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
        font-weight: 400;
    }
`

const Content = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: calc(100% - 150px);
    padding: 0 35px;
    box-sizing: border-box;
    top: -64px;
`

/* ----- Principal function ----- */
function Stock({ user, stock, getStock, isFetching, alertMessage, history, updateStock }) {

    const columsTable = [
        { title: 'Image', field: 'productData.img', render: rowData => <img src={ rowData.productData.img ? rowData.productData.img.images.length > 0 ? rowData.productData.img.images[0] : NoImage : NoImage} alt='SinImagen' style={{width: 40, height:40, borderRadius: '50%'}}/>, width: 50, align: 'center' },
        { title: 'ID', field: 'idProduct', width: 50 },
        { title: 'ID Depósito', field: 'idStorage', width: 50, hidden: true },
        { title: 'Código', field: 'productData.code', width: 200 },
        { title: 'Códigode barras', field: 'productData.barcode',width: 300, hidden: true },
        { title: 'Baja', field: 'productData.deleted', hidden: true },
        { title: 'Moneda', field: 'productData.idCurrencyUnit', hidden: true },
        { title: 'Unidad', field: 'productData.idQuantityUnit', hidden: true },
        { title: 'Categoría', field: 'productData.id_Category.name', width: 200 },
        { title: 'Marca', field: 'productData.trademark.name', width: 200 },
        { 
            title: 'Nombre', 
            field: 'productData.name', 
            width: 400
        },
        { 
            title: 'Depósito', 
            field: 'storage.name', 
            width: 400,
            hidden: true
        },
        { 
            title: 'Unidad', 
            field: 'productData.idQuantity_Unit.value',
            width: 150
        },
        { 
            title: 'Stock', 
            field: 'quantity',
            width: 150
        },
        { 
            title: 'Stock mínimo', 
            field: 'minStockQuantity',
            width: 150
        }
    ]

    const tableRef = React.createRef()

    const [ editData, setEditData ] = React.useState(null);

    const [open, setOpen] = React.useState(false);

    const handleEdit = (rowData) => {
        setEditData(rowData);
        setOpen(true);
    }

    const handleClose = () => {
        tableRef.current.onQueryChange()
        setOpen(false);
    }

    return(
        <React.Fragment>
            <Desktop>
                <Root>
                    <Header>
                        <Title>
                            <img src={StockIcon} alt='Icon' />
                            <h6>Stock</h6>
                        </Title>
                    </Header>
                    <Content>
                        <Route path={'/admin/stocksystem/stock'} exact={true} >
                            <Table urlapi="/api/stock" tableRef={tableRef} autoExport={true}  hiddenView={true} hiddenNew={true} hiddenDelete={true}  title="STOCK DE PRODUCTOS" columnsTable={columsTable} data={stock} getData={getStock} handleEdit={handleEdit} />   
                        </Route>
                        {
                            open && <ModalEditStock title='EDITAR STOCK' textButton='APLICAR' rowData={editData} user={user} handleUpdate={updateStock} openModal={open} closeModal={handleClose} alertMessage={alertMessage} />
                        }
                    </Content>
                </Root>
            </Desktop>
            <Tablet>VERSIÓN TABLET EN CONSTRUCCIÓN</Tablet>
            <Mobile>VERSIÓN MOBILE EN CONSTRUCCIÓN</Mobile>
        </React.Fragment>
    );
}

function mapState(state) {
    const { error, isFetching, stock } = state.stock;
    const { user } = state.login;
    return { user, error, isFetching, stock};
}

const actionCreators = {
    getStock: stockActions.getStock,
    updateStock: stockActions.updateStock,
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(Stock);