/* General imports */
import React from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { devices, config } from '../app/config'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import axios from 'axios';
import  { authHeader } from '../app/auth-header';

/* Components imports */
import Container from './Container'


/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

const HeightHeader = '70px'

/* Styled components */
const ContentBox = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: calc(100% - ${HeightHeader});
    align-items: flex-start;
    justify-content: center;
    padding-top: 25px;
`

const Header = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: ${HeightHeader};
    padding: 0 25px;
    box-sizing: border-box;
    background: #234;
`

const HeaderTitle = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 60%;
    height: 100%;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    text-shadow: 1px 1px 2px black;
`

const HeaderButtons = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 40%;
    height: 100%;
`

const BackButton = styled.button`
    margin: 0 5px;
`

/* ----- Principal function ----- */
function PageEditor({ match, history }) {

    const [ pageData, setPageData ] = React.useState(null)

    const { idPage } = match.params

    const onBack = () => {
        history.push(config.webeditorpath+'/pages')
    }

    React.useEffect(() => {
        if(!pageData){
            let config = {
                headers: { ...authHeader(), 'Content-Type': 'application/json' }
            }
            axios.get('/api/pages/'+idPage,config)
            .then(pageDataResponse => {
                const page = pageDataResponse.data.page
                setPageData(page)
            })
            .catch(err => {
                alert('Hubo un error...')
            })
        } 
        
    }, [ idPage, pageData ]) 

    return(
        
        <React.Fragment>
            <Header>
                {
                    pageData && <HeaderTitle>EDITANDO LA PÁGINA "{pageData.title}"</HeaderTitle>
                }
                <HeaderButtons><BackButton onClick={onBack}>VOLVER</BackButton></HeaderButtons>
            </Header>
            <Desktop>
                <ContentBox>
                    <DndProvider backend={HTML5Backend}>
                        <Container idPage={idPage} />
                    </DndProvider>
                </ContentBox>
            </Desktop>
            <Tablet>
                <ContentBox>
                    PAGE EDITOR TABLET
                </ContentBox>
            </Tablet>
            <Mobile>
                <ContentBox>
                    PAGE EDITOR MOBILE
                </ContentBox>
            </Mobile>
        </React.Fragment>
    );
}

export default PageEditor;