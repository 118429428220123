/* General imports */
import React from 'react'
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { devices } from '../../app/config';
import axios from 'axios'
import  { authHeader } from '../../app/auth-header';

/* Components imports */
import Spinner from '../../spinners/LoaderSpinner'
import Zoom from 'react-reveal/Zoom';
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';

/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled Components */
const Root = styled.div`
    width:100%;
    height:auto;
    display: flex;
    position: relative;
    font-family: 'IBM Plex Sans';
    background-color: #FFF;
`

const Row = styled.div`
    width:100%;
    height: ${(props) => props.height ? props.height : '70vh'};
    display:flex;
    align-items: center;
    flex-direction: ${(props) => props.flexDirection ? props.flexDirection : 'row'};
    @media (max-width: 600px) {
        margin: 3rem  0;
    }
`;

const Column = styled.div`
    width: ${(props) => props.width ? props.width : '50%'};
    height:100%;
    box-sizing: border-box;
    display:flex;
    justify-content:center;
    align-items: ${(props) => props.alignItems ? props.alignItems : "flex-start"};
    flex-direction:column;
    padding: ${(props) => props.padding ? props.padding : 0};
    background: ${(props) => props.background ? props.background : 'transparent'};
    ${(props) => props.backgroundImage ? `background-image: url(${props.backgroundImage})` : ''};
    ${(props) => props.parallax ? 'background-attachment: fixed' : ''};
    background-position: center;
    background-repeat: no-repeat;
    background-size: ${(props) => props.backgroundSize ? props.backgroundSize : '65%'};
    color: #000A12;
    font-size: 1.25rem;
    text-align: left;
    font-family: 'IBM Plex Sans';
    font-weight: 300;
    h3 {
        width: 80%;
        color: #001554;
        text-align: left;
        font-family: 'IBM Plex Sans';
        font-weight: 300;
        font-size: 2.5rem;
        margin: 20px 0;
    }
    span {
        width: 100%;
        font-size: 1rem;
    }
    @media (max-width: 600px) {
        align-items: center;
        h3 {
            width: 100%;
            font-size: 2rem;
            text-align: center;
        }
        span {
            width: 100%;
            text-align: center;
            font-size: 1rem;
        }
    }
`

const ColumnImage = styled.img`
    width: 100%;
    height: 100%;
    max-width: 100vw;
    object-fit: contain;
    object-position: center;
    box-sizing: border-box;
    display:flex;
`

const ContentColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    font-size: 0.85rem;
`

const Button = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 216px;
    height: 48px;
    font-size: 20px;
    background: #001554;
    border-radius: 24px;
    color: #FFF;
    margin: 50px 0 20px 0;
    cursor: pointer;
    text-decoration: none;
    @media (max-width: 600px) {
        position: absolute;
        bottom: 70px;
        left: 50%;
        transform: translateX(-50%);
    }
`

/* Principal function*/
/*
Params
title
switchColumns
text
img
*/
function TwoColums({idsection}) {

    const [twoColumnsData, setTwoColumnsData] = React.useState(null)

    React.useEffect(() => {
        if(!twoColumnsData){
            let configHeader = {
                headers: { ...authHeader(), 'Content-Type': 'application/json' }
            }
            axios.get('/api/sections/' + idsection,configHeader)
            .then(sectionData => {
                setTwoColumnsData(sectionData.data.section.data);
            })
            .catch(err => {
                alert('Hubo un error...');
            })
        }
    }, [twoColumnsData, idsection])

    return (
        <Root>
            <Desktop>
                {
                    !twoColumnsData ? <Spinner spinnerType='ThreeDots' />
                    :
                    twoColumnsData.switchColumns === 1 ?
                        <Row height="auto">
                            <Column width="50%" padding='0 3rem 0 6rem' background='#FFF'>
                                <Zoom duration={2000} delay={500}>
                                    <ContentColumn>
                                        <h3> {twoColumnsData.title} </h3>
                                        <div dangerouslySetInnerHTML={{ __html: twoColumnsData.text }} />
                                        {
                                        twoColumnsData.link && <Button href={twoColumnsData.link}>Conocenos</Button>
                                        }
                                    </ContentColumn>
                                </Zoom>
                            </Column>
                            <Column width="50%" alignItems="center" padding="100px" >
                                <Fade bottom duration={2000} delay={300}>
                                    <ColumnImage src={twoColumnsData.img} parallax={twoColumnsData.parallax} alt="Image twocolumns"/>
                                </Fade>
                            </Column>
                        </Row>
                        :
                        <Row height="auto">
                            <Column width="50%" alignItems="center" padding="100px" >
                                <Fade bottom duration={2000} delay={300}>
                                    <ColumnImage src={twoColumnsData.img} parallax={twoColumnsData.parallax} alt="Image twocolumns"/>
                                </Fade>
                            </Column>
                            <Column width="50%" padding='0 3rem 0 6rem' background='#FFF'>
                                <Zoom duration={2000} delay={800}>
                                    <ContentColumn>
                                        <h3> {twoColumnsData.title} </h3>
                                        <div dangerouslySetInnerHTML={{ __html: twoColumnsData.text }} />
                                        {
                                        twoColumnsData.link && <Button href={twoColumnsData.link}>Conocenos</Button>
                                        }
                                    </ContentColumn>
                                </Zoom>
                            </Column>
                        </Row>
                }
            </Desktop>
            <Tablet>
                {
                    !twoColumnsData ? <Spinner spinnerType='ThreeDots' />
                    :
                    twoColumnsData.switchColumns === 1 ?
                    <Row>
                        <Column width="45%" padding='0 3rem' background='#FFF' fontSize="0.75rem">
                            <Zoom duration={2000} delay={500}>
                                <ContentColumn>
                                    <h3> {twoColumnsData.title} </h3>
                                    <div dangerouslySetInnerHTML={{ __html: twoColumnsData.text }} />
                                    {
                                    twoColumnsData.link && <Button href={twoColumnsData.link}>Conocenos</Button>
                                    }
                                </ContentColumn>
                            </Zoom>
                        </Column>
                        <Column width="55%" alignItems="center" >
                            <Fade bottom duration={2000} delay={300}>
                                <ColumnImage src={twoColumnsData.img} parallax={twoColumnsData.parallax} alt="Image twocolumns"/>
                            </Fade>
                        </Column>
                    </Row>
                    :
                    <Row>
                        <Column width="55%" alignItems="center" >
                            <Fade bottom duration={2000} delay={300}>
                                <ColumnImage src={twoColumnsData.img} parallax={twoColumnsData.parallax} alt="Image twocolumns"/>
                            </Fade>
                        </Column>
                        <Column width="45%" padding='0 3rem' background='#FFF' fontSize="0.75rem">
                            <Zoom duration={2000} delay={800}>
                                <ContentColumn>
                                    <h3> {twoColumnsData.title} </h3>
                                    <div dangerouslySetInnerHTML={{ __html: twoColumnsData.text }} />
                                    {
                                    twoColumnsData.link && <Button href={twoColumnsData.link}>Conocenos</Button>
                                    }
                                </ContentColumn>
                            </Zoom>
                        </Column>
                    </Row>
                }
            </Tablet>
            <Mobile>
                {
                    !twoColumnsData ? <Spinner spinnerType='ThreeDots' />
                    :
                    <Row height='auto' flexDirection='column'>
                        <Column width='100%' padding='0 1rem' background='#FFF' fontSize='0.75rem'>
                            <Zoom duration={2000} delay={800}>
                                <ContentColumn>
                                    <h3> {twoColumnsData.title} </h3>
                                    <div dangerouslySetInnerHTML={{ __html: twoColumnsData.text }} />
                                </ContentColumn>
                            </Zoom>
                        </Column>
                        <Column width='100%' >
                            <Fade bottom duration={2000} delay={300}>
                                <ColumnImage src={twoColumnsData.img} parallax={twoColumnsData.parallax} alt="Image twocolumns"/>
                            </Fade>
                            <Fade bottom duration={2000} delay={300}>
                                {
                                    twoColumnsData.link && <Button href={twoColumnsData.link}>Conocenos</Button>
                                }
                            </Fade>
                        </Column>
                    </Row>
                }
            </Mobile>
        </Root>
    )
}

export default TwoColums;