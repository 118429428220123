/* General imports */
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { devices, config } from '../../app/config'
import axios from 'axios'
import  { authHeader } from '../../app/auth-header';

/* Components imports */

/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled components */
const ContentBox = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    justify-content: flex-start;
`

/* ----- Principal function ----- */
function ProductSettings({match, history, user}) {
    
    let configHeader = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    const { idPage, idSection } = match.params;

    const [data, setData] = React.useState(null);

    React.useEffect(() => {
        if(!data){
            axios.get('/api/sections/' + idSection,configHeader)
            .then(sectionData => {
                if(parseInt(Object.keys(sectionData.data.section.data).length) > 0){
                    setData(sectionData.data.section.data);
                } else {
                    setData({
                        
                    })
                }
            })
            .catch(err => {
                alert('Hubo un error...');
            })
        }
    }, [data, idSection])

    return(
        <React.Fragment>
            <Desktop>
                <ContentBox>
                    DESKTOP PRODUCT SETTINGS - SECTION {idSection}
                </ContentBox>
            </Desktop>
            <Tablet>
                <ContentBox>
                    TABLET PRODUCT SETTINGS - SECTION {idSection}
                </ContentBox>
            </Tablet>
            <Mobile>
                <ContentBox>
                    MOBILE PRODUCT SETTINGS - SECTION {idSection}
                </ContentBox>
            </Mobile>
        </React.Fragment>
    );
}

function mapState(state) {
    const { user } = state.login;
    return { user };
}


export default connect(mapState)(ProductSettings);