/* General imports */
import React from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { devices } from '../../app/config'

/* Components imports */

/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled components */
const Root = styled.section`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
`

const ContentBox = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: aliceblue;
`

/* ----- Principal function ----- */
function BasicType(props) {

    return(
        <Root>
            <Desktop>
                <ContentBox>
                    DESKTOP - {props.datasection.text}
                </ContentBox>
            </Desktop>
            <Tablet>
                <ContentBox>
                    TABLET
                </ContentBox>
            </Tablet>
            <Mobile>
                <ContentBox>
                    MOBILE
                </ContentBox>
            </Mobile>
        </Root>
    );
}

export default BasicType;