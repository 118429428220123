import { accountsConstants } from './AccountsConstants';

let accounts = JSON.parse(localStorage.getItem('accounts'));
const initialState = accounts ? 
        { 
            error: false,
            isFetching: false,
            accounts: accounts
        }
    : 
        { 
            error: false,
            isFetching: true,
        };

export function account(state = initialState, action) {
    switch (action.type) {
        case accountsConstants.FETCHING:
            return { 
                ...state,
                isFetching: true
            };
        case accountsConstants.SUCCESS:
            return { 
                ...state,
                isFetching: false,
                accounts: action.accounts
            };
        case accountsConstants.ERROR:
            return { 
                ...state,
                error: true
            };
        case accountsConstants.FETCHING_FORMFIELDS:
            return { 
                ...state,
                isFetchingFields: true
            };
        case accountsConstants.SUCCESS_FORMFIELDS:
            return { 
                ...state,
                isFetchingFields: false,
                accountsFields: action.accountsFields,
                error: false
        };
        case accountsConstants.SUCCESS_UPDATEFORMFIELDS:
            return { 
                ...state,
                isFetchingFields: false,
                accountsUpdateFields: action.accountsUpdateFields,
                error: false
        };
        case accountsConstants.ERROR_FORMFIELDS:
            return {
                ...state,
                error: true
        };
        default:
            return state
    }
}