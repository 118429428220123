import { invoicesConstants } from './InvoicesConstants';
import { alertActions } from '../alert/AlertActions';
import axios from 'axios';
import  { authHeader } from '../app/auth-header';
/* import PersonIcon from '../icons/userCircle.svg';
import LockIcon from '../icons/lock.svg'; */

export const invoicesActions = {
    createInvoice,
    updateInvoice,
    getInvoices,
    deleteInvoice,
    getFields,
    getUpdateFields
};

function createInvoice(invoice) {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return dispatch => {
        axios.post( '/api/invoices' , {
            invoiceTypeCode: invoice.invoiceTypeCode,
            pointSale: invoice.pointSale,
            voucherNumber: invoice.voucherNumber,
            invoiceDate: invoice.invoiceDate,
            invoicedPeriodFrom: invoice.invoicedPeriodFrom,
            invoicedPeriodTo: invoice.invoicedPeriodTo,
            paymentDueDate: invoice.paymentDueDate,
            caeNumber: invoice.caeNumber,
            caeExpirationDate: invoice.caeExpirationDate,
            saleCondition: invoice.saleCondition,
            transmitterCuit: invoice.transmitterCuit,
            transmitterBusinessName: invoice.transmitterBusinessName,
            transmitterCommercialAddress: invoice.transmitterCommercialAddress,
            transmitterIvaCondition: invoice.transmitterIvaCondition,
            transmitterGrossIncome: invoice.transmitterGrossIncome,
            transmitterActivityStartDate: invoice.transmitterActivityStartDate,
            afipDocTipo: invoice.afipDocTipo,
            receiverCuit: invoice.receiverCuit,
            receiverBusinessName: invoice.receiverBusinessName,
            receiverCommercialAddress: invoice.receiverCommercialAddress,
            receiverIvaCondition: invoice.receiverIvaCondition,
            invoiceDetails: invoice.invoiceDetails,
            subtotal: invoice.subtotal,
            amountOtherTaxes: invoice.amountOtherTaxes,
            total: invoice.total,
            retention: invoice.retention,
            nettotal: invoice.nettotal,
            createdUser: invoice.createdUser,
            deleted: 0,
            otherTaxes: invoice.otherTaxes,
            metaVoucher: invoice.metaVoucher
        }, config)
        .then(response => {
            dispatch(alertActions.success('Factura creada'));
        })
        .catch(error => {
            dispatch(alertActions.error(error.toString()));

        })
    };
}

function updateInvoice(invoice) {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    return dispatch => {
        axios.put( '/api/invoices/'+ invoice.idInvoice, {
            invoiceTypeCode: invoice.invoiceTypeCode,
            pointSale: invoice.pointSale,
            voucherNumber: invoice.voucherNumber,
            invoiceDate: invoice.invoiceDate,
            invoicedPeriodFrom: invoice.invoicedPeriodFrom,
            invoicedPeriodTo: invoice.invoicedPeriodTo,
            paymentDueDate: invoice.paymentDueDate,
            caeNumber: invoice.caeNumber,
            caeExpirationDate: invoice.caeExpirationDate,
            saleCondition: invoice.saleCondition,
            transmitterCuit: invoice.transmitterCuit,
            transmitterBusinessName: invoice.transmitterBusinessName,
            transmitterCommercialAddress: invoice.transmitterCommercialAddress,
            transmitterIvaCondition: invoice.transmitterIvaCondition,
            transmitterGrossIncome: invoice.transmitterGrossIncome,
            transmitterActivityStartDate: invoice.transmitterActivityStartDate,
            afipDocTipo: invoice.afipDocTipo,
            receiverCuit: invoice.receiverCuit,
            receiverBusinessName: invoice.receiverBusinessName,
            receiverCommercialAddress: invoice.receiverCommercialAddress,
            receiverIvaCondition: invoice.receiverIvaCondition,
            invoiceDetails: invoice.invoiceDetails,
            subtotal: invoice.subtotal,
            amountOtherTaxes: invoice.amountOtherTaxes,
            total: invoice.total,
            retention: invoice.retention,
            nettotal: invoice.nettotal,
            updatedUser: invoice.updatedUser,
            deleted: 0,
            deletedInvoiceDetails: invoice.deletedInvoiceDetails,
            otherTaxes: invoice.otherTaxes,
            metaVoucher: invoice.metaVoucher
        }, config)
        .then(response => {
            dispatch(alertActions.success('Factura actualizada'));
        })
        .catch(error => {
            dispatch(alertActions.error(error.toString()));

        })
    };
}

function getInvoices() {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return dispatch => {
        dispatch({ type: invoicesConstants.FETCHING });
        axios.get('/api/invoices',config)
        .then(invoicesData => {
            const invoices = invoicesData.data.invoices
            dispatch({ type: invoicesConstants.SUCCESS, invoices });
        })
        .catch(err => {
            dispatch({ type: invoicesConstants.ERROR });
            dispatch(alertActions.error('Hubo un error...'));
        })
    }
}

function deleteInvoice(id) {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    return dispatch => {
        axios
        .delete( '/api/invoices/' + id, config)
        .then(response => {
            dispatch(alertActions.success('Factura eliminada'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}

function getFields() {

    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    return dispatch => {
        dispatch({ type: invoicesConstants.FETCHING_FORMFIELDS });
        axios.get('/api/invoicesFields', config)
        .then(response => {
            const invoicesFields = response.data.invoicesFields;
            dispatch({ type: invoicesConstants.SUCCESS_FORMFIELDS, invoicesFields });
        })
        .catch(error => {
            dispatch(alertActions.error(error));
        })
    }
}

function getUpdateFields() {

    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    return dispatch => {
        dispatch({ type: invoicesConstants.FETCHING_FORMFIELDS });
        axios.get('/api/registerFields', config)
        .then(response => {
            const professionalUpdateFields = response.data.registerFields.filter(element => ((element.name !== 'idRole') && (element.name !== 'username') && (element.type !== 'password')));
            professionalUpdateFields.push({
                name: 'specialization',
                type: 'text',
                placeholder: 'Especialización',
                required: false
            })
            professionalUpdateFields.push({
                name: 'enrollment',
                type: 'text',
                placeholder: 'Matrícula',
                required: false
            })
            dispatch({ type: invoicesConstants.SUCCESS_UPDATEFORMFIELDS, professionalUpdateFields });
        })
        .catch(error => {
            dispatch(alertActions.error(error));
        })
    }
}