/* General imports */
import React from 'react'
import styled from 'styled-components'
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import axios from 'axios'
import  { authHeader } from '../app/auth-header';

/* Components imports */
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

/* Styled components */
const ModalStyled = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 10px 10px!important;
    z-index: 99999;
`

const ModalContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    color: black;
    box-sizing: border-box;
    border-radius: 10px 10px 10px 10px;
    width: 550px;
    min-height: 450px;
    align-items: center;
    justify-content: center;
    overflow: auto;
    outline: none;
`

const ModalTitle = styled.div`
    position: absolute;
    display: flex;
    top: 0;
    width: 100%;
    height: 70px;
    color: white;
    box-sizing: border-box;
    background: linear-gradient(to left,#122230 0%,#192d3e 100%);
`

const ModalBody = styled.div`
    position: absolute;
    display: flex;
    top: 70px;
    width: 100%;
    height: calc(100% - 70px);
    align-items: center;
    justify-content: center;
    outline: none;
`

const ImageProfile = styled.div`
    position: absolute;
    display: flex;
    left: 50px;
    top: 30px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: white;
    border: 1px solid black;
    box-shadow: 1px 1px 5px black;
`

const TitleProfile = styled.div`
    position: absolute;
    display: flex;
    left: 180px;
    top: 45px;
    width: auto;
    height: 25px;
    color: white;
    font-size: 20px;
    font-weight: bold;
    text-shadow: .5px .5px 5px black;
`

const Balance = styled.div`
    position: absolute;
    display: flex;
    top: 0;
    right: 0;
    width: 40%;
    height: 50%;
    padding: 10px;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h2 {
        font-size: 40px;
        margin: 5px 0;
    }
    p {
        font-size: 60px;
        font-weight: bold;
        margin: 0;
        color: ${(props) => props.color ? props.color : 'black'}
    }
`

const DataAccount = styled.ul`
    position: absolute;
    display: inline;
    top: 60px;
    left: 0;
    width: 60%;
    height: calc(50% - 60px);
    padding: 10px 25px;
    box-sizing: border-box;
    list-style:none;
    margin: 0;
    font-size: 20px;
    label {
        font-weight: bold;
    }
    li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 5px 0;
    }
    p {
        margin: 0;
    }
`

const AccountHistory = styled.div`
    position: absolute;
    display: flex;
    right: 25px;
    left: 25px;
    bottom: 25px;
    width: auto;
    height: 40%;
    align-items: center;
    justify-content: center;
    padding-right: 25px;
`

const ChartLabel = styled.div`
    border: 1px solid black;
    border-radius: 10px;
    font-size: 14px;
    text-align: center;
    padding: 10px;
    background-color: white;
    h4 {
        margin: 0 0 1px 0;
        font-weight: bold;
        color: ${(props) => props.color ? props.color : 'black'}
    }
    p {
        margin: 0 0 5px 0;
        font-size: 10px;
    }
    span {
        margin: 0 0 5px 0;
        font-size: 10px;
    }
`



/* ----- Principal function ----- */
function ModalViewAccount({ rowData, openModal, closeModal }) {

    const [logs, setLogs] = React.useState(null);
    
    React.useEffect(() => {
        if(!logs){
            let config = {
                headers: { ...authHeader(), 'Content-Type': 'application/json' }
            }
            axios.get('/api/logaccount/' + rowData.idAccount,config)
            .then(logData => {
                let logAccount = logData.data.logAccount;
                logAccount.forEach(element => {
                    const date = new Date(element.createdDate);
                    element.createdDate = date.getDate() + '-' + date.getMonth() + '-' + date.getFullYear();
                });
                setLogs(logAccount);
            })
            .catch(err => {
                alert('Hubo un error...');
            })
        }
    }, [ logs, rowData.idAccount ])

    

    /* const submit = (data) => {
        handleAction(data)
    } */

    const CustomTooltip = (data) => {
        if (data.active) {
            /* const date = new Date(data.label);
            const label = date.getDate() + '-' + date.getMonth() + '-' + date.getFullYear(); */
            return (
            <ChartLabel color={data.payload[0].value > 0 ? 'green' : data.payload[0].value < 0 ? 'red' : 'black'} >
                <h4>{`Saldo: $${data.payload[0].value}`}</h4>
                <span>{data.label}</span>
                <p>{`${data.payload[0].payload.observation}`}</p>
            </ChartLabel>
            ); 
        }
        return null;
    }

    /* const data = [{name: '27/10/2020', observation:'Extracción de $50' , Saldo: 50}, {name: '30/10/2020', Saldo: 100}]; */

    return(
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openModal}
            onClose={closeModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={openModal}>
                <ModalContent>
                    <ModalTitle />
                    <ImageProfile />
                    <TitleProfile> { rowData.id_user.firstname + " " + rowData.id_user.lastname }</TitleProfile>
                    <ModalBody>
                        <DataAccount>
                            <li><label>Código:&nbsp; </label><p> { rowData.idAccount_Type.code }.{ rowData.aux } </p></li>
                            <li><label>Tipo:&nbsp; </label><p> { rowData.idAccount_Type.name } </p></li>
                        </DataAccount>
                        <Balance color={rowData.balance > 0 ? 'green' : rowData.balance < 0 ? 'red' : 'black'}>
                            <h2>SALDO</h2>
                            <p>${rowData.balance}</p>
                        </Balance>
                        
                        <AccountHistory>
                            {
                                !logs ? <h2>Cargando...</h2> 
                                :
                                <LineChart width={500} height={150} data={logs} margin={{ top: 5, right: 0, bottom: 5, left: 0 }}>
                                    <Line type="monotone" dataKey="balance" stroke="#8884d8" />
                                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                    <XAxis dataKey="createdDate" />
                                    <YAxis />
                                    <Tooltip content={<CustomTooltip />}/>
                                </LineChart>
                            }
                            
                        </AccountHistory>
                    </ModalBody>
                </ModalContent>
            </Fade>
        </ModalStyled>
    );
}

export default ModalViewAccount;