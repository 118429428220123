/* General imports */
import React from 'react';
import styled, {keyframes} from 'styled-components';
import { connect } from 'react-redux';
import axios from 'axios';
import  { authHeader } from '../../app/auth-header';
import { sidebarCartActions } from '../../SidebarCart/SidebarCartActions';
import { useMediaQuery } from 'react-responsive';
import { devices } from '../../app/config';

/* Components imports */
import Spinner from '../../spinners/LoaderSpinner'
import ProductCard from '../ProductList/ProductCard';
import Zoom from 'react-reveal/Zoom'
import ProductTemplate1 from './ProductTemplate1';
import ProductTemplate2 from './ProductTemplate2';


/* Styled components */

const Root = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    min-height: 100vh;
    align-items: center;
    justify-content: flex-start;
    font-family: 'IBM Plex Sans';
`



const RelatedProducts = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 50px;
    /* transform: translateY(-100px); */
    @media (max-width: 600px) {
        padding: 0 20px;
    }
`

const RelatedTitle = styled.h2`
    display: flex;
    align-items: center;
    width: 100%;
    height: 150px;
    text-align: left;
    font-weight: 300;
    font-size: 2.5rem;
    color: #001554;
    padding-left: 20px;
    margin: auto 0;
    @media (max-width: 600px) {
        font-size: 1.8rem;
        padding: 0;
    }
`

const ContentList = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;
    box-sizing: border-box;
    width: 100%;
    height: 350px;
    margin: 0 0 50px 0;
    @media (max-width: 800px) {
        grid-template-columns: 1fr 1fr;
        height: auto;
    }
    @media (max-width: 600px) {
        grid-template-columns: 1fr;
        gap: 50px 0;
        height: auto;   
    }
`

/* ----- Principal function ----- */
function Product({idsection, match, cartitems, addToCart, switchSidebarCart}) {

    const { idproduct } = match.params;

    const [ productsData, setProductsData ] = React.useState(null);
    const [productData, setProductData] = React.useState(null) 
    const [principalFeatures, setPrincipalFeatures] = React.useState(null);
    const [advantage, setAdvantage] = React.useState(null);
    const [temp, setTemp] = React.useState(0);

    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })

    const getDescription = () => {
        switch (parseInt(temp)) {
            case 0:
                return  <ProductTemplate1 productData={productData} principalFeatures={principalFeatures} advantage={advantage} isDesktop={isDesktop} />
                break;
            case 1:
                return  <ProductTemplate2 productData={productData} principalFeatures={principalFeatures} advantage={advantage} isDesktop={isDesktop} />
                break;
        
            default:
                break;
        }
    }

    React.useEffect(() => {
        let configHeader = {
            headers: { ...authHeader(), 'Content-Type': 'application/json' }
        }
        if(!productData){
            axios.get('/api/products/' + idproduct)
            .then(response => {
                setProductData(response.data.product);
                if(response.data.product.aptitudes){
                    setPrincipalFeatures(response.data.product.aptitudes)
                }
                if(response.data.product.features && response.data.product.features.length > 0){
                    setAdvantage(response.data.product.features.filter(apt => apt.name === 'Ventaja'))
                }
                if(response.data.product.tags && response.data.product.tags.length > 0){
                    if(response.data.product.tags.filter(tag => tag.name === 'Novedades').length > 0){
                        setTemp(1);
                    }
                }
                const url = `/api/products?idCategory=${response.data.product.idCategory}&limit=4`;
                if(!productsData){
                    axios.get(url,configHeader)
                    .then(productData => {
                        const relatedProducts = productData.data.rows.filter(rp => rp.idProduct !== response.data.product.idProduct);
                        setProductsData(relatedProducts);
                    })
                    .catch(err => {
                        alert('Hubo un error al obtener los productos relacionados');
                    })
                }
            })
            .catch(err => {
                alert('Hubo un error al obtener los datos de la sección');
            })
        }
    }, [])

    return(
        !productData ? <Spinner spinnerType='ThreeDots' /> :
        <Root>
            {
                getDescription()
            }
            <RelatedProducts>
                <Zoom>
                    <RelatedTitle>Productos relacionados</RelatedTitle>
                </Zoom>
                <ContentList>
                    {
                        productsData && productsData.length > 0 && productsData.map((prod, index) =>
                            <ProductCard key={`product_${index}`} productInfo={prod} />
                        )
                    }
                </ContentList>
            </RelatedProducts>
        </Root>
    );
}

function mapState(state) {
    const { error, isFetching, cartitems} = state.sidebarcart;
    return { error, isFetching, cartitems};
}

const actionCreators = {
    addToCart: sidebarCartActions.addToCart,
    switchSidebarCart: sidebarCartActions.switchSidebarCart
}

export default connect(mapState, actionCreators)(Product);