/* General imports */
import React from 'react';
import styled from 'styled-components';
import axios from 'axios';

/* Components imports */
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Cancel from '@material-ui/icons/Cancel';
import NoImage from '../images/no-image.png';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

/* Styled components */
const Root = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
`

const Placeholder = styled.span`
    color: rgba(0,0,0,0.54);
`

const ImageContainer = styled.div`
    position: relative;
    display: flex;
    width: 150px;
    height: 150px;
    margin-bottom: 10px;
    img {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`

const UploadImage = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin: 10px 0;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    box-sizing: border-box;
`

const Input = styled.input`
    font-size: 0.75rem;
    width: 100%;
`

const Button = styled.button`
    border: none;
    background: transparent;
    cursor: pointer;
    outline: none;
`

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
`

const CancelButton = styled.button`
    position: absolute;
    display: flex;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 5px;
    border: none;
    background: transparent;
    color: red;
    opacity: 0.5;
    &:hover {
        opacity: 1;
    }
`

const Counter = styled.div`
    display: ${(props) => props.hidden ? 'none' : 'flex'};
    position: absolute;
    top: 10px;
    width: 100%;
    height: auto;
    font-size: 20px;
    align-items: center;
    justify-content: center;
    color: black;
    z-index: 10;
    p {
        margin: 5px
    }
`

const BackArrow = styled(ArrowBackIosIcon)`
    display: ${(props) => props.hidden ? 'none' : 'flex'};
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 5px;
    font-size: 25px;
    transition: all .5s;
    z-index: 10;
    &:hover {
        font-size: 30px;
    }
`

const ForwardArrow = styled(ArrowForwardIosIcon)`
    display: ${(props) => props.hidden ? 'none' : 'flex'};
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
    font-size: 25px;
    transition: all .5s;
    z-index: 10;
    &:hover {
        font-size: 30px;
    }
`

function ImageInput(props) {

    const inputRef = React.useRef();

    const [ selectedFiles, setSelectedFiles ] = React.useState(null);

    const [ imagePreview, setImagePreview ] = React.useState(0);

    const fileSelectedFileHandler = (e) => {
        setSelectedFiles(Array.from(e.target.files));
    };
    const handleFileUpload = (e) => {
        if(selectedFiles.length > 0){
            Promise.all(selectedFiles.map(file => {
                return (new Promise((resolve,reject) => {
                    const formData = new FormData()
                    formData.append('image', file)
                    resolve(axios.post("/api/images/upload", formData, {}))
                }));
            }))
            .then(res => {
                let loaded = [];
                res.forEach(element => {
                    loaded.push('/uploads/'+element.data.filename)
                });
                props.handleImages(props.images.concat(loaded))
            }, error => {     
                alert('Huvo un error al cargar las imágenes.');
                console.error(error);
            });
        }
    }

    const cancelImage = (e) => {
        e.preventDefault()
        // eslint-disable-next-line no-restricted-globals
        if(confirm('¿Desea eliminar esta imagen?')){
            const oldImages = props.images;
            props.handleImages(oldImages.filter((image, index) => index !== parseInt(imagePreview)));
        }
    }

    const handleBackImage = () => {
        if(imagePreview > 0){
            setImagePreview(imagePreview - 1)
        } else {
            setImagePreview(props.images.length - 1)
        }
    }

    const handleNextImage = () => {
        if(imagePreview < props.images.length - 1){
            setImagePreview(imagePreview + 1)
        } else {
            setImagePreview(0)
        }
    }

    return (
        <Root>
            {
                props.placeholder && <Placeholder>{props.placeholder}</Placeholder>
            }
            <ImageContainer>
                {
                    props.images && props.images.length > 0 &&
                    <Counter hidden={props.images && props.images.length === 0 ? true : false} >
                        <p>{`${imagePreview + 1} / ${props.images.length}`}</p>
                    </Counter>
                }
                {
                    props.images && props.images.length > 0 &&
                    <BackArrow hidden={props.images && props.images.length === 0 ? true : false} onClick={handleBackImage} />
                }
                
                <Image src={props.images && props.images.length > 0 ? props.images[imagePreview] : NoImage} alt="uploaded image" />
                {
                    props.images && props.images.length > 0 && <CancelButton onClick={cancelImage}><Cancel /></CancelButton>
                }
                {
                    props.images && props.images.length > 0 &&
                    <ForwardArrow hidden={props.images && props.images.length === 0 ? true : false} onClick={handleNextImage} />
                }
            </ImageContainer>
            <UploadImage>
                <Input ref={inputRef} id={props.id} name={props.name} type="file" multiple="true" accept="image/*" onChange={fileSelectedFileHandler} />
                
                <Button onClick={handleFileUpload} disabled={selectedFiles && selectedFiles.length === 0}><CloudUploadIcon /></Button>
            </UploadImage>
        </Root>
    );
}

export default ImageInput;