/* General imports */
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { devices, config } from '../../app/config'
import axios from 'axios'
import  { authHeader } from '../../app/auth-header';

/* Components imports */
import Spinner from '../../spinners/LoaderSpinner';
import Input from '../../form/Input';

/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

const HeightHeader = '70px'

/* Styled components */
const ContentBox = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
`

const Header = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: ${HeightHeader};
    padding: 0 25px;
    box-sizing: border-box;
    background: #234;
`

const HeaderTitle = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 60%;
    height: 100%;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    text-shadow: 1px 1px 2px black;
`

const HeaderButtons = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 40%;
    height: 100%;
`

const ButtonHeader = styled.button`
    margin: 0 5px;
`

const CheckOneButton = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: ${(props) => props.width ? props.width : '100%'};
    margin: ${(props) => props.margin ? props.margin : 0};
    label {
        margin-left: 5px;
    }
`

/* ----- Principal function ----- */
function ContactUsSettings({match, history, user}) {

    let configHeader = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    const { idPage, idSection } = match.params;

    const [data, setData] = React.useState(null);

    const onBack = () => {
        history.push(config.webeditorpath+'/page/'+idPage)
    }

    const onSave = () => {
        const saveData = {
            data: data,
            updatedUser: user.idUser
        }
        axios.put("/api/updatedatasection/"+idSection, saveData, configHeader)
        .then(res => {
            history.push(config.webeditorpath+'/page/'+idPage)
        })
        .catch(error => {
            alert('Hubo un error...')
        })
    }

    const handleChange = (name, value) => {
        setData({ 
            ...data,
            [name]: value 
        });
    }

    const handleChecklinearBackground = (e) => {
        const { name, checked } = e.target;
        setData({
            ...data,
            [name]: checked ? 1 : 0
        })
    }

    React.useEffect(() => {
        if(!data){
            axios.get('/api/sections/' + idSection,configHeader)
            .then(sectionData => {
                if(parseInt(Object.keys(sectionData.data.section.data).length) > 0){
                    setData(sectionData.data.section.data);
                } else {
                    setData({
                        title: '',
                        subtitle: '',
                        whatsapp: ''
                    })
                }
            })
            .catch(err => {
                alert('Hubo un error...');
            })
        }
    }, [data, idSection])

    return(
        <React.Fragment>
            <Header>
                {
                    <HeaderTitle>CONFIGURACIÓN CONTACT US SECTION</HeaderTitle>
                }
                <HeaderButtons><ButtonHeader onClick={onBack}>VOLVER</ButtonHeader> <ButtonHeader onClick={onSave}>GUARDAR</ButtonHeader> </HeaderButtons>
            </Header>
            <Desktop>
                    {
                        !data ? <Spinner spinnerType='ThreeDots' />
                        :
                        <ContentBox>
                            <Input name='title' data={data} width='500px' border={true} fontSize='20px' onHandleChange={handleChange} type='text' effectColor='blue' placeholder='TÍTULO' required={true} inputMargin='20px' error={data[`title_error`]} />
                            <Input name='subtitle' data={data} width='500px' border={true} fontSize='20px' onHandleChange={handleChange} type='text' effectColor='blue' placeholder='SUBTÍTULO' required={true} inputMargin='20px' error={data[`subtitle_error`]} />
                            <Input name='whatsapp' data={data} width='500px' border={true} fontSize='20px' onHandleChange={handleChange} type='number' effectColor='blue' placeholder='WHATSAPP' required={true} inputMargin='20px' error={data[`whatsapp_error`]} />
                            <CheckOneButton width='90%' margin='20px 0'>
                                <input id='linearBackground' name='linearBackground' checked={data.linearBackground === 0 ? false : true} type='checkbox' onChange={handleChecklinearBackground} />
                                <label>Linear Background</label>
                            </CheckOneButton>
                        </ContentBox>
                    }
            </Desktop>
            <Tablet>
                <ContentBox>
                    TABLET CONTACT US SECTION SETTINGS
                </ContentBox>
            </Tablet>
            <Mobile>
                <ContentBox>
                    MOBILE CONTACT US SECTION SETTINGS
                </ContentBox>
            </Mobile>
        </React.Fragment>
    );
}

function mapState(state) {
    const { user } = state.login;
    return { user };
}

export default connect(mapState)(ContactUsSettings);