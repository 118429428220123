import { productConstants } from './ProductConstants';

let products = JSON.parse(localStorage.getItem('products'));
const initialState = products ? 
        { 
            error: false,
            isFetching: false,
            products: products
        }
    : 
        { 
            error: false,
            isFetching: true,
        };

export function product(state = initialState, action) {
    switch (action.type) {
        case productConstants.FETCHING:
            return { 
                ...state,
                isFetching: true
            };
        case productConstants.SUCCESS:
            return { 
                ...state,
                isFetching: false,
                products: action.products
            };
        case productConstants.ERROR:
            return { 
                ...state,
                error: true
            };
        case productConstants.FETCHING_FORMFIELDS:
            return { 
                ...state,
                isFetchingFields: true
            };
        case productConstants.SUCCESS_FORMFIELDS:
            return { 
                ...state,
                isFetchingFields: false,
                productFields: action.productFields,
                error: false
        };
        case productConstants.SUCCESS_UPDATEFORMFIELDS:
            return { 
                ...state,
                isFetchingFields: false,
                productUpdateFields: action.productUpdateFields,
                error: false
        };
        case productConstants.SUCCESS_CURRENCYUNITS:
            return { 
                ...state,
                isFetchingFields: false,
                currencyUnits: action.currencyUnits,
                error: false
        };
        case productConstants.SUCCESS_TRADEMARKS:
            return { 
                ...state,
                isFetchingFields: false,
                trademarks: action.trademarks,
                error: false
        };
        case productConstants.SUCCESS_QUANTITYUNITS:
            return { 
                ...state,
                isFetchingFields: false,
                quantityUnits: action.quantityUnits,
                error: false
        };
        case productConstants.ERROR_FORMFIELDS:
            return {
                ...state,
                error: true
        };
        default:
            return state
    }
}