/* General imports */
import React from 'react'
import styled from 'styled-components';
import axios from 'axios'
import  { authHeader } from '../../app/auth-header';

/* Components imports */
import Map from '../../images/NewMapMAX.png'
import Fade from 'react-reveal/Fade';

/* Styled Components */

const Root = styled.div`
    width: 100%;
    height: 80vh;
    display: flex;
    position:relative;
    align-items: center;
    justify-content: center;
    font-family: 'IBM Plex Sans';
    padding: 0 100px;
    box-sizing: border-box;
    @media (max-width: 600px) {
        flex-direction: column;
        padding: 0 10px;
        height: auto;
    }
`

const Decoration = styled.img`
    width: auto;
    height: 80%;
    object-position: center;
    object-fit: cover;
    margin-right: 50px;
    @media (max-width: 600px) {
        height: 45vh;
        margin: 0;
    }
`

const Content = styled.div`
    position: relative;
    display: flex;
    width: 35%;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
    @media (max-width: 600px) {
        width: 100%;
        padding: 0 10px;
        min-height: 30vh;
    }
`

const Title = styled.h2`
    width: 100%;
    font-family: 'IBM Plex Sans';
    font-size: 2.5rem;
    color: #2196F3;
    margin: 0;
    @media (max-width: 600px) {
        font-size: 2.25rem;
    }
`

const Text = styled.p`
    width: 100%;
    font-family: 'IBM Plex Sans';
    font-size: 1.5rem;
    color: #001554;
    font-weight: 300;
    margin: 1rem 0 2rem 0;
    @media (max-width: 600px) {
        font-size: 1.25rem;
    }
`

const Button = styled.a`
    background: #2196F3;
    border-radius: 20px;
    color: #FFF;
    font-family: 'IBM Plex Sans';
    font-size: 1rem;
    font-weight: 500;
    text-decoration: none;
    box-sizing: border-box;
    padding: 0.5rem 2rem;
`

function Always({idsection}) {

    const [data, setData] = React.useState(null);

    React.useEffect(() => {
        if(!data){
            let configHeader = {
                headers: { ...authHeader(), 'Content-Type': 'application/json' }
            }
            axios.get('/api/sections/' + idsection,configHeader)
            .then(sectionData => {
                setData(sectionData.data.section.data);
                console.log("ALWAYS")
                console.log(sectionData.data.section.data)
            })
            .catch(err => {
                alert('Hubo un error...');
            })
        }
    }, [data, idsection])

    return (
        data && 
        <Root>
            <Decoration src={Map} alt="always_decoration" />
            <Content>
                <Fade bottom>
                    <Title>{data.title}</Title>
                    <Text>{data.text}</Text>
                    {
                        data.link &&
                        <Button href={data.link} target='_blank'>{data.button}</Button>
                    }
                </Fade>
            </Content>
        </Root>
    )
}

export default Always;