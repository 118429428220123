/* General imports */
import React from 'react'
import styled from 'styled-components'
import { useDrag, useDrop } from 'react-dnd'
import { ItemTypes } from './ItemTypes'

/* Components imports */
import RemoveIcon from '../icons/remove.svg'
import EditIcon from '../icons/edit.svg'
import ConfigIcon from '../icons/blackconfig.svg'

/* Styled components */
const Root = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
  border: 1px dashed gray;
  padding: 0.5rem 1rem;
  margin-bottom: .5rem;
  background-color: white;
  cursor: move;
  opacity: ${(props) => props.isDragging ? 0 : 1};
`

const ZoneButtons = styled.div`
  display: flex;
  position:absolute;
  top: 0;
  right: 0;
  height: 40px;
  width: 100px;
  align-items: center;
  justify-content: flex-end;
  z-index: 99999;
`

const CardButton = styled.img`
  position: relative;
  display: flex;
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  cursor: pointer;
`

export const Card = ({ id, idSection, text, findCard, moveCard, updateOrder, editCard, deleteCard, editDataType }) => {
  const originalIndex = findCard(id).index;
    const [{ isDragging }, drag] = useDrag({
        item: { type: ItemTypes.CARD, id, originalIndex },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (dropResult, monitor) => {
            const { id: droppedId, originalIndex } = monitor.getItem();
            const didDrop = monitor.didDrop();
            if (!didDrop) {
                moveCard(droppedId, originalIndex);
            }
            updateOrder()
        },
    });
    const [, drop] = useDrop({
        accept: ItemTypes.CARD,
        canDrop: () => false,
        hover({ id: draggedId }) {
            if (draggedId !== id) {
                const { index: overIndex } = findCard(id);
                moveCard(draggedId, overIndex);
            }
        },
    });

  const editItem = () => {
    editCard(originalIndex)
  }

  const deleteItem = () => {
    deleteCard(idSection)
  }

  const editDataTypeItem = () => {
    editDataType(originalIndex, idSection)
  }

  return (
    <Root ref={(node) => drag(drop(node))} isDragging={isDragging}>
      <ZoneButtons>
        <CardButton src={ConfigIcon} alt='Editar tipo de sección' onClick={editDataTypeItem} />
        <CardButton src={EditIcon} alt='Editar' onClick={editItem} />
        <CardButton src={RemoveIcon} alt='Eliminar' onClick={deleteItem} />
      </ZoneButtons>
      {text}
    </Root>
  )
}
