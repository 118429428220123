import { carouselConstants } from './CarouselConstants';
import { alertActions } from '../../alert/AlertActions';
import axios from 'axios';
import  { authHeader } from '../../app/auth-header';

export const carouselActions = {
    createCarousel,
    updateCarousel,
    getCarousels,
    deleteCarousel
};

function createCarousel(carousel) {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return dispatch => {
        axios.post( '/api/carousels' , {
            idSection: carousel.idSection,
            name: carousel.name,
            slideType: carousel.slideType,
            title: carousel.title,
            text: carousel.text,
            textButton: carousel.textButton,
            link: carousel.link,
            order: carousel.order,
            img: carousel.img,
            videolink: carousel.videolink,
            createdUser: carousel.createdUser
        }, config)
        .then(response => {
            dispatch(alertActions.success('Slide creado'));
        })
        .catch(error => {
            dispatch(alertActions.error(error.toString()));

        })
    };
}

function updateCarousel(carousel) {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    return dispatch => {
        axios.put( '/api/carousel/'+ carousel.idCarousel, {
            idSection: carousel.idSection,
            name: carousel.name,
            slideType: carousel.slideType,
            title: carousel.title,
            text: carousel.text,
            textButton: carousel.textButton,
            link: carousel.link,
            order: carousel.order,
            img: carousel.img,
            videolink: carousel.videolink,
            updatedUser: carousel.updatedUser
        }, config)
        .then(response => {
            dispatch(alertActions.success('Slide actualizado'));
        })
        .catch(error => {
            dispatch(alertActions.error(error.toString()));

        })
    };
}

function getCarousels(idSection) {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return dispatch => {
        dispatch({ type: carouselConstants.FETCHING });
        axios.get('/api/carousels/' + idSection,config)
        .then(carouselsData => {
            const carousels = carouselsData.data.carousels
            dispatch({ type: carouselConstants.SUCCESS, carousels });
        })
        .catch(err => {
            dispatch({ type: carouselConstants.ERROR });
            dispatch(alertActions.error('Hubo un error...'));
        })
    }
}

function deleteCarousel(idCarousel) {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    return dispatch => {
        axios
        .delete( '/api/carousel/' + idCarousel, config)
        .then(response => {
            dispatch(alertActions.success('Slide eliminado'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}
