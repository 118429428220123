import { loginConstants } from './LoginConstants';
import { alertActions } from '../alert/AlertActions';
import axios from 'axios';
import  { authHeader } from '../app/auth-header';
import PersonIcon from '../icons/userCircle.svg';
import LockIcon from '../icons/lock.svg';

export const loginActions = {
    login,
    modalLogin,
    logout,
    getFields
};


function login(data, history, next) {
    return dispatch => {
        dispatch(request());

        axios
        .post( '/api/users/login', {
        username: data.username,
        password: data.password,
        idRole: data.idRole
        })
        .then(user => {
            localStorage.setItem('user', JSON.stringify(user.data));
            dispatch(success(user.data));
            history.push(next);;
        })
        .catch(err => {
            /* dispatch(failure(err.response.data.message)); */
            dispatch(alertActions.error('Hubo un error en el login'));
        })
    };

    function request() { return { type: loginConstants.LOGIN_REQUEST } }
    function success(user) { return { type: loginConstants.LOGIN_SUCCESS, user } }
    /* function failure(error) { return { type: loginConstants.LOGIN_FAILURE, error } } */
}

function modalLogin(data) {
    return dispatch => {
        dispatch(request());

        axios
        .post( '/api/users/login', {
        username: data.username,
        password: data.password
        })
        .then(user => {
            localStorage.setItem('user', JSON.stringify(user.data));
            dispatch(success(user.data));
            dispatch(alertActions.success(`Bienvenido/a ${user.data.username}`));
        })
        .catch(err => {
            /* dispatch(failure(err.response.data.message)); */
            dispatch(alertActions.error('Hubo un error en el login'));
        })
    };

    function request() { return { type: loginConstants.LOGIN_REQUEST } }
    function success(user) { return { type: loginConstants.LOGIN_SUCCESS, user } }
    /* function failure(error) { return { type: loginConstants.LOGIN_FAILURE, error } } */
}

function logout() {
    return dispatch => {
        localStorage.removeItem('user');
        /* if(exitFlag){
            history.push('/login');
        } */
        dispatch({ type: loginConstants.LOGOUT });
    }
    
}

function getFields() {

    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    return dispatch => {
        dispatch({ type: loginConstants.FETCHING_FORMFIELDS });
        axios.get('/api/loginFields', config)
        .then(response => {
            const loginFields = response.data.loginFields;
            loginFields.forEach(element => {
                if(element.icon === 'PersonIcon'){
                    element.icon = PersonIcon
                }
                if(element.icon === 'LockIcon'){
                    element.icon = LockIcon
                }
            });
            dispatch({ type: loginConstants.SUCCESS_FORMFIELDS, loginFields });
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error en la carga el formulario'));
        })
    }
}