/* General imports */
import React from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { devices } from '../app/config'

/* Components imports */
import MaterialTable from 'material-table';
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import RefreshIcon from '@material-ui/icons/Refresh';
import VisibilityIcon from '@material-ui/icons/Visibility';

/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    Refresh: forwardRef((props, ref) => <RefreshIcon {...props} ref={ref} />),
    View: forwardRef((props, ref) => <VisibilityIcon {...props} ref={ref} />)
};

const Root = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    

    .MuiPaper-root {
        border-radius: 10px;
        height: 100%;
    }

    &>div>div:nth-child(2) {
        height: calc(100% - 128px);
    }
    
    .MuiToolbar-root  {
        /* background: linear-gradient(to left, rgba(37,140,250,1) 0%, rgba(6,49,70,1) 100%); */
        background-color: #f6f7f9;
        border-radius: 10px 10px 0 0;
        color: black;
        height: 64px;
        .MuiIconButton-root {
            color: black;
        }
    }

    .MuiTableCell-body {
        color: black;
    }

    .MuiTablePagination-toolbar {
        /* background: linear-gradient(to right, rgba(37,140,250,1) 0%, rgba(6,49,70,1) 100%); */
        background-color: #f6f7f9;
        border-radius: 0 0 10px 10px;
        .MuiTypography-caption {
            color: black;
        }
        .MuiIconButton-root {
            color: black;
        }
    }

    .MuiInputBase-root {
        color: black;
        &:hover {
            &::before {
                border-bottom-color: black;
            }
        }
        &:focus {
            &::before {
                border-bottom-color: black;
            }
        }
        &::before {
            border-bottom-color: #dededf;
        }
        &::after {
            border-bottom-color: black;
        }
    }
`

function Table({title, columnsTable, data, getData, handleView, handleNew, handleEdit, handleDelete, tableRef, exportCsv, autoExport, rowClick, hiddenNew, hiddenView, hiddenDelete, hiddenEdit }) {

    
    React.useEffect(() => {
        if(!data){
            getData()
        }
    }, [getData, data])

    const handleViewItem = (event, rowData) => {
        event.preventDefault()
        handleView(rowData);
    };

    const handleNewItem = async (event) => {
        event.preventDefault()
        await handleNew();
    };


    const handleEditItem = async (e, rowData) => {
        e.preventDefault()
        await handleEdit(rowData)
    }


    const handleDeleteItem = async (e, rowData) => {
        e.preventDefault()
        await handleDelete(rowData)
        getData() && tableRef.current.onQueryChange()
    }

    return(
        <React.Fragment>
            <Desktop>
                <Root>
                        <MaterialTable
                            title={title}
                            icons={tableIcons}
                            tableRef= {tableRef}
                            options={{
                                pageSize: 5,
                                pageSizeOptions: [5],
                                actionsColumnIndex: -1,
                                exportButton: !autoExport ? false : { csv: true },
                                exportAllData: true
                                }}
                            localization={{
                                pagination: {
                                    labelDisplayedRows: '{from}-{to} de {count}'
                                },
                                header: {
                                    actions: 'Acciones'
                                },
                                body: {
                                    emptyDataSourceMessage: 'No hay registros'
                                },
                                toolbar: {
                                    exportTitle: 'Exportar',
                                    exportName: 'Exportar a CSV'
                                }
                            }}
                            columns={columnsTable}
                            data={data}
                            actions={[
                                {
                                    icon: tableIcons.View,
                                    tooltip: 'Ver',
                                    onClick: handleViewItem,
                                    hidden: hiddenView ? true : false
                                },
                                {
                                    icon: tableIcons.Export,
                                    tooltip: 'Exportar',
                                    isFreeAction: true,
                                    onClick: exportCsv,
                                    hidden: exportCsv === undefined ? true : false
                                },
                                {
                                    icon: tableIcons.Add,
                                    tooltip: 'Nuevo',
                                    isFreeAction: true,
                                    onClick: handleNewItem,
                                    hidden: hiddenNew ? true : false
                                },
                                {
                                    icon: tableIcons.Edit,
                                    tooltip: 'Editar',
                                    onClick: handleEditItem,
                                    hidden: hiddenEdit ? true : false
                                },
                                {
                                    icon: tableIcons.Delete,
                                    tooltip: 'Eliminar',
                                    onClick: handleDeleteItem,
                                    hidden: hiddenDelete ? true : false
                                },
                                {
                                    icon: tableIcons.Refresh,
                                    tooltip: 'Recargar',
                                    isFreeAction: true,
                                    onClick: () => getData() && tableRef.current.onQueryChange()
                                }
                            ]}
                        />
                </Root>
            </Desktop>
            <Tablet>
                En construcción...
            </Tablet>
            <Mobile>
                <Root>
                    <MaterialTable
                        title={title}
                        icons={tableIcons}
                        tableRef= {tableRef}
                        onRowClick={(evt, rowData) => rowClick(rowData)}
                        options={{
                            pageSize: 5,
                            pageSizeOptions: [5],
                            actionsColumnIndex: -1,
                            }}
                        localization={{
                            pagination: {
                                labelDisplayedRows: '{from}-{to} de {count}'
                            },                            
                            body: {
                                emptyDataSourceMessage: 'No hay registros'
                            }
                        }}
                        columns={columnsTable}
                        data={data}
                    />
                </Root>
            </Mobile>
        </React.Fragment>
    )
}

export default Table;