/* General imports */
import React from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { devices } from '../app/config'
import { Route } from 'react-router-dom'

/* Components imports */
import Logo from '../logos/logo.png'
import NavAnimate from '../navs/NavAnimate'
import { components } from '../app/componentList'
import axios from 'axios'


/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled components */
const NavSpace = styled.div`
    width: 100%;
    height: 65px;
    min-height: 65px;
`

const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    perspective: 1px;
`

const WebContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    z-index: 1;
    background-color: #fff;
    margin-top: ${(props) => props.bannerSpace ? '100vh' : 0};
`

/* ----- Principal function ----- */
function BasicWebPage({idpage, listpages}) {

    const [ sectionsPage, setSectionsPage ] = React.useState(null)

    React.useEffect(() => {
        async function fechData() {
            axios.get('/api/sectionspage/page/'+idpage)
            .then(sectionsData => {
                const sections = sectionsData.data.sectionsPage
                setSectionsPage(sections)
            })
            .catch(err => {
                alert('Hubo un error...');
            })    
        }
        if(!sectionsPage){
            fechData()
        }
    }, [ sectionsPage, idpage ]) 

    const RouteComponent = ({ component: Component, idsection, datasection, ...rest }) => (
        <Route
            {...rest}
            render={(props) => {
                return <Component idsection={idsection} datasection={datasection} {...props} />
            }}
        />
    )

    return(
        <React.Fragment>
            <Desktop>
                <NavAnimate data={{logo: Logo, items: listpages}} />
                <NavSpace />
                <WebContent bannerSpace={parseInt(idpage) === 1 ? true : false}>
                    {
                        sectionsPage &&
                        sectionsPage.map( (item, index) =>
                            <RouteComponent key={`${item.idSectionPage}_${index}`} path={`${item.page.link}`} idsection={item.idSection} datasection={item.section.data} component={components.find(comp => comp.name === item.section.section_type.reference).component} />    
                        )
                    }
                </WebContent>
            </Desktop>
            <Tablet>
                <WebContent bannerSpace={parseInt(idpage) === 1 ? true : false}>
                    {
                        sectionsPage &&
                        sectionsPage.map( (item, index) =>
                            <RouteComponent key={`${item.idSectionPage}_${index}`} path={`${item.page.link}`} idsection={item.idSection} datasection={item.section.data} component={components.find(comp => comp.name === item.section.section_type.reference).component} />    
                        )
                    }
                </WebContent>
            </Tablet>
            <Mobile>
                <NavAnimate data={{logo: Logo, items: listpages}} />
                <NavSpace />
                <WebContent bannerSpace={parseInt(idpage) === 1 ? true : false}>
                    {
                        sectionsPage &&
                        sectionsPage.map( (item, index) =>
                            <RouteComponent key={`${item.idSectionPage}_${index}`} path={`${item.page.link}`} idsection={item.idSection} datasection={item.section.data} component={components.find(comp => comp.name === item.section.section_type.reference).component} />    
                        )
                    }
                </WebContent>
            </Mobile>
        </React.Fragment>
    );
}

export default BasicWebPage;