/* General imports */
import React from 'react'
import { Route } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { devices } from '../../app/config'
import { productActions } from './ProductActions'
import { alertActions } from '../../alert/AlertActions'

/* Components imports */
import NoImage from '../../images/no-image.png';
import Table from '../../tables/AsyncTable'
import Spinner from '../../spinners/LoaderSpinner'
import NewProduct from './NewProduct'
import UpdatePrices from './UpdatePrices'
import UpdatePromos from './UpdatePromos'
import ProfessionalsIcon from '../../icons/professionalsWhite.png'

/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled components */
const Root = styled.div`
    width: ${(props) => props.width ? props.width : '100%'};
    height: 100%;
    padding: ${(props) => props.padding ? props.padding : 0};
    box-sizing: border-box;
`

const Header = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 150px;
    background: linear-gradient(to left, #122230 0%, #192d3e 100%);
    align-items: flex-start;
    padding: 35px 35px 70px 35px;
    box-sizing: border-box;
    justify-content: space-between;
`

const Title = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img {
        position: relative;
        display: flex;
        width: 30px;
        height: 30px;
    }
    h6 {
        position: relative;
        display: flex;
        margin: 0 20px;
        color: white;
        font-size: 1.5rem;
        font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
        font-weight: 400;
    }
`

const ProductUpdates = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
`

const Content = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: calc(100% - 150px);
    padding: 0 35px;
    box-sizing: border-box;
    top: -64px;
`

const UpdatePricesButton = styled.button`
    display: flex;
    position: relative;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 5px 10px;
    margin: 0 10px;
`

/* ----- Principal function ----- */
function Product({ user, products, getProducts, deleteProduct, isFetching, alertMessage, history }) {

    const columsTable = [
        { title: 'Image', field: 'img', render: rowData => <img src={rowData.img ? rowData.img.images.length > 0 ? rowData.img.images[0] : NoImage : NoImage} alt='SinImagen' style={{width: 40, height:40, borderRadius: '50%'}}/>, width: 50, align: 'center' },
        { title: 'ID', field: 'idProduct', width: 50 },
        { title: 'Descripción', field: 'description', hidden: true },
        { title: 'Código', field: 'code', width: 200 },
        { title: 'Códigode barras', field: 'barcode',width: 300, hidden: true },
        { title: 'Baja', field: 'deleted', hidden: true },
        { title: 'Moneda', field: 'idCurrencyUnit', hidden: true },
        { title: 'Unidad', field: 'idQuantityUnit', hidden: true },
        { title: 'Categoría', field: 'id_Category.name', width: 200 },
        { title: 'Marca', field: 'trademark.name', width: 200 },
        { 
            title: 'Nombre', 
            field: 'name', 
            width: 400
        },
        { 
            title: 'Precio Costo', 
            field: 'costPrice',
            width: 150,
            render: rowData => <span>${rowData.costPrice}</span>
        },
        { 
            title: 'Precio Venta', 
            field: 'salePrice',
            width: 150,
            render: rowData => <span>${rowData.salePrice}</span>
        },
        { title: 'Ecommerce', field: 'ecommerce', width: 50, type: 'boolean' },
        { title: 'Características', field: 'features', hidden: true },
    ]

    const tableRef = React.createRef()

    const [ editData, setEditData ] = React.useState(null);

    const handleNew = () => {
        history.push('/admin/stocksystem/product/new')
    };

    const handleEdit = (rowData) => {
        setEditData(rowData)
        history.push('/admin/stocksystem/product/update/'+rowData.idProduct)
    }

    const handleDelete = (rowData) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("¿Desea eliminar el producto "+rowData.idProduct+"?")) {
            deleteProduct(rowData.idProduct);
        }
    }

    const handleUpdatePrices = (e) => {
        e.preventDefault();
        history.push('/admin/stocksystem/product/updateprices')
    }

    const handleUpdatePromos = (e) => {
        e.preventDefault();
        history.push('/admin/stocksystem/product/updatepromos')
    }

    /* React.useEffect(() => {
        if(!products){
            getProducts()
        }
    }, [getProducts, products]) */

    const RouteComponent = ({ component: Component, rowData, ...rest }) => (
        <Route
            {...rest}
            render={(props) => {
                return <Component rowData={rowData} {...props} />
            }}
        />
    )

    return(
        <React.Fragment>
            <Desktop>
                <Root>
                    <Header>
                        <Title>
                            <img src={ProfessionalsIcon} alt='Icon' />
                            <h6>Productos</h6>
                        </Title>
                        <ProductUpdates>
                            <Route path={'/admin/stocksystem/product'} exact={true} >
                                <UpdatePricesButton onClick={handleUpdatePromos}>Actualizar promos</UpdatePricesButton>
                            </Route>
                            <Route path={'/admin/stocksystem/product'} exact={true} >
                                <UpdatePricesButton onClick={handleUpdatePrices}>Actualizar precios</UpdatePricesButton>
                            </Route>
                        </ProductUpdates>
                    </Header>
                    <Content>
                        <Route path={'/admin/stocksystem/product'} exact={true} >
                            <Table urlapi="/api/products" tableRef={tableRef} autoExport={true} hiddenView={true}  title="LISTADO DE PRODUCTOS" columnsTable={columsTable} data={products} getData={getProducts} handleNew={handleNew} handleEdit={handleEdit} handleDelete={handleDelete} />   
                        </Route>
                        <Route path={'/admin/stocksystem/product/new'} component={NewProduct} />
                        <Route path={'/admin/stocksystem/product/updateprices'} component={UpdatePrices} />
                        <Route path={'/admin/stocksystem/product/updatepromos'} component={UpdatePromos} />
                        <RouteComponent path={'/admin/stocksystem/product/update'} rowData={editData} component={NewProduct} />
                    </Content>
                </Root>
            </Desktop>
            <Tablet>VERSIÓN TABLET EN CONSTRUCCIÓN</Tablet>
            <Mobile>VERSIÓN MOBILE EN CONSTRUCCIÓN</Mobile>
        </React.Fragment>
    );
}

function mapState(state) {
    const { error, isFetching, products } = state.product;
    const { user } = state.login;
    return { user, error, isFetching, products};
}

const actionCreators = {
    getProducts: productActions.getProducts,
    deleteProduct: productActions.deleteProduct,
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(Product);