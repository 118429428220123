/* General imports */
import React from 'react';
import styled from 'styled-components';


/* Components imports */
import { CarouselProvider, Slide, Slider, Image, DotGroup } from 'pure-react-carousel';
import DotCarousel from './DotCarousel'
import 'pure-react-carousel/dist/react-carousel.es.css';

/* Styled-Components */

const StyledCarouselProvider = styled(CarouselProvider)`
    position: relative;
    display: flex;
    width: ${(props) => props.mobile ? "100%" : "90%"};
    height: ${(props) => props.mobile ? "20vh" : "70vh"};
    outline: none;
    margin: 50px 0;
`

const StyledSlider = styled(Slider)`
    position: relative;
    height: 100%;
    width: 80%;
    outline: none;
`

const StyledDoGroup = styled(DotGroup)`
    position: relative;
    display: flex;
    width: 20%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-y: scroll;
    outline: none;
`

const StyledImage = styled(Image)`
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: ${(props) => props.mobile ? "20vh" : "70vh"};;
    object-fit: contain;
    object-position: center;
    outline: none;
    background: #000;
`

const Gallery = ({images, isDesktop}) => {

    const [ currentSlide, setCurrentSlide ] = React.useState(0);

    const selectSlide = (e) => {
        e.preventDefault();
        setCurrentSlide(e.target.dataset.num);
        console.log('CurrentSlide: '+currentSlide+' - selectSlide: '+e.target.dataset.num)
    }

    const renderDot = () => {
        return(
            images && images.length > 0 &&
            images.map((img, index) =>
                <DotCarousel key={`dot${index}`} index={index} currentSlide={currentSlide} handleClick={selectSlide} src={img} alt='ImageDot' />
            )
        )
    }

    return (
        images && images.length > 0 &&
        <StyledCarouselProvider
            naturalSlideWidth={400}
            naturalSlideHeight={550}
            visibleSlides={1}
            totalSlides={images && images.length > 0 ? images.length : 0}
            isIntrinsicHeight={true}
            isPlaying={false}
            currentSlide={currentSlide}
            dragEnabled={false}
            touchEnabled={false}
            mobile={isDesktop ? false : true}
        >
            <StyledSlider>
                {
                    images.map((img, index) => 
                    <Slide >
                        <StyledImage key={index} src={img} mobile={isDesktop ? false : true} />
                    </Slide>
                    )
                }
            </StyledSlider>
            <StyledDoGroup showAsSelectedForCurrentSlideOnly renderDots={renderDot} />
        </StyledCarouselProvider>
    );
}

export default Gallery;