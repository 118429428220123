/* General imports */
import React from 'react'
import styled from 'styled-components';
import axios from 'axios'
import  { authHeader } from '../../app/auth-header';

/* Components imports */
import Spinner from '../../spinners/LoaderSpinner'
import Fade from 'react-reveal/Fade';
import NoImage from '../../images/no-image.png'

/* Styled Components */
const Root = styled.div`
    position: relative;
    width:100%;
    height: auto;
    display: grid;
    grid-template-columns: 0.6fr 0.4fr;
    grid-template-rows: 500px auto;
    position: relative;
    font-family: 'IBM Plex Sans';
    box-sizing: border-box;
    padding: 50px;
    @media (max-width: 600px) {
        display: flex;
        flex-direction: column;
        padding: 0;
    }
`

const Image = styled.img`
    position: relative;
    display: flex;
    width: 700px;
    height: 400px;
    object-fit: cover;
    object-position: center;
    @media (max-width: 600px) {
        width: 100%;
        height: auto;
        box-shadow: 1px 1px 5px rgba(0,0,0,0.5);
    }
`

const Text = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 20px 100px 20px 50px;
    transform: translateX(-20%);
    width: 586px;
    height: 272px;
    background: #2196F3;
    color: #FFF;
    font-size: 40px;
    font-weight: 400;
    @media (max-width: 600px) {
        padding: 20px;
        width: 80%;
        height: 172px;
        font-size: 1.5rem;
        align-items: center;
        justify-content: center;
        transform: translate(0, -20%);
        box-shadow: 1px 1px 5px rgba(0,0,0,0.5);
    }
`

const Box = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    padding-left: 1rem;
    overflow-x: hidden;
    @media (max-width: 600px) {
        justify-content: center;
        padding-left: 1rem;
    }
`

const Box2 = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    @media (max-width: 600px) {
        align-items: flex-start;
    }
`

/* Principal function*/
/*
Params
text
*/
function TwoFrames({idsection}) {

    const [data, setData] = React.useState(null)

    React.useEffect(() => {
        if(!data){
            let configHeader = {
                headers: { ...authHeader(), 'Content-Type': 'application/json' }
            }
            axios.get('/api/sections/' + idsection,configHeader)
            .then(sectionData => {
                setData(sectionData.data.section.data);
            })
            .catch(err => {
                alert('Hubo un error...');
            })
        }
    }, [data, idsection])

    return (
        data &&
        <Root>
            <Box>
                <Fade left>
                    <Image src={data.img ? data.img : NoImage} alt="img_section" />
                </Fade>
            </Box>
            <Box2>
                <Fade right>
                    <Text>{data.text}</Text>
                </Fade>
            </Box2>
        </Root>
    )
}

export default TwoFrames;