/* General imports */
import React from 'react';
import styled, {keyframes} from 'styled-components';
import { config } from '../../app/config';

/* Components imports */
import HeaderBackground from '../../images/TemplateDestacado_Banner.webp';
import NoImage from '../../images/no-image.png';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Advantage from './Advantage';
import BackgroundNovelty from '../../images/Backgorund_Bluedots_TemplateDestacado.png';
import ThreeImagesV2 from './ThreeImagesV2';
import SpecialFeaturesV2 from './SpecialFeaturesV2';

/* Styled components */
const appearAnim = keyframes`
  0% {
    opacity: 1;
    border: none;
  }

  45% {
    width: 100%;  
    opacity: 1;
    border: none;
  }

  50% {
    width: 100%;
    left: 0;  
    border: none;
  }

  51% {
    right:0;
      left: unset;
  }

  60% {
      right:0;
      left: unset;
      border: none;
  }


  75% {
    right:0;
    left: unset;
    opacity: 1;
    border: none;
  }

  95% {
    right:0;
    left: unset;
    width: 55%;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
    border: none;
  }

  100% {
    right:0;
    left: unset;
    width: 45%;
    opacity: 0;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
    border: none;
  }
`;

const imageAnim = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const textAnim = keyframes`
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const bgAnim = keyframes`
  0% {
    background-color: transparent;
  }

  100% {
    background-color: #F1F2F5;
  }
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background: ${`url(${HeaderBackground})`};
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    padding: 0 70px;
    box-sizing: border-box;
    @media (max-width: 600px) {
        padding: 0 20px;
        min-height: 50vh;
    }
`

const ProductCategory = styled.h2`
    width: 100%;
    text-align: left;
    font-weight: 300;
    font-size: 1.5rem;
    color: #001554;
    margin: 0;
    @media (max-width: 600px) {
        font-size: 1.5rem;
    }
`

const ProductName = styled.h2`
    width: 100%;
    text-align: left;
    font-weight: 300;
    font-size: 2.5rem;
    color: #2196F3;
    margin: 0;
    @media (max-width: 600px) {
        font-size: 1.5rem;
        margin-bottom: 20px;
    }
`

const ProductContainer = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: 60vh;
    height: auto;
    width: 100%;
    overflow-x: hidden;
    @media (max-width: 600px) {
        width: 100%;
        grid-template-columns: 1fr;
        overflow-x: visible;
    }
`

const ProducDescription = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 0.45fr 0.55fr;
    width: 100%;
    height: 500px;
    background: transparent;
    z-index: 1;
    @media (max-width: 600px) {
        grid-template-columns: 1fr;
        height: auto;
    }
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background-color: #F1F2F5;
        border: none;
        animation: ${appearAnim};
        /* animation-delay: 2s; */
        animation-duration: 2.5s;
        animation-fill-mode: forwards;
    }
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        animation: ${bgAnim};
        animation-delay: 0.9s;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        background: transparent;
    }
`

const ProductImage = styled.img`
    position: relative;
    height: 500px;
    width: 100%;
    opacity: 0;
    object-position: center;
    object-fit: cover;
    animation: ${imageAnim};
    animation-delay: 1s;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    @media (max-width: 600px) {
        height: 250px;
        width: 337px;
        object-fit: cover;
    }
`

const BoxDescription = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 100px 100px;
    @media (max-width: 600px) {
        padding: 50px 10px;
        height: auto;
        align-items: center;
    }
`

const Trademark = styled.img`
    width: auto;
    height: 120px;
    object-fit: contain;
    object-position: center;
    transform: translate(-100px, 100px);
    @media (max-width: 600px) {
        height: 100px;
        transform: translate(0, 0);
    }
`

const WaterMark = styled.span`
    position: ${(props) => props.waterEffect ? 'fixed' : 'absolute'};
    top: ${(props) => props.waterEffect ? '30%' : 0};
    left: 0;
    width: 100%;
    height: auto;
    color: #f1f2f5;
    font-weight: 900;
    text-align: center;
    overflow-x: hidden;
    transform: translateY(-50px);
    font-size: 11rem;
    z-index: -1;
    @media (max-width: 600px) {
        font-size: 3rem;
        position: absolute; 
        top: 0;
        transform: translateY(-10px);
    }
`

const ProductText = styled.span`
    position: relative;
    text-align: left;
    width: 100%;
    height: auto;
    max-height: 280px;
    overflow-y: auto;
    box-sizing: border-box;
    color: #00002C;
    font-size: 1.1rem;
    font-weight: 300;
    padding: 20px 10px 20px 20px;
    @media (max-width: 600px) {
        padding: 0;
        max-height: none;
    }
`

const Access = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 70px;
    box-sizing: border-box;
    padding: 0 20px;
    span {
        margin: 0 10px;
        color: #2196F3;
    }
    @media (max-width: 600px) {
        padding: 0;
    }
`

const AccessLink = styled.a`
    display: flex;
    align-items: center;
    color: #2196F3;
    font-size: 1.2rem;
    cursor: pointer;
    text-decoration: none;
    svg {
        margin-right: 10px;
    }
    @media (max-width: 600px) {
        font-size: 1rem;
        svg {
            margin-right: 5px;
        }
    }
`

const BoxTradeMark = styled.div`
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
`

const DataSheet = styled.div`
    position: relative;
    display: none;
    grid-template-columns: 1fr;
    padding: 20px;
    width: 100%;
    height: auto;
    background: #FFF;
    box-sizing: border-box;
    gap: 20px 0;
    @media (max-width: 600px) {
        display: grid;
    }
`

const HeaderDataSheet = styled.h3`
    width: 100%;
    font-weight: 500;
    height: 40px;
    font-size: 1.5rem;
    color: #213781;
    margin: 0;
`

const Feature = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: auto;
    min-height: 35px;
    font-size: 1.1rem;
`

const FeatureName = styled.span`
    color: #2196F3;
    font-weight: 400;
`

const SeparatorFeature = styled.span`
    color: #2196F3;
    font-weight: 400;
    margin: 0 10px;
`

const FeatureDescription = styled.span`
    color: #707070;
    font-weight: 300;
`

const Breadcrumbs = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: auto;
    height: 50px;
    font-family: 'IBM Plex Sans';
    font-weight: 300;
    font-size: 1.2rem;
    color: #FFFFFF;
    transform: translateY(-100px);
    svg {
        font-size: 12px;
        margin: 0 5px;
    }
    @media (max-width: 600px) {
        display: none;
    }
`

const Video = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 1024px;
    height: 600px;
    align-items: center;
    justify-content: center;
    transform: translateY(-130px);
    @media (max-width: 600px) {
        width: 100%;
        height: 250px;
    }
`

const ProductTemplate2 = ({productData, principalFeatures, advantage, isDesktop}) => {

    const waterRef = React.useRef();

    const  [ waterEffect, setWaterEffect ] = React.useState(true);

    const scrollEffect = () => {
        if(waterRef && waterRef.current){
            console.log( waterRef.current.getBoundingClientRect().top)
            if(((window.innerHeight * 0.3) > waterRef.current.getBoundingClientRect().top)){
                setWaterEffect(false);
            } else {
                setWaterEffect(true);
            }
        }
    }

    React.useEffect(() => {
        window.addEventListener("scroll", scrollEffect);
        console.log(productData)
        return () => {
            window.removeEventListener('scroll', scrollEffect);
          };
      });

    return (
        <React.Fragment>
            <Header />
            <ProductContainer>
                <WaterMark  waterEffect={waterEffect}>NOVEDAD MAX</WaterMark>
                <BoxDescription ref={waterRef}>
                    <ProductCategory>{productData.id_Category.name}</ProductCategory>
                    <ProductName>{productData.name}</ProductName>
                    <ProductText dangerouslySetInnerHTML={{ __html: productData.description }} />
                    <Access>
                        {/* <AccessLink href="#"><DescriptionOutlinedIcon />Descargar instructivo</AccessLink>
                        <span>|</span> */}
                        <AccessLink href={`https://api.whatsapp.com/send?phone=${config.whatsapp}&text=Hola%2C%20quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20producto ${config.url}/product/${productData.idProduct}`} target="_blank">Consultar</AccessLink>
                    </Access>
                </BoxDescription>
                <BoxTradeMark>
                    <Trademark src={productData.trademark && productData.trademark.img ? productData.trademark.img : NoImage} alt={productData.trademark.name} />
                </BoxTradeMark>
            </ProductContainer>
            {
                productData.img && productData.img.images.length > 1 &&
                <ThreeImagesV2 images={productData.img.images} isDesktop={isDesktop} />
            }
            {
                advantage && advantage.length > 0 &&
                <Advantage height="60vh" advantage={advantage[0].value} bg={BackgroundNovelty} color="#FFF" />
            }
            {
                principalFeatures && 
                <SpecialFeaturesV2 features={principalFeatures} invertColor={true} />
            }
            <DataSheet>
                <HeaderDataSheet>Ficha técnica</HeaderDataSheet>
                {
                    productData.features && productData.features.length > 0 &&
                    productData.features.map((fea, index) =>
                        fea.name !== 'Especial' && fea.name !== 'Ventaja' &&
                        <Feature key={`feature_${index}`}>
                            <FeatureName>{fea.name}</FeatureName>
                            <FeatureDescription>{fea.value}</FeatureDescription>
                        </Feature>
                    )
                }
            </DataSheet>
        </React.Fragment>
    );
}

export default ProductTemplate2;