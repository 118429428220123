import { categoryConstants } from './CategoryConstants';
import { alertActions } from '../../alert/AlertActions';
import axios from 'axios';
import  { authHeader } from '../../app/auth-header';

export const categoryActions = {
    createCategory,
    updateCategory,
    getCategories,
    deleteCategory,
    getFields
};

function createCategory(category) {
    return dispatch => {
        axios.post( '/api/category' , {
            name: category.name,
            description: category.description,
            img: category.img,
            features: category.features,
            createdUser: category.createdUser,
            deleted: category.deleted,
            parent_idCategory: category.parent_idCategory,
            order: category.order           
        })
        .then(response => {
            dispatch(alertActions.success('Categoría creada'));
        })
        .catch(error => {
            dispatch(alertActions.error(error.toString()));

        })
    };
}

function updateCategory(idCategory, category) {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    return dispatch => {
        axios
        .put( '/api/category/' + idCategory, {
            name: category.name,
            description: category.description,
            img: category.img,
            features: category.features,
            updatedUser: category.updatedUser,
            deleted: category.deleted,
            parent_idCategory: category.parent_idCategory,
            order: category.order
            }, config)
        .then(response => {
            dispatch(alertActions.success('Categoría actualizada'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}

function getCategories() {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return dispatch => {
        dispatch({ type: categoryConstants.FETCHING });
        axios.get('/api/category',config)
        .then(categoriesData => {
            const categories = categoriesData.data.categories;
            dispatch({ type: categoryConstants.SUCCESS, categories });
        })
        .catch(err => {
            dispatch({ type: categoryConstants.ERROR });
            dispatch(alertActions.error('Hubo un error...'));
        })
        
    }
}

function deleteCategory(idCategory) {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    return dispatch => {
        if(idCategory === 1){
            dispatch(alertActions.error('Esta categoría no se puede eliminar.'));
        } else {
            axios.delete( '/api/category/' + idCategory, config)
            .then(response => {
                dispatch(alertActions.success('Categoría eliminada'));
            })
            .catch(error => {
                dispatch(alertActions.error('Hubo un error...'));
            })
        }
    };
}

function getFields() {

    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    return dispatch => {
        dispatch({ type: categoryConstants.FETCHING_FORMFIELDS });
        axios.get('/api/categoryFields', config)
        .then(response => {
            const categoryFields = response.data.categoryFields;
            dispatch({ type: categoryConstants.SUCCESS_FORMFIELDS, categoryFields });
        })
        .catch(error => {
            dispatch(alertActions.error(error));
        })
    }
}