/* General imports */
import React from 'react';
import styled from 'styled-components';
import { alertActions } from '../../alert/AlertActions';
import { connect } from 'react-redux';
import axios from 'axios';
import  { authHeader } from '../../app/auth-header';

/* Components imports */
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import TextField from '@material-ui/core/TextField'
import NoImage from '../../images/no-image.png'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';


/* Styled components */
const Root = styled.form`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 20px;
    flex-direction: column;
    background-color: white;
`

const BoxContainer = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
`

const Box = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 20px;
`

const ImageContainer = styled.div`
    position: relative;
    display: flex;
    width: 300px;
    height: 300px;
    margin-bottom: 25px;
    img {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`

const UploadImage = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    box-sizing: border-box;
`

const IconAction = styled.button`
    position: absolute;
    display: flex;
    width: 40px;
    height: 40px;
    top: 5px;
    right: ${(props) => props.right ? props.right : '10px'};
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    background: transparent;
    svg {
        font-size: 35px;
        cursor: pointer;
        z-index: 999;
    }
`

/* ----- Principal function ----- */
function NewTrademark({user, rowData, alertMessage, history }) {
 
    const [ dataForm, setDataForm ] = React.useState(rowData ? rowData : { 
        name: '',
        description: '',
        img: '',
        order: ''
    })    
    const [ image, setImage ] = React.useState(rowData ? rowData.img : '')

    let configHeader = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    const onChange = (event) => {
        const {name, value} = event.target;
        setDataForm({
            ...dataForm,
            [name]: value
        })
      }

    const onFileChange = (e) => {
        setImage(e.target.files[0])
    }

    const handleFileUpload = (e) => {
        if(image !== '' ){
            if(!dataForm.img){
                const formData = new FormData()
                formData.append('image', image)
                axios.post("/api/images/upload", formData, {
                }).then(res => {
                    const url_img = '/uploads/'+res.data.filename
                    setDataForm({
                        ...dataForm,
                        img: url_img,
                        img_error:false
                    })
                })
                .catch(error => {
                    alert('Error al cargar la imagen. Sólo se soporta hasta 2MB y los tipos jpeg-jpg-png-gif-webp.')
                })
            } else {
                // eslint-disable-next-line no-restricted-globals
                if(confirm('¿Desea subir esta nueva imagen y reemplazar la anterior?')){
                    const formData = new FormData()
                    formData.append('image', image)
                    axios.post("/api/images/upload", formData, {
                    }).then(res => {
                        const url_img = '/uploads/'+res.data.filename
                        setDataForm({
                            ...dataForm,
                            img: url_img,
                            img_error:false
                        })    
                    })
                    .catch(error => {
                        alert('Error al cargar la imagen. Sólo se soporta hasta 2MB y los tipos jpeg-jpg-png-gif-webp.')
                    })  
                }
            }
        }else{
            alert("Debe selecionar una imagen...")
        }
    }

    const cancel = (e) => {
        e.preventDefault();
        history.push('/admin/stocksystem/trademarks')
    }

    const save = (e) => {
        e.preventDefault();
        if(rowData){
            axios.put( `/api/trademarks/${rowData.idTrademark}` , {
                ...dataForm,
                updatedUser: user.idUser
            }, configHeader)
            .then(response => {
                alertMessage("Marca actualizada");
                history.push('/admin/stocksystem/trademarks');
            })
            .catch(error => {
                alertMessage("Hubo un error al actualizar la marca.");
                console.log(error);
            })
        } else {
            axios.post( '/api/trademarks' , {
                ...dataForm,
                createdUser: user.idUser
            }, configHeader)
            .then(response => {
                alertMessage("Marca creada");
                history.push('/admin/stocksystem/trademarks');
            })
            .catch(error => {
                alertMessage("Hubo un error al crear la marca.");
                console.log(error);
            })
        }
    } 

    return(
        <Root onSubmit={save}>
            <IconAction type="submit" right='60px'><SaveIcon /></IconAction>
            <IconAction ><CancelIcon onClick={cancel} /></IconAction>

            <BoxContainer>
                <Box>
                    <ImageContainer>
                        <img src={dataForm.img ? dataForm.img : NoImage} alt="Imagen subida" />
                    </ImageContainer>
                    <UploadImage>
                        <input style={{fontSize: '0.75rem', width: '300px', color: dataForm['img_error'] ? 'red' : 'black'}} id="image" name="image" type="file" onChange={onFileChange} error={dataForm['img_error']} /><CloudUploadIcon onClick={handleFileUpload} />
                    </UploadImage>
                </Box>
                <Box>
                    <TextField fullWidth margin="normal" id="name" name="name" label="Nombre" type='text' value={dataForm.name} onChange={onChange} required />
                    <TextField fullWidth margin="normal" id="description" name="description" label="Descripción" type='text' value={dataForm.description} onChange={onChange} />
                    <TextField fullWidth margin="normal" id="order" name="order" label="Orden" type='number' value={dataForm.order} onChange={onChange} required />
                </Box>
            </BoxContainer>            
        </Root>
    );
}

function mapState(state) {
    const { user } = state.login;
    return { user };
}

const actionCreators = {
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(NewTrademark);