import { loginConstants } from './LoginConstants';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? 
  { 
    loggingIn: false,
    loggedIn: true,
    user ,
    isFetching: false
  } :
  {
    loggingIn: false,
    loggedIn: false,
    isFetching: false
  };

export function login(state = initialState, action) {
  switch (action.type) {
    case loginConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        loggedIn: false,
      };
    case loginConstants.LOGIN_SUCCESS:
      return {
        loggingIn: false,
        loggedIn: true,
        user: action.user
      };
    case loginConstants.LOGIN_FAILURE:
      return {
        ...state,
        loggingIn: false,
        loggedIn: false
      };
    case loginConstants.LOGOUT:
      return {
        loggingIn: false,
        loggedIn: false
      };
    case loginConstants.FETCHING_FORMFIELDS:
      return { 
        ...state,
        isFetching: true
      };
    case loginConstants.SUCCESS_FORMFIELDS:
      return { 
        ...state,
        isFetching: false,
        loginFields: action.loginFields,
      };
    default:
      return state
  }
}