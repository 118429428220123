import { nameAccess } from '../app/nameAccessConstants'
import GeneralIcon from '../icons/config.svg'
import PagesIcon from '../icons/pages.svg'

export const menuItems = [
    {
        id: 'generalconfigpage',
        name: 'GENERAL',
        name_access: nameAccess.WEBEDITOR,
        icon: GeneralIcon,
        link: '/webeditor',
        exact: true
    },
    { 
        id: 'configpages',
        name: 'PÁGINAS',
        name_access: nameAccess.WEBEDITOR,
        icon: PagesIcon,
        link: '/webeditor/pages'
    }
]