/* General imports */
import React from 'react'
import styled from 'styled-components'

/* Components imports */
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

/* Styled components */
const ModalStyled = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 10px 10px!important;
    z-index: 99999;
    outline: none;
`

const ModalContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    color: black;
    box-sizing: border-box;
    border-radius: 10px 10px 10px 10px;
    width: 900px;
    min-height: 550px;
    align-items: center;
    justify-content: center;
    overflow: auto;
    outline: none;
`

const ModalTitle = styled.div`
    position: absolute;
    display: flex;
    top: 0;
    width: 100%;
    height: 70px;
    color: white;
    font-size: 24px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    align-items: center;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
    box-sizing: border-box;
    background: #234;
`

const ModalBody = styled.div`
    position: absolute;
    display: flex;
    top: 70px;
    width: 100%;
    height: calc(100% - 70px);
    align-items: center;
    justify-content: center;
`

const Container = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    
`

const Slide = styled.div`
    position: absolute;
    display: flex;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    img {
        position: absolute;
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
    }
`

const Caption = styled.div`
    position: absolute;
    bottom: 50px;
    left: 60px;
    right: 60px;
    padding: 30px;
    background: rgba(0,0,0,.5);
    box-sizing: border-box;
    text-decoration: none;
    user-select: none;
    h2 {
        margin: 0 0 20px;
        padding: 0;
        color: #fff;
        font-size: 28px;
    }

    p {
        margin: 0;
        padding: 0;
        color: #fff;
        font-size: 14px;
    }

`


/* ----- Principal function ----- */
function ModalCreateSlide({ title, rowData, openModal, closeModal }) {

    return(
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openModal}
            onClose={closeModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={openModal}>
                <ModalContent>
                    <ModalTitle>
                        {title}
                    </ModalTitle>
                    <ModalBody>
                        <Container>
                            <Slide >
                                <img src={rowData.img} alt={`Slide preview`} />
                                <Caption>
                                    <h2>{rowData.title}</h2>
                                    <p>{rowData.text}</p>
                                </Caption>
                            </Slide>
                        </Container>
                    </ModalBody>
                </ModalContent>
            </Fade>
        </ModalStyled>
    );
}

export default ModalCreateSlide;