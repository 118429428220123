/* General imports */
import React from 'react'
import { Route } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { devices } from '../app/config'
import { professionalsActions } from './ProfessionalsActions'
import { alertActions } from '../alert/AlertActions'

/* Components imports */
import Table from '../tables/Table'
import ModalCreateUpdateProfessional from './ModalCreateUpdateProfessional'
import ModalViewProfessional from './ModalViewProfessional'
import ModalAddData from './ModalAddData'
import ProfessionalsIcon from '../icons/professionalsWhite.png'
import NewProfessional from './NewProfessional'
import NoImage from '../images/no-image.png'

/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled components */
const Root = styled.div`
    width: ${(props) => props.width ? props.width : '100%'};
    height: 100%;
    padding: ${(props) => props.padding ? props.padding : 0};
    box-sizing: border-box;
`

const Header = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 150px;
    background: linear-gradient(to left, #122230 0%, #192d3e 100%);
    align-items: flex-start;
    padding: 35px 35px 70px 35px;
    box-sizing: border-box;
`

const Title = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img {
        position: relative;
        display: flex;
        width: 30px;
        height: 30px;
    }
    h6 {
        position: relative;
        display: flex;
        margin: 0 20px;
        color: white;
        font-size: 1.5rem;
        font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
        font-weight: 400;
    }
`

const Content = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: calc(100% - 150px);
    padding: 0 35px;
    box-sizing: border-box;
    top: -64px;
`

/* ----- Principal function ----- */
function Professionals({ user, professionals, getProfessionals, createProfessional, updateProfessional, updateInvoiceData, deleteProfessional, isFetching, alertMessage, professionalFields, getFields, professionalUpdateFields, getUpdateFields, history }) {

    const columsTable = [
        { title: 'ID', field: 'idUser', width: 50 },
        { title: 'Image', field: 'img', render: rowData => <img src={rowData.img ? rowData.img : NoImage } alt='SinImagen' style={{width: 40, height:40, borderRadius: '50%', boxShadow: '1px 1px 2px rgba(0,0,0,0.5)'}}/>, width: 50, align: 'center' },
        { 
            title: 'Apellidos', 
            field: 'lastname', 
            width: 300
        },
        { 
            title: 'Nombres', 
            field: 'firstname', 
            width: 300
        },
        { title: 'DNI', field: 'documentNumber' },
        { title: 'Teléfono', field: 'tel' },
        { 
            title: 'Correo electrónico', 
            field: 'email', 
            width: 300
        },
        { title: 'Usuario', field: 'username', hidden: true },
/*         { title: 'Creado', field: 'createdDate', type: 'date' },
        { title: 'Modificado', field: 'updatedDate', type: 'date' } */
    ]

    const tableRef = React.createRef()

    const [open, setOpen] = React.useState(false);

    const [ view, setView ] = React.useState(false)

    const [ viewData, setViewData ] = React.useState(null);

    const [ edit, setEdit ] = React.useState(false)

    const [ editData, setEditData ] = React.useState(null);

    const [ add, setAdd ] = React.useState(false)
    
    const [ addData, setAddData ] = React.useState(null);

    const refreshData = () => getProfessionals() && tableRef.current.onQueryChange()

    const handleView = (rowData) => {
        setViewData(rowData)
        setView(true);
    };

    const handleCloseView = () => {
        setView(false)
        refreshData()
    };

    const handleNew = () => {
        /* setOpen(true); */
        history.push('/admin/professionals/new')
    };

    const handleConfirmNew = async(data) => {
        if(data.password === data.repassword){
            data = {
                ...data,
                createdUser: user.idUser
            }
            await createProfessional(data)
            handleClose()    
        } else {
            alertMessage('Las contraseñas no coinciden')
        }
    }
    
    const handleClose = () => {
        setOpen(false)
        refreshData()
    };

    const handleEdit = (rowData) => {
        setEditData(rowData)
        /* setEdit(true) */ 
        history.push('/admin/professionals/update')
    }

    const handleConfirmEdit = async (data) => {
        data = {
            ...data,
            updatedUser: user.idUser
        }
        await updateProfessional(data.idUser, data)
        handleCloseEdit()
    }

    const handleCloseEdit = () => {
        setEdit(false)
        setEditData(null)
        refreshData()
    }

    const handleAdd = (rowData) => {
        setAddData(rowData)
        setAdd(true);
    };

    const handleConfirmAdd = async (data) => {
        data = {
            ...data,
            updatedUser: user.idUser
        }
        await updateInvoiceData(data.idUser, data)
        handleCloseAdd()
    }

    const handleCloseAdd = () => {
        setAdd(false)
        refreshData()
    }

    const handleDelete = (rowData) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("¿Desea eliminar el profesional "+rowData.idUser+"?")) {
            deleteProfessional(rowData.idUser)
        }
    }

    React.useEffect(() => {
        if(!professionals){
            getProfessionals()
        }
    }, [getProfessionals, professionals])

    const RouteComponent = ({ component: Component, rowData, ...rest }) => (
        <Route
            {...rest}
            render={(props) => {
                return <Component rowData={rowData} {...props} />
            }}
        />
    )

    return(
        <React.Fragment>
            <Desktop>
                <Root>
                    <Header>
                        <Title>
                            <img src={ProfessionalsIcon} alt='Icon' />
                            <h6>PROVEEDORES</h6>
                        </Title>
                    </Header>
                    <Content>
                        <Route path={'/admin/professionals'} exact={true} >
                            {
                                isFetching ? <h1>CARGANDO...</h1> : 
                                <Table tableRef={tableRef} autoExport={true}  title="LISTADO DE PROFESIONALES" columnsTable={columsTable} data={professionals} getData={getProfessionals} handleView={handleView} handleNew={handleNew} handleEdit={handleEdit} handleDelete={handleDelete} />   
                            }
                        </Route>
                        <Route path={'/admin/professionals/new'} component={NewProfessional} />
                        <RouteComponent path={'/admin/professionals/update'} rowData={editData} component={NewProfessional} />
                        
                        {
                            view && <ModalViewProfessional openModal={view} rowData={viewData} addData={handleAdd} closeModal={handleCloseView} />
                        }
                        {/* {
                            open && <ModalCreateUpdateProfessional title='CREAR PROFESIONAL' textButton='CREAR' handleAction={handleConfirmNew} getFields={getFields} fields={professionalFields} openModal={open} closeModal={handleClose} />
                        }
                        {
                            editData && <ModalCreateUpdateProfessional title='EDITAR PROFESIONAL' textButton='EDITAR' handleAction={handleConfirmEdit} rowData={editData} getFields={getUpdateFields} fields={professionalUpdateFields} openModal={edit} closeModal={handleCloseEdit} />
                        } */}
                        {
                            add && <ModalAddData title='DATOS DE FACTURACIÓN' textButton='ENVIAR' handleAction={handleConfirmAdd} rowData={addData} openModal={add} closeModal={handleCloseAdd} />
                        }
                    </Content>
                </Root>
            </Desktop>
            <Tablet>PROVEEDORES TABLET</Tablet>
            <Mobile>PROVEEDORES MOBILE</Mobile>
        </React.Fragment>
    );
}

function mapState(state) {
    const { error, isFetching, professionals, professionalFields, professionalUpdateFields } = state.professional;
    const { user } = state.login;
    return { user, error, isFetching, professionals, professionalFields, professionalUpdateFields};
}

const actionCreators = {
    getFields: professionalsActions.getFields,
    getUpdateFields: professionalsActions.getUpdateFields,
    createProfessional: professionalsActions.createProfessional,
    getProfessionals: professionalsActions.getProfessionals,
    updateProfessional: professionalsActions.updateProfessional,
    updateInvoiceData: professionalsActions.updateInvoiceData,
    deleteProfessional: professionalsActions.deleteProfessional,
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(Professionals);