import React from 'react'
import styled from 'styled-components'
import DecorationBackground from '../../images/Slider4_Background.png'


const ContentImageSlide = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    height: 100%;
    @media (max-width: 600px) {
        grid-template-columns: 1fr;
    }
`

const Image = styled.img`
    position: relative;
    object-position: center;
    width: 100%;
    height: 100%;
    @media (max-width: 600px) {
        position: absolute;
        top: 0;
        left: 0;
    }
`

const Decoration = styled.img`
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    @media (max-width: 600px) {
        display: flex;
    }
`

const ContentBoxSlide = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
    padding: 0 70px;
    z-index: 99;
    background: ${`url(${DecorationBackground})`};
    background-position: center;
    @media (max-width: 600px) {
        padding: 0 20px;
        align-items: flex-start;
        background: transparent;
    }
`



const TitleImage = styled.h2`
    width: 428px;
    font-size: 2.9rem;
    color: #2196F3;
    font-weight: 400;
    margin: 20px 0;
    @media (max-width: 600px) {
        width: 50%;
        font-size: 1.5rem;
        text-align: left;
        margin: 50px 0 10px 0;
    }
`

const TextImage = styled.span`
    width: 356px;
    font-size: 1.8rem;
    color: #001554;
    font-weight: 300;
    @media (max-width: 600px) {
        width: 50%;
        font-size: 1rem;
        text-align: left;
    }
`

const ButtonImage = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 216px;
    height: 48px;
    font-size: 20px;
    background: ${(props) => props.background ? props.background : '#2196F3'};
    border-radius: 24px;
    color: #FFF;
    margin: 30px 168px 30px 0;
    cursor: pointer;
    text-decoration: none;
    @media (max-width: 600px) {
        margin: 30px 0;
        width: 50%;
        height: 40px;
        font-size: 16px;
    }
`

const TwoColumns2 = (props) => {
    return (
        <ContentImageSlide>
            <ContentBoxSlide>
                {
                    props.data.title && <TitleImage>{props.data.title}</TitleImage> 
                }
                {
                    props.data.text && <TextImage>{props.data.text}</TextImage>
                }
                {
                    props.data.link && <ButtonImage href={props.data.link} target="_blank" background="#001554">{props.data.textButton}</ButtonImage>
                }
            </ContentBoxSlide>
            <Image src={props.data.img} alt='image_slide' />
            <Decoration src={DecorationBackground} alt='image_decoration' />
        </ContentImageSlide>
    );
}

export default TwoColumns2;