/* General imports */
import React from 'react';
import styled from 'styled-components';
import { productActions } from './ProductActions';
import { alertActions } from '../../alert/AlertActions';
import { connect } from 'react-redux';
import axios from 'axios';
import  { authHeader } from '../../app/auth-header';

/* Components imports */
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import TextField from '../../form/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';


/* Styled components */
const Root = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 20px;
    flex-direction: column;
    background-color: white;
`

const BoxContainer = styled.form`
    display: grid;
    position: relative;
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 10px;
    box-sizing: border-box;
    padding-top: 20px;
`

const Box = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 20px;
`

const IconAction = styled.div`
    position: absolute;
    display: flex;
    width: 40px;
    height: 40px;
    top: 5px;
    right: ${(props) => props.right ? props.right : '10px'};
    align-items: center;
    justify-content: center;
    
    svg {
        font-size: 35px;
        cursor: pointer;
        z-index: 999;
    }
`

const Title = styled.h2`
    font-size: 1.5rem;
`

const StyledFormControl = styled(FormControl)`
  width: 100%;
  margin: 10px 0;
`

const UpdateButton = styled.button`
    width: 50%;
    box-sizing: border-box;
    padding: 10px 20px;
    font-size: 2rem;
    outline: none;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
    border: none;
    margin: 20px 0;
    cursor: pointer;
    transition: all 300ms ease-in-out;
    &:hover {
        box-shadow: 0px 0px 15px rgba(0,0,0,0.8);
    }
`

/* ----- Principal function ----- */
function UpdatePrices({user, rowData, error, trademarks, getTrademarks, alertMessage, history }) {
 
    const [ dataForm, setDataForm ] = React.useState({ 
        category: -1,
        supplier: -1,
        trademark: -1,
        price: 1,
        action: 1,
        percentage: 0
    })

    let configHeader = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    const [ categories, setCategories ] = React.useState(null);
    const [ suppliers, setSuppliers ] = React.useState(null);

    const cancel = () => {
        history.push('/admin/stocksystem/product')
    }

    const onChange = (event) => {
        const {name, value} = event.target;
        setDataForm({
            ...dataForm,
            [name]: value
        })
    }

    const onChangePerc = (name, value) => {
        setDataForm({
            ...dataForm,
            [name]: value
        })
    }

    const update = (e) => {
        e.preventDefault();
        // eslint-disable-next-line no-restricted-globals
        if (confirm("¿Está seguro/a de aplicar esta actualización?")) {
            let conditions = "";
            if(parseInt(dataForm.category) !== -1){
                conditions = conditions + `idCategory=${dataForm.category}&`
            }
            if(parseInt(dataForm.trademark) !== -1){
                conditions = conditions + `idTrademark=${dataForm.trademark}&`
            }
            if(parseInt(dataForm.supplier) !== -1){
                conditions = conditions + `idSupplier=${dataForm.supplier}&`
            }
            axios.put(`/api/products/update/${parseInt(dataForm.price) === 1 ? "sale" : "cost"}?action=${dataForm.action}&porc=${dataForm.percentage}&${conditions}`,{},configHeader)
            .then(resultUpdate => {
                alertMessage("¡Actualización exitosa!");
            })
            .catch(err => {
                console.log(err)
                console.log(err.response)
                alertMessage('Hubo un error al actualizar los precios');
            })
            
        }
    }

    React.useEffect(() => {
        if(!categories){
            axios.get('/api/categoryListOptions',configHeader)
            .then(categoriesData => {
                setCategories(categoriesData.data.categoriesList)
            })
            .catch(err => {
                alertMessage('Hubo un error al cargar las categorías')
            })
        }
        if(!trademarks){
            getTrademarks()
        }
        
    }, [])

    React.useEffect(()=> {
        if(!suppliers){
            axios.get(`/api/suppliers`,configHeader)
            .then(suppliersData => {
                const suppliersList = [];
                suppliersData.data.rows.forEach(element => {
                    suppliersList.push({
                        name: element.name,
                        value: element.idSupplier
                    })
                });
                setSuppliers(suppliersList);
            })
            .catch(err => {
                console.log(err);
            })
        }
    }, [])

    return(
        <Root>
            <IconAction ><CancelIcon onClick={cancel} /></IconAction>
            <BoxContainer onSubmit={update}>
                <Box>
                    <Title>Seleccione los filtros:</Title>
                    {   
                        categories &&
                        <StyledFormControl required>
                            <InputLabel id="category-label"> Categoría </InputLabel>
                            <Select
                                native
                                labelId="category-label"
                                
                                onChange={onChange}
                                required
                                inputProps={{
                                id:"category",
                                name:"category",
                                value:dataForm.category
                                }}
                            >

                                <option value={-1}>Todas</option>
                                {
                                    categories.map((cat, index) =>
                                        <option key={`cat_option_${index}`} value={cat.value}> {cat.name} </option>   
                                    )
                                }
                            </Select>
                        </StyledFormControl>
                    }
                    {   
                        trademarks &&
                        <StyledFormControl required>
                            <InputLabel id="trademarks-label"> Marca </InputLabel>
                            <Select
                                native
                                labelId="trademarks-label"
                                
                                onChange={onChange}
                                required
                                inputProps={{
                                id:"trademark",
                                name:"trademark",
                                value:dataForm.trademark
                                }}
                            >

                                <option value={-1}>Todas</option>
                                {
                                    trademarks.map((tm, index) =>
                                        <option key={`tm_option_${index}`} value={tm.value}> {tm.name} </option>   
                                    )
                                }
                            </Select>
                        </StyledFormControl>
                    }
                    {   
                        suppliers &&
                        <StyledFormControl required>
                            <InputLabel id="suppliers-label"> Proveedor </InputLabel>
                            <Select
                                native
                                labelId="suppliers-label"
                                
                                onChange={onChange}
                                required
                                inputProps={{
                                id:"supplier",
                                name:"supplier",
                                value:dataForm.supplier
                                }}
                            >

                                <option value={-1}>Todos</option>
                                {
                                    suppliers.map((sup, index) =>
                                        <option key={`sup_option_${index}`} value={sup.value}> {sup.name} </option>   
                                    )
                                }
                            </Select>
                        </StyledFormControl>
                    }
                </Box>
                <Box>
                    <StyledFormControl required>
                        <InputLabel id="price-label"> Precio </InputLabel>
                        <Select
                            native
                            labelId="price-label"
                            onChange={onChange}
                            required
                            inputProps={{
                            id:"price",
                            name:"price",
                            value:dataForm.price
                            }}
                        >
                            <option value={1}>Venta</option>
                            <option value={0}>Costo</option>
                        </Select>
                    </StyledFormControl>
                    <StyledFormControl required>
                        <InputLabel id="action-label"> Acción </InputLabel>
                        <Select
                            native
                            labelId="action-label"
                            onChange={onChange}
                            required
                            inputProps={{
                            id:"action",
                            name:"action",
                            value:dataForm.action
                            }}
                        >
                            <option value={1}>Aumentar</option>
                            <option value={0}>Disminuir</option>
                        </Select>
                    </StyledFormControl>
                    <TextField 
                        id="percentage" 
                        value={dataForm.percentage} 
                        required={true} 
                        name="percentage" 
                        placeholder="Porcentaje"  
                        width="50%" 
                        type="number"
                        min={0}
                        max={100}
                        step=".01" 
                        fontSize="2rem"
                        onChange={onChangePerc}
                    />
                    <UpdateButton type="submit">Actualizar</UpdateButton>
                </Box>
            </BoxContainer>
        </Root>
    );
}

function mapState(state) {
    const { trademarks } = state.product;
    const { user } = state.login;
    return { user, trademarks };
}

const actionCreators = {
    getTrademarks: productActions.getTrademarks,
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(UpdatePrices);