import { professionalsConstants } from './ProfessionalsConstants';
import { alertActions } from '../alert/AlertActions';
import axios from 'axios';
import  { authHeader } from '../app/auth-header';
import PersonIcon from '../icons/userCircle.svg';
import LockIcon from '../icons/lock.svg';

export const professionalsActions = {
    createProfessional,
    updateProfessional,
    getProfessionals,
    deleteProfessional,
    getFields,
    getUpdateFields,
    updateInvoiceData
};

function createProfessional(professional) {
    return dispatch => {
        axios.post( '/api/users/register' , {
            firstname: professional.firstname,
            lastname: professional.lastname,
            documentType: professional.documentType,
            documentNumber: professional.documentNumber,
            birthday: professional.birthday,
            tel: professional.tel,
            email: professional.email,
            street: professional.street,
            number: professional.number,
            username: professional.username,
            password: professional.password,
            createdUser: professional.createdUser,
            enabled: professional.enabled,
            idRole: professionalsConstants.IDROLE,
            img: professional.img,
            roleData: {
                specialization: professional.specialization,
                enrollment: professional.enrollment
            },
            invoiceData: professional.invoiceData
        })
        .then(response => {
            dispatch(alertActions.success('Profesional creado'));
        })
        .catch(error => {
            dispatch(alertActions.error(error.toString()));

        })
    };
}

function updateProfessional(idUser, professional) {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    return dispatch => {
        axios
        .put( '/api/users/' + idUser + '/' + professionalsConstants.IDROLE, {
            firstname: professional.firstname,
            lastname: professional.lastname,
            documentType: professional.documentType,
            documentNumber: professional.documentNumber,
            birthday: professional.birthday || null,
            tel: professional.tel,
            email: professional.email,
            street: professional.street,
            number: professional.number,
            username: professional.username,
            updatedUser: professional.updatedUser,
            enabled: professional.enabled,
            img: professional.img,
            roleData: {
                specialization: professional.specialization,
                enrollment: professional.enrollment
            },
            invoiceData: professional.invoiceData
            }, config)
        .then(response => {
            dispatch(alertActions.success('Profesional actualizado'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}

function updateInvoiceData(idUser, data) {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return dispatch => {
        axios
        .put( '/api/users/' + idUser + '/' + professionalsConstants.IDROLE, {
            invoiceData: {
                cuit: data.cuit,
                businessname: data.businessname,
                commercialAddress: data.commercialAddress,
                ivaCondition: data.ivaCondition,
                grossIncome: data.grossIncome || null,
                activityStartDate: data.activityStartDate || null
            }
        }, config)
        .then(response => {
            dispatch(alertActions.success('Datos actualizados'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}


function getProfessionals() {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return dispatch => {
        const idRole = professionalsConstants.IDROLE;
        dispatch({ type: professionalsConstants.FETCHING });
        axios.get('/api/users/role/'+idRole,config)
        .then(professionalsData => {
            const professionals = professionalsData.data.users
            professionals.forEach(element => {
                if(element.roles[0].userRole.roleData){
                    element['specialization'] = element.roles[0].userRole.roleData.specialization;
                    element['enrollment'] = element.roles[0].userRole.roleData.enrollment;
                }
                if(element.roles[0].userRole.invoiceData){
                    element['invoiceData'] = element.roles[0].userRole.invoiceData
                } else {
                    element['invoiceData'] = null
                }
                if(element.address[0]){
                    element['street'] = element.address[0].street;
                    element['number'] = element.address[0].number;
                }
            });
            dispatch({ type: professionalsConstants.SUCCESS, professionals });
        })
        .catch(err => {
            dispatch({ type: professionalsConstants.ERROR });
            dispatch(alertActions.error('Hubo un error...'));
        })
        
    }
}

function deleteProfessional(id) {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    return dispatch => {
        axios
        .delete( '/api/users/' + id, config)
        .then(response => {
            dispatch(alertActions.success('Profesional eliminado'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}

function getFields() {

    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    return dispatch => {
        dispatch({ type: professionalsConstants.FETCHING_FORMFIELDS });
        axios.get('/api/registerFields', config)
        .then(response => {
            const professionalFields = response.data.registerFields.filter(element => element.name !== 'idRole');
            professionalFields.forEach((element, index) => {
                if(element.icon === 'PersonIcon'){
                    element.icon = PersonIcon
                }
                if(element.icon === 'LockIcon'){
                    element.icon = LockIcon
                }
            });
            professionalFields.push({
                name: 'specialization',
                type: 'text',
                placeholder: 'Especialización',
                required: false
            })
            professionalFields.push({
                name: 'enrollment',
                type: 'text',
                placeholder: 'Matrícula',
                required: false
            })
            dispatch({ type: professionalsConstants.SUCCESS_FORMFIELDS, professionalFields });
        })
        .catch(error => {
            dispatch(alertActions.error(error));
        })
    }
}

function getUpdateFields() {

    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    return dispatch => {
        dispatch({ type: professionalsConstants.FETCHING_FORMFIELDS });
        axios.get('/api/registerFields', config)
        .then(response => {
            const professionalUpdateFields = response.data.registerFields.filter(element => ((element.name !== 'idRole') && (element.name !== 'username') && (element.type !== 'password')));
            professionalUpdateFields.push({
                name: 'specialization',
                type: 'text',
                placeholder: 'Especialización',
                required: false
            })
            professionalUpdateFields.push({
                name: 'enrollment',
                type: 'text',
                placeholder: 'Matrícula',
                required: false
            })
            dispatch({ type: professionalsConstants.SUCCESS_UPDATEFORMFIELDS, professionalUpdateFields });
        })
        .catch(error => {
            dispatch(alertActions.error(error));
        })
    }
}