/* General imports */
import React from 'react';
import styled from 'styled-components';

/* Components imports */
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Table from '../../tables/AsyncTable'

/* Styled components */
const ModalStyled = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 10px 10px!important;
    z-index: 99999;
    outline: none;
`

const ModalContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    color: black;
    box-sizing: border-box;
    border-radius: 10px 10px 10px 10px;
    width: 90%;
    min-height: 500px;
    align-items: center;
    justify-content: center;
    overflow: auto;
    outline: none;
`

const ModalTitle = styled.div`
    position: absolute;
    display: flex;
    top: 0;
    width: 100%;
    height: 70px;
    color: white;
    font-size: 24px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    align-items: center;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
    box-sizing: border-box;
    background: linear-gradient(to left,#122230 0%,#192d3e 100%);
`

const ModalBody = styled.div`
    position: absolute;
    display: flex;
    top: 70px;
    width: 100%;
    height: calc(100% - 70px);
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
    box-sizing: border-box;
    flex-direction: column;
`

/* ----- Principal function ----- */
function ModalSale({ title, openModal, closeModal, sale }) {

    const columsTable = [
        { title: 'ID Detalle', field: 'idSaleDetail', width: 10, editable: 'never' },
        { title: 'ID Venta', field: 'idSale', hidden: true},
        { title: 'ID producto', field: 'idProduct', width: 10, editable: 'never' },
        { title: 'Producto', field: 'details.name', width: 250, editable: 'never' },
        { title: 'Unitario', field: 'price', width: 50, type: 'currency' },
        { title: 'Cantidad', field: 'quantity', width: 50, type: 'numeric' },
        { title: 'Total', field: 'totalAmount', width: 50, type: 'currency', editable: 'never' }
    ]

    const tableRef = React.useRef();

    return(
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openModal}
            onClose={closeModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={openModal}>
                <ModalContent>
                    <ModalTitle>
                        {title}
                    </ModalTitle>
                    <ModalBody>
                        <Table pageSize={10} cellEditable={true} idName="idSaleDetail" urlapi_celledit="/api/saledetails/field" urlapi={`/api/saledetails/sale/${sale}`} tableRef={tableRef} autoExport={true}  hiddenView={true} hiddenNew={true} hiddenEdit={true} hiddenDelete={true}  title="DETALLE VENTA" columnsTable={columsTable} />   
                    </ModalBody>
                </ModalContent>
            </Fade>
        </ModalStyled>
    );
}

export default ModalSale;