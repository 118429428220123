/* General imports */
import React from 'react'
import styled from 'styled-components'
import InvoiceAConfirm from './InvoiceAConfirm'
import InvoiceBConfirm from './InvoiceBConfirm'
import ReceiptAConfirm from './ReceiptAConfirm'

/* Components imports */
import TicketConfirm from './TicketConfirm'

const ContainerStep = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`

const VoucherConfirm = ({idForm, rowData, voucherPrintRef, onBack, handleOnFinishStep}) => {

    const getFormData = (idFormData) => {
        switch (parseInt(idFormData)) {
            case 19:
                return <TicketConfirm rowData={rowData} voucherPrintRef={voucherPrintRef} onBack={onBack} handleFinish={handleOnFinishStep} />
                break;
            case 29:
                return <InvoiceAConfirm rowData={rowData} voucherPrintRef={voucherPrintRef} onBack={onBack} handleFinish={handleOnFinishStep} />
                break;
            case 30:
                return <InvoiceBConfirm rowData={rowData} voucherPrintRef={voucherPrintRef} onBack={onBack} handleFinish={handleOnFinishStep} />
                break;
            case 6:
                return <h2>FACTURA C</h2>
                break;
            case 31:
                return <h2>NOTA DE CRÉDITO</h2>
                break;
            case 32:
                return <h2>ORDEN DE COMPRE</h2>
                break;
            case 33:
                return <ReceiptAConfirm rowData={rowData} voucherPrintRef={voucherPrintRef} onBack={onBack} handleFinish={handleOnFinishStep} />
                break;
            case 34:
                return <h2>REMITO</h2>
                break;
            case 35:
                return <h2>PRESUPUESTO</h2>
                break;
            case 36:
                return <h2>RECIBO B</h2>
                break;
            default:
                return <h2>Tipo de comprobante desconocido...</h2>
                break;
        }
    }

    return (
        <ContainerStep>
            {getFormData(idForm)}
        </ContainerStep>
    );
}

export default VoucherConfirm;