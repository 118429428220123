/* General imports */
import React from 'react'
import styled from 'styled-components'

/* Components imports */
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Form from '../form/Form'
/* import { theme } from '../app/config' */

/* Styled components */
const ModalStyled = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 10px 10px!important;
    z-index: 99999;
    outline: none;
`

const ModalContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    color: black;
    box-sizing: border-box;
    border-radius: 10px 10px 10px 10px;
    width: 500px;
    min-height: 600px;
    align-items: center;
    justify-content: center;
    overflow: auto;
    outline: none;
`

const ModalTitle = styled.div`
    position: absolute;
    display: flex;
    top: 0;
    width: 100%;
    height: 70px;
    color: white;
    font-size: 24px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    align-items: center;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
    box-sizing: border-box;
    background: linear-gradient(to left,#122230 0%,#192d3e 100%);
`

const ModalBody = styled.div`
    position: absolute;
    display: flex;
    top: 70px;
    width: 100%;
    height: calc(100% - 70px);
    align-items: flex-start;
    justify-content: center;
    overflow-y: scroll;
    box-sizing: border-box;
`

/* ----- Principal function ----- */
function ModalAddData({ title, textButton, rowData, openModal, closeModal, handleAction }) {

    const fields = [
        {
            name: 'cuit',
            type: 'text',
            placeholder: 'CUIT',
            required: true
        },
        {
            name: 'businessname',
            type: 'text',
            placeholder: 'Razón Social',
            required: true
        },
        {
            name: 'commercialAddress',
            type: 'text',
            placeholder: 'Dirección de facturación',
            required: true
        },
        {
            name: 'ivaCondition',
            type: 'text',
            placeholder: 'Condición frente al IVA',
            required: true
        },
        {
            name: 'grossIncome',
            type: 'text',
            placeholder: 'Ingresos Brutos',
            required: false
        },
        {
            name: 'activityStartDate',
            type: 'date',
            placeholder: 'Inicio de actividades',
            required: false
        }
    ]

    const submit = (data) => {
        data = {
            ...data,
            idUser: rowData.idUser
        }
        handleAction(data)
    }

    return(
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openModal}
            onClose={closeModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={openModal}>
                <ModalContent>
                    <ModalTitle>
                        {title}
                    </ModalTitle>
                    <ModalBody>
                        {
                            (!fields) ? 
                                <h1>CARGANDO...</h1> 
                            : 
                            <Form width='450px' height='600px' data={rowData} columWidth='300px' alignV='flex-start' fields={fields} submitButton={textButton} submitFunction={submit}/>
                        }
                    </ModalBody>
                </ModalContent>
            </Fade>
        </ModalStyled>
    );
}

export default ModalAddData;