/* General imports */
import React from 'react'
import styled from 'styled-components'
import axios from 'axios';
import { authHeader } from '../../app/auth-header';
import CloseIcon from '@material-ui/icons/Close';
import { TextField } from '@material-ui/core';

const Root = styled.div`
    position: absolute;
    bottom: 0;
    right: 50px;
    background: #fff;
    width: 384px;
    height: ${(props) => props.show ? '300px' : 0};
    overflow-y: hidden;
    z-index: 1;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
    transition: all 300ms ease-in-out;
    font-family: 'IBM Plex Sans';
    z-index: 9999;
`

const Content = styled.form`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 20px 75px 10px 37px;
    flex-direction: column;
`

const CloseButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    box-shadow: none;
    background: transparent;
    z-index: 99;
    cursor: pointer;
    svg {
        font-size: 20px;
        color: #001554;
    }
`

const Title = styled.span`
    font-size: 1.25rem;
    color: #213781;
    margin-bottom: 5px;
`

const Subtitle = styled.span`
    font-size: 1.1rem;
    color: #2196F3;
    margin-bottom: 8px;
`

const StyledButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background: #CCCDCF;
    color: #001554;
    text-align: center;
    border-radius: 24px;
    width: 216px;
    height: 48px;
    box-shadow: none;
    border: none;
    margin: 20px 0;
    font-size: 1.2rem;
`

const NewsLetter = (props) => {

    const [ showNewsLetter, setShowNewsLetter ] = React.useState(false);

    const [ data, setData ] = React.useState({
        key: 'newsletter',
        fullname: '',
        email: ''
    })

    const onClose = (e) => {
        e.preventDefault();
        setShowNewsLetter(false);
    }

    const onChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        });
    }

    const onSubmit = (e) => {
        e.preventDefault();
        let configHeader = {
            headers: { ...authHeader(), 'Content-Type': 'application/json' }
        }
        axios.post("/api/audiencies", data, configHeader)
        .then(res => {
            setShowNewsLetter(false);
        })
        .catch(error => {
            alert('Hubo un error...')
        })
        
    }

    React.useEffect(() => {
        setTimeout(function(){ setShowNewsLetter(true); }, 1000);
    }, [])

    return (
        <Root show={showNewsLetter}>
            <CloseButton onClick={onClose}><CloseIcon /></CloseButton>
            <Content onSubmit={onSubmit}>
                <Title>¿Querés enterarte de nuestras novedades antes que nadie?</Title>
                <Subtitle>Suscribite al newsletter de MAX</Subtitle>
                <TextField fullWidth id="fullname" name="fullname" type="text" value={data.fullname} label="Nombre y apellido" variant="standard" onChange={onChange} size="small" required />
                <TextField fullWidth id="email" name="email" type="email" value={data.email} label="E-mail" variant="standard" onChange={onChange} size="small" required />
                <StyledButton type="submit">Suscribite</StyledButton>
            </Content>
        </Root>
    );
}

export default NewsLetter;