/* General imports */
import React from 'react';
import styled from 'styled-components';
import { alertActions } from '../alert/AlertActions';
import { connect } from 'react-redux';
import axios from 'axios';
import  { authHeader } from '../app/auth-header';

/* Components imports */
import Spinner from '../spinners/LoaderSpinner';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import NoImage from '../images/no-image.png';
import FormStep from '../form/FormStep'
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { patientsActions } from './PatientsActions'


/* Styled components */
const Root = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 20px;
    background-color: white;
`

const Box = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: ${(props) => props.width ? props.width : '100%'};
    height: 100%;
    align-items: ${(props) => props.alignX ? props.alignX : 'center'};
    justify-content: ${(props) => props.alignY ? props.alignY : 'flex-start'};
`

const ImageContainer = styled.div`
    position: relative;
    display: flex;
    width: 150px;
    height: 150px;
    margin-bottom: 25px;
    img {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`

const UploadImage = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    box-sizing: border-box;
`

const IconAction = styled.div`
    position: absolute;
    display: flex;
    width: 40px;
    height: 40px;
    top: 5px;
    right: ${(props) => props.right ? props.right : '10px'};
    align-items: center;
    justify-content: center;
    
    svg {
        font-size: 35px;
        cursor: pointer;
        z-index: 999;
    }
`

/* ----- Principal function ----- */
function NewPatient({user, rowData, createPatient, updatePatient, isFetchingFields, alertMessage, patientFields, patientUpdateFields, getFields, getUpdateFields, getPatients, history }) {
 
    const [ dataForm, setDataForm ] = React.useState(rowData ? rowData : {})

    const [ image, setImage ] = React.useState(rowData ? rowData.img : '')

    let configHeader = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    const checkForm = async () => {
        let flag = true
        let message = 'Faltan completar campos'
        if(rowData) {
            patientUpdateFields.forEach(element => {
                if(element.required){
                    if(dataForm[element.name] === null || dataForm[element.name] === '' || dataForm[element.name] === undefined){
                        flag = false;
                        dataForm[`${element.name}_error`] = true;
                    } else {
                        dataForm[`${element.name}_error`] = false;
                    }
                }
            });
        } else {
            patientFields.forEach(element => {
                if(element.required){
                    if(dataForm[element.name] === null || dataForm[element.name] === '' || dataForm[element.name] === undefined){
                        flag = false;
                        dataForm[`${element.name}_error`] = true;
                    } else {
                        dataForm[`${element.name}_error`] = false;
                    }
                }
            });
        }
        
        return { flag, message }
    }

    const handleChange = (name, value) => {
        setDataForm({ 
            ...dataForm,
            [name]: value 
        });
    }

    const onFileChange = (e) => {
        setImage(e.target.files[0])
    }


    const handleFileUpload = (e) => {
        if(image !== '' ){
            if(!dataForm.img){
                const formData = new FormData()
                formData.append('image', image)
                axios.post("/api/images/upload", formData, configHeader, {
                }).then(res => {
                    const url_img = '/uploads/'+res.data.filename
                    setDataForm({
                        ...dataForm,
                        img: url_img,
                        img_error:false
                    })
                })
                .catch(error => {
                    alert('Error al cargar la imagen. Sólo se soporta hasta 2MB y los tipos jpeg-jpg-png-gif-webp.')
                })
            } else {
                // eslint-disable-next-line no-restricted-globals
                if(confirm('¿Desea subir esta nueva imagen y reemplazar la anterior?')){
                    const formData = new FormData()
                    formData.append('image', image)
                    axios.post("/api/images/upload", formData, configHeader, {
                    }).then(res => {
                        const url_img = '/uploads/'+res.data.filename
                        setDataForm({
                            ...dataForm,
                            img: url_img,
                            img_error:false
                        })    
                    })
                    .catch(error => {
                        alert('Error al cargar la imagen. Sólo se soporta hasta 2MB y los tipos jpeg-jpg-png-gif-webp.')
                    })  
                }
            }
        }else{
            alert("Debe selecionar una imagen...")
        }
    }

    const cancelPatient = () => {
        history.push('/admin/patients')
    }

    const savePatient = async () => {
        const {flag, message} = await checkForm();
        if(flag){
            let savePromise = new Promise((resolve, reject) => {
                if(rowData){
                    updatePatient(rowData.idUser, {
                        ...dataForm,
                        updatedUser: user.idUser
                    });
                } else {
                    createPatient({
                        ...dataForm,
                        createdUser: user.idUser
                    });
                }
                resolve("Paciente guardado");    
            });
            savePromise.then(()=> {
                getPatients();
                history.push('/admin/patients');
            });
        } else {
            alertMessage(message);
        }
    }

    React.useEffect(() => {
        if(rowData){
            if(!patientUpdateFields){
                getUpdateFields()
            }    
        } else {
            if(!patientFields){
                getFields()
            }   
        }     
    }, [getFields, patientFields, patientUpdateFields, getUpdateFields])

    return(
        isFetchingFields ? <Spinner spinnerType='ThreeDots' />
        :
        <Root>
            <IconAction right='60px'><SaveIcon onClick={savePatient} /></IconAction>
            <IconAction ><CancelIcon onClick={cancelPatient} /></IconAction>
            <Box width='20%' alignY='center' >
                <ImageContainer>
                    <img src={dataForm.img ? dataForm.img : NoImage} alt="Imagen subida" />
                    </ImageContainer>
                <UploadImage>
                    <input style={{fontSize: '0.75rem', width: '300px', color: dataForm['img_error'] ? 'red' : 'black'}} id="image" name="image" type="file" onChange={onFileChange} error={dataForm['img_error']} required /><CloudUploadIcon onClick={handleFileUpload} />
                </UploadImage>
            </Box>
            <Box width='80%' alignX='flex-start' >
                {
                    rowData ?
                    patientUpdateFields && <FormStep border={true} fontSize='18px' fields={patientUpdateFields} width='100%' height='100%' inputMargin={'18px'} data={dataForm} onHandleChange={handleChange} columWidth='30%' alignV='flex-start' fontSize='14px' />
                    :
                    patientFields && <FormStep border={true} fontSize='18px' fields={patientFields} width='100%' height='100%' inputMargin={'18px'} data={dataForm} onHandleChange={handleChange} columWidth='20%' alignV='flex-start' fontSize='14px' />
                }
            </Box>
        </Root>
    );
}

function mapState(state) {
    const { error, isFetchingFields, patientFields, patientUpdateFields } = state.patient;
    const { user } = state.login;
    return { user, error, isFetchingFields, patientFields, patientUpdateFields};
}

const actionCreators = {
    getFields: patientsActions.getFields,
    getUpdateFields: patientsActions.getUpdateFields,
    createPatient: patientsActions.createPatient,
    updatePatient: patientsActions.updatePatient,
    getPatients: patientsActions.getPatients,
    alertMessage: alertActions.error
}
export default connect(mapState, actionCreators)(NewPatient);