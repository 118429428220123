import { sidebarCartConstants } from './SidebarCartConstants';

export const sidebarCartActions = {
    switchSidebarCart,
    addToCart,
    removeToCart,
    setAmount,
    clearCart
};

function switchSidebarCart() {
    return dispatch => {
        dispatch({ type: sidebarCartConstants.SWITCH });
    };
}

function addToCart(item) {
    return dispatch => {
        let cartitems = [];
        let items = JSON.parse(localStorage.getItem('cartitems'));
        if(items){
            cartitems = items;
        }
        cartitems.push(item);
        localStorage.setItem('cartitems', JSON.stringify(cartitems));
        dispatch({ type: sidebarCartConstants.ADD, cartitems });
    };
}

function removeToCart(indexDelete) {
    return dispatch => {
        let items = JSON.parse(localStorage.getItem('cartitems'));
        let cartitems = items.filter(function(value, index, arr){ 
            return parseInt(index) !== parseInt(indexDelete);
        });
        localStorage.setItem('cartitems', JSON.stringify(cartitems));
        dispatch({ type: sidebarCartConstants.REMOVE, cartitems });
    };
}

function setAmount(indexSet, amount) {
    return dispatch => {
        let cartitems = JSON.parse(localStorage.getItem('cartitems'));
        cartitems[indexSet] = {
            ...cartitems[indexSet],
            amount: amount
        };
        localStorage.setItem('cartitems', JSON.stringify(cartitems));
        dispatch({ type: sidebarCartConstants.SET, cartitems });
    };
}

function clearCart() {
    return dispatch => {
        localStorage.removeItem('cartitems');
    };
}