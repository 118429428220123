import { pointSaleConstants } from './PointSaleConstants';
import { alertActions } from '../alert/AlertActions';
import axios from 'axios';
import  { authHeader } from '../app/auth-header';

export const pointSaleActions = {
    setItems,
    collectSale
};

function setItems(items) {
    return dispatch => {
        localStorage.setItem('items', JSON.stringify(items));
        dispatch({ type: pointSaleConstants.ADD, items });
    };
}

function collectSale(saleData) {
    let configHeader = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return dispatch => {
        const items = localStorage.getItem('items');
        dispatch({ type: pointSaleConstants.FETCHING });
        axios.post(`/api/sales`, {items, saleData} ,configHeader)
        .then(result => {
            dispatch({ type: pointSaleConstants.SUCCESS });
        })
        .catch(err => {
            dispatch({ type: pointSaleConstants.ERROR });
            dispatch(alertActions.error('Hubo un error...'));
        })
        dispatch({ type: pointSaleConstants.SUCCESS });
    };
}