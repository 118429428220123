/* General imports */
import React from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { devices } from '../../app/config'
import axios from 'axios';
import  { authHeader } from '../../app/auth-header';
import { Link } from 'react-router-dom';

/* Components imports */
import ProductCard from '../ProductList/ProductCard'
import HeaderBackground from '../../images/ProductosDestacados_Header.png';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { InputAdornment, TextField } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';

/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled components */
const Root = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    min-height: 100vh;
    align-items: center;
    justify-content: flex-start;
    font-family: 'IBM Plex Sans';
`

const Header = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 216px;
    background: ${`url(${HeaderBackground})`};
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
    padding: 0 50px;
    @media (max-width: 600px) {
        padding: 0 20px;
        align-items: flex-end;
    }
`

const HeaderTitle = styled.h2`
    width: 100%;
    text-align: left;
    font-weight: 300;
    font-size: 3.5rem;
    color: #213781;
    margin: 0;
    @media (max-width: 600px) {
        font-size: 2rem;
        margin: 20px 0;
    }
`

const FilterZone = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100px;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 30px;
    @media (max-width: 600px) {
        padding: 0 10px;
    }
`

const CatFilter = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    span {
        margin: 0 10px;
    }
    @media (max-width: 600px) {
        flex-direction: column;
        width: 45%;
        .MuiSelect-root {
            width: 100px;
        }
    }
`

const OrderFilter = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    span {
        margin: 0 10px;
    }
    @media (max-width: 600px) {
        flex-direction: column;
        width: 45%;
        .MuiSelect-root {
            width: 100px;
        }
    }
`

const StyledFormControl = styled(FormControl)`
    width: auto;
    min-width: 150px;
`

const ProductsGrid = styled.div`
    position: relative;
    display: grid;
    width: 100%;
    height: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 50px 10px;
    margin: 50px 0;
    box-sizing: border-box;
    padding: 0 20px;
    @media (max-width: 800px) {
        grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 600px) {
        grid-template-columns: 1fr;
    }
`

const ContentProductCard = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    & > span {
        font-size:18px;
        color: #2196F3;
        font-weight: 400;
        margin: 10px 20px;
        text-transform: uppercase;
    }
`

const Pagination = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
`

const InfoPage = styled.span`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
    font-size: 12px;
    color: #707070;
    @media (max-width: 600px) {
        display: none;
    }
`

const Dot = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #001554;
    border-radius: 50%;
    font-size: 12px;
    width: 25px;
    height: 25px;
    color: ${(props) => props.selected ? '#FFF' : '#001554'};
    background: ${(props) => props.selected ? '#001554' : 'transparent'};
    margin: 0 5px;
    cursor: pointer;
`

const StyledArrowForwardIosIcon = styled(ArrowForwardIosIcon)`
    margin-left: 5px;
    display: ${(props) => props.show ? 'flex' : 'none'};
    cursor: pointer;
`

const StyledArrowBackIosIcon = styled(ArrowBackIosIcon)`
    display: ${(props) => props.show ? 'flex' : 'none'};
    cursor: pointer;
`

const Breadcrumbs = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: auto;
    height: 50px;
    font-family: 'IBM Plex Sans';
    font-weight: 300;
    font-size: 1.2rem;
    color: #FFFFFF;
    svg {
        font-size: 12px;
        margin: 0 5px;
    }
`

const Search = styled(TextField)`
    
`

/* ----- Principal function ----- */
function CategoryProducts({idsection, match}) {

    const [data, setData] = React.useState(null);

    const [ showFilter, SetShowFilter ] = React.useState(false)

    const [productList, setProductList] = React.useState(null)  
    const [categories, setCategories] = React.useState(null)
    const [search, setSearch] = React.useState('');
    const [ page, setPage ] = React.useState(0);
    const [ limit, setLimit ] = React.useState(12);

    const [ selectedCat, setSelectedCat ] = React.useState(-1);
    const [ selectedOrder, setSelectedOrder ] = React.useState('nameAsc');
    const [ amountProducts, setAmountProducts ] = React.useState(0);

    const PAGINATION_LIMIT = 3;

    const [ principalFilter, setPrincipalFilter ]  = React.useState(null);

    const orderOptions = [
        { name: "Alfabeticamente A-Z", value: 'nameAsc' }, 
        { name: "Alfabeticamente Z-A", value: 'nameDesc' },
        { name: "Últimos agregados", value: 'default' }
    ]

    const getPages = () => {
        const pages = Math.ceil(amountProducts / limit);
        let nroPages = [];
        for (let index = 0; index < pages; index++) {
            nroPages.push(index);
        }
        return nroPages;
    }

    const onChangeSearch = (e) => {
        e.preventDefault();
        setSearch(e.target.value);
    }

    const searchProducts = (e) => {
        const keycode = (e.keyCode ? e.keyCode : e.which);
        if (keycode == '13') {
            getProductList(selectedCat, selectedOrder, 0, principalFilter);
        }
        if(keycode == '27') {
            setSearch('');
        }
        
    }

    const getProductList = (idCat, orderBy, page, tagfilter) => {
        axios.get(`/api/products?idCategory=${idCat}&orderBy=${orderBy}&search=${search}&ecommerce=1&page=${page}&limit=${limit}&tags=${tagfilter}`)
            .then(response => {
                setProductList(response.data.rows);
                setAmountProducts(response.data.total);
            })
            .catch(err => {
                alert('Hubo un error al obtener los productos V2...');
            })
    }

    const onFilter = () => {
        SetShowFilter(!showFilter)
    }

    const handleChangeCat = (e) => {
        e.preventDefault();
        const {value} = e.target;
        setSelectedCat(value);
        getProductList(value, selectedOrder, 0, principalFilter);
    }

    const handleChangeOrder = (e) => {
        e.preventDefault();
        const {value} = e.target;
        setSelectedOrder(value);
        getProductList(selectedCat, value, page, principalFilter);
    }

    const backPage = () => {
        if(page > 0){
            setPage(page - 1);
            getProductList(selectedCat, selectedOrder, page - 1, principalFilter )
        }
    }

    const jumpPage = (e) => {
        const {index} = e.target.dataset;
        if(index != page){
            setPage(parseInt(index));
            getProductList(selectedCat, selectedOrder, parseInt(index), principalFilter );
        }
    }

    const forwardPage = () => {
        if(page < getPages().length - 1){
            setPage(page + 1);
            getProductList(selectedCat, selectedOrder, page + 1, principalFilter )
        }
    }

    React.useEffect(() => {
        if(!data){
            let configHeader = {
                headers: { ...authHeader(), 'Content-Type': 'application/json' }
            }
            axios.get('/api/sections/' + idsection,configHeader)
            .then(sectionData => {
                setData(sectionData.data.section.data);
                const filterProducts = sectionData.data.section.data ? sectionData.data.section.data.tags : "";
                setPrincipalFilter(filterProducts);
                if(!productList){
                    getProductList(selectedCat, selectedOrder, page, filterProducts);
                }
                if(!categories){
                    axios.get(`/api/category`)
                    .then(categoriesData => {
                        setCategories(categoriesData.data.categories);
                    })
                    .catch(err => {
                        console.log(err);
                    })
                }
            })
            .catch(err => {
                alert('Hubo un error...');
            })
        }
    }, [data, idsection])

    React.useEffect(() => {
        
    }, [])

    return(
        <Root>
            <Header>
                <Breadcrumbs><span>Inicio</span><ArrowForwardIosIcon /><span style={{fontWeight: 400}}>{data ? data.title : "Productos"}</span></Breadcrumbs>
                <HeaderTitle>{data ? data.title : "Productos"}</HeaderTitle>
            </Header>
            <FilterZone>
                <CatFilter>
                    <span>FILTRAR POR: </span>
                    <StyledFormControl size="small" variant="outlined" >
                        {/* <InputLabel id="catfilter-label">CATEGORÍA</InputLabel> */}
                        <Select
                        labelId="catfilter-label"
                        id="catfilter-outlined"
                        value={selectedCat}
                        onChange={handleChangeCat}
                        /* label="CATEGORÍA" */
                        >
                        <MenuItem value={-1}>
                            CATEGORÍA
                        </MenuItem>
                        {
                            categories && categories.map((cat, index) => 
                                cat.idCategory !== 0 &&
                                <MenuItem key={`cat_${index}`} value={cat.idCategory}>{cat.name}</MenuItem>
                            )
                        }
                        </Select>
                    </StyledFormControl>
                </CatFilter>
                <Search 
                    id="search" 
                    name="search" 
                    size="small" 
                    variant="outlined" 
                    onChange={onChangeSearch}
                    value={search}
                    onKeyPress={searchProducts}
                    placeholder="Buscar producto"
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <SearchOutlined />
                        </InputAdornment>
                        ),
                    }}
                />
                <OrderFilter>
                    <span>ORDENAR POR: </span>
                    <StyledFormControl size="small" variant="outlined" >
                        {/* <InputLabel id="orderfilter-label">ORDEN</InputLabel> */}
                        <Select
                        labelId="orderfilter-label"
                        id="orderfilter-outlined"
                        value={selectedOrder}
                        onChange={handleChangeOrder}
                        /* label="ORDEN" */
                        >
                        {
                            orderOptions && orderOptions.map((ord, index) => 
                                <MenuItem key={`ord_${index}`} value={ord.value}>{ord.name}</MenuItem>
                            )
                        }
                        </Select>
                    </StyledFormControl>
                </OrderFilter>
            </FilterZone>
            <ProductsGrid>
                {
                    productList && productList.length > 0 && productList.map((product, index) => 
                    <ContentProductCard key={`product_${index}_${product.name}`}>
                        <span>{product.id_Category ? product.id_Category.name : 'Categoría'}</span>
                        <ProductCard productInfo={product} />
                    </ContentProductCard>
                    )
                }
            </ProductsGrid>
            <Pagination>
                {
                    productList && productList.length > 0 &&
                    <StyledArrowBackIosIcon show={page !== 0} onClick={backPage} />
                }
                {
                    productList && productList.length > 0 && getPages().map((p, index) =>
                        (Math.abs(page - p) < PAGINATION_LIMIT) &&
                        <Dot key={`dot_${index}`} data-index={p} selected={p === page} onClick={jumpPage}>{p+1}</Dot>
                    )
                }
                {
                    productList && productList.length > 0 &&
                    <StyledArrowForwardIosIcon show={page !== getPages().length - 1} onClick={forwardPage} />
                }
                {
                    productList && productList.length > 0 &&
                    <InfoPage>{`Pág. ${page+1} / ${getPages().length}`}</InfoPage>
                }
            </Pagination>
        </Root>
    );
}

export default CategoryProducts;