/* General imports */
import React from 'react';
import styled from 'styled-components';
import TextField from '../form/TextField';
import axios from 'axios';
import  { authHeader } from '../app/auth-header';
import { config } from '../app/config';
import { pointSaleConstants } from './PointSaleConstants';

/* Styled components */
const SaleParams = styled.div`
    display: flex;
    position: relative;
    width: 95%;
    margin: 10px 0;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-around;
`

const Param = styled.div`
    display: flex;
    position: relative;
    width: 30%;
    margin: 0;
    box-sizing: border-box;
    padding: 5px;
`

const MetaSale = (props) => {

    const [storages, setStorages] = React.useState(null);

    const [ customers, setCustomers ] = React.useState(null);
    const [ paymentMethods, setPaymentMethods ] = React.useState(null);

    const handleChange = (name, value) => {
        props.handleChange(name, value)
    }

    React.useEffect(() => {
        let configHeader = {
            headers: { ...authHeader(), 'Content-Type': 'application/json' }
        }
        if(!storages){
            axios.get(`/api/storages`,configHeader)
            .then(storagesData => {
                const storagesList = [];
                storagesData.data.storages.forEach(element => {
                    storagesList.push({
                        name: element.name,
                        value: element.idStorage
                    })
                });
                setStorages(storagesList);
            })
            .catch(err => {
                console.log(err);
            })
        }
    }, [ storages ])

    React.useEffect(() => {
        let configHeader = {
            headers: { ...authHeader(), 'Content-Type': 'application/json' }
        }
        if(!customers){
            axios.get(`/api/users/role/${config.idCustomerRole}`,configHeader)
            .then(customersData => {
                const customerList = [];
                customersData.data.users.forEach(element => {
                    customerList.push({
                        name: `${element.firstname} ${element.lastname}`,
                        value: element.idUser
                    })
                });
                setCustomers(customerList);
            })
            .catch(err => {
                console.log(err);
            })
        }
    }, [ customers ])


    React.useEffect(() => {
        let configHeader = {
            headers: { ...authHeader(), 'Content-Type': 'application/json' }
        }
        if(!paymentMethods){
            axios.get(`/api/valuelist/group/${pointSaleConstants.SALECONDITIONS_GROUP}`,configHeader)
            .then(saleConditionsData => {
                const saleConditionsList = [];
                saleConditionsData.data.valueslist.forEach(option => {
                    saleConditionsList.push({
                        name: option.value,
                        key: option.key,
                        value: option.idValueList
                    })
                });
                setPaymentMethods(saleConditionsList);
            })
            .catch(err => {
                console.log(err);
            })
        }
    }, [ paymentMethods ])
    
    return (
        <SaleParams>
            <Param>
                <TextField id="customer" value={props.metaData.customer} required={true} name="customer" placeholder="Cliente" options={customers} type="select" fontSize="14px" onChange={handleChange} />    
            </Param>
            <Param>
                <TextField id="storage" value={props.metaData.storage} required={true} name="storage" placeholder="Punto de venta" options={storages} type="select" fontSize="14px" onChange={handleChange} />    
            </Param>
            <Param>
                <TextField id="observation" value={props.metaData.observation} name="observation" placeholder="Observación" type="textarea" fontSize="14px" onChange={handleChange} />    
            </Param>
            <Param>
                <TextField id="saleDate" value={props.metaData.saleDate} required={true} name="saleDate" placeholder="Fecha" type="date" fontSize="14px" onChange={handleChange} />    
            </Param>
            <Param>
                <TextField id="paymentMethod" value={props.metaData.paymentMethod} required={true} name="paymentMethod" placeholder="Forma de pago" options={paymentMethods} type="select" fontSize="14px" onChange={handleChange} />    
            </Param>
            <Param></Param>
        </SaleParams>
    );
}

export default MetaSale;