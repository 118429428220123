/* General imports */
import React from 'react'
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { devices } from '../../app/config';
import axios from 'axios'
import  { authHeader } from '../../app/auth-header';

/* Components imports */
import Spinner from '../../spinners/LoaderSpinner'
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import ReactPlayer from 'react-player/lazy'
import FullscreenIcon from '@material-ui/icons/Fullscreen';

/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled Components */
const Root = styled.div`
    width:100%;
    height:auto;
    display: flex;
    justify-content: center;
    position: relative;
    font-family: 'IBM Plex Sans';
    background-color: #FFF;
`

const Row = styled.div`
    width:90%;
    height: ${(props) => props.height ? props.height : '70vh'};
    display:flex;
    align-items: center;
    flex-direction: ${(props) => props.flexDirection ? props.flexDirection : 'row'};
`;

const Column = styled.div`
    width: ${(props) => props.width ? props.width : '50%'};
    height:100%;
    box-sizing: border-box;
    display:flex;
    justify-content:center;
    align-items: ${(props) => props.alignItems ? props.alignItems : "flex-start"};
    flex-direction:column;
    padding: ${(props) => props.padding ? props.padding : 0};
    color: #000A12;
    font-size: 1.25rem;
    text-align: left;
    font-family: 'IBM Plex Sans';
    font-weight: 300;
    h3 {
        width: 100%;
        color: #001554;
        text-align: left;
        font-family: 'IBM Plex Sans';
        font-weight: 300;
        font-size: 2rem;
        margin: 10px 0 0 0;
    }
    span {
        width: 100%;
        font-size: 0.85rem;
    }
    @media (max-width: 600px) {
        align-items: center;
        h3 {
            width: 100%;
            font-size: 2rem;
            text-align: center;
            margin: 10px 0;
        }
    }
`

const ColumnImage = styled.img`
    width: 100%;
    height: 100%;
    max-width: 100vw;
    object-fit: contain;
    object-position: center;
    box-sizing: border-box;
    display:flex;
`

const ContentColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    font-size: 0.85rem;
`

const Button = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 216px;
    height: 48px;
    font-size: 20px;
    background: #001554;
    border-radius: 24px;
    color: #FFF;
    margin: 50px 0 20px 0;
    cursor: pointer;
    text-decoration: none;
    @media (max-width: 600px) {
        position: absolute;
        bottom: 70px;
        left: 50%;
        transform: translateX(-50%);
    }
`

const FullScreen = styled.button`
    align-self: flex-end;
`

/* Principal function*/
/*
Params
title
switchColumns
text
img
*/
function TwoColumsVideo({idsection}) {

    const [twoColumnsData, setTwoColumnsData] = React.useState(null);

    const handleClickFullscreen = () => {
        var el = document.getElementById("full-screenVideo");
            if (el.requestFullscreen) {
            el.requestFullscreen();
            } else if (el.msRequestFullscreen) {
            el.msRequestFullscreen();
            } else if (el.mozRequestFullScreen) {
            el.mozRequestFullScreen();
            } else if (el.webkitRequestFullscreen) {
            el.webkitRequestFullscreen();
            }
      };

    React.useEffect(() => {
        if(!twoColumnsData){
            let configHeader = {
                headers: { ...authHeader(), 'Content-Type': 'application/json' }
            }
            axios.get('/api/sections/' + idsection,configHeader)
            .then(sectionData => {
                setTwoColumnsData(sectionData.data.section.data);
            })
            .catch(err => {
                alert('Hubo un error...');
            })
        }
    }, [twoColumnsData, idsection])

    return (
        <Root>
            <Desktop>
                {
                    !twoColumnsData ? <Spinner spinnerType='ThreeDots' />
                    :
                    twoColumnsData.switchColumns === 1 ?
                        <Row>
                            <Column width="55%" background='#FFF'>
                                <Zoom duration={2000} delay={500}>
                                    <ContentColumn>
                                        <h3> {twoColumnsData.title} </h3>
                                        <div dangerouslySetInnerHTML={{ __html: twoColumnsData.text }} />
                                        {
                                        twoColumnsData.link && <Button href={twoColumnsData.link}>Conocenos</Button>
                                        }
                                    </ContentColumn>
                                </Zoom>
                            </Column>
                            <Column width="45%" padding="10px 0 10px 50px"  alignItems="center" >
                                <ReactPlayer 
                                    id="full-screenVideo"
                                    url={twoColumnsData.videolink} 
                                    autoPlay 
                                    loop={true} 
                                    muted={true} 
                                    volume={0}
                                    width='100%'
                                    height='80%'
                                    config={{
                                        youtube: {
                                            playerVars: { showinfo: 1, autoplay: 1, loop: 1, fs: 1 }
                                        }
                                    }}
                                />
                                <FullScreen onClick={handleClickFullscreen}><FullscreenIcon /></FullScreen>
                            </Column>
                        </Row>
                        :
                        <Row>
                            <Column width="45%" padding="10px 50px 10px 0" alignItems="center" >
                                <ReactPlayer 
                                    id="full-screenVideo"
                                    url={twoColumnsData.videolink} 
                                    autoPlay 
                                    loop={true} 
                                    muted={true} 
                                    volume={0}
                                    width='100%'
                                    height='80%' 
                                    config={{
                                        youtube: {
                                            playerVars: { showinfo: 0, autoplay: 1, loop: 1 }
                                        }
                                    }}
                                />
                                <FullScreen onClick={handleClickFullscreen}><FullscreenIcon /></FullScreen>
                            </Column>
                            <Column width="55%" padding="10px 50px 10px 0"  background='#FFF'>
                                <Zoom duration={2000} delay={800}>
                                    <ContentColumn>
                                        <h3> {twoColumnsData.title} </h3>
                                        <div dangerouslySetInnerHTML={{ __html: twoColumnsData.text }} />
                                        {
                                        twoColumnsData.link && <Button href={twoColumnsData.link}>Conocenos</Button>
                                        }
                                    </ContentColumn>
                                </Zoom>
                            </Column>
                        </Row>
                }
            </Desktop>
            <Tablet>
                {
                    !twoColumnsData ? <Spinner spinnerType='ThreeDots' />
                    :
                    twoColumnsData.switchColumns === 1 ?
                    <Row>
                        <Column width="45%" padding='0 3rem' background='#FFF' fontSize="0.75rem">
                            <Zoom duration={2000} delay={500}>
                                <ContentColumn>
                                    <h3> {twoColumnsData.title} </h3>
                                    <span>{twoColumnsData.text}</span>
                                    {
                                    twoColumnsData.link && <Button href={twoColumnsData.link}>Conocenos</Button>
                                    }
                                </ContentColumn>
                            </Zoom>
                        </Column>
                        <Column width="55%" alignItems="center" >
                            <ReactPlayer 
                                id="full-screenVideo"
                                url={twoColumnsData.videolink} 
                                autoPlay 
                                loop={true} 
                                muted={true} 
                                volume={0}
                                width='100%'
                                height='400px' 
                                config={{
                                    youtube: {
                                        playerVars: { showinfo: 0, autoplay: 1, loop: 1 }
                                    }
                                }}
                            />
                            <FullScreen onClick={handleClickFullscreen}><FullscreenIcon /></FullScreen>
                        </Column>
                    </Row>
                    :
                    <Row>
                        <Column width="55%" alignItems="center" >
                            <ReactPlayer 
                                id="full-screenVideo"
                                url={twoColumnsData.videolink} 
                                autoPlay 
                                loop={true} 
                                muted={true} 
                                volume={0}
                                width='100%'
                                height='400px' 
                                config={{
                                    youtube: {
                                        playerVars: { showinfo: 0, autoplay: 1, loop: 1 }
                                    }
                                }}
                            />
                            <FullScreen onClick={handleClickFullscreen}><FullscreenIcon /></FullScreen>
                        </Column>
                        <Column width="45%" padding='0 3rem' background='#FFF' fontSize="0.75rem">
                            <Zoom duration={2000} delay={800}>
                                <ContentColumn>
                                    <h3> {twoColumnsData.title} </h3>
                                    <span>{twoColumnsData.text}</span>
                                    {
                                    twoColumnsData.link && <Button href={twoColumnsData.link}>Conocenos</Button>
                                    }
                                </ContentColumn>
                            </Zoom>
                        </Column>
                    </Row>
                }
            </Tablet>
            <Mobile>
                {
                    !twoColumnsData ? <Spinner spinnerType='ThreeDots' />
                    :
                    <Row height='auto' flexDirection='column'>
                        <Column width='100%' padding='0' background='#FFF' fontSize='0.75rem'>
                            <Zoom duration={2000} delay={800}>
                                <ContentColumn>
                                    <h3> {twoColumnsData.title} </h3>
                                    <div dangerouslySetInnerHTML={{ __html: twoColumnsData.text }} />
                                </ContentColumn>
                            </Zoom>
                        </Column>
                        <Column width='100%' padding="0" >
                            <ReactPlayer 
                                id="full-screenVideo"
                                url={twoColumnsData.videolink} 
                                autoPlay 
                                loop={true} 
                                muted={true} 
                                volume={0}
                                width='100%'
                                height='250px' 
                                config={{
                                    youtube: {
                                        playerVars: { showinfo: 0, autoplay: 1, loop: 1 }
                                    }
                                }}
                            />
                            <FullScreen onClick={handleClickFullscreen}><FullscreenIcon /></FullScreen>
                            <Fade bottom duration={2000} delay={300}>
                                {
                                    twoColumnsData.link && <Button href={twoColumnsData.link}>Conocenos</Button>
                                }
                            </Fade>
                        </Column>
                    </Row>
                }
            </Mobile>
        </Root>
    )
}

export default TwoColumsVideo;