/* General imports */
import React from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { devices } from '../../app/config'
import axios from 'axios'
import  { authHeader } from '../../app/auth-header';


/* Components imports */
import Spinner from '../../spinners/LoaderSpinner'

/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled components */
const Root = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 30vh;
    align-items: center;
    justify-content: center;
`

const ZoneButtons = styled.div`
    position: absolute;
    top: -50px;
    display: flex;
    height: 200px;
    z-index: 999;
    box-sizing: border-box;
`

const Button = styled.a`
    position: relative;
    text-decoration: none;
    user-select: none;
    display: none;
    height: 100%;
    width: ${(props) => props.width ? props.width : '50%'};
    align-items: center;
    justify-content: center;
    border-radius: ${(props) => props.borderRadius ? props.borderRadius === 'left' ? '10px 0 0 10px' : '0 10px 10px 0' : 0};
    z-index: 995;
    opacity: 0;
    background: ${(props) => props.bg === 0 ? 'linear-gradient(0deg, rgba(150,173,47,1) 34%, rgba(0,65,113,1) 100%)' : props.bg === 1 ? 'linear-gradient(0deg, rgba(150,173,47,1) 34%, rgba(0,85,149,1) 100%)' : 'linear-gradient(0deg, rgba(150,173,47,1) 34%, rgba(0,107,185,1) 100%)' };
    transition: all .5s ease-in-out;
    color: white;
    &:hover {
        background: ${(props) => props.bg === 0 ? 'linear-gradient(0deg, rgba(0,65,113,1) 34%, rgba(150,173,47,1) 100%)' : props.bg === 1 ? 'linear-gradient(0deg, rgba(0,85,149,1) 34%, rgba(150,173,47,1) 100%)' : 'linear-gradient(0deg, rgba(0,107,185,1) 34%, rgba(150,173,47,1) 100%)' };
    }
    h3 {
        font-size: 30px;
    }
`

const WrapButton = styled.div`
    position: relative;
    display: flex;
    height: 100%;
    width: 300px;
    box-sizing: border-box;
    border-radius: ${(props) => props.borderRadius ? props.borderRadius === 'left' ? '10px 0 0 10px' : '0 10px 10px 0' : 0};
    transition: all .5s ease-in-out;
    box-shadow: 5px 5px 15px black;
    align-items: center;
    justify-content: center;

    h2 {
        display: flex;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -100%);
        opacity: 1;
        z-index: 999;
        transition: all .5s ease-in-out;
        color: white;
        text-align: center;
        font-size: 30px;
    }

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgb(130,162,185);
        background: ${(props) => props.bg === 0 ? 'linear-gradient(0deg, rgba(130,162,185,1) 0%, rgba(0,65,113,1) 100%)' : props.bg === 1 ? 'linear-gradient(0deg, rgba(130,162,185,1) 0%, rgba(0,85,149,1) 100%)' : 'linear-gradient(0deg, rgba(130,162,185,1) 0%, rgba(0,107,185,1) 100%)' } ;
        opacity: 1;
        border-radius: ${(props) => props.borderRadius ? props.borderRadius === 'left' ? '10px 0 0 10px' : '0 10px 10px 0' : 0};
        font-size: 20px;
        transition: all .5s ease-in-out;
        z-index: 998;
    }
    &:hover {
        width: 400px;
        &::before {
            opacity: 0;
            z-index: 990;
        }
        ${Button} {
            display: flex;
            opacity:1;
        }
        h2 {
            opacity: 0;
            display: none;
        }
    }
`

const ImageContainer = styled.div`
    position: relative;
    display: flex;
    width: 60%;
    height: 60%;
    img {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`

/* ----- Principal function ----- */
function ButtonsAccess({idsection}) {
    const [buttonsData, setButtonsData] = React.useState(null)

    React.useEffect(() => {
        if(!buttonsData){
            let config = {
                headers: { ...authHeader(), 'Content-Type': 'application/json' }
            }
            axios.get('/api/sections/' + idsection,config)
            .then(sectionData => {
                setButtonsData(sectionData.data.section.data);
            })
            .catch(err => {
                alert('Hubo un error...');
            })
        }
    }, [buttonsData, idsection])

    return(
        <Root>
            <Desktop>
                <Root>
                    {
                        !buttonsData ? <Spinner spinnerType='ThreeDots' />
                        :
                        <ZoneButtons>
                            {
                                buttonsData.buttontype1 === 1 ? 
                                    <WrapButton borderRadius='left' bg={0}>
                                        <h2>{buttonsData.groupName1}</h2>
                                        <Button borderRadius='left' href={buttonsData.link1} bg={0}>
                                        {
                                                buttonsData.img1 ?
                                                <ImageContainer>
                                                    <img src={buttonsData.img1} alt={buttonsData.text1} />
                                                </ImageContainer>
                                                :
                                                <h3>{ buttonsData.text1 }</h3>
                                            }
                                        </Button>
                                        <Button href={buttonsData.link2} bg={0}>
                                            {
                                                buttonsData.img2 ?
                                                <ImageContainer>
                                                    <img src={buttonsData.img2} alt={buttonsData.text2} />
                                                </ImageContainer>
                                                :
                                                <h3>{ buttonsData.text2 }</h3>
                                            }
                                        </Button>
                                    </WrapButton>
                                : 
                                <WrapButton borderRadius='left' bg={0}>
                                    <h2>{buttonsData.groupName1}</h2>
                                    <Button borderRadius='left' width='100%' href={buttonsData.link1} bg={0}>
                                        {
                                            buttonsData.img1 ?
                                            <ImageContainer>
                                                <img src={buttonsData.img1} alt={buttonsData.text1} />
                                            </ImageContainer>
                                            :
                                            <h3>{ buttonsData.text1 }</h3>
                                        }
                                    </Button>
                                </WrapButton>
                            }
                            {
                                buttonsData.buttontype2 === 1 ? 
                                    <WrapButton bg={1}>
                                        <h2>{buttonsData.groupName2}</h2>
                                        <Button href={buttonsData.link3} bg={1}>
                                            {
                                                buttonsData.img3 ?
                                                <ImageContainer>
                                                    <img src={buttonsData.img3} alt={buttonsData.text3} />
                                                </ImageContainer>
                                                :
                                                <h3>{ buttonsData.text3 }</h3>
                                            }
                                        </Button>
                                        <Button href={buttonsData.link4} bg={1}>
                                            {
                                                buttonsData.img4 ?
                                                <ImageContainer>
                                                    <img src={buttonsData.img4} alt={buttonsData.text4} />
                                                </ImageContainer>
                                                :
                                                <h3>{ buttonsData.text4 }</h3>
                                            }
                                        </Button>
                                    </WrapButton>
                                :
                                <WrapButton bg={1}>
                                    <h2>{buttonsData.groupName2}</h2>
                                    <Button width='100%' href={buttonsData.link3} bg={1}>
                                        {
                                            buttonsData.img3 ?
                                            <ImageContainer>
                                                <img src={buttonsData.img3} alt={buttonsData.text3} />
                                            </ImageContainer>
                                            :
                                            <h3>{ buttonsData.text3 }</h3>
                                        }
                                    </Button>
                                </WrapButton>
                            }
                            {
                                buttonsData.buttontype3 === 1 ?
                                    <WrapButton borderRadius='right' bg={2}>
                                        <h2>{buttonsData.groupName3}</h2>
                                        <Button href={buttonsData.link5} bg={2}>
                                            {
                                                buttonsData.img5 ?
                                                <ImageContainer>
                                                    <img src={buttonsData.img5} alt={buttonsData.text5} />
                                                </ImageContainer>
                                                :
                                                <h3>{ buttonsData.text5 }</h3>
                                            }
                                        </Button>
                                        <Button borderRadius='right' href={buttonsData.link6} bg={2}>
                                            {
                                                buttonsData.img6 ?
                                                <ImageContainer>
                                                    <img src={buttonsData.img6} alt={buttonsData.text6} />
                                                </ImageContainer>
                                                :
                                                <h3>{ buttonsData.text6 }</h3>
                                            }
                                        </Button>
                                    </WrapButton>
                                :
                                <WrapButton borderRadius='right' bg={2}>
                                    <h2>{buttonsData.groupName3}</h2>
                                    <Button width='100%' href={buttonsData.link5} bg={2}>
                                        {
                                            buttonsData.img5 ?
                                            <ImageContainer>
                                                <img src={buttonsData.img5} alt={buttonsData.text5} />
                                            </ImageContainer>
                                            :
                                            <h3>{ buttonsData.text5 }</h3>
                                        }
                                    </Button>
                                </WrapButton>
                            }
                        </ZoneButtons>
                    }
                </Root>
            </Desktop>
            <Tablet>
                Tablet - En construcción
            </Tablet>
            <Mobile>
                Mobile - En construcción
            </Mobile>
        </Root>
    );
}

export default ButtonsAccess;