/* General imports */
import React from 'react'
import styled from 'styled-components';
import axios from 'axios'
import  { authHeader } from '../../app/auth-header';

/* Components imports */
import Zoom from 'react-reveal/Zoom';


/* Styled Components */
const Root = styled.div`
    width: 100%;
    height: 50vh;
    background: #FFF;
    display: grid;
    box-sizing: border-box;
    padding: 50px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 70px 200px;
    gap: 10px;
    position:relative;
    font-family: 'IBM Plex Sans';
    @media (max-width: 600px) {
        height: auto;
        padding: 20px 10px;
        grid-template-rows: 70px auto;
    }
`

const Box = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    ${(props) => props.gridColumn ? `grid-column: ${props.gridColumn};` : ''}
    @media (max-width: 600px) {
        flex-direction: column;
        justify-content: flex-start;
    }
`

const Title = styled.h2`
    width: 100%;
    text-align: center;
    font-weight: 300;
    font-size: 2.5rem;
    color: #001554;
    margin: auto 0;
    @media (max-width: 600px) {
        font-size: 1.8rem;
    }
`

const Icon = styled.img`
    width: 100px;
    height: 100px;
    margin: 0 20px;
    @media (max-width: 600px) {
        width: 65px;
        height: 65px;
    }
`

const Text = styled.span`
    position: relative;
    display: flex;
    font-size: 1.5rem;
    color: #000A12;
    width: 230px;
    font-weight: 300;
    @media (max-width: 600px) {
        width: 100%;
        font-size: 0.8rem;
        text-align: center;
    }
`


function ThreeDataIcon({idsection}) {

    const [threeServicesData, setThreeServicesData] = React.useState(null)

    React.useEffect(() => {
        if(!threeServicesData){
            let configHeader = {
                headers: { ...authHeader(), 'Content-Type': 'application/json' }
            }
            axios.get('/api/sections/' + idsection,configHeader)
            .then(sectionData => {
                setThreeServicesData(sectionData.data.section.data);
            })
            .catch(err => {
                alert('Hubo un error...');
            })
        }
    }, [threeServicesData, idsection])

    return (
        threeServicesData &&
        <Root>
            <Box gridColumn="1/4">
                <Zoom delay={300}>
                    <Title>{threeServicesData.title}</Title>
                </Zoom>
            </Box>
            <Box>
                <Zoom delay={800}>
                    <Icon src={threeServicesData.img1} alt="icon_1" />
                    <Text>{threeServicesData.text1}</Text>
                </Zoom>
            </Box>
            <Box>
                <Zoom delay={800}>
                    <Icon src={threeServicesData.img2} alt="icon_2" />
                    <Text>{threeServicesData.text2}</Text>
                </Zoom>
            </Box>
            <Box>
                <Zoom delay={800}>
                    <Icon src={threeServicesData.img3} alt="icon_3" />
                    <Text>{threeServicesData.text3}</Text>
                </Zoom>
            </Box>
        </Root>
    )
}

export default ThreeDataIcon;