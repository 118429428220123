/* General imports */
import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { alertActions } from '../../alert/AlertActions';
import axios from 'axios';

/* Components imports */
import Table from '../../tables/AsyncTable';
import ReportsIcon from '../../icons/reports.svg';
import ModalSale from './ModalSale';

/* Styled components */
const Root = styled.div`
    width: ${(props) => props.width ? props.width : '100%'};
    height: 100%;
    padding: ${(props) => props.padding ? props.padding : 0};
    box-sizing: border-box;
`

const Header = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 150px;
    background: linear-gradient(to left, #122230 0%, #192d3e 100%);
    align-items: flex-start;
    padding: 35px 35px 70px 35px;
    box-sizing: border-box;
`

const Title = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img {
        position: relative;
        display: flex;
        width: 30px;
        height: 30px;
    }
    h6 {
        position: relative;
        display: flex;
        margin: 0 20px;
        color: white;
        font-size: 1.5rem;
        font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
        font-weight: 400;
    }
`

const Content = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: calc(100% - 150px);
    padding: 0 35px;
    box-sizing: border-box;
    top: -64px;
`

/* ----- Principal function ----- */
function Sales({ user, alertMessage, history }) {

    const columsTable = [
        { title: 'ID', field: 'idSale', width: 50, editable: 'never' },
        { title: 'Fecha', field: 'saleDate', width: 100, type: 'datetime', editable: 'never'},
        { title: 'ID cliente', field: 'idUserClient', hidden: true, editable: 'never' },
        { title: 'Nombre cliente', field: 'idUser_Client.firstname', width: 100, editable: 'never' },
        { title: 'Apellido cliente', field: 'idUser_Client.lastname', width: 100, editable: 'never' },
        { title: 'ID vendedor', field: 'idUserClient', hidden: true, editable: 'never' },
        { title: 'Nombre vendedor', field: 'idUser_Seller.firstname', width: 100, editable: 'never' },
        { title: 'Apellido vendedor', field: 'idUser_Seller.lastname', width: 100, editable: 'never' },
        { title: 'Cond. pago', field: 'sale_condition.value', hidden: true, editable: 'never' },
        { title: 'Observación', field: 'observation', width: 100},
        { title: 'Subtotal', field: 'subTotal', width: 100, type: 'currency', editable: 'never' },
        { title: 'Descuento', field: 'discount', width: 100, type: 'numeric', editable: 'never' },
        { title: 'Total', field: 'totalAmount', width: 100, type: 'currency', editable: 'never' }
    ]

    const [open, setOpen] = React.useState(false);

    const [ sale, setSale ] = React.useState(null);

    const tableRef = React.useRef();

    const handleView = (rowData) => {
        setSale(rowData.idSale);
        setOpen(true);
    }

    const handleDelete = (rowData) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("¿Desea eliminar la venta "+rowData.idSale+"?")) {
            axios
            .delete( '/api/sales/' + rowData.idSale)
            .then(response => {
                tableRef.current.onQueryChange();
            })
            .catch(error => {
                alert("Hubo un error...")
            })
        }
    }

    const handleClose = () => {
        tableRef.current.onQueryChange()
        setOpen(false);
    }

    return(
        <Root>
            <Header>
                <Title>
                    <img src={ReportsIcon} alt='Icon' />
                    <h6>Ventas</h6>
                </Title>
            </Header>
            <Content>
                <Route path={'/admin/reports/sales'} exact={true} >
                    <Table 
                    idName="idSale"
                    urlapi="/api/sales" 
                    cellEditable={true}
                    urlapi_celledit={`/api/sales/field`}
                    tableRef={tableRef} 
                    autoExport={true}  
                    handleView={handleView} 
                    hiddenNew={true} 
                    hiddenEdit={true} 
                    handleDelete={handleDelete}  
                    title="VENTAS REALIZADAS" 
                    columnsTable={columsTable} />   
                    {
                        open && sale && <ModalSale sale={sale} openModal={open} closeModal={handleClose} />
                    }
                </Route>
            </Content>
        </Root>
    );
}

function mapState(state) {
    const { user } = state.login;
    return { user};
}

const actionCreators = {
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(Sales);