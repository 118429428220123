/* General imports */
import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { theme, devices, config } from '../app/config'
import { sidebarCartActions } from './SidebarCartActions';
import { alertActions } from '../alert/AlertActions'

/* Components imports */
import CloseIcon from '@material-ui/icons/Close';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import NoImage from '../images/no-image.png';


/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled components */
const Root = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: ${(props) => props.open ? "100vw" : 0};
    height: 100vh;
    z-index: 999999;
    overflow: hidden;
`

const Smooth = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
`
const Sidebar = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    top: 0;
    right: 0;
    width: ${(props) => props.open ? props.mobile ? "100%" : "400px" : 0};
    height: 100%;
    background: white;
    z-index: 9999999;
    transition: all 300ms ease-in-out;
`

const Header = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100px;
    color: white;
    background: black;
    h2 {
        font-size: 20px;
        font-weight: 400;
        user-select: none;
    }
`

const ItemsList = styled.ul`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    height: calc(100% - 300px);
`

const Footer = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 200px;
`

const Subtotal = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100px;
    box-sizing: border-box;
    padding: 0 20px;
    h4 {
        margin: 5px 0;
        color: #3d3d3d;
        font-size: 20px;
        font-weight: 400;
    }
    h5 {
        margin: 0;
        font-size: 16px;
        font-weight: 300;
    }
`

const Hr = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    height: 1px;
    background: black;
    opacity: 0.5;
`

const Checkout = styled.a`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: black;
    border: 1px solid black;
    border-radius: 10px;
    width: 80%;
    box-sizing: border-box;
    padding: 10px 20px;
    margin: 20px 0;
    cursor: pointer;
`

const CancelItem = styled(HighlightOffOutlinedIcon)`
    display: flex;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    z-index: 9999999;
    opacity: 0;
    transition: opacity 500ms ease-in-out;
`

const Item = styled.li`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 80%;
    height: auto;
    box-sizing: border-box;
    padding: 20px 0;
    border-bottom: 1px solid  rgba(0,0,0,0.1);
    &:hover ${CancelItem} {
        opacity: 1;
    }
`

const ItemImage = styled.img`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
`

const ItemData = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: calc(100% - 100px);
    box-sizing: border-box;
    padding: 0 20px;
    span {
        margin: 2px 0;
        font-size: 12px;
    }
    input {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 5px;
        box-sizing: border-box;
        margin: 5px 0;
        width: 50px;
    }
`

const Close = styled(CloseIcon)`
    display: flex;
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    cursor: pointer;
`

/* ----- Principal function ----- */
function SidebarCart({open, switchSidebarCart, cartitems, removeToCart, setAmount, history, alertMessage}) {

    const removeItem = (e) => {
        const { index } = e.target.dataset;
        removeToCart(index);
    }

    const setAmountItem = (e) => {
        if(e.target.value > 0){
            const { index } = e.target.dataset;
            setAmount(index, e.target.value);
        }
    }

    const getSubtotal = () => {
        let subtotal = 0;
        cartitems.forEach(element => {
            subtotal = subtotal + (element.amount * element.salePrice);
        });
        return subtotal;
    }

    return(
        <React.Fragment>
            <Desktop>
                <Root open={open}>
                    <Smooth />
                    <Sidebar open={open}>
                        <Header>
                            <Close onClick={switchSidebarCart} />
                            <h2>Carrito</h2>
                        </Header>
                        <ItemsList>
                            {
                                cartitems.map((cartItem, index) => 
                                <Item>
                                    <CancelItem data-index={index} onClick={removeItem}  />
                                    <ItemImage src={cartItem.img && cartItem.img.images && cartItem.img.images.length > 0 ? cartItem.img.images[0] : NoImage} alt={`item_${index}`} />
                                    <ItemData>
                                        <span>{cartItem.name}</span>
                                        <span><strong>${cartItem.salePrice}</strong></span>
                                        <input type="number" data-index={index} value={cartItem.amount} onChange={setAmountItem} />
                                    </ItemData>
                                </Item>   
                                )
                            }
                        </ItemsList>
                        <Footer>
                            <Subtotal>
                                <h4>Subtotal:</h4>
                                <h5>${getSubtotal()}</h5>
                            </Subtotal>
                            <Hr />
                            <Checkout href={`${config.url}/checkout`}>COMPRAR</Checkout>
                        </Footer>
                    </Sidebar>
                </Root>
            </Desktop>
            <Tablet>
                <Root open={open}>
                    <Smooth />
                    <Sidebar open={open}>
                        <Header>
                            <Close onClick={switchSidebarCart} />
                            <h2>Carrito</h2>
                        </Header>
                        <ItemsList>
                            {
                                cartitems.map((cartItem, index) => 
                                <Item>
                                    <CancelItem data-index={index} onClick={removeItem}  />
                                    <ItemImage src={cartItem.img && cartItem.img.images && cartItem.img.images.length > 0 ? cartItem.img.images[0] : NoImage} alt={`item_${index}`} />
                                    <ItemData>
                                        <span>{cartItem.name}</span>
                                        <span><strong>${cartItem.salePrice}</strong></span>
                                        <input type="number" data-index={index} value={cartItem.amount} onChange={setAmountItem} />
                                    </ItemData>
                                </Item>   
                                )
                            }
                        </ItemsList>
                        <Footer>
                            <Subtotal>
                                <h4>Subtotal:</h4>
                                <h5>${getSubtotal()}</h5>
                            </Subtotal>
                            <Hr />
                            <Checkout href={`${config.url}/checkout`}>COMPRAR</Checkout>
                        </Footer>
                    </Sidebar>
                </Root>
            </Tablet>
            <Mobile>
                <Root open={open}>
                    <Smooth />
                    <Sidebar open={open} mobile={true}>
                        <Header>
                            <Close onClick={switchSidebarCart} />
                            <h2>Carrito</h2>
                        </Header>
                        <ItemsList>
                            {
                                cartitems.map((cartItem, index) => 
                                <Item>
                                    <CancelItem data-index={index} onClick={removeItem}  />
                                    <ItemImage src={cartItem.img && cartItem.img.images && cartItem.img.images.length > 0 ? cartItem.img.images[0] : NoImage} alt={`item_${index}`} />
                                    <ItemData>
                                        <span>{cartItem.name}</span>
                                        <span><strong>${cartItem.salePrice}</strong></span>
                                        <input type="number" data-index={index} value={cartItem.amount} onChange={setAmountItem} />
                                    </ItemData>
                                </Item>   
                                )
                            }
                        </ItemsList>
                        <Footer>
                            <Subtotal>
                                <h4>Subtotal:</h4>
                                <h5>${getSubtotal()}</h5>
                            </Subtotal>
                            <Hr />
                            <Checkout href={`${config.url}/checkout`}>COMPRAR</Checkout>
                        </Footer>
                    </Sidebar>
                </Root>
            </Mobile>
        </React.Fragment>
    );
}

function mapState(state) {
    const { error, isFetching, open, cartitems} = state.sidebarcart;
    return { error, isFetching, open, cartitems};
}

const actionCreators = {
    switchSidebarCart: sidebarCartActions.switchSidebarCart,
    removeToCart: sidebarCartActions.removeToCart,
    setAmount: sidebarCartActions.setAmount,
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(SidebarCart);