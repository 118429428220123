/* General imports */
import React from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { devices } from '../app/config'
import { NavLink } from 'react-router-dom'

/* Components imports */

/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled components */
const Nav = styled.nav`
    position: relative;
    width: 100%;
    height: ${(props) => props.height ? props.height : '100px'};;
    padding: 0 25px;
    box-sizing: border-box;
    background: #234;
    transition: all .5s ease;
    ul {
        margin: 0;
        padding: 0;
        display: flex;
        height: 100%;
        li {
            list-style: none;
            cursor: pointer;
            transition: all .5s ease;
        }
        li:hover {
            background: #28435d;
            color: white;
        }
    }
`

const ItemLink = styled(NavLink)`
    position: relative;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    color: white;
    padding: 5px 20px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
    text-shadow: 1px 1px 2px black;
    transition: all .5s ease;

    &::after {
        position: absolute;
        content: '';
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid transparent;
        border-bottom: 10px solid #fff;
        bottom: 0;
        left: 50%;
        transition: all .5s ease;
        transform: translateX(-50%);
        opacity: 0;
        visibility: hidden;
    }

    &.active::after {
        opacity: 1;
        visibility: visible;
    }
`

/* ----- Principal function ----- */
function NavTab(props) {

    return(
        <React.Fragment>
            <Desktop>
                <Nav height={props.height}>
                    <ul>
                        {
                            props.items.map((item, index) =>
                                <li key={`MenuItem${index}`}><ItemLink exact to={item.link} >{item.name}</ItemLink></li>
                            )
                        }
                    </ul>
                </Nav>
            </Desktop>
            <Tablet>
                <Nav height={props.height}>
                    <ul>
                        {
                            props.items.map((item, index) =>
                            <li key={`MenuItem${index}`}><ItemLink exact to={item.link} >{item.name}</ItemLink></li>
                            )
                        }
                    </ul>
                </Nav>
            </Tablet>
            <Mobile>
                <Nav height={props.height}>
                    <ul>
                        {
                            props.items.map((item, index) =>
                                item.icon && <li key={`MenuItem${index}`}><ItemLink exact to={item.link} ><img src={item.icon} alt={`${item.name}Icon`} /></ItemLink></li>
                            )
                        }
                    </ul>
                </Nav>
            </Mobile>
        </React.Fragment>
    );
}

export default NavTab;