/* General imports */
import React from 'react'
import styled from 'styled-components';
import axios from 'axios'
import  { authHeader } from '../../app/auth-header';

/* Components imports */
import Zoom from 'react-reveal/Zoom';


/* Styled Components */
const Root = styled.div`
    width: 100%;
    height: 50vh;
    background: #FFF;
    display: grid;
    box-sizing: border-box;
    padding: 50px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 70px 200px;
    gap: 30px 10px;
    position:relative;
    font-family: 'IBM Plex Sans';
    background: #2196F3;
    @media (max-width: 600px) {
        height: auto;
        padding: 20px 10px;
        grid-template-columns: 1fr;
        grid-template-rows: 70px 200px 200px 200px;
    }
`

const Box = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    ${(props) => props.gridColumn ? `grid-column: ${props.gridColumn};` : ''}
    @media (max-width: 600px) {
        justify-content: flex-start;
        text-align: center;
        grid-column: auto;
    }
`

const Title = styled.h2`
    width: 100%;
    text-align: center;
    font-weight: 400;
    font-size: 2.8rem;
    color: #FFF;
    margin: auto 0;
    @media (max-width: 600px) {
        font-size: 1.8rem;
    }
`

const Icon = styled.img`
    width: 50px;
    height: 50px;
    margin: 0 20px;
    @media (max-width: 600px) {
        width: 100px;
        height: 100px;
    }
`

const Name = styled.span`
    position: relative;
    display: flex;
    font-size: 20px;
    color: #001554;
    width: 230px;
    font-weight: 400;
    justify-content: center;
    text-align: center;
    @media (max-width: 600px) {
        font-size: 1.5rem;
    }
`

const Text = styled.span`
    position: relative;
    display: flex;
    font-size: 1rem;
    color: #FFF;
    width: 230px;
    font-weight: 300;
    justify-content: center;
    text-align: center;
    @media (max-width: 600px) {
        width: 100%;
        font-size: 1.2rem;
        padding: 0 50px;
        box-sizing: border-box;
    }
`


function ThreeVerticalDataIcon({idsection}) {

    const [threeServicesData, setThreeServicesData] = React.useState(null);

    React.useEffect(() => {
        if(!threeServicesData){
            let configHeader = {
                headers: { ...authHeader(), 'Content-Type': 'application/json' }
            }
            axios.get('/api/sections/' + idsection,configHeader)
            .then(sectionData => {
                setThreeServicesData(sectionData.data.section.data);
            })
            .catch(err => {
                alert('Hubo un error...');
            })
        }
    }, [threeServicesData, idsection])

    return (
        threeServicesData &&
        <Root>
            <Box gridColumn="1/4">
                <Zoom delay={300}>
                    <Title>{threeServicesData.title}</Title>
                </Zoom>
            </Box>
            <Box>
                <Zoom delay={800}>
                    <Icon src={threeServicesData.img1} alt="icon_1" />
                    <Name>{threeServicesData.name1}</Name>
                    <Text>{threeServicesData.text1}</Text>
                </Zoom>
            </Box>
            <Box>
                <Zoom delay={800}>
                    <Icon src={threeServicesData.img2} alt="icon_2" />
                    <Name>{threeServicesData.name2}</Name>
                    <Text>{threeServicesData.text2}</Text>
                </Zoom>
            </Box>
            <Box>
                <Zoom delay={800}>
                    <Icon src={threeServicesData.img3} alt="icon_3" />
                    <Name>{threeServicesData.name3}</Name>
                    <Text>{threeServicesData.text3}</Text>
                </Zoom>
            </Box>
        </Root>
    )
}

export default ThreeVerticalDataIcon;