/* General imports */
import React from 'react'
import styled from 'styled-components'
import { theme } from '../app/config'
import axios from 'axios'
import  { authHeader } from '../app/auth-header';

/* Components imports */
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import PhoneIcon from '../icons/phone.svg';
import EmailIcon from '../icons/email.svg';
import LocationIcon from '../icons/location.svg';
import BirthdayIcon from '../icons/birthday.svg';
import Spinner from '../spinners/LoaderSpinner'
import NoImage from '../images/no-image.png';

/* Styled components */
const ModalStyled = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 10px 10px!important;
    z-index: 99999;
    outline: none;
`

const ModalContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    color: black;
    box-sizing: border-box;
    border-radius: 10px 10px 10px 10px;
    width: 900px;
    min-height: 600px;
    align-items: center;
    justify-content: center;
    overflow: auto;
    outline: none;
`

const ModalTitle = styled.div`
    position: absolute;
    display: flex;
    top: 0;
    width: 100%;
    height: 70px;
    color: white;
    box-sizing: border-box;
    background: linear-gradient(to left,#122230 0%,#192d3e 100%);
`

const ModalBody = styled.div`
    position: absolute;
    display: flex;
    top: 70px;
    width: 100%;
    height: calc(100% - 70px);
    align-items: center;
    justify-content: center;
`

const ImageProfile = styled.div`
    position: absolute;
    display: flex;
    left: 50px;
    top: 20px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: white;
    border: 1px solid black;
    box-shadow: 1px 1px 5px black;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
    }
`

const TitleProfile = styled.div`
    position: absolute;
    display: flex;
    left: 155px;
    top: 15px;
    width: auto;
    height: 25px;
    color: white;
    font-size: 20px;
    font-weight: bold;
    text-shadow: .5px .5px 5px black;
`

const SubtitleProfile = styled.div`
    position: absolute;
    display: flex;
    left: 165px;
    top: 45px;
    width: auto;
    height: 25px;
    color: white;
    font-size: 14px;
    font-weight: bold;
    text-shadow: .5px .5px 5px black;
`

const BasicInfo = styled.div`
    position: absolute;
    display: flex;
    left: 50px;
    top: 75px;
    width: 42.5%;
    height: 42.5%;
    border-radius: 10px;
    border: 1px solid black;
    padding: 20px 5px 5px 5px;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    box-sizing: border-box;
    h3 {
        position: absolute;
        display: flex;
        top: -15px;
        left: 20px;
        margin: 0;
        font-size: 20px;
        background: white;
        color: white;
        text-shadow: 1px 1px 2px black, 0 0 1em blue, 0 0 0.2em blue;
        
    }
`

const AddiotionalInfo = styled.div`
    position: absolute;
    display: flex;
    right: 50px;
    top: 75px;
    width: 42.5%;
    height: 42.5%;
    border-radius: 10px;
    border: 1px solid black;
    padding: 30px 10px 10px 5px;
    box-sizing: border-box;
    align-items: ${(props) => props.alignH ? props.alignH : 'center'};
    justify-content: center;
    flex-direction: column;
    h3 {
        position: absolute;
        display: flex;
        top: -15px;
        left: 20px;
        margin: 0;
        font-size: 20px;
        background: white;
        color: white;
        text-shadow: 1px 1px 2px black, 0 0 1em blue, 0 0 0.2em blue;
    }
    h5 {
        font-size: 18px;
        color: #ccc;
        text-shadow: 1px 1px 2px black;
    }
`
const StyledButton = styled.button`
    width: calc(55%);
    height: 35px;
    background-color: ${theme.default.primaryButtonBg};
    color: ${theme.default.primaryButtonText};
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 600;
    border-radius: 80px;
    border-color: transparent;
    border-style: solid;
    margin-top: 10px;
    ${(props) => props.alignH ? `align-self: ${props.alignH}` : ''};
`

const InvoiceAccountData = styled.div`
    position: absolute;
    display: flex;
    right: 50px;
    left: 50px;
    bottom: 25px;
    width: auto;
    height: 32.5%;
    border-radius: 10px;
    border: 1px solid black;
    box-sizing: border-box;
    padding: 20px 5px 5px 5px;
    align-items: flex-start;
    justify-content: center;
    h3 {
        position: absolute;
        display: flex;
        top: -15px;
        left: 20px;
        margin: 0;
        font-size: 20px;
        background: white;
        color: white;
        text-shadow: 1px 1px 2px black, 0 0 1em blue, 0 0 0.2em blue;
    }
    table {
        width: 100%;
        height: auto;
        tr {
            max-height: 30px;
            text-align: center;
        }
    }
    h5 {
        font-size: 18px;
        color: #ccc;
        text-shadow: 1px 1px 2px black;
        align-self: center;
    }
`

const ItemData = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 15px;
    margin-top: 2.5px;
    margin-bottom: 2.5px;
    margin-left: 20px;
    label {
        font-weight: bold;
        margin-right: 10px;
    }
    a {
        text-decoration: none;
        display: flex;
        align-items: center;
    }
`

const Icon = styled.img`
    font-size: 26px;
    width: 30px;
    height: 30px;
    margin: 5px 10px 5px 0;
`


/* ----- Principal function ----- */
function ModalViewProfessional({ rowData, openModal, closeModal, addData }) {

    const [accounts, setAccounts] = React.useState(null)
    
     React.useEffect(() => {
        if(!accounts){
            let config = {
                headers: { ...authHeader(), 'Content-Type': 'application/json' }
            }
            axios.get('/api/accounts/user/' + rowData.idUser,config)
            .then(response => {
                let accounts = response.data.accounts;
                setAccounts(accounts);
            })
            .catch(err => {
                alert('Hubo un error...');
            })
        }
    }, [ accounts, rowData.idUser ])

    
/*
    const submit = (data) => {
        handleAction(data)
    } */

    const handleAddData = () => {
        addData(rowData)
    }

    const handleEditData = () => {
        addData({
            idUser: rowData.idUser,
            cuit: rowData.invoiceData.cuit,
            businessname: rowData.invoiceData.businessname,
            commercialAddress: rowData.invoiceData.commercialAddress,
            ivaCondition: rowData.invoiceData.ivaCondition,
            grossIncome: rowData.invoiceData.grossIncome,
            activityStartDate: rowData.invoiceData.activityStartDate
        })
    }

    return(
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openModal}
            onClose={closeModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={openModal}>
                <ModalContent>
                    <ModalTitle />
                    <ImageProfile>
                        <img src={rowData.img ? rowData.img : NoImage} alt={'ImageProfile'} />
                    </ImageProfile>
                    <TitleProfile> { rowData.firstname + " " + rowData.lastname }</TitleProfile>
                    <SubtitleProfile> { rowData.specialization } - Mat. { rowData.enrollment} </SubtitleProfile>
                    <ModalBody>
                        <BasicInfo>
                            <h3>INFORMACIÓN BÁSICA</h3>
                            <ItemData><label>DNI:</label>  {rowData.documentNumber} </ItemData>
                            {
                                rowData.tel ? <ItemData><a href={'https://api.whatsapp.com/send?phone='+ rowData.tel + '&text=Hola%20'+rowData.firstname+'.'} target='_blank' rel="noopener noreferrer"><Icon src={PhoneIcon} alt='Teléfono' /> {rowData.tel} </a></ItemData> : ''
                            }
                            <ItemData><a href={'mailto:'+rowData.email} target='_blank' rel="noopener noreferrer"><Icon src={EmailIcon} alt='Correo electrónico' /> {rowData.email} </a></ItemData>
                            {
                                true ? <ItemData><a href={'https://www.google.com/maps/search/Bereterbide+762'} target='_blank' rel="noopener noreferrer"><Icon src={LocationIcon} alt='Dirección' /> Bereterbide 762 </a></ItemData> : ''
                            }
                            {
                                rowData.birthday ? <ItemData><Icon src={BirthdayIcon} alt='Fecha de nacimiento' /> {rowData.birthday} </ItemData> : ''
                            }
                        </BasicInfo>
                        {
                            rowData.invoiceData ?
                                <AddiotionalInfo alignH='left'>
                                    <h3>INFORMACIÓN ADICIONAL</h3>
                                    <ItemData><label>CUIT:</label> {rowData.invoiceData.cuit} </ItemData>
                                    <ItemData><label>Razón social:</label> {rowData.invoiceData.businessname} </ItemData>
                                    <ItemData><label>Dirección comercial:</label> {rowData.invoiceData.commercialAddress} </ItemData>
                                    <ItemData><label>Condición frente al IVA:</label> {rowData.invoiceData.ivaCondition} </ItemData>
                                    {
                                        rowData.invoiceData.grossIncome ? <ItemData><label>Ingresos brutos:</label> {rowData.invoiceData.grossIncome} </ItemData> : ''
                                    }
                                    {
                                        rowData.invoiceData.activityStartDate ? <ItemData><label>Inicio de actividades:</label> {rowData.invoiceData.activityStartDate} </ItemData> : ''
                                    }
                                    <StyledButton alignH='center' onClick={handleEditData}> MODIFICAR </StyledButton>
                                </AddiotionalInfo>
                            :
                                <AddiotionalInfo>
                                    <h3>INFORMACIÓN ADICIONAL</h3>
                                    <h5>NO HAY INFORMACIÓN ADICIONAL</h5>
                                    <StyledButton onClick={handleAddData}> AGREGAR </StyledButton>
                                </AddiotionalInfo>
                        }
                        
                        <InvoiceAccountData>
                            <h3>INFORMACIÓN DE CUENTA</h3>
                            {
                                !accounts ? <Spinner spinnerType='ThreeDots' />
                                :
                                accounts.length > 0 ?
                                <table>
                                    <thead>
                                        <th>CÓDIGO</th>
                                        <th>TIPO</th>
                                        <th>SALDO</th>
                                    </thead>
                                    <tbody>
                                        {
                                            accounts.map((account, index) => 
                                                <tr key={`tr-${index}`}>
                                                    <td>
                                                        {account.idAccount_Type.code + '.' + account.aux}
                                                    </td>
                                                    <td>
                                                        {account.idAccount_Type.name}
                                                    </td>
                                                    <td>
                                                        {`$${account.balance}`}
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                                :
                                <h5>NO EXITEN CUENTAS</h5>
                            }
                        </InvoiceAccountData>
                    </ModalBody>
                </ModalContent>
            </Fade>
        </ModalStyled>
    );
}

export default ModalViewProfessional;