/* General imports */
import React from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { devices } from '../../app/config'
import axios from 'axios';
import  { authHeader } from '../../app/auth-header';

/* Components imports */
import ProductCard from '../ProductList/ProductCard'
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tada from 'react-reveal/Tada'
import BgRelatedProducts from '../../images/Background_Productos_Destacados.png'
import { InputAdornment, TextField } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';


/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled components */
const Root = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    min-height: 100vh;
    align-items: center;
    justify-content: flex-start;
    font-family: 'IBM Plex Sans';
`

const Header = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 216px;
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
    padding: 0 50px;
    @media (max-width: 600px) {
        padding: 0 20px;
        align-items: flex-end;
    }
`

const HeaderTitle = styled.h2`
    width: 100%;
    text-align: left;
    font-weight: 300;
    font-size: 3.5rem;
    color: #213781;
    margin: 0;
    @media (max-width: 600px) {
        font-size: 2rem;
        margin: 20px 0;
    }
`

const FilterZone = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100px;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 30px;
    background: #f1f2f5;
    @media (max-width: 600px) {
        padding: 0 10px;
    }
`

const CatFilter = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    span {
        margin: 0 10px;
    }
    @media (max-width: 600px) {
        flex-direction: column;
        width: 45%;
        .MuiSelect-root {
            width: 100px;
        }
    }
`

const LimitFilter = styled.div`
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    font-size: 1rem;
    span {
        margin: 0 10px;
    }
    @media (max-width: 600px) {
        right: 20px;
        left: unset;
        span {
            font-size: 0.75rem;
        }
        .MuiSelect-root {
            width: 30px;
        }
    }
`

const OrderFilter = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    span {
        margin: 0 10px;
    }
    @media (max-width: 600px) {
        flex-direction: column;
        width: 45%;
        .MuiSelect-root {
            width: 100px;
        }
    }
`

const StyledFormControl = styled(FormControl)`
    width: auto;
    min-width: ${(props) => props.minWidth ? props.minWidth : '150px'};
`

const ProductsGrid = styled.table`
    position: relative;
    display: grid;
    width: 100%;
    height: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 50px 10px;
    margin: 50px 0;
    box-sizing: border-box;
    padding: 0 20px;
    @media (max-width: 800px) {
        grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 600px) {
        grid-template-columns: 1fr;
    }
`

const ContentProductCard = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    & > span {
        font-size:18px;
        color: #001554;
        font-weight: 400;
        margin: 10px 20px;
        text-transform: uppercase;
    }
`

const Pagination = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    padding: 30px 0 10px 0;
`

const MetaPagination = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    padding: 10px 0 10px 0;
`

const InfoPage = styled.span`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
    font-size: 12px;
    color: #707070;
    @media (max-width: 600px) {
        left: 20px;
    }
`

const Dot = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #001554;
    border-radius: 50%;
    font-size: 12px;
    width: 25px;
    height: 25px;
    color: ${(props) => props.selected ? '#FFF' : '#001554'};
    background: ${(props) => props.selected ? '#001554' : 'transparent'};
    margin: 0 5px;
    user-select: none;
    cursor: pointer;
`

const StyledArrowForwardIosIcon = styled(ArrowForwardIosIcon)`
    margin-left: 5px;
    display: ${(props) => props.show ? 'flex' : 'none'};
    cursor: pointer;
`

const StyledArrowBackIosIcon = styled(ArrowBackIosIcon)`
    display: ${(props) => props.show ? 'flex' : 'none'};
    cursor: pointer;
`

const Breadcrumbs = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: auto;
    height: 50px;
    font-family: 'IBM Plex Sans';
    font-weight: 300;
    font-size: 1.2rem;
    color: #2196F3;
    svg {
        font-size: 12px;
        margin: 0 5px;
    }
    @media (max-width: 600px) {
        align-self: flex-start;
    }
`

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 80%;
    height: auto;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
    @media (max-width: 600px) {
        width: 95%;
    }
`

const StyledTable = styled(Table)`
    .MuiTableCell-head {
        color: #2196f3; 
    }
    .MuiTableCell-body {
        color: #001554;
    }
    .MuiPaper-elevation1 {
        box-shadow: none;
    }
   /*  tr {
        cursor: pointer;
    } */
`

const AccessCatag = styled.a`
    position: absolute;
    top: 70%;
    transform: translateY(-50%);
    right: 50px;
    color: #001554;
    @media (max-width: 600px) {
        font-size: 1rem;
        right: 20px;
    }
`

const RelatedProducts = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 50px;
    background: url(${BgRelatedProducts});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 50px 0 20px 0;
    @media (max-width: 600px) {
        padding: 0 20px;
    }
`

const RelatedTitle = styled.h2`
    display: flex;
    align-items: center;
    width: 100%;
    height: 150px;
    text-align: left;
    font-weight: 300;
    font-size: 2.5rem;
    color: #FFF;
    padding-left: 20px;
    margin: auto 0;
    @media (max-width: 600px) {
        font-size: 1.8rem;
        padding: 0;
    }
`

const ContentList = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;
    box-sizing: border-box;
    width: 100%;
    height: 400px;
    @media (max-width: 800px) {
        grid-template-columns: 1fr 1fr;
        height: auto;
    }
    @media (max-width: 600px) {
        grid-template-columns: 1fr;
        gap: 50px 0;
        height: auto;   
    }
`

const Search = styled(TextField)`
    
`

/* ----- Principal function ----- */
function CategoryProducts({idsection, match, history}) {

    const [ showFilter, SetShowFilter ] = React.useState(false)

    const [productList, setProductList] = React.useState(null)
    const [ productsData, setProductsData ] = React.useState(null);
    const [categories, setCategories] = React.useState(null)
    const [search, setSearch] = React.useState('');
    const [ page, setPage ] = React.useState(0);
    const [ limit, setLimit ] = React.useState(10);

    const [ selectedCat, setSelectedCat ] = React.useState(-1);
    const [ selectedOrder, setSelectedOrder ] = React.useState('nameAsc');
    const [ amountProducts, setAmountProducts ] = React.useState(0);

    const PAGINATION_LIMIT = 3;

    const orderOptions = [
        { name: "Alfabeticamente A-Z", value: 'nameAsc' }, 
        { name: "Alfabeticamente Z-A", value: 'nameDesc' },
        { name: "Últimos agregados", value: 'default' }
    ]

    const limitOptions = [
        { name: "10", value: 10 }, 
        { name: "15", value: 15 },
        { name: "20", value: 20 }
    ]

    const getPages = () => {
        const pages = Math.ceil(amountProducts / limit);
        let nroPages = [];
        for (let index = 0; index < pages; index++) {
            nroPages.push(index);
        }
        return nroPages;
    }

    const getProductList = (idCat, orderBy, page, selectedLimit) => {
        const url = `/api/products?idCategory=${idCat}&orderBy=${orderBy}&search=${search}&ecommerce=1&page=${page}&limit=${selectedLimit}`;
        axios.get(url)
            .then(response => {
                setProductList(response.data.rows);
                setAmountProducts(response.data.total);

            })
            .catch(err => {
                alert('Hubo un error al cargar los productos...');
                console.log(err)
            })
    }

    const onFilter = () => {
        SetShowFilter(!showFilter)
    }

    const handleChangeCat = (e) => {
        e.preventDefault();
        const {value} = e.target;
        setSelectedCat(value);
        getProductList(value, selectedOrder, page, limit);
    }

    const handleChangeOrder = (e) => {
        e.preventDefault();
        const {value} = e.target;
        setSelectedOrder(value);
        getProductList(selectedCat, value, page, limit);
    }

    const handleChangeLimit = (e) => {
        e.preventDefault();
        const {value} = e.target;
        setLimit(value);
        getProductList(selectedCat, selectedOrder, 0, value);
    }

    const backPage = () => {
        if(page > 0){
            setPage(page - 1);
            getProductList(selectedCat, selectedOrder, page - 1, limit )
        }
    }

    const jumpPage = (e) => {
        const {index} = e.target.dataset;
        if(index != page){
            setPage(parseInt(index));
            getProductList(selectedCat, selectedOrder, parseInt(index), limit );
        }
    }

    const forwardPage = () => {
        if(page < getPages().length - 1){
            setPage(page + 1);
            getProductList(selectedCat, selectedOrder, page + 1, limit );
        }
    }

    const onRow = (event) =>  {
        event.preventDefault();
        const { name } = event.currentTarget.dataset;
        history.push(`/product/${name}`);
    }
    
    const onChangeSearch = (e) => {
        e.preventDefault();
        setSearch(e.target.value);
    }

    const searchProducts = (e) => {
        const keycode = (e.keyCode ? e.keyCode : e.which);
        if (keycode == '13') {
            getProductList(selectedCat, selectedOrder, 0, limit);
        }
        if(keycode == '27') {
            setSearch('');
        }
        
    }

    React.useEffect(() => {
        let configHeader = {
            headers: { ...authHeader(), 'Content-Type': 'application/json' }
        }
        if(!productList){
            getProductList(selectedCat, selectedOrder, page, limit);
        }
        if(!categories){
            axios.get(`/api/category`)
            .then(categoriesData => {
                setCategories(categoriesData.data.categories);
            })
            .catch(err => {
                console.log(err);
            })
        }
        if(!productsData){
            const url = `/api/products?tags=Destacados&limit=4`;
            axios.get(url,configHeader)
            .then(productData => {
                setProductsData(productData.data.rows);
            })
            .catch(err => {
                alert('Hubo un error al obtener los productos relacionados');
            })
        }
    }, [])

    return(
        <React.Fragment>
            <Desktop>
                <Root>
                    <Header>
                        <Breadcrumbs><span>Inicio</span><ArrowForwardIosIcon /><span style={{fontWeight: 400}}>Productos</span></Breadcrumbs>
                        <HeaderTitle>Productos</HeaderTitle>
                        {/* <AccessCatag href="#">Descargar Catálogo Completo</AccessCatag> */}
                    </Header>
                    <Container>
                        <FilterZone>
                            <CatFilter>
                                <span>FILTRAR POR: </span>
                                <StyledFormControl size="small" variant="outlined" >
                                    {/* <InputLabel id="catfilter-label">CATEGORÍA</InputLabel> */}
                                    <Select
                                    labelId="catfilter-label"
                                    id="catfilter-outlined"
                                    value={selectedCat}
                                    onChange={handleChangeCat}
                                    /* label="CATEGORÍA" */
                                    >
                                    <MenuItem value={-1}>
                                        CATEGORÍA
                                    </MenuItem>
                                    {
                                        categories && categories.map((cat, index) => 
                                            cat.idCategory !== 0 &&
                                            <MenuItem key={`cat_${index}`} value={cat.idCategory}>{cat.name}</MenuItem>
                                        )
                                    }
                                    </Select>
                                </StyledFormControl>
                            </CatFilter>
                            <Search 
                                id="search" 
                                name="search" 
                                size="small" 
                                variant="outlined" 
                                onChange={onChangeSearch}
                                value={search}
                                onKeyPress={searchProducts}
                                placeholder="Buscar producto"
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchOutlined />
                                    </InputAdornment>
                                    ),
                                }}
                            />
                            <OrderFilter>
                                <span>ORDENAR POR: </span>
                                <StyledFormControl size="small" variant="outlined" >
                                    {/* <InputLabel id="orderfilter-label">ORDEN</InputLabel> */}
                                    <Select
                                    labelId="orderfilter-label"
                                    id="orderfilter-outlined"
                                    value={selectedOrder}
                                    onChange={handleChangeOrder}
                                    /* label="ORDEN" */
                                    >
                                    {
                                        orderOptions && orderOptions.map((ord, index) => 
                                            <MenuItem key={`ord_${index}`} value={ord.value}>{ord.name}</MenuItem>
                                        )
                                    }
                                    </Select>
                                </StyledFormControl>
                            </OrderFilter>
                        </FilterZone>
                        <TableContainer component={Paper}>
                            <StyledTable sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>CÓDIGO</TableCell>
                                        <TableCell>PRODUCTO</TableCell>
                                        <TableCell>CATEGORÍA</TableCell>
                                        {/* <TableCell align="right">U. POR CAJA</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {productList && productList.length > 0  && productList.map((row, index) => (
                                    <TableRow
                                    key={`row_${index}`}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    data-name={row.idProduct}
                                    /* onClick={onRow} */
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.code}
                                        </TableCell>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.id_Category.name}</TableCell>
                                        {/* <TableCell align="right">{row.costPrice}</TableCell> */}
                                    </TableRow>
                                ))}
                                </TableBody>
                            </StyledTable>
                        </TableContainer>
                        <Pagination>
                            <LimitFilter>
                                <StyledFormControl size="small" variant="outlined" minWidth="75px" >
                                    <Select
                                    labelId="limitfilter-label"
                                    id="limitfilter-outlined"
                                    value={limit}
                                    onChange={handleChangeLimit}
                                    /* label="CATEGORÍA" */
                                    >
                                    {
                                        limitOptions.map((l, index) => 
                                            <MenuItem key={`lim_${index}`} value={l.value}>{l.name}</MenuItem>
                                        )
                                    }
                                    </Select>
                                </StyledFormControl>
                                <span>FILAS POR PÁGINA</span>
                            </LimitFilter>
                            {
                                productList && productList.length > 0 &&
                                <StyledArrowBackIosIcon show={page !== 0} onClick={backPage} />
                            }
                            {
                                productList && productList.length > 0 && getPages().map((p, index) =>
                                    (Math.abs(page - p) < PAGINATION_LIMIT) &&
                                    <Dot key={`dot_${index}`} data-index={p} selected={p === page} onClick={jumpPage}>{p+1}</Dot>
                                )
                            }
                            {
                                productList && productList.length > 0 &&
                                <StyledArrowForwardIosIcon show={page !== getPages().length - 1} onClick={forwardPage} />
                            }
                            {
                                productList && productList.length > 0 &&
                                <InfoPage>{`Pág. ${page+1} / ${getPages().length}`}</InfoPage>
                            }
                        </Pagination>
                    </Container>
                    <RelatedProducts>
                        <Tada>
                            <RelatedTitle>Destacados</RelatedTitle>
                        </Tada>
                        <ContentList>
                            {
                                productsData && productsData.length > 0 && productsData.map((prod, index) =>
                                <ContentProductCard key={`product_${index}_${prod.name}`}>
                                    <span>{prod.id_Category ? prod.id_Category.name : 'Categoría'}</span>
                                    <ProductCard colorName="#FFF" colorData="#FFF" productInfo={prod} />
                                </ContentProductCard>
                                )
                            }
                        </ContentList>
                    </RelatedProducts>
                </Root>
            </Desktop>
            <Tablet>
                <Root>
                    <Header>
                        <Breadcrumbs><span>Inicio</span><ArrowForwardIosIcon /><span style={{fontWeight: 400}}>Productos</span></Breadcrumbs>
                        <HeaderTitle>Productos</HeaderTitle>
                        <AccessCatag href="#">Descargar Catálogo Completo</AccessCatag>
                    </Header>
                    <Container>
                        <FilterZone>
                            <CatFilter>
                                <span>FILTRAR POR: </span>
                                <StyledFormControl size="small" variant="outlined" >
                                    {/* <InputLabel id="catfilter-label">CATEGORÍA</InputLabel> */}
                                    <Select
                                    labelId="catfilter-label"
                                    id="catfilter-outlined"
                                    value={selectedCat}
                                    onChange={handleChangeCat}
                                    /* label="CATEGORÍA" */
                                    >
                                    <MenuItem value={-1}>
                                        CATEGORÍA
                                    </MenuItem>
                                    {
                                        categories && categories.map((cat, index) => 
                                            cat.idCategory !== 0 &&
                                            <MenuItem key={`cat_${index}`} value={cat.idCategory}>{cat.name}</MenuItem>
                                        )
                                    }
                                    </Select>
                                </StyledFormControl>
                            </CatFilter>
                            <OrderFilter>
                                <span>ORDENAR POR: </span>
                                <StyledFormControl size="small" variant="outlined" >
                                    {/* <InputLabel id="orderfilter-label">ORDEN</InputLabel> */}
                                    <Select
                                    labelId="orderfilter-label"
                                    id="orderfilter-outlined"
                                    value={selectedOrder}
                                    onChange={handleChangeOrder}
                                    /* label="ORDEN" */
                                    >
                                    {
                                        orderOptions && orderOptions.map((ord, index) => 
                                            <MenuItem key={`ord_${index}`} value={ord.value}>{ord.name}</MenuItem>
                                        )
                                    }
                                    </Select>
                                </StyledFormControl>
                            </OrderFilter>
                        </FilterZone>
                        <TableContainer component={Paper}>
                            <StyledTable sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>CÓDIGO</TableCell>
                                        <TableCell>PRODUCTO</TableCell>
                                        <TableCell>CATEGORÍA</TableCell>
                                        {/* <TableCell align="right">U. POR CAJA</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {productList && productList.length > 0 && productList.map((row) => (
                                    <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    data-name={row.idProduct}
                                    onClick={onRow}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.code}
                                        </TableCell>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.id_Category.name}</TableCell>
                                        {/* <TableCell align="right">{row.costPrice}</TableCell> */}
                                    </TableRow>
                                ))}
                                </TableBody>
                            </StyledTable>
                        </TableContainer>
                        <Pagination>
                            <LimitFilter>
                                <StyledFormControl size="small" variant="outlined" minWidth="75px" >
                                    <Select
                                    labelId="limitfilter-label"
                                    id="limitfilter-outlined"
                                    value={limit}
                                    onChange={handleChangeLimit}
                                    /* label="CATEGORÍA" */
                                    >
                                    {
                                        limitOptions.map((l, index) => 
                                            <MenuItem key={`lim_${index}`} value={l.value}>{l.name}</MenuItem>
                                        )
                                    }
                                    </Select>
                                </StyledFormControl>
                                <span>FILAS POR PÁGINA</span>
                            </LimitFilter>
                            {
                                productList && productList.length > 0 &&
                                <StyledArrowBackIosIcon show={page !== 0} onClick={backPage} />
                            }
                            {
                                productList && productList.length > 0 && getPages().map((p, index) =>
                                    (Math.abs(page - p) < PAGINATION_LIMIT) &&
                                    <Dot key={`dot_${index}`} data-index={p} selected={p === page} onClick={jumpPage}>{p+1}</Dot>
                                )
                            }
                            {
                                productList && productList.length > 0 &&
                                <StyledArrowForwardIosIcon show={page !== getPages().length - 1} onClick={forwardPage} />
                            }
                            {
                                productList && productList.length > 0 &&
                                <InfoPage>{`Pág. ${page+1} / ${getPages().length}`}</InfoPage>
                            }
                        </Pagination>
                    </Container>
                    <RelatedProducts>
                        <Tada>
                            <RelatedTitle>Destacados</RelatedTitle>
                        </Tada>
                        <ContentList>
                            {
                                productsData && productsData.length > 0 && productsData.map((prod, index) =>
                                    <ProductCard colorName="#FFF" colorData="#FFF" key={`product_${index}`} productInfo={prod} />
                                )
                            }
                        </ContentList>
                    </RelatedProducts>
                </Root>
            </Tablet>
            <Mobile>
                <Root>
                    <Header>
                        <Breadcrumbs><span>Inicio</span><ArrowForwardIosIcon /><span style={{fontWeight: 400}}>Productos</span></Breadcrumbs>
                        <HeaderTitle>Productos</HeaderTitle>
                        <AccessCatag href="#">Descargar Catálogo</AccessCatag>
                    </Header>
                    <Container>
                        <FilterZone>
                            <CatFilter>
                                <span>FILTRAR POR: </span>
                                <StyledFormControl size="small" variant="outlined" >
                                    {/* <InputLabel id="catfilter-label">CATEGORÍA</InputLabel> */}
                                    <Select
                                    labelId="catfilter-label"
                                    id="catfilter-outlined"
                                    value={selectedCat}
                                    onChange={handleChangeCat}
                                    /* label="CATEGORÍA" */
                                    >
                                    <MenuItem value={-1}>
                                        CATEGORÍA
                                    </MenuItem>
                                    {
                                        categories && categories.map((cat, index) => 
                                            cat.idCategory !== 0 &&
                                            <MenuItem key={`cat_${index}`} value={cat.idCategory}>{cat.name}</MenuItem>
                                        )
                                    }
                                    </Select>
                                </StyledFormControl>
                            </CatFilter>
                            <OrderFilter>
                                <span>ORDENAR POR: </span>
                                <StyledFormControl size="small" variant="outlined" >
                                    {/* <InputLabel id="orderfilter-label">ORDEN</InputLabel> */}
                                    <Select
                                    labelId="orderfilter-label"
                                    id="orderfilter-outlined"
                                    value={selectedOrder}
                                    onChange={handleChangeOrder}
                                    /* label="ORDEN" */
                                    >
                                    {
                                        orderOptions && orderOptions.map((ord, index) => 
                                            <MenuItem key={`ord_${index}`} value={ord.value}>{ord.name}</MenuItem>
                                        )
                                    }
                                    </Select>
                                </StyledFormControl>
                            </OrderFilter>
                        </FilterZone>
                        <TableContainer component={Paper}>
                            <StyledTable sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>CÓDIGO</TableCell>
                                        <TableCell>PRODUCTO</TableCell>
                                        <TableCell>CATEGORÍA</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {productList && productList.length > 0 && productList.map((row) => (
                                    <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    data-name={row.idProduct}
                                    onClick={onRow}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.code}
                                        </TableCell>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.id_Category.name}</TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </StyledTable>
                        </TableContainer>
                        <Pagination>
                            {
                                productList && productList.length > 0 &&
                                <StyledArrowBackIosIcon show={page !== 0} onClick={backPage} />
                            }
                            {
                                productList && productList.length > 0 && getPages().map((p, index) =>
                                    (Math.abs(page - p) < PAGINATION_LIMIT) &&
                                    <Dot key={`dot_${index}`} data-index={p} selected={p === page} onClick={jumpPage}>{p+1}</Dot>
                                )
                            }
                            {
                                productList && productList.length > 0 &&
                                <StyledArrowForwardIosIcon show={page !== getPages().length - 1} onClick={forwardPage} />
                            }
                            
                        </Pagination>
                        <MetaPagination>
                            {
                                productList && productList.length > 0 &&
                                <InfoPage>{`Pág. ${page+1} / ${getPages().length}`}</InfoPage>
                            }
                            <LimitFilter>
                                <span>FILAS POR PÁGINA</span>
                                <StyledFormControl size="small" variant="outlined" minWidth="75px" >
                                    <Select
                                    labelId="limitfilter-label"
                                    id="limitfilter-outlined"
                                    value={limit}
                                    onChange={handleChangeLimit}
                                    /* label="CATEGORÍA" */
                                    >
                                    {
                                        limitOptions.map((l, index) => 
                                            <MenuItem key={`lim_${index}`} value={l.value}>{l.name}</MenuItem>
                                        )
                                    }
                                    </Select>
                                </StyledFormControl>
                            </LimitFilter>
                        </MetaPagination>
                    </Container>
                    <RelatedProducts>
                        <Tada>
                            <RelatedTitle>Destacados</RelatedTitle>
                        </Tada>
                        <ContentList>
                            {
                                productsData && productsData.length > 0 && productsData.map((prod, index) =>
                                    <ProductCard colorName="#FFF" colorData="#FFF" key={`product_${index}`} productInfo={prod} />
                                )
                            }
                        </ContentList>
                    </RelatedProducts>
                </Root>
            </Mobile>
        </React.Fragment>
    );
}

export default CategoryProducts;