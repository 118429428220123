/* General imports */
import React from 'react'
import axios from 'axios';
import { authHeader } from '../../app/auth-header';
import { useMediaQuery } from 'react-responsive';
import { devices } from '../../app/config';

/* Components imports */
import Gallery from './Gallery';

/* ----- Principal function ----- */
function ImageGallery({idsection, match}) {

    const [data, setData] = React.useState(null);

    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })

    React.useEffect(() => {
        if(!data){
            let configHeader = {
                headers: { ...authHeader(), 'Content-Type': 'application/json' }
            }
            axios.get('/api/sections/' + idsection,configHeader)
            .then(sectionData => {
                setData(sectionData.data.section.data);
            })
            .catch(err => {
                alert('Hubo un error...');
            })
        }
    }, [data, idsection])

    return (
        data && 
        <Gallery images={data.img.images} isDesktop={isDesktop} />
    )
}

export default ImageGallery;