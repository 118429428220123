/* General imports */
import React, { useState } from 'react'
import styled from 'styled-components'
import { theme } from '../app/config'

/* Components imports */
import TextField from './TextField'


/* Styled components */
const Root = styled.form`
    width: ${(props) => props.width ? props.width : '100%'};
    height: ${(props) => props.height ? props.height : '100%'};
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: ${(props) => props.alignH ? props.alignH : 'center'};
    justify-content: ${(props) => props.alignV ? props.alignV : 'center'};
    flex-wrap: ${(props) => props.noWrap ? 'nowrap' : 'wrap' };
    ${(props) => props.overflowY ? `overflow-y: ${props.overflowY}` : '' };
    ${(props) => props.overflowX ? `overflow-x: ${props.overflowX}` : '' };
`

const Inputbox = styled.div`
    width: ${(props) => props.columWidth ? props.columWidth : '100%'};
    position: relative;
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 20px;
    margin: 18px;
    box-sizing: border-box;
    height: 48px;
    border-bottom: 1px solid black;
    label {
        position: absolute;
        top: ${(props) => props.type === 'date' ? '-10px' : props.effect ? '-10px' : '18px' };
        left: 0;
        color: #ccc;
        transition: .5s;
        pointer-events: none;
        font-size: ${(props) => props.type === 'date' ? '16px' : props.effect ? '16px' : '20px'};
        margin-left: ${(props) => props.icon ? '40px' : 0};
        height: 16px;
    }

    input {
        border: none;
        outline: none;
        background: none;
        color: black;
        font-size: 18px;
        width: calc(100% - 35px);
        padding-bottom: 12px;
        /* border-bottom: 1px solid black;
        border-width: 100%; */
        padding-left: ${(props) => props.icon ? '5px' : 0};
        margin-top: 16px;
        box-sizing: border-box;
        height: 32px;
    }

    /* input:focus, select:focus {
        border-bottom: ${(props) => props.effectColor ? `1px solid ${props.effectColor}` : '1px solid black'};
    } */

    input:focus + label {
        top: -10px;
        color: ${(props) => props.effectColor ? props.effectColor : 'black'};
        font-size: 16px;
    }

    select:focus + label {
        top: -10px;
        color: ${(props) => props.effectColor ? props.effectColor : 'black'};
        font-size: 16px;
    }
`

const Select = styled.select`
    background: transparent;
    border: none;
    font-size: 18px;
    height: 30px;
    width: 100%;
    padding-bottom: 12px;
    margin-top: 16px;
    height: 32px;
    /* &:focus{ outline: none;} */
`

const CheckOneButton = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: ${(props) => props.width ? props.width : '100%'};
    margin: ${(props) => props.margin ? props.margin : 0};
    label {
        margin-left: 5px;
    }
`

const Option = styled.option`
    font-size: 18px;
`

const Icon = styled.img`
    font-size: 26px;
    width: 30px;
    height: 30px;
    margin: 5px 5px 5px 0;
`



const StyledButton = styled.button`
    width: calc(${(props) => props.columWidth ? props.columWidth : '100%'} * 0.75);
    height: 44px;
    background-color: ${theme.default.primaryButtonBg};
    color: ${theme.default.primaryButtonText};
    font-family: 'Montserrat';
    font-size: 18px;
    font-weight: 600;
    border-radius: 80px;
    border-color: transparent;
    border-style: solid;
    margin-top: 25px;
    margin-bottom: 25px;
`

/* ----- Principal function ----- */
function Form(props) {

    const [ state, setState ] = useState(props.data ? props.data : {});
    
    const handleChange = (name, value) => {
        /* e.preventDefault();
        const { name, value } = e.target; */
        setState({ 
            ...state,
            [name]: value 
        });
    }

    const handleCheck = (e) => {
        e.preventDefault();
        const { name, checked } = e.target;
        setState({
            ...state,
            [name]: checked ? 1 : 0
        })
    }

    const submit = (e) => {
        e.preventDefault();
        props.submitFunction(state)
    }

    return(
        <Root width={props.width} height={props.height} alignV={props.alignV} alignH={props.alignH} noWrap={props.noWrap} overflowX={props.overflowX} overflowY={props.overflowY}  onSubmit={submit}>
            {
                props.fields ?
                props.fields.map(field => 
                    <TextField key={field.name} id={field.name} value={state[field.name]} required={field.required} name={field.name} placeholder={field.placeholder} options={field.options} margin={props.inputMargin} error={state[`${field.name}_error`]} width={props.columWidth} icon={field.icon} type={field.type} fontSize={props.fontSize} autoFocus={field.autoFocus} onChange={handleChange}>
                        
                    </TextField>    
                )
                :
                <h1>Cargando...</h1>
            }
            {/* {
                props.fields.map(field => 
                    <Inputbox key={field.name} columWidth={props.columWidth} icon={field.icon} type={field.type} effect={ state[field.name] ? true : false} effectColor={props.effectColor} >
                        
                        {
                            field.icon && <Icon src={field.icon} alt={field.placeholder} />
                        }
                        {
                            field.type === 'select' ?
                                (props.data && props.data[field.name]) ?
                                    <Select id={field.name} value={state[field.name]} required={field.required} name={field.name} onChange={handleChange}>
                                        <Option value="" hidden>{field.placeholder}</Option>
                                        {
                                            field.options.map((option, index) => 
                                                <Option key={index} value={option.value}>{option.name}</Option>
                                            )
                                        }
                                    </Select>
                                :
                                    <Select id={field.name} required={field.required} name={field.name} onChange={handleChange}>
                                        <Option value="" hidden></Option>
                                        {
                                            field.options.map((option, index) => 
                                                <Option key={index} value={option.value}>{option.name}</Option>
                                            )
                                        }
                                    </Select>
                            :
                                (props.data && props.data[field.name]) ? 
                                    <input id={field.name} value={state[field.name]} step={field.step ? field.step : ''} required={field.required} autoFocus={field.autoFocus} name={field.name} type={field.type} placeholder={field.placeholder} onChange={handleChange} />
                                :
                                    <input id={field.name} step={field.step ? field.step : ''} required={field.required} autoFocus={field.autoFocus} name={field.name} type={field.type}  onChange={handleChange} />
                        }
                        <label>{field.required ? `${field.placeholder}*` : field.placeholder }</label>
                    </Inputbox>
                )
            } */}
            {
                props.submitButton && <StyledButton columWidth={props.columWidth}> {props.submitButton} </StyledButton>
            }
        </Root>
    );
}

export default Form;