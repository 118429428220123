/* General imports */
import React from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { devices } from '../app/config'

/* Components imports */
import GridLayout from '../gridLayout/StaticGridLayout'

/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled components */
const Root = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    max-height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
`


const RootMobile = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: auto;
    align-self: flex-start;
    align-items: flex-start;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 0;
    padding: 0 25px;
`

const GridContainer = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
`

/* ----- Principal function ----- */
function ShortcutScreen(props) {

    return(
        <React.Fragment>
            <Desktop>
                <Root>
                    <GridContainer>
                        <GridLayout  />
                    </GridContainer>
                </Root>
            </Desktop>
            <Tablet>
                <Root>
                    <GridContainer>
                        <GridLayout  />
                    </GridContainer>
                </Root>
            </Tablet>
            <Mobile>
                <Root>
                    <GridContainer>
                        <GridLayout  />
                    </GridContainer>
                </Root>
                    
            </Mobile>
        </React.Fragment>
    );
}

export default ShortcutScreen;