/* General imports */
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { config } from '../../app/config'
import axios from 'axios'
import  { authHeader } from '../../app/auth-header';

/* Components imports */
import Spinner from '../../spinners/LoaderSpinner'
import ImagesInput from '../../form/ImagesInput'

const HeightHeader = '70px'

/* Styled components */

const Header = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: ${HeightHeader};
    padding: 0 25px;
    box-sizing: border-box;
    background: #234;
`

const HeaderTitle = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 60%;
    height: 100%;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    text-shadow: 1px 1px 2px black;
`

const HeaderButtons = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 40%;
    height: 100%;
`

const ContentBox = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 50%;
    height: calc(100% - ${HeightHeader});
    align-items: center;
    justify-content: center;
`

const ButtonHeader = styled.button`
    margin: 0 5px;
`

/* ----- Principal function ----- */
function ImageGallerySettings({match, history, user}) {

    let configHeader = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    const { idPage, idSection } = match.params

    const [ images, setImages ] = React.useState([]);

    const [data, setData] = React.useState(null)

    const onBack = () => {
        history.push(config.webeditorpath+'/page/'+idPage)
    }

    const onSave = () => {
         const saveData = {
            data: {
                ...data,
                img: {
                    images: images
                }
            },
            updatedUser: user.idUser
        }
        
        axios.put("/api/updatedatasection/"+idSection, saveData, configHeader)
        .then(res => {
            history.push(config.webeditorpath+'/page/'+idPage)
        })
        .catch(error => {
            alert('Hubo un error...')
        })
    }

    React.useEffect(() => {
        if(!data){
            axios.get('/api/sections/' + idSection,configHeader)
            .then(sectionData => {
                if(parseInt(Object.keys(sectionData.data.section.data).length) > 0){
                    setData(sectionData.data.section.data);
                    setImages(sectionData.data.section.data.img.images);
                } else {
                    setData({
                        img: null
                    })
                }
            })
            .catch(err => {
                alert('Hubo un error...');
            })
        }
    }, [data, idSection])

    return(
        <React.Fragment>
            <Header>
                {
                    <HeaderTitle>CONFIGURACIÓN IMAGE GALLERY</HeaderTitle>
                }
                <HeaderButtons><ButtonHeader onClick={onBack}>VOLVER</ButtonHeader> <ButtonHeader onClick={onSave}>GUARDAR</ButtonHeader> </HeaderButtons>
            </Header>
            {
                !data ? <Spinner spinnerType='ThreeDots' />
                :
                <ContentBox>
                    <ImagesInput images={images} handleImages={setImages} name='images' />
                </ContentBox>
            }
        </React.Fragment>
    );
}

function mapState(state) {
    const { user } = state.login;
    return { user };
}

export default connect(mapState)(ImageGallerySettings);