import { stockConstants } from './StockConstants';

let stock = JSON.parse(localStorage.getItem('stock'));
const initialState = stock ? 
        { 
            error: false,
            isFetching: false,
            stock: stock
        }
    : 
        { 
            error: false,
            isFetching: true,
        };

export function stockReducer(state = initialState, action) {
    switch (action.type) {
        case stockConstants.FETCHING:
            return { 
                ...state,
                isFetching: true
            };
        case stockConstants.SUCCESS:
            return { 
                ...state,
                isFetching: false,
                stock: action.stock
            };
        case stockConstants.ERROR:
            return { 
                ...state,
                error: true
            };
        case stockConstants.FETCHING_FORMFIELDS:
            return { 
                ...state,
                isFetchingFields: true
            };
        case stockConstants.SUCCESS_FORMFIELDS:
            return { 
                ...state,
                isFetchingFields: false,
                stockFields: action.stockFields,
                error: false
        };
        case stockConstants.SUCCESS_UPDATEFORMFIELDS:
            return { 
                ...state,
                isFetchingFields: false,
                stockUpdateFields: action.stockUpdateFields,
                error: false
        };
        case stockConstants.SUCCESS_CURRENCYUNITS:
            return { 
                ...state,
                isFetchingFields: false,
                currencyUnits: action.currencyUnits,
                error: false
        };
        case stockConstants.SUCCESS_QUANTITYUNITS:
            return { 
                ...state,
                isFetchingFields: false,
                quantityUnits: action.quantityUnits,
                error: false
        };
        case stockConstants.ERROR_FORMFIELDS:
            return {
                ...state,
                error: true
        };
        default:
            return state
    }
}