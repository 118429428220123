/* General imports */
import React, { useState } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { alertActions } from '../alert/AlertActions'
import update from 'immutability-helper'
import { pageEditorActions } from './PageEditorActions'
import { useDrop } from 'react-dnd';
import { ItemTypes } from './ItemTypes'
import { withRouter } from 'react-router';
import { config } from '../app/config'

/* Components imports */
import { Card } from './Card'
import AddIcon from '../icons/add2.svg'
import ModalCreateSection from './ModalCreateSection'

/* Styled components */

const Root = styled.div`
  position: relative;
  display: flex;
  width: 900px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const NewCard = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50%;
  border: 1px solid gray;
  padding: 0.5rem 1rem;
  margin-bottom: .5rem;
  border-radius: 10px;
  background-color: white;
  cursor: pointer;
  img {
    width: 40px;
    height: 40px;
  }
`

const Message = styled.h5`
  font-size: 50px;
  color: #ccc;
  text-shadow: 1px 1px 2px black;
`

/* ----- Principal function ----- */
function Container({idPage, user, isFetching, sections, sectionFields, getFields, sectionUpdateFields, getUpdateFields, createSection, getSections, updateSection, deleteSection, changeOrder, history}) {

  const [ cards, setCards ] = useState(null)

  const [open, setOpen] = React.useState(false);

  const [edit, setEdit] = React.useState(false);

  const [ editData, setEditData ] = React.useState(null);


  const [assign, setAssign] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const moveCard = (id, atIndex) => {
    const { card, index } = findCard(id);
    setCards(update(cards, {
        $splice: [
            [index, 1],
            [atIndex, 0, card],
        ],
    }));
  };

  const findCard = (idSectionPage) => {
    const card = cards.filter((c) => c.idSectionPage === idSectionPage)[0];
    return {
        card,
        index: cards.indexOf(card),
    };
  };

  const [, drop] = useDrop({ accept: ItemTypes.CARD });

  const updateOrder = () => {
    changeOrder(cards, user.idUser)
  }

  const deleteCard = (idSection) => {
    // eslint-disable-next-line no-restricted-globals
    if(confirm('¿Desea eliminar esta sección?')){
      deleteSection(idPage, idSection)
      setAssign(false)
    }
    
  }

  React.useEffect(() => {
    async function fechData() {
      setIsLoading(true);
      await getSections(idPage)
      setCards(sections)
      setIsLoading(false);
    }
    if(!cards){
      fechData()
    } else {
      if(!assign){
        fechData()
        setAssign(true)
      }
    }
  }, [ sections, getSections, idPage ]) 

  const newSection = () => {
    setOpen(true);
  }

  const handleConfirmNew = async(data) => {
    data = {
        ...data,
        idPage: idPage,
        data: {},
        createdUser: user.idUser
    }
    await createSection(data)
    handleClose()
  }

  const handleClose = () => {
    setOpen(false)
    setAssign(false)
  };

  const editSection = (idData) => {
    setEditData(cards[idData])
    setEdit(true)
  }

  const handleConfirmEdit = async(data) => {
    const dataSection = {
        ...data,
        updatedUser: user.idUser
    }
    await updateSection(idPage, editData.idSection, dataSection)
    handleCloseEdit()
  }

  const handleCloseEdit = () => {
    setEdit(false)
    setAssign(false)
  };

  const editDataType = (order, idSection) => {
    history.push(config.webeditorpath+'/sectiontype/'+cards[order].section.section_type.settings+'/'+idPage+'/'+idSection)
  }


  return (
    <Root ref={drop}>
      {
        !cards || cards.length < 1 ? <Message>NO HAY SECCIONES</Message> :
        !isLoading && cards && cards.map((card) => <Card key={card.idSectionPage} id={card.idSectionPage} idSection={card.idSection} text={card.section.title} moveCard={moveCard} findCard={findCard} updateOrder={updateOrder} editCard={editSection} editDataType={editDataType} deleteCard={deleteCard} />)
      }
      
      <NewCard onClick={newSection} ><img src={AddIcon} alt='Agregar sección' /></NewCard>
      {
        open && <ModalCreateSection title='CREAR SECCIÓN' textButton='CREAR' handleAction={handleConfirmNew} fields={sectionFields} getFields={getFields} openModal={open} closeModal={handleClose} />
      }
      {
        editData && <ModalCreateSection title='EDITAR SECCIÓN' textButton='EDITAR' rowData={editData.section} handleAction={handleConfirmEdit} fields={sectionUpdateFields} getFields={getUpdateFields} openModal={edit} closeModal={handleCloseEdit} />
      }
    </Root>
  )
}

function mapState(state) {
  const { error, isFetching, sections, sectionFields, sectionUpdateFields } = state.pageEditor;
  const { user } = state.login;
  return { user, error, isFetching, sections, sectionFields, sectionUpdateFields };
}

const actionCreators = {
  getFields: pageEditorActions.getFields,
  getUpdateFields: pageEditorActions.getUpdateFields,
  createSection: pageEditorActions.createSection,
  getSections: pageEditorActions.getSections,
  updateSection: pageEditorActions.updateSection,
  deleteSection: pageEditorActions.deleteSection,
  changeOrder: pageEditorActions.changeOrder,
  alertMessage: alertActions.error
}

export default withRouter(connect(mapState, actionCreators)(Container));