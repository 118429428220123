/* General imports */
import React from 'react'
import { Route } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { devices } from '../app/config'
import { alertActions } from '../alert/AlertActions'
import axios from 'axios';
import  { authHeader } from '../app/auth-header';

/* Components imports */
import Table from '../tables/AsyncTable'
import PatientIcon from '../icons/professionalsWhite.png'
import NewSupplier from './NewSupplier'
import NoImage from '../images/no-image.png'

/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled components */
const Root = styled.div`
    width: ${(props) => props.width ? props.width : '100%'};
    height: 100%;
    padding: ${(props) => props.padding ? props.padding : 0};
    box-sizing: border-box;
`

const Header = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 150px;
    background: linear-gradient(to left, #122230 0%, #192d3e 100%);
    align-items: flex-start;
    padding: 35px 35px 70px 35px;
    box-sizing: border-box;
`

const Title = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img {
        position: relative;
        display: flex;
        width: 30px;
        height: 30px;
    }
    h6 {
        position: relative;
        display: flex;
        margin: 0 20px;
        color: white;
        font-size: 1.5rem;
        font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
        font-weight: 400;
    }
`

const Content = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: calc(100% - 150px);
    padding: 0 35px;
    box-sizing: border-box;
    top: -64px;
`

/* ----- Principal function ----- */
function Suppliers({ user, alertMessage, history }) {

    const columsTable = [
        { title: 'Image', field: 'img', render: rowData => <img src={rowData.img ? rowData.img : NoImage } alt='SinImagen' style={{width: 40, height:40, borderRadius: '50%', boxShadow: '1px 1px 2px rgba(0,0,0,0.5)'}}/>, width: 50, align: 'center' },
        { title: 'ID', field: 'idSupplier', width: 50 },
        { 
            title: 'Nombre', 
            field: 'name', 
            width: 300
        },
        { 
            title: 'CUIT', 
            field: 'cuit'
        },
        { 
            title: 'IVA', 
            field: 'iva',
            hidden: true
        },
        { 
            title: 'Dirección', 
            field: 'address',
            hidden: true
        },
        { 
            title: 'Ciudad', 
            field: 'city',
            hidden: true
        },
        { 
            title: 'Tel.', 
            field: 'phone'
        },
        { 
            title: 'Web', 
            field: 'web',
            hidden: true
        },
        { 
            title: 'Bonificación', 
            field: 'bonus',
            hidden: true
        },
        { 
            title: 'Moneda', 
            field: 'idCurrency_Unit.key'
        },
        { 
            title: 'Precio Moneda', 
            field: 'currencyValue'
        },
        { 
            title: 'Perc. IIBB', 
            field: 'grossIncomePerception',
            hidden: true
        },
        { 
            title: 'Flete', 
            field: 'freight',
            hidden: true
        },
        { 
            title: 'TaxValue', 
            field: 'taxValue',
            hidden: true
        },
        { 
            title: 'Margen', 
            field: 'margin',
            hidden: true
        },
        { 
            title: 'Observación', 
            field: 'observation',
            hidden: true
        }
    ]

    const tableRef = React.createRef();

    const [ editData, setEditData ] = React.useState(null);

    const handleNew = () => {
        history.push('/admin/suppliers/new')
    };

    const handleEdit = (rowData) => {
        setEditData(rowData);
        history.push('/admin/suppliers/update');
    }

    let configHeader = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    const handleDelete = (rowData) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("¿Desea eliminar el proveedor "+rowData.idSupplier+"?")) {
            axios.delete(`/api/suppliers/${rowData.idSupplier}`,configHeader)
            .then(result => {
                alertMessage("Proveedor eliminado");
            })
            .catch(err => {
                alertMessage("Hubo un error al eliminar el proveedor.")
            })
        }
    }

    const RouteComponent = ({ component: Component, rowData, ...rest }) => (
        <Route
            {...rest}
            render={(props) => {
                return <Component rowData={rowData} {...props} />
            }}
        />
    )

    return(
        <React.Fragment>
            <Desktop>
                <Root>
                    <Header>
                        <Title>
                            <img src={PatientIcon} alt='Icon' />
                            <h6>Proveedores</h6>
                        </Title>
                    </Header>
                    <Content>
                        <Route path={'/admin/suppliers'} exact={true} >
                        { 
                            <Table urlapi="/api/suppliers" tableRef={tableRef} autoExport={true}  hiddenView={true} handleNew={handleNew} handleEdit={handleEdit} handleDelete={handleDelete}  title="PROVEEDORES" columnsTable={columsTable} />   
                        }
                        </Route>
                        <Route path={'/admin/suppliers/new'} component={NewSupplier} />
                        <RouteComponent path={'/admin/suppliers/update'} rowData={editData} component={NewSupplier} />
                    </Content>
                </Root>
            </Desktop>
            <Tablet>SUPPLIERS TABLET</Tablet>
            <Mobile>SUPPLIERS MOBILE</Mobile>
        </React.Fragment>
    );
}

function mapState(state) {
    const { user } = state.login;
    return { user};
}

const actionCreators = {
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(Suppliers);