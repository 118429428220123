/* General imports */
import React from 'react'
import styled from 'styled-components'
import { Route } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { devices } from '../app/config'

/* Components imports */
import { components } from '../app/componentList'
import NavTab from '../navs/NavTab'
import { menuItems } from './menuItems'

/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

const HeightNav = '70px'

/* Styled components */
const ContentBox = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: calc(100% - ${HeightNav});
    align-items: flex-start;
    justify-content: flex-start;
`

/* ----- Principal function ----- */
function WebEditor(props) {

    return(
        <React.Fragment>
            <NavTab height={HeightNav} items={menuItems} />
            <Desktop>
                <ContentBox>
                    {
                        menuItems.map( (r, index) => 
                            <Route key={`${r.id}+${index}`} path={r.link} exact={r.exact} component={components.find(comp => comp.name === r.id).component} />    
                        )
                    }
                </ContentBox>
            </Desktop>
            <Tablet>
                <ContentBox>
                    {
                        menuItems.map( (r, index) => 
                            <Route key={`${r.id}+${index}`} path={r.link} exact={r.exact} component={components.find(comp => comp.name === r.id).component} />    
                        )
                    }
                </ContentBox>
            </Tablet>
            <Mobile>
                <ContentBox>
                    {
                        menuItems.map( (r, index) => 
                            <Route key={`${r.id}+${index}`} path={r.link} exact={r.exact} component={components.find(comp => comp.name === r.id).component} />    
                        )
                    }
                </ContentBox>
            </Mobile>
        </React.Fragment>
    );
}

export default WebEditor;