/* General imports */
import React from 'react'
import { connect } from 'react-redux'
import { registerActions } from './RegisterActions'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { theme, devices } from '../app/config'
import { alertActions } from '../alert/AlertActions'

/* Components imports */
import Form from '../form/Form'
import Spinner from '../spinners/LoaderSpinner'

/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled components */
const Root = styled.div`
    width: 100%;
    min-height: 100%;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Title = styled.div`
    width: 100%;
    color: ${theme.default.sidebarBackground};
    font-family: 'Montserrat';
    font-size: 37px;
    font-size: ${(props) => props.fontSize ? props.fontSize : '37px'};
    font-weight: 700;
    text-align: center;
    margin-top: 25px;
    margin-bottom: 30px;
`


const TextRegister = styled(Title)`
    font-weight: 400;
    font-size: ${(props) => props.fontSize ? props.fontSize : '14px'};;
    color: ${theme.default.greyText};
    margin-top: 10px;
`

const RegisterLink = styled.a`
    color: ${theme.default.redText};
    font-family: 'Montserrat';
    font-size: ${(props) => props.fontSize ? props.fontSize : '14px'};;
    font-weight: 600;
    text-decoration: none;
`

const HorizontalSeparator = styled.div`
    display: flex;
    width: ${(props) => props.width ? props.width : '400px'};
    align-items: center;
    justify-content: center;
    opacity: .3;
    margin-top: 10px;
    &::before {
        content: '';
        position: relative;
        width: 40%;
        height: 1px;
        display: flex;
        margin-right: 5%;
        background-color: black;
    }
    &::after {
        content: '';
        position: relative;
        width: 40%;
        height: 1px;
        display: flex;
        margin-left: 5%;
        background-color: black;
    }
`

/* ----- Principal function ----- */
function Register({ register, getFields, alertMessage, history, isFetching, registerFields }) {

    // TODO: Mejorar como trae los registros en vez de hacerlo desde el backend
    React.useEffect(() => {
        if(!registerFields){
            getFields()
        }
    }, [ registerFields, getFields ])

    

    const submit = (data) => {
        if(data.password === data.repassword){
            register(data, history, '/login')
        } else {
            alertMessage('Las contraseñas no coinciden')
        }
        
    }

    return(
        (!registerFields) ? 
            <Spinner spinnerType='ThreeDots' />
        :
            <React.Fragment>
                <Desktop>
                    <Root>
                        <Title>Registrarse</Title>
                        <Form width='90%' height='500px' inputMargin='14px' alignV={'flex-start'} columWidth='45%' fields={registerFields} submitButton='Registrarse' submitFunction={submit} />
                        <HorizontalSeparator>o</HorizontalSeparator>
                        <TextRegister>¿Ya tienes cuenta? <RegisterLink href='/login'>Iniciar sesión</RegisterLink></TextRegister>
                    </Root>
                </Desktop>
                <Tablet>
                    <Root>
                        <Title>Registrarse</Title>
                        <Form width='600px' height='500px' alignV={'flex-start'} columWidth='45%'  fields={registerFields} submitButton='Registrarse' submitFunction={submit} />    
                        <HorizontalSeparator width='300px'>o</HorizontalSeparator>
                        <TextRegister>¿Ya tienes cuenta? <RegisterLink href='/login'>Iniciar sesión</RegisterLink></TextRegister>
                    </Root>
                </Tablet>
                <Mobile>
                    <Root>
                        <Title fontSize='28px'>Registrarse</Title>
                        <Form width='250px'  fields={registerFields} submitButton='Registrarse' submitFunction={submit}/>
                        <HorizontalSeparator width='250px'>o</HorizontalSeparator>
                        <TextRegister>¿Ya tienes cuenta? <RegisterLink href='/login'>Iniciar sesión</RegisterLink></TextRegister>
                    </Root>
                </Mobile>
            </React.Fragment>
    );
}

function mapState(state) {
    const { isFetching, registerFields } = state.register;
    return { isFetching, registerFields };
}

const actionCreators = {
    register: registerActions.register,
    getFields: registerActions.getFields,
    alertMessage: alertActions.error
};

export default connect(mapState, actionCreators)(Register);