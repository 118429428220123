/* General imports */
import React from 'react'
import styled from 'styled-components';

/* Components imports */
import Fade from 'react-reveal/Fade';


/* Styled Components */

const Root = styled.div`
    width: 100%;
    height: ${(props) => props.height ? props.height : '50vh'};
    display: flex;
    position:relative;
    align-items: center;
    justify-content: center;
    font-family: 'IBM Plex Sans';
    background: ${(props) => props.invertBg ? '#FFF' : '#001554'};
    transform: translateY(-130px);
    box-sizing: border-box;
`

const Content = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-left: 100px;
    padding-right: 40%;
    width: 90%;
    height: 280px;
    justify-content: center;
    @media (max-width: 600px) {
        width: 95%;
        padding: 0 10px;
    }
`

const ContentImage = styled.img`
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
    transform: ${(props) => props.invertBg ? 'rotate(180deg)' : 'none'};
    @media (max-width: 600px) {
        object-position: left;
        /* transform: rotate(180deg) translateX(-100px); */
        width: 100vw;
    }
`

const Text = styled.div`
    color: ${(props) => props.color ? props.color : '#2196F3'}!important;
    font-size: 3rem;
    margin: 0;
    @media (max-width: 600px) {
        font-size: 2rem;
        text-align: center;
    }
`

const Advantage = (props) => {

    return (
        props.advantage && 
        <Root height={props.height} invertBg={props.invertBg}>
            <ContentImage invertBg={props.invertBg} src={props.bg} alt="Background content" />
            <Content>
                <Fade left>
                    <Text color={props.color} dangerouslySetInnerHTML={{ __html: props.advantage }} />
                </Fade>
            </Content>
            
        </Root>
    );
}

export default Advantage;