/* General imports */
import React from 'react';
import styled from 'styled-components';
import { alertActions } from '../alert/AlertActions';
import { connect } from 'react-redux';
import axios from 'axios';
import  { authHeader } from '../app/auth-header';

/* Components imports */
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import ImageInput from '../form/ImageInput';


/* Styled components */
const Root = styled.form`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 20px;
    flex-direction: column;
    background-color: white;
`

const BoxContainer = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: auto;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
`

const Box = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 20px;
`


const IconAction = styled.button`
    position: absolute;
    display: flex;
    width: 40px;
    height: 40px;
    top: 5px;
    right: ${(props) => props.right ? props.right : '10px'};
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    background: transparent;
    svg {
        font-size: 35px;
        cursor: pointer;
        z-index: 999;
    }
`

const StyledFormControl = styled(FormControl)`
  width: 100%;
`

/* ----- Principal function ----- */
function NewSupplier({user, rowData, alertMessage, history }) {
 
    const [ dataForm, setDataForm ] = React.useState(rowData ? rowData : { 
        fantasyName: '',
        name: '',
        cuit: '',
        iva: '',
        address: '',
        city: '',
        phone: '',
        web: '',
        bonus: 0,
        idCurrencyUnit: '',
        currencyValue: 0,
        grossIncomePerception: 0,
        freight: 0,
        taxValue: 0,
        margin: 0,
        observation: '',
        img: null
    })

    const [currencyUnits, setCurrencyUnits] = React.useState(null);

    const [ ivaConditions, setIvaConditions ] = React.useState(null);    

    let configHeader = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    const onChange = (event) => {
        const {name, value} = event.target;
        setDataForm({
            ...dataForm,
            [name]: value
        })
      }

      const handleImage = (name, value) => {
        setDataForm({
          ...dataForm,
          img: value
        })
      }

    const cancel = (e) => {
        e.preventDefault();
        history.push('/admin/suppliers')
    }

    const save = (e) => {
        e.preventDefault();
        if(rowData){
            axios.put( `/api/suppliers/${rowData.idSupplier}` , {
                ...dataForm,
                updatedUser: user.idUser
            }, configHeader)
            .then(response => {
                alertMessage("Proveedor actualizado");
                history.push('/admin/suppliers');
            })
            .catch(error => {
                alertMessage("Hubo un error al crear el proveedor.");
                console.log(error);
            })
        } else {
            axios.post( '/api/suppliers' , {
                ...dataForm,
                createdUser: user.idUser
            }, configHeader)
            .then(response => {
                alertMessage("Proveedor creado");
                history.push('/admin/suppliers');
            })
            .catch(error => {
                alertMessage("Hubo un error al crear el proveedor.");
                console.log(error);
            })
        }
    } 

    React.useEffect(() => {
        if(!currencyUnits){
            axios.get('/api/valuelist/group/unitsCurrency',configHeader)
            .then(currencyData => {
                const currencyUnits_temp = [];
                currencyData.data.valueslist.forEach(option => {
                    currencyUnits_temp.push({
                    name: option.value,
                    key: option.key,
                    value: option.idValueList
                    })
                });
                setCurrencyUnits(currencyUnits_temp);
            })
            .catch(err => {
                alert("Hubo un error al cargar las unidades de la moneda.")
            })
        }
    }, [])

    React.useEffect(() => {
        let configHeader = {
            headers: { ...authHeader(), 'Content-Type': 'application/json' }
        }
        if(!ivaConditions){
            axios.get(`/api/valuelist/group/ivaConditions`,configHeader)
            .then(ivaConditionsData => {
                const ivaConditionsList = [];
                ivaConditionsData.data.valueslist.forEach(option => {
                    ivaConditionsList.push({
                        name: option.value,
                        key: option.key,
                        value: option.idValueList
                    })
                });
                setIvaConditions(ivaConditionsList);
            })
            .catch(err => {
                console.log(err);
            })
        }
    }, [ ivaConditions ])

    return(
        <Root onSubmit={save}>
            <IconAction type="submit" right='60px'><SaveIcon /></IconAction>
            <IconAction ><CancelIcon onClick={cancel} /></IconAction>

            <BoxContainer>
                <Box>
                    <TextField fullWidth margin="normal" id="fantasyName" name="fantasyName" label="Nombre de fantasía" type='text' value={dataForm.fantasyName} onChange={onChange} />
                    <TextField fullWidth margin="normal" id="name" name="name" label="Nombre" type='text' value={dataForm.name} onChange={onChange} required />
                    <TextField fullWidth margin="normal" id="cuit" name="cuit" label="CUIT" type='text' value={dataForm.cuit} onChange={onChange} />
                    {/* <TextField fullWidth margin="normal" id="iva" name="iva" label="IVA" type='text' value={dataForm.iva} onChange={onChange} /> */}
                    <StyledFormControl required>
                        <InputLabel id="iva-label"> Cond. de IVA </InputLabel>
                        <Select
                            native
                            labelId="iva-label"
                            
                            onChange={onChange}
                            required
                            inputProps={{
                            id:"iva",
                            name:"iva",
                            value: dataForm.iva
                            }}
                        >
                            <option value="" />
                            {
                                ivaConditions && ivaConditions.map((ic, index) =>
                                    <option key={`iva_option_${index}`} value={ic.value}> {`${ic.key} (${ic.name})`} </option>
                                )
                            }
                        </Select>
                    </StyledFormControl>
                    <TextField fullWidth margin="normal" id="address" name="address" label="Dirección" type='text' value={dataForm.address} onChange={onChange} />
                    <TextField fullWidth margin="normal" id="city" name="city" label="Ciudad" type='text' value={dataForm.city} onChange={onChange} />
                    <TextField fullWidth margin="normal" id="phone" name="phone" label="Tel./Cel." type='text' value={dataForm.phone} onChange={onChange} />
                    <TextField fullWidth margin="normal" id="web" name="web" label="Web" type='text' value={dataForm.web} onChange={onChange} />
                </Box>
                <Box>
                    <TextField fullWidth margin="normal" id="bonus" name="bonus" label="Bonificación" type='number' value={dataForm.bonus} onChange={onChange} />
                    <StyledFormControl required>
                        <InputLabel id="idCurrencyUnit-label"> Tipo moneda </InputLabel>
                        <Select
                            native
                            labelId="idCurrencyUnit-label"
                            onChange={onChange}
                            required
                            inputProps={{
                                id:"idCurrencyUnit",
                                name:"idCurrencyUnit",
                                value:dataForm.idCurrencyUnit
                            }}
                        >
                            <option value="" />
                            {
                                currencyUnits &&
                                currencyUnits.map((cu, index) => 
                                    <option key={`currency_value_option_${index}`} value={cu.value}> {`${cu.key} (${cu.name})`} </option>
                                )
                            }
                        </Select>
                    </StyledFormControl>
                    <TextField fullWidth margin="normal" id="currencyValue" name="currencyValue" label="Valor moneda" type='number' value={dataForm.currencyValue} onChange={onChange} required />
                    <TextField fullWidth margin="normal" id="grossIncomePerception" name="grossIncomePerception" label="Percep. IIBB" type='number' value={dataForm.grossIncomePerception} onChange={onChange} />
                    <TextField fullWidth margin="normal" id="freight" name="freight" label="Flete" type='number' value={dataForm.freight} onChange={onChange} />
                    <TextField fullWidth margin="normal" id="taxValue" name="taxValue" label="Valor fizcal" type='number' value={dataForm.taxValue} onChange={onChange} />
                    <TextField fullWidth margin="normal" id="margin" name="margin" label="Margen" type='number' value={dataForm.margin} onChange={onChange} />
                    <TextField fullWidth margin="normal" id="observation" name="observation" label="Observación" type='text' value={dataForm.observation} onChange={onChange} /> 
                </Box>
                <Box>
                    <ImageInput id="img" name="img" image={dataForm.img} handleImage={handleImage} />
                </Box>
            </BoxContainer>            
        </Root>
    );
}

function mapState(state) {
    const { user } = state.login;
    return { user };
}

const actionCreators = {
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(NewSupplier);