import { patientsConstants } from './PatientsConstants';

let patients = JSON.parse(localStorage.getItem('patients'));
const initialState = patients ? 
        { 
            error: false,
            isFetching: false,
            patients: patients
        }
    : 
        { 
            error: false,
            isFetching: true
        };

export function patient(state = initialState, action) {
    switch (action.type) {
        case patientsConstants.FETCHING:
            return { 
                ...state,
                isFetching: true
            };
        case patientsConstants.SUCCESS:
            return { 
                ...state,
                isFetching: false,
                patients: action.patients
            };
        case patientsConstants.SUCCESS_PROFESSIONALSLIST:
            return { 
                ...state,
                isFetching: false,
                professionalsList: action.professionalsList
            };
        case patientsConstants.SUCCESS_ASSIGNPROFESSIONALS:
            return { 
                ...state,
                isFetching: false,
                assignProfessionals: action.assignProfessionals
            };
        case patientsConstants.SUCCESS_ACTIVITIESLIST:
            return { 
                ...state,
                isFetching: false,
                activitiesList: action.activitiesList
            };
        case patientsConstants.ERROR:
            return { 
                ...state,
                error: true
            };
        case patientsConstants.FETCHING_FORMFIELDS:
            return { 
                ...state,
                isFetchingFields: true
            };
        case patientsConstants.SUCCESS_FORMFIELDS:
            return { 
                ...state,
                isFetchingFields: false,
                patientFields: action.patientFields,
                error: false
        };
        case patientsConstants.SUCCESS_UPDATEFORMFIELDS:
            return { 
                ...state,
                isFetchingFields: false,
                patientUpdateFields: action.patientUpdateFields,
                error: false
        };
        case patientsConstants.ERROR_FORMFIELDS:
            return {
                ...state,
                error: true
        };
        default:
            return state
    }
}