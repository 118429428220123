import { stockConstants } from './StockConstants';
import { alertActions } from '../../alert/AlertActions';
import axios from 'axios';
import  { authHeader } from '../../app/auth-header';

export const stockActions = {
    updateStock,
    getStock,
    getCurrencyUnits,
    getQuantityUnits
};

function updateStock(idProduct, idStorage, data) {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    return dispatch => {
        axios
        .put( '/api/stock/' + idProduct + '/' + idStorage, {
            quantity: data.quantity || 0,
            minStockQuantity: data.minStockQuantity || 0,
            updatedUser: data.updatedUser
            }, config)
        .then(response => {
            dispatch(alertActions.success('Stock actualizado'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}

function getStock() {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return dispatch => {
        dispatch({ type: stockConstants.FETCHING });
        axios.get('/api/stock',config)
        .then(stockData => {
            const stock = stockData.data.stock;
            dispatch({ type: stockConstants.SUCCESS, stock });
        })
        .catch(err => {
            dispatch({ type: stockConstants.ERROR });
            dispatch(alertActions.error('Hubo un error...'));
        })
        
    }
}

function getCurrencyUnits() {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return dispatch => {
        dispatch({ type: stockConstants.FETCHING_FORMFIELDS });
        axios.get('/api/valuelist/group/'+stockConstants.CURRENCY_GROUP,config)
        .then(currencyData => {
            const currencyUnits = [];
            currencyData.data.valueslist.forEach(option => {
                currencyUnits.push({
                  name: option.value,
                  key: option.key,
                  value: option.idValueList
                })
            });
            dispatch({ type: stockConstants.SUCCESS_CURRENCYUNITS, currencyUnits });
        })
        .catch(err => {
            dispatch({ type: stockConstants.ERROR });
            dispatch(alertActions.error('Hubo un error...'));
        })
    }
}

function getQuantityUnits() {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return dispatch => {
        dispatch({ type: stockConstants.FETCHING_FORMFIELDS });
        axios.get('/api/valuelist/group/'+stockConstants.QUANTITY_GROUP,config)
        .then(quantityData => {
            const quantityUnits = [];
            quantityData.data.valueslist.forEach(option => {
                quantityUnits.push({
                  name: option.value,
                  key: option.key,
                  value: option.idValueList
                })
            });
            dispatch({ type: stockConstants.SUCCESS_QUANTITYUNITS, quantityUnits });
        })
        .catch(err => {
            dispatch({ type: stockConstants.ERROR });
            dispatch(alertActions.error('Hubo un error...'));
        })
    }
}
