import React from 'react'
import styled from 'styled-components'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'

/* Styled components */
const StyledLoader = styled(Loader)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
`


/* ----- Principal function ----- */
function Spinner({spinnerType, color, height, width}) {

    return(
        <StyledLoader
            type={spinnerType !== undefined ? spinnerType : 'Puff'}
            color={color ? color : 'black'}
            height={height ? height : 100}
            width={width ? width : 100}
            timeout={3000} //3 secs
        />
    );
}

export default Spinner;