/* General imports */
import React from 'react';
import styled from 'styled-components';
import axios from 'axios';

/* Components imports */
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Cancel from '@material-ui/icons/Cancel';
import NoImage from '../images/no-image.png'

/* Styled components */
const Root = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
`

const Placeholder = styled.span`
    color: rgba(0,0,0,0.54);
`

const ImageContainer = styled.div`
    position: relative;
    display: flex;
    width: 150px;
    height: 150px;
    margin-bottom: 10px;
    img {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`

const UploadImage = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 10px 0;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    box-sizing: border-box;
`

const Input = styled.input`
    font-size: 0.75rem;
    width: 100%;
`

const Button = styled.button`
    border: none;
    background: transparent;
    cursor: pointer;
    outline: none;
`

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
`

const CancelButton = styled.button`
    position: absolute;
    display: flex;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 5px;
    border: none;
    background: transparent;
    color: red;
    opacity: 0.5;
    &:hover {
        opacity: 1;
    }
`

function ImageInput(props) {

    const inputRef = React.useRef();

    const [ selectedFile, setSelectedFile ] = React.useState(null);

    const fileSelectedFileHandler = (e) => {
        setSelectedFile(e.target.files[0]);
    }

    const uploadImage = (name) => {
        const formData = new FormData();
        formData.append('image', selectedFile);
        axios.post("/api/images/upload", formData, {
        }).then(res => {
            const url = '/uploads/'+res.data.filename;
            props.handleImage(name, url);
        })
        .catch(error => {
            alert('Error al cargar la imagen.');
            console.log(error.toString());
        })
    }

    const cancelImage = (e) => {
        e.preventDefault();
        props.handleImage(props.name, null);
    }

    const fileUploadHandler = (e) => {
        e.preventDefault();
        if(!props.image){
            uploadImage(inputRef.current.name);
        } else {
            // eslint-disable-next-line no-restricted-globals
            if(confirm('¿Desea subir esta nueva imagen y reemplazar la anterior?')){
                uploadImage(inputRef.current.name);
            }
        }
        
    }

    return (
        <Root>
            {
                props.placeholder && <Placeholder>{props.placeholder}</Placeholder>
            }
            <ImageContainer>
                <Image src={props.image ? props.image : NoImage} alt="uploaded image" />
                {
                    selectedFile && <CancelButton onClick={cancelImage}><Cancel /></CancelButton>
                }
            </ImageContainer>
            <UploadImage>
                <Input ref={inputRef} id={props.id} name={props.name} type="file" accept="image/png, image/jpeg, image/webp" onChange={fileSelectedFileHandler} />
                <Button onClick={fileUploadHandler} disabled={!selectedFile}><CloudUploadIcon /></Button>
            </UploadImage>
        </Root>
    );
}

export default ImageInput;