import { pointPurchaseConstants } from './PointPurchaseConstants';
import { alertActions } from '../alert/AlertActions';
import axios from 'axios';
import  { authHeader } from '../app/auth-header';

export const pointPurchaseActions = {
    setItems,
    collectPurchase
};

function setItems(items) {
    return dispatch => {
        localStorage.setItem('purchaseitems', JSON.stringify(items));
        dispatch({ type: pointPurchaseConstants.ADD, items });
    };
}

function collectPurchase(purchaseData) {
    let configHeader = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return dispatch => {
        const items = localStorage.getItem('purchaseitems');
        dispatch({ type: pointPurchaseConstants.FETCHING });
        axios.post(`/api/purchases`, {items, purchaseData} ,configHeader)
        .then(result => {
            dispatch({ type: pointPurchaseConstants.SUCCESS });
        })
        .catch(err => {
            dispatch({ type: pointPurchaseConstants.ERROR });
            dispatch(alertActions.error('Hubo un error...'));
        })
        dispatch({ type: pointPurchaseConstants.SUCCESS });
    };
}