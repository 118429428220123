/* General imports */
import React from 'react';
import styled, {keyframes} from 'styled-components';
import { config } from '../../app/config';

/* Components imports */
import HeaderBackground from '../../images/Template_ProductoDestacado_Header.png';
import NoImage from '../../images/no-image.png';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Advantage from './Advantage';
import SpecialFeatures from './SpecialFeatures';
import ThreeImages from './ThreeImages';
import BackgroundNovelty from '../../images/Background_Novelty.png';
import ByProduct from './ByProduct';
import ReactPlayer from 'react-player/lazy'
import FullscreenIcon from '@material-ui/icons/Fullscreen';

/* Styled components */
const appearAnim = keyframes`
  0% {
    opacity: 1;
    border: none;
  }

  45% {
    width: 100%;  
    opacity: 1;
    border: none;
  }

  50% {
    width: 100%;
    left: 0;  
    border: none;
  }

  51% {
    right:0;
      left: unset;
  }

  60% {
      right:0;
      left: unset;
      border: none;
  }


  75% {
    right:0;
    left: unset;
    opacity: 1;
    border: none;
  }

  95% {
    right:0;
    left: unset;
    width: 55%;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
    border: none;
  }

  100% {
    right:0;
    left: unset;
    width: 45%;
    opacity: 0;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
    border: none;
  }
`;

const imageAnim = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const textAnim = keyframes`
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const bgAnim = keyframes`
  0% {
    background-color: transparent;
  }

  100% {
    background-color: #F1F2F5;
  }
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100vw;
    height: 432px;
    background: ${`url(${HeaderBackground})`};
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding: 0 70px;
    box-sizing: border-box;
    @media (max-width: 600px) {
        padding: 50px 20px 0 20px;
        min-height: 264px;
    }
`

const ProductName = styled.h2`
    width: 100%;
    text-align: left;
    font-weight: 300;
    font-size: 2.5rem;
    color: #FFF;
    margin: 0;
    transform: translateY(-100px);
    @media (max-width: 600px) {
        font-size: 1.75rem;
        transform: translateY(-80px);
    }
`

const ProductContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* box-shadow: 0px 0px 5px rgba(0,0,0,0.5); */
    height: auto;
    width: 80%;
    transform: translateY(-230px);
    @media (max-width: 600px) {
        width: 90%;
        transform: translateY(-100px);
    }
`

const ProducDescription = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 0.45fr 0.55fr;
    width: 100%;
    height: 500px;
    background: transparent;
    z-index: 1;
    @media (max-width: 600px) {
        grid-template-columns: 1fr;
        height: auto;
    }
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background-color: #F1F2F5;
        border: none;
        animation: ${appearAnim};
        animation-duration: 2.5s;
        animation-fill-mode: forwards;
    }
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        animation: ${bgAnim};
        animation-delay: 0.9s;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        background: transparent;
    }
    @media (max-width: 600px) {
        &:after {
            display: none;
        }
        &:before {
            animation: none;
        }
    }
`

const ProductImage = styled.img`
    position: relative;
    height: 500px;
    width: 100%;
    opacity: 0;
    object-position: center;
    object-fit: cover;
    animation: ${imageAnim};
    animation-delay: 1s;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    @media (max-width: 600px) {
        height: 250px;
        width: 337px;
        object-fit: cover;
        opacity: 1;
        animation: none;
    }
`

const BoxDescription = styled.div`
    position: relative;
    display: flex;
    opacity: 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    box-sizing: border-box;
    padding: 0px 100px 20px 50px;
    animation: ${textAnim};
    animation-delay: 2.5s;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    @media (max-width: 600px) {
        padding: 10px;
        animation-delay: 0.3s;
        height: auto;
    }
`

const Trademark = styled.img`
    width: auto;
    height: 120px;
    object-fit: contain;
    object-position: center;
    @media (max-width: 600px) {
        height: 100px;
    }
`

const ProductText = styled.div`
    position: relative;
    text-align: left;
    width: 100%;
    height: auto;
    max-height: 280px;
    overflow-y: hidden;
    box-sizing: border-box;
    color: #00002C;
    font-size: 1rem;
    font-weight: 300;
    padding: 20px 10px 20px 20px;
    @media (max-width: 600px) {
        padding: 0;
        max-height: none;
    }
`

const Access = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 70px;
    box-sizing: border-box;
    padding: 0 20px;
    span {
        margin: 0 10px;
        color: #2196F3;
    }
    @media (max-width: 600px) {
        padding: 0;
    }
`

const AccessLink = styled.a`
    display: flex;
    align-items: center;
    color: #2196F3;
    font-size: 1.2rem;
    cursor: pointer;
    text-decoration: none;
    svg {
        margin-right: 10px;
    }
    @media (max-width: 600px) {
        font-size: 1rem;
        svg {
            margin-right: 5px;
        }
    }
`

const DataSheet = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    height: auto;
    background: #FFF;
    box-sizing: border-box;
    padding: 20px 50px;
    gap: 10px 20px;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
    @media (max-width: 600px) {
        display: none;
        grid-template-columns: 1fr;
        padding: 20px 10px;
    }
`

const HeaderDataSheet = styled.h3`
    width: 100%;
    font-weight: 500;
    height: 40px;
    font-size: 1.2rem;
    color: #2196F3;
    border-bottom: 1px solid #2196F3;
    grid-column: 1/3;
    @media (max-width: 600px) {
        grid-column: 1/2;
    }
`

const Feature = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: auto;
    min-height: 35px;
    font-size: 1rem;
    border-bottom: 1px solid #213781;
`

const FeatureName = styled.span`
    color: #213781;
    font-weight: 400;
`

const SeparatorFeature = styled.span`
    color: #213781;
    font-weight: 400;
    margin: 0 10px;
`

const FeatureDescription = styled.span`
    color: #707070;
    font-weight: 300;
`

const Breadcrumbs = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: auto;
    height: 50px;
    font-family: 'IBM Plex Sans';
    font-weight: 300;
    font-size: 1.2rem;
    color: #FFFFFF;
    transform: translateY(-100px);
    svg {
        font-size: 12px;
        margin: 0 5px;
    }
    @media (max-width: 600px) {
        display: none;
    }
`

const Video = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 1024px;
    height: 648px;
    max-height: 648px;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    @media (max-width: 600px) {
        width: 100%;
        height: auto;
        min-height: 300px;
        transform: translateY(0);
    }
`

const FullScreen = styled.button`
    position: absolute;
    bottom: 30px;
    right: 0;
`

const ProductTemplate1 = ({productData, principalFeatures, advantage, isDesktop}) => {
    
    const handleClickFullscreen = () => {
        var el = document.getElementById("full-screenVideo");
            if (el.requestFullscreen) {
            el.requestFullscreen();
            } else if (el.msRequestFullscreen) {
            el.msRequestFullscreen();
            } else if (el.mozRequestFullScreen) {
            el.mozRequestFullScreen();
            } else if (el.webkitRequestFullscreen) {
            el.webkitRequestFullscreen();
            }
      };

    return (
        <React.Fragment>
            <Header>
                <Breadcrumbs><span>Inicio</span><ArrowForwardIosIcon /><span>Productos</span><ArrowForwardIosIcon /><span>{productData.id_Category.name}</span><ArrowForwardIosIcon /><span style={{fontWeight: 400}}>{productData.name}</span></Breadcrumbs>
                <ProductName>{productData.name}</ProductName>
            </Header>
            <ProductContainer>
                <ProducDescription>
                    <ProductImage src={productData.img && productData.img.images.length > 0 ? productData.img.images[0] : NoImage} alt={productData.name} />
                    <BoxDescription>
                        {
                            (!productData.byproducts || productData.byproducts === '') &&
                            <Trademark src={productData.trademark && productData.trademark.img ? productData.trademark.img : NoImage} alt={productData.trademark ? productData.trademark.name : 'marca'} />
                        }
                        <ProductText dangerouslySetInnerHTML={{ __html: productData.description }} />
                        <Access>
                            {/* <AccessLink href="#"><DescriptionOutlinedIcon />Descargar instructivo</AccessLink>
                            <span>|</span> */}
                            <AccessLink href={`https://api.whatsapp.com/send?phone=${config.whatsapp}&text=Hola%2C%20quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20producto ${config.url}/product/${productData.idProduct}`} target="_blank" >Consultar</AccessLink>
                        </Access>
                    </BoxDescription>
                </ProducDescription>
                {
                    productData.features && productData.features.length > 0 &&
                    <DataSheet>
                    <HeaderDataSheet>Ficha técnica</HeaderDataSheet>
                        {
                            productData.features.map((fea, index) =>
                                fea.name !== 'Especial' && fea.name !== 'Ventaja' &&
                                <Feature key={`feature_${index}`}>
                                    <FeatureName>{fea.name}</FeatureName>
                                    <SeparatorFeature>|</SeparatorFeature>
                                    <FeatureDescription>{fea.value}</FeatureDescription>
                                </Feature>
                            )
                        }
                    </DataSheet>
                }
            </ProductContainer>
            {
                productData.promo && productData.promo !== '' &&
                <Advantage color="#2196F3" height="60vh" advantage={productData.promo} bg={BackgroundNovelty} invertBg={true} />
            }
            {
                productData.byproducts && productData.byproducts !== '' &&
                productData.byproducts.split(",").map((idProd, index) => 
                    <ByProduct key={`byproduct_${index}`} idProduct={idProd} />
                )
            }
            {
                productData.video && productData.video !== '' &&
                <Video>
                    <ReactPlayer 
                        id="full-screenVideo"
                        url={productData.video} 
                        autoPlay 
                        loop={true} 
                        muted={true} 
                        volume={0}
                        width='100%'
                        height='80%'
                        config={{
                            youtube: {
                                playerVars: { showinfo: 1, autoplay: 1, loop: 1, fs: 1 }
                            }
                        }}
                    />
                    <FullScreen onClick={handleClickFullscreen}><FullscreenIcon /></FullScreen>
                </Video>
            }
            {
                productData.img && productData.img.images.length > 1 &&
                <ThreeImages images={productData.img.images} isDesktop={isDesktop} />
            }
            
            {
                principalFeatures && 
                <SpecialFeatures features={principalFeatures} invertColor={false} />
            }
        </React.Fragment>
    );
}

export default ProductTemplate1;