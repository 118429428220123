import { categoryConstants } from './CategoryConstants';

let categories = JSON.parse(localStorage.getItem('categories'));
const initialState = categories ? 
        { 
            error: false,
            isFetching: false,
            categories: categories
        }
    : 
        { 
            error: false,
            isFetching: true,
        };

export function category(state = initialState, action) {
    switch (action.type) {
        case categoryConstants.FETCHING:
            return { 
                ...state,
                isFetching: true
            };
        case categoryConstants.SUCCESS:
            return { 
                ...state,
                isFetching: false,
                categories: action.categories
            };
        case categoryConstants.ERROR:
            return { 
                ...state,
                error: true
            };
        case categoryConstants.FETCHING_FORMFIELDS:
            return { 
                ...state,
                isFetchingFields: true
            };
        case categoryConstants.SUCCESS_FORMFIELDS:
            return { 
                ...state,
                isFetchingFields: false,
                categoryFields: action.categoryFields,
                error: false
        };
        case categoryConstants.SUCCESS_UPDATEFORMFIELDS:
            return { 
                ...state,
                isFetchingFields: false,
                categoryUpdateFields: action.categoryUpdateFields,
                error: false
        };
        case categoryConstants.ERROR_FORMFIELDS:
            return {
                ...state,
                error: true
        };
        default:
            return state
    }
}