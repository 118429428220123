import { accountsConstants } from './AccountsConstants';
import { alertActions } from '../alert/AlertActions';
import axios from 'axios';
import  { authHeader } from '../app/auth-header';
/* import { createLogger } from 'redux-logger'; */

export const accountsActions = {
    createAccount,
    updateAccount,
    getAccounts,
    deleteAccount,
    getFields
};

function createAccount(account) {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return dispatch => {
        axios.post( '/api/accounts' , {
            idUser: account.idUser,
            idAccountType: account.idAccountType,
            balance: account.balance,
            observation: account.observation,
            createdUser: account.createdUser,
            deleted: 0
        }, config)
        .then(response => {
            dispatch(alertActions.success('Cuenta creada'));
        })
        .catch(error => {
            if(error.response.data.msg){
                dispatch(alertActions.error(error.response.data.msg));
            }else{
                dispatch(alertActions.error(error.toString()));
            }
            

        })
    };
}

function updateAccount(account) {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    return dispatch => {
        axios.put( '/api/accounts/'+ account.idAccount, {
            idAccountType: account.idAccountType,
            idUser: account.idUser,
            balance: account.balance,
            observation: account.observation,
            updatedUser: account.updatedUser,
            deleted: 0
        }, config)
        .then(response => {
            dispatch(alertActions.success('Cuenta actualizada'));
        })
        .catch(error => {
            dispatch(alertActions.error(error.toString()));
        })
    };
}

function getAccounts() {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return dispatch => {
        dispatch({ type: accountsConstants.FETCHING });
        axios.get('/api/accounts',config)
        .then(accountsData => {
            const accounts = accountsData.data.accounts
            dispatch({ type: accountsConstants.SUCCESS, accounts });
        })
        .catch(err => {
            dispatch({ type: accountsConstants.ERROR });
            dispatch(alertActions.error('Hubo un error...'));
        })
    }
}

function deleteAccount(account) {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    return dispatch => {
        axios.put( '/api/accounts/'+ account.idAccount, {
            idAccountType: account.idAccountType,
            balance: account.balance,
            observation: account.observation,
            updatedUser: account.updatedUser,
            deleted: 1
        }, config)
        .then(response => {
            dispatch(alertActions.success('Cuenta eliminada'));
        })
        .catch(error => {
            dispatch(alertActions.error(error.toString()));
        })
    };
}

function getFields() {

    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    return dispatch => {
        dispatch({ type: accountsConstants.FETCHING_FORMFIELDS });
        axios.get('/api/accountsFields', config)
        .then(response => {
            const accountsFields = response.data.accountsFields;
            dispatch({ type: accountsConstants.SUCCESS_FORMFIELDS, accountsFields });
        })
        .catch(error => {
            dispatch(alertActions.error(error));
        })
    }
}
