/* General imports */
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { devices, config } from '../../app/config'
import axios from 'axios'
import  { authHeader } from '../../app/auth-header';

/* Components imports */
import NoImage from '../../images/no-image.png'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';


/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}
const HeightHeader = '70px'

/* Styled components */
const ContentBox = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: calc(100% - ${HeightHeader});
    align-items: center;
    justify-content: center;
`

const Header = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: ${HeightHeader};
    padding: 0 25px;
    box-sizing: border-box;
    background: #234;
`

const HeaderTitle = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 60%;
    height: 100%;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    text-shadow: 1px 1px 2px black;
`

const HeaderButtons = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 40%;
    height: 100%;
`

const WrapBox = styled.div`
    position: relative;
    display: flex;
    height: 400px;
    width: ${(props) => props.flag === 1 ? '500px' : '400px'};
    box-sizing: border-box;
    border-radius: 10px;
    transition: all .5s ease-in-out;
    box-shadow: 5px 5px 15px black;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 5px;
`

const NameButton = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 70px;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 25px 0 25px;
    box-sizing: border-box;
`

const ZoneSubButtons = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 330px;
    align-items: center;
    justify-content: center;
`

const Box = styled.div`
    position: relative;
    display: ${(props) => props.flag === 1 ? 'flex' : 'none'};;
    height: 100%;
    width: ${(props) => props.flag === 1 ? '100%' : '50%'};
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    border-radius: ${(props) => props.borderRadius ? props.borderRadius : 0};
    h4 {
        margin: 20px 0 0 0;
    }
`

const ButtonHeader = styled.button`
    margin: 0 5px;
`

const Inputbox = styled.div`
    width: ${(props) => props.columWidth ? props.columWidth : '100%'};
    position: relative;
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 16px;
    box-sizing: border-box;
    height: 44px;
    border-bottom: 1px solid black;
    ${(props) => props.error ? 'border-color: red;' : ''};
    margin: ${(props) => props.margin ? '10px 10px 0 10px' : 0};
    label {
        position: absolute;
        top: ${(props) => props.type === 'date' ? '-10px' : props.effect ? '0px' : '20px' };
        left: 0;
        color: #ccc;
        transition: .5s;
        pointer-events: none;
        font-size: ${(props) => props.type === 'date' ? '14px' : props.effect ? '14px' : '16px'};
        margin-left: ${(props) => props.icon ? '40px' : 0};
        height: 16px;
    }

    input {
        border: none;
        outline: none;
        background: none;
        color: black;
        font-size: 16px;
        width: 100%;
        padding-left: ${(props) => props.icon ? '5px' : 0};
        margin-top: 14px;
        box-sizing: border-box;
        height: 24px;
    }

    input:focus + label {
        top: 0px;
        color: ${(props) => props.effectColor ? props.effectColor : 'black'};
        font-size: 14px;
    }

    select:focus + label {
        top: 0px;
        color: ${(props) => props.effectColor ? props.effectColor : 'black'};
        font-size: 14px;
    }
`

const CancelImg = styled.div`
    position: absolute;
    display: none;
    width: 30px;
    height: 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 50%;
    background-color: #ff000080;
    align-items: center;
    justify-content: center;
    opacity: 0;
    user-select: none;
    cursor: pointer;
`

const ImageContainer = styled.div`
    position: relative;
    display: flex;
    width: 100px;
    height: 100px;
    margin-top: 20px;
    margin-bottom: 10px;
    img {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    ${(props) => props.active ?
    `&:hover {
        ${CancelImg} {
            display: flex;
            opacity: 1;
        }
        img {
            opacity: 0.4;
        }
    }`
    :
    ''
    }
`

const UploadImage = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    box-sizing: border-box;
`

const CheckOneButton = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: ${(props) => props.width ? props.width : '100%'};
    label {
        margin-left: 5px;
    }
`

/* ----- Principal function ----- */
function ButtonsAccessSettings({match, history, user}) {

    let configHeader = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    const { idPage, idSection } = match.params

    const [ image, setImage ] = React.useState('')

    const [data, setData] = React.useState(null)

    const onBack = () => {
        history.push(config.webeditorpath+'/page/'+idPage)
    }

    const handleChangeGroupName = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        })
    }

    const handleCheckButtonType = (e) => {
        const { name, checked } = e.target;
        setData({
            ...data,
            [name]: checked ? 0 : 1
        })
    }

    const onFileChange = (e) => {
        setImage(e.target.files[0])
    }

    const handleFileUpload = (e) => {
        e.preventDefault()
        const { name } = e.currentTarget.dataset;
        if(image !== '' ){
            if(!data[name]){
                const formData = new FormData()
                formData.append('image', image)
                axios.post("/api/images/upload", formData, configHeader)
                .then(res => {
                    const url_img = '/uploads/'+res.data.filename
                    setData({
                        ...data,
                        [name]: url_img
                    })
                })
                .catch(error => {
                    alert('Error al cargar la imagen. Sólo se soporta hasta 2MB y los tipos jpeg-jpg-png-gif-webp.')
                })
            } else {
                // eslint-disable-next-line no-restricted-globals
                if(confirm('¿Desea subir esta nueva imagen y reemplazar la anterior?')){
                    const formData = new FormData()
                    formData.append('image', image)
                    axios.post("/api/images/upload", formData, configHeader)
                    .then(res => {
                        const url_img = '/uploads/'+res.data.filename
                        setData({
                            ...data,
                            [name]: url_img
                        })   
                    })
                    .catch(error => {
                        alert('Error al cargar la imagen. Sólo se soporta hasta 2MB y los tipos jpeg-jpg-png-gif-webp.')
                    })  
                }
            }
        }else{
            alert("Debe selecionar una imagen...")
        }
    }

    const onSave = () => {
        const saveData = {
            data: data,
            updatedUser: user.idUser
        }
        axios.put("/api/updatedatasection/"+idSection, saveData, configHeader)
        .then(res => {
            history.push(config.webeditorpath+'/page/'+idPage)
        })
        .catch(error => {
            alert('Hubo un error...')
        })
    }

    const cancelImage = (e) => {
        e.preventDefault()
        const { name } = e.currentTarget.dataset;
        setData({
            ...data,
            [name]:''
        })
    }

    React.useEffect(() => {
        if(!data){
            axios.get('/api/sections/' + idSection,configHeader)
            .then(sectionData => {
                if(parseInt(Object.keys(sectionData.data.section.data).length) > 0){
                    setData(sectionData.data.section.data);
                } else {
                    setData({
                        buttontype1: 1,
                        groupName1: '',
                        buttontype2: 1,
                        groupName2: '',
                        buttontype3: 1,
                        groupName3: '',
                        text1: '',
                        link1: '',
                        img1: '',
                        text2: '',
                        link2: '',
                        img2: '',
                        text3: '',
                        link3: '',
                        img3: '',
                        text4: '',
                        link4: '',
                        img4: '',
                        text5: '',
                        link5: '',
                        img5: '',
                        text6: '',
                        link6: '',
                        img6: ''
                    })
                }
            })
            .catch(err => {
                alert('Hubo un error...');
            })
        }
    }, [data, idSection])

    return(
        <React.Fragment>
            <Header>
                {
                    <HeaderTitle>CONFIGURACIÓN BUTTONS ACCESS</HeaderTitle>
                }
                <HeaderButtons><ButtonHeader onClick={onBack}>VOLVER</ButtonHeader> <ButtonHeader onClick={onSave}>GUARDAR</ButtonHeader> </HeaderButtons>
            </Header>
            <Desktop>
                {
                    !data ? <h2>CARGANDO...</h2>
                    :
                    <ContentBox>
                        <WrapBox flag={data.buttontype1} borderRadius='left'>
                            <NameButton>
                                <Inputbox type='text' effect={ data.groupName1 !== '' ? true : false} columWidth='60%' >
                                    <input id='groupName1' required={true} name='groupName1' value={data.groupName1} type='text' onChange={handleChangeGroupName} />
                                    <label>Nombre principal</label>
                                </Inputbox>
                                <CheckOneButton width='40%'>
                                    <input id='buttontype1' name='buttontype1' checked={data.buttontype1 === 0 ? true : false} type='checkbox' onChange={handleCheckButtonType} />
                                    <label>Botón simple</label>
                                </CheckOneButton>
                            </NameButton>
                            <ZoneSubButtons>
                                <Box borderRadius={data.buttontype1 === 1 ? '0 0 0 10px' : '0 0 10px 10px'} flag={1}>
                                    <h4>BOTÓN 1</h4>
                                    <Inputbox type='text' effect={ data.text1 !== '' ? true : false} columWidth='85%' margin={true} >
                                        <input id='text1' required={true} name='text1' value={data.text1} type='text' onChange={handleChangeGroupName} />
                                        <label>Nombre</label>
                                    </Inputbox>
                                    <Inputbox type='text' effect={ data.link1 !== '' ? true : false} columWidth='85%' margin={true} >
                                        <input id='link1' required={true} name='link1' value={data.link1} type='text' onChange={handleChangeGroupName} />
                                        <label>Enlace</label>
                                    </Inputbox>
                                    <ImageContainer active={data.img1 ? true : false}>
                                        <img src={data.img1 ? data.img1 : NoImage} alt="Imagen subida" />
                                        {
                                            data.img1 && <CancelImg data-name='img1' onClick={cancelImage}> X </CancelImg>
                                        }
                                    </ImageContainer>
                                    <UploadImage>
                                        <input style={{fontSize: '0.75rem', width: '100%'}} id="img1" name="img1" type="file" onChange={onFileChange} /><CloudUploadIcon data-name='img1' onClick={handleFileUpload} />
                                    </UploadImage>
                                </Box>
                                <Box flag={data.buttontype1} borderRadius='0 0 10px 0'>
                                    <h4>BOTÓN 2</h4>
                                    <Inputbox type='text' effect={ data.text2 !== '' ? true : false} columWidth='85%' margin={true} >
                                        <input id='text2' required={true} name='text2' value={data.text2} type='text' onChange={handleChangeGroupName} />
                                        <label>Nombre</label>
                                    </Inputbox>
                                    <Inputbox type='text' effect={ data.link2 !== '' ? true : false} columWidth='85%' margin={true} >
                                        <input id='link2' required={true} name='link2' value={data.link2} type='text' onChange={handleChangeGroupName} />
                                        <label>Enlace</label>
                                    </Inputbox>
                                    <ImageContainer active={data.img2 ? true : false}>
                                        <img src={data.img2 ? data.img2 : NoImage} alt="Imagen subida" />
                                        {
                                            data.img2 && <CancelImg data-name='img2' onClick={cancelImage}> X </CancelImg>
                                        }
                                    </ImageContainer>
                                    <UploadImage>
                                        <input style={{fontSize: '0.75rem', width: '100%'}} id="img2" name="img2" type="file" onChange={onFileChange} /><CloudUploadIcon data-name='img2' onClick={handleFileUpload} />
                                    </UploadImage>
                                </Box>
                            </ZoneSubButtons>
                        </WrapBox>
                        <WrapBox flag={data.buttontype2}>
                            <NameButton>
                                <Inputbox type='text' effect={ data.groupName2 !== '' ? true : false} columWidth='60%' >
                                    <input id='groupName2' required={true} name='groupName2' value={data.groupName2} type='text' onChange={handleChangeGroupName} />
                                    <label>Nombre principal</label>
                                </Inputbox>
                                <CheckOneButton width='40%'>
                                    <input id='buttontype2' name='buttontype2' checked={data.buttontype2 === 0 ? true : false} type='checkbox' onChange={handleCheckButtonType} />
                                    <label>Botón simple</label>
                                </CheckOneButton>
                            </NameButton>
                            <ZoneSubButtons>
                                <Box flag={1} borderRadius={data.buttontype2 === 1 ? '0 0 0 10px' : '0 0 10px 10px'}>
                                    <h4>BOTÓN 1</h4>
                                    <Inputbox type='text' effect={ data.text3 !== '' ? true : false} columWidth='85%' margin={true} >
                                        <input id='text3' required={true} name='text3' value={data.text3} type='text' onChange={handleChangeGroupName} />
                                        <label>Nombre</label>
                                    </Inputbox>
                                    <Inputbox type='text' effect={ data.link3 !== '' ? true : false} columWidth='85%' margin={true} >
                                        <input id='link3' required={true} name='link3' value={data.link3} type='text' onChange={handleChangeGroupName} />
                                        <label>Enlace</label>
                                    </Inputbox>
                                    <ImageContainer active={data.img3 ? true : false}>
                                        <img src={data.img3 ? data.img3 : NoImage} alt="Imagen subida" />
                                        {
                                            data.img3 && <CancelImg data-name='img3' onClick={cancelImage}> X </CancelImg>
                                        }
                                    </ImageContainer>
                                    <UploadImage>
                                        <input style={{fontSize: '0.75rem', width: '100%'}} id="img3" name="img3" type="file" onChange={onFileChange} /><CloudUploadIcon data-name='img3' onClick={handleFileUpload} />
                                    </UploadImage>
                                </Box>
                                <Box flag={data.buttontype2} borderRadius='0 0 10px 0'>
                                    <h4>BOTÓN 2</h4>
                                    <Inputbox type='text' effect={ data.text4 !== '' ? true : false} columWidth='85%' margin={true} >
                                        <input id='text4' required={true} name='text4' value={data.text4} type='text' onChange={handleChangeGroupName} />
                                        <label>Nombre</label>
                                    </Inputbox>
                                    <Inputbox type='text' effect={ data.link4 !== '' ? true : false} columWidth='85%' margin={true} >
                                        <input id='link4' required={true} name='link4' value={data.link4} type='text' onChange={handleChangeGroupName} />
                                        <label>Enlace</label>
                                    </Inputbox>
                                    <ImageContainer active={data.img4 ? true : false}>
                                        <img src={data.img4 ? data.img4 : NoImage} alt="Imagen subida" />
                                        {
                                            data.img4 && <CancelImg data-name='img4' onClick={cancelImage}> X </CancelImg>
                                        }
                                    </ImageContainer>
                                    <UploadImage>
                                        <input style={{fontSize: '0.75rem', width: '100%'}} id="img4" name="img4" type="file" onChange={onFileChange} /><CloudUploadIcon data-name='img4' onClick={handleFileUpload} />
                                    </UploadImage>
                                </Box>
                            </ZoneSubButtons>
                        </WrapBox>
                        <WrapBox flag={data.buttontype3} borderRadius='right'>
                            <NameButton>
                                <Inputbox type='text' effect={ data.groupName3 !== '' ? true : false} columWidth='60%' >
                                    <input id='groupName3' required={true} name='groupName3' value={data.groupName3} type='text' onChange={handleChangeGroupName} />
                                    <label>Nombre principal</label>
                                </Inputbox>
                                <CheckOneButton width='40%'>
                                    <input id='buttontype3' name='buttontype3' checked={data.buttontype3 === 0 ? true : false} type='checkbox' onChange={handleCheckButtonType} />
                                    <label>Botón simple</label>
                                </CheckOneButton>
                            </NameButton>
                            <ZoneSubButtons>
                                <Box flag={1} borderRadius={data.buttontype3 === 1 ? '0 0 0 10px' : '0 0 10px 10px'}>
                                    <h4>BOTÓN 1</h4>
                                    <Inputbox type='text' effect={ data.text5 !== '' ? true : false} columWidth='85%' margin={true} >
                                        <input id='text5' required={true} name='text5' value={data.text5} type='text' onChange={handleChangeGroupName} />
                                        <label>Nombre</label>
                                    </Inputbox>
                                    <Inputbox type='text' effect={ data.link5 !== '' ? true : false} columWidth='85%' margin={true} >
                                        <input id='link5' required={true} name='link5' value={data.link5} type='text' onChange={handleChangeGroupName} />
                                        <label>Enlace</label>
                                    </Inputbox>
                                    <ImageContainer active={data.img5 ? true : false}>
                                        <img src={data.img5 ? data.img5 : NoImage} alt="Imagen subida" />
                                        {
                                            data.img5 && <CancelImg data-name='img5' onClick={cancelImage}> X </CancelImg>
                                        }
                                    </ImageContainer>
                                    <UploadImage>
                                        <input style={{fontSize: '0.75rem', width: '100%'}} id="img5" name="img5" type="file" onChange={onFileChange} /><CloudUploadIcon data-name='img5' onClick={handleFileUpload} />
                                    </UploadImage>
                                </Box>
                                <Box borderRadius='0 0 10px 0' flag={data.buttontype3}>
                                    <h4>BOTÓN 2</h4>
                                    <Inputbox type='text' effect={ data.text6 !== '' ? true : false} columWidth='85%' margin={true} >
                                        <input id='text6' required={true} name='text6' value={data.text6} type='text' onChange={handleChangeGroupName} />
                                        <label>Nombre</label>
                                    </Inputbox>
                                    <Inputbox type='text' effect={ data.link6 !== '' ? true : false} columWidth='85%' margin={true} >
                                        <input id='link6' required={true} name='link6' value={data.link6} type='text' onChange={handleChangeGroupName} />
                                        <label>Enlace</label>
                                    </Inputbox>
                                    <ImageContainer active={data.img6 ? true : false}>
                                        <img src={data.img6 ? data.img6 : NoImage} alt="Imagen subida" />
                                        {
                                            data.img6 && <CancelImg data-name='img6' onClick={cancelImage}> X </CancelImg>
                                        }
                                    </ImageContainer>
                                    <UploadImage>
                                        <input style={{fontSize: '0.75rem', width: '100%'}} id="img6" name="img6" type="file" onChange={onFileChange} /><CloudUploadIcon data-name='img6' onClick={handleFileUpload} />
                                    </UploadImage>
                                </Box>
                            </ZoneSubButtons>
                        </WrapBox>
                    </ContentBox>
                }
            </Desktop>
            <Tablet>
                <ContentBox>
                    TABLET BUTTONSACCESS SETTINGS 
                </ContentBox>
            </Tablet>
            <Mobile>
                <ContentBox>
                    MOBILE BUTTONSACCESS SETTINGS 
                </ContentBox>
            </Mobile>
        </React.Fragment>
    );
}

function mapState(state) {
    const { user } = state.login;
    return { user };
}

export default connect(mapState)(ButtonsAccessSettings);