/* General imports */
import React from 'react';
import styled from 'styled-components';

/* Styled components */
const FileUpload = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    height: auto;
    min-height: 150px;
    align-items: center;
    justify-content: flex-start;
    span {
        color: #FFF;
        text-align: center;
    }
`

const Button = styled.label`
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 30px;
    font-size: 16px;
    background: #2196F3;
    border-radius: 24px;
    color: #FFF;
    margin: 0 20px;
    cursor: pointer;
    border: none;
    box-shadow: none;
    padding: 10px 20px;
    box-sizing: border-box;
    @media (max-width: 600px) {
        font-size: 16px;
    }
`

function FileInput (props) {

    
    const onChange = (e) => {
        e.preventDefault();
        props.onChange(e);
    }
    return (
        <FileUpload>
            <input
                id="contained-button-file"
                name='matchFile'
                type="file"
                style={{ display: "none" }}
                onChange={onChange}
                required
            />
            <span>
                {props.file ? props.file.name : 'Archivo no seleccionado'}
            </span>
            <Button htmlFor="contained-button-file">
                {
                    props.file ?
                    'Reemplazar archivo'
                    :
                    'Subir archivo'
                }
            </Button>
        </FileUpload>
    );
}

export default FileInput;