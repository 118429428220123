/* General imports */
import React from 'react'
import styled, {keyframes} from 'styled-components'
import axios from 'axios';
import  { authHeader } from '../../app/auth-header';
import Zoom from 'react-reveal/Zoom';

/* Components imports */
import ProductCard from './ProductCard'
import { ArrowBack, ArrowForward } from '@material-ui/icons';

/* Styled components */
const moveSlideshow = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-50%, 0, 0);
  }
`;

const Root = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 83px;
    scroll-behavior: smooth;
    background-color: #FFF;
    @media (max-width: 600px) {
        padding: 0 20px;
    }
`

const Title = styled.h2`
    display: flex;
    align-items: center;
    width: 100%;
    height: 150px;
    text-align: left;
    font-weight: 300;
    font-size: 2.5rem;
    color: #001554;
    margin: auto 0;
    @media (max-width: 600px) {
        font-size: 1.8rem;
    }
`

const ContentList = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 400px;
    align-items: center;
    justify-content: flex-start;
    overflow-x: hidden;
`

const Band = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: fit-content;
    height: 100%;
    /* transform-origin: center center 0px; 
    transition: all 1s ease-in-out;
    transition-delay: 200ms;
    -webkit-transform-origin: center center 0px; 
    -webkit-transition: all 0.2s ease 0s;
    transform: ${(props) => `matrix3d(1,0,0.00,0,0.00,1,0.00,0,0,0,1,0,${props.moveX},0,0,1)`};
    -webkit-transform: ${(props) => `matrix3d(1,0,0.00,0,0.00,1,0.00,0,0,0,1,0,${props.moveX},0,0,1)`}; */
    transform: ${props => `translate3d(${props.moveBand}px,0,0)`};
    animation: ${moveSlideshow} ${props => props.amount * 3}s linear infinite;
    ${(props) => props.automove ? '' : 'transition: all 300ms ease-in-out;'}
    &:hover {
        ${(props) => props.automove ? 'animation-play-state: paused;' : '' }
    }
`

const BackButton = styled.button`
    border: none;
    background: transparent;
    position: absolute;
    top: 55%;
    left: 20px;
    z-index: 999;
    color: #c9c9c9;
    cursor: pointer;
    opacity: 0.5;
    &:hover {
        opacity: 1;
    }
    @media (max-width: 600px) {
        left: 10px;
    }
`

const ForwardButton = styled.button`
    border: none;
    background: transparent;
    position: absolute;
    top: 55%;
    left: calc(100vw - 70px);
    z-index: 999;
    color: #c9c9c9;
    cursor: pointer;
    opacity: 0.5;
    &:hover {
        opacity: 1;
    }
    @media (max-width: 600px) {
        left: calc(100vw - 50px);
    }
`



/* ----- Principal function ----- */
function ProductList({idsection}) {      

    const [productsListData, setProductsListData] = React.useState(null);
    const [ productsData, setProductsData ] = React.useState(null);
    const [ scrollPosition, setScrollPosition ] = React.useState(0);
    const [ moveX, setMoveX ] = React.useState(0);
    const [touchStart, setTouchStart] = React.useState(0);
    const [touchEnd, setTouchEnd] = React.useState(0);
    const bandRef = React.useRef();
    const [ moveBand, setMoveBand ] = React.useState(0);

    const PRODUCTCARD_WIDTH = 320;

    const PRINCIPAL_FILTER = "Destacados";

    const moveSliderRight = (num) => {
        if(moveBand < 0){
            setMoveBand(moveBand + PRODUCTCARD_WIDTH);
        }
    }

    const moveSliderLeft = (num) => {
        if(moveBand > productsData.length * -PRODUCTCARD_WIDTH + PRODUCTCARD_WIDTH){
            setMoveBand(moveBand - PRODUCTCARD_WIDTH);
        }
    }

    const scrollBand = (e) => {
            e.preventDefault();
            setScrollPosition(Math.round(window.scrollY));
            if(Math.round(window.scrollY) - scrollPosition > 0 && productsData){
                if(Math.abs(moveX) < ((productsData.length - 4) * PRODUCTCARD_WIDTH)){
                    setMoveX(moveX - 5);
                }
            } else {
                if(moveX < -5 ){
                    setMoveX(moveX + 5);
                }
            }
    }

    /* React.useEffect(() => {
        window.addEventListener("scroll", scrollBand);
        return () => {
            window.removeEventListener('scroll', scrollBand);           
          };
      }); */

    const back = (e) => {
        moveSliderLeft(PRODUCTCARD_WIDTH);
    }

    const forward = (e) => {
        moveSliderRight(PRODUCTCARD_WIDTH);
    }

    function handleTouchStart(e) {
        setTouchStart(e.targetTouches[0].clientX);
    }

    function handleTouchMove(e) {
        setTouchEnd(e.targetTouches[0].clientX);
    }

    function handleTouchEnd() {
        if (touchStart - touchEnd > 150) {
            // do your stuff here for left swipe
            moveSliderRight(PRODUCTCARD_WIDTH)
        }

        if (touchStart - touchEnd < -150) {
            // do your stuff here for right swipe
            moveSliderLeft(PRODUCTCARD_WIDTH)
        }
    }

    React.useEffect(() => {
        let configHeader = {
            headers: { ...authHeader(), 'Content-Type': 'application/json' }
        }
        if(!productsListData){
            axios.get('/api/sections/' + idsection,configHeader)
            .then(sectionData => {
                setProductsListData(sectionData.data.section.data);
                const url = parseInt(sectionData.data.section.data.productFilter) === -1 ? `/api/products?tags=${PRINCIPAL_FILTER}` : `/api/products?idCategory=${sectionData.data.section.data.productFilter}&tags=${PRINCIPAL_FILTER}`;
                if(!productsData){
                    axios.get(url,configHeader)
                    .then(productData => {
                        setProductsData(productData.data.rows);
                    })
                    .catch(err => {
                        alert('Hubo un error...');
                    })
                }
            })
            .catch(err => {
                alert('Hubo un error...');
            })
        }
    }, [productsData, productsListData])

    return(
        productsListData && productsData &&
        <Root>
            <Zoom delay={300}>
                <Title>{productsListData.title}</Title>
            </Zoom>
           <ContentList>
                <Band moveBand={moveBand} amount={productsData.length} automove={true} ref={bandRef} moveX={moveX} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
                    {
                        productsData.map((product, index) =>
                            <ProductCard key={`product_${index}`} productInfo={product} />
                        )
                    }
                </Band>
           </ContentList>
           {/* <BackButton onClick={back}><ArrowBack /></BackButton>
            <ForwardButton onClick={forward}><ArrowForward /></ForwardButton> */}
        </Root>
    );
}

export default ProductList;