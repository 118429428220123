import { sectionConstants } from './SectionConstants';

let sections = JSON.parse(localStorage.getItem('sections'));
const initialState = sections ? 
  { 
      error: false,
      isFetching: false,
      sections: sections
  } : 
  { 
    error: false,
    isFetching: true,
  };

export function section(state = initialState, action) {
  switch (action.type) {
    case sectionConstants.FETCHING:
      return { 
            ...state,
            isFetching: true
        };
    case sectionConstants.SUCCESS:
      return { 
            ...state,
            isFetching: false,
            sections: action.sections
        };
    case sectionConstants.ERROR:
      return { 
            ...state,
            error: true
        };
    default:
      return state
  }
}