export const professionalsConstants = {
    FETCHING_FORMFIELDS: 'PROFESSIONALS_FETCHING_FORMFIELDS',
    SUCCESS_FORMFIELDS: 'PROFESSIONALS_SUCCESS_FORMFIELDS',
    SUCCESS_UPDATEFORMFIELDS: 'PROFESSIONALS_SUCCESS_UPDATEFORMFIELDS',
    ERROR_FORMFIELDS: 'PROFESSIONALS_ERROR_FORMFIELDS',
    SUCCESS: 'PROFESSIONALS_SUCCESS',
    ERROR: 'PROFESSIONALS_ERROR',
    FETCHING: 'PROFESSIONALS_FETCHING',
    IDROLE: 4
};
