/* General imports */
import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { devices } from '../app/config'
import { NavLink } from 'react-router-dom'
import { loginActions } from '../login/LoginActions'
import { sidebarCartActions } from '../SidebarCart/SidebarCartActions'

/* Components imports */
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import ModalLogin from '../login/ModalLogin'
import ModalRegister from '../register/ModalRegister'
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled components */
const Nav = styled.nav`
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 0;
    left: 0;
    width: 100vw;
    height: ${(props) => props.height ? props.height : '65px'};
    padding: ${(props) => props.padding ? props.padding : '0px 50px 0 50px'};
    box-sizing: border-box;
    transition: all .5s ease;
    /* background: ${(props) => props.animate ? 'rgba(33, 40, 45, 0.8)' : 'transparent'}; */
    background: #FFF;
    z-index: 100;
`

const LogoLink = styled.a`
    position: relative;
    text-decoration: none;
    height: 100%;
    width: auto;
    display: flex;
    align-items: center;
`

const Logo = styled.img`
    height: 80%;
    width: auto;
`

const NavBorder = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 4px;
    background: linear-gradient(0deg, #213781, #001554);
`

const NavUl = styled.ul`
    height: 100%;
    float: right;
    margin: 0;
    padding: 0;
    display: flex;
    span {
        text-shadow: ${(props) => props.animate ? '1px 1px 2px white' : '1px 1px 2px black'};
    }
`

const NavUlMobile = styled.ul`
    position: absolute;
    display: flex;
    flex-direction: column;
    background: #00002C;
    top: 100%;
    left: 0;
    margin: 0;
    height: ${(props) => props.show ? '100vh' : 0};
    width: 100%;
    border-radius: 10px 0 0 10px;
    overflow-y: hidden;
    transition: all 300ms ease-in-out;
    align-items: flex-start;
    box-sizing: border-box;
    padding: ${(props) => props.show ? '50px 0 50px 20px' : 0};
    justify-content: flex-start;
    span {
        text-shadow: ${(props) => props.animate ? '1px 1px 2px white' : '1px 1px 2px black'};
    }
`

const NavLi = styled.li`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    list-style: none;
    transition: all .1s ease-in-out;
    color: white;
    padding: 0 10px;
    box-sizing: border-box;
    background: ${(props) => props.active ? 'linear-gradient(90deg, rgba(135,18,55,1) 0%, rgba(245,44,62,1) 100%)' : 'transparent'};
    cursor: pointer;
    line-height: ${(props) => props.lineHeight ? props.lineHeight : '50px'};
    user-select: none;
    &:hover {
        background: ${(props) => props.noHover ? 'none' : '#2196F3'};
    }
`

const NavLiUlList = styled.ul`
    position: absolute;
    display: none;
    top: 100%;
    left: 0;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    z-index: 1;
    background: transparent;
`

const NavLiUl = styled.li`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    list-style: none;
    transition: all .1s ease-in-out;
    color: white;
    padding: 0 10px;
    box-sizing: border-box;
    background: ${(props) => props.active ? 'linear-gradient(90deg, rgba(135,18,55,1) 0%, rgba(245,44,62,1) 100%)' : 'transparent'};
    cursor: pointer;
    line-height: ${(props) => props.lineHeight ? props.lineHeight : '50px'};
    &:hover {
        background: #2196F3;
        ${NavLiUlList} {
            display: block;
        }
    }
`

const NavSubLi = styled.li`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    list-style: none;
    transition: all 0.5s ease-in-out;
    color: white;
    padding: 0 10px;
    box-sizing: border-box;
    background: ${(props) => props.active ? 'linear-gradient(90deg, rgba(135,18,55,1) 0%, rgba(245,44,62,1) 100%)' : '#FFF'};
    cursor: pointer;
    line-height: ${(props) => props.lineHeight ? props.lineHeight : '50px'};
    user-select: none;
    &:hover {
        background: ${(props) => props.noHover ? 'none' : '#001554'};
        color: #FFF;
        width: 95%;
    }
`

const ItemLink = styled(NavLink)`
    color: ${(props) => props.color ? props.color : '#001554'};
    padding: ${(props) => props.padding ? props.padding : '5px 15px'};
    text-decoration: none;
    font-family: 'IBM Plex Sans';
    font-size: ${(props) => props.fontSize ? props.fontSize : '18px'};
    font-weight: 400;
    user-select: none;
`

const ItemSubLink = styled(NavLink)`
    color: #001554;
    padding: ${(props) => props.padding ? props.padding : '5px 15px'};
    text-decoration: none;
    font-family: 'IBM Plex Sans';
    font-size: ${(props) => props.fontSize ? props.fontSize : '18px'};
    font-weight: 400;
    user-select: none;
    &:hover {
        color: #FFF;
    }
`

const Item = styled.div`
    display: flex;
    align-items: center;
    color: ${(props) => props.color ? props.color : '#001554'};
    padding: ${(props) => props.padding ? props.padding : '5px 15px'};
    text-decoration: none;
    font-family: 'IBM Plex Sans';
    font-size: ${(props) => props.fontSize ? props.fontSize : '18px'};
    font-weight: 400;
    user-select: none;
`

const CartIcon = styled(ShoppingCartOutlinedIcon)`
    display: flex;
    position: relative;
    cursor: pointer;
    filter: brightness(0.5);
`

const Profile = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    padding: 0 15px;
    border-radius: 10px;
    cursor: pointer;
    &:hover {
        background-color: ${(props) => props.open ? 'transparent' : 'rgba(0, 0, 0, 0.2)'};
    }
    img {
        width: 40px;
        height: 40px;
        margin: 0 0 0 15px;
    }
`

const ProfileMenu = styled.ul`
    position: absolute;
    top: 90%;
    margin: 0;
    display: ${(props) => props.open ? 'flex' : 'none'};
    opacity: ${(props) => props.open ? 1 : 0};
    transition: all .5s ease;
    width: 100%;
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    outline: none;
    z-index: 100;
    padding: 10px;
    box-sizing: border-box;
    right: 2.5%;
    border-radius: 0 0 10px 10px;
    background: linear-gradient(90deg, rgba(135,18,55,1) 0%, rgba(245,44,62,1) 100%);
    a{
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 100%;
        line-height: 40px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.87);
        box-sizing: border-box;
        padding: 0 12px;
        transition: all 200ms ease;
        span {
            font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
            font-weight: 400;
            line-height: 1.5;
        }
    }
    a:hover {
        background-color: rgba(0, 0, 0, 0.2);
    }
`

const ItemProfile = styled.li`
    display: flex;
    position: relative;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
    span {
        font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
        font-weight: 400;
        line-height: 1.5;
    }
    &:hover {
        background: linear-gradient(90deg, rgba(245,44,62,1) 0%, rgba(135,18,55,1) 100%);
    }
`

const DataProfile = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: flex-end;
    justify-content: center;
    font-size: 14px;
    text-align: right;
    user-select: none;
    text-shadow: 0 0 5px rgba(0,0,0,0.8);
    span {
        opacity: .5;
    }
`

const MenuButton = styled.button`
    position: relative;
    display: flex;
    background: transparent;
    color: ${(props) => props.open ? '#2196F3' : '#001554'};
    outline: none;
    border: none;
    cursor: pointer;
    padding: 0;
`

const SearchButton = styled.button`
    position: relative;
    display: flex;
    background: transparent;
    color: #001554;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 0;
`

const Divider = styled.div`
    width: 150px;
    height: 1px;
    background: #2196F3;
`

const StyledSearchIcon = styled(SearchIcon)`
    color: #001554;
`

/* ----- Principal function ----- */
function NavAnimate({data, switchSidebarCart, cartitems, loggingIn, loggedIn, user, modalLogin, logout, getFields }) {

    const [navAnimate, setNavAnimate] = React.useState(false)
    const [profileMenu, setProfileMenu] = React.useState(false);
    const [ openLogin, setOpenLogin ] = React.useState(false);
    const [ openRegister, setOpenRegister ] = React.useState(false);
    const [ openMenu, setOpenMenu ] = React.useState(false);

    const navRef = React.useRef(null)

    const handleProfileMenu = () => {
        setProfileMenu(!profileMenu);
    };

    const openModalLogin = () => {
        setOpenLogin(true);
    }

    const closeModalLogin = () => {
        setOpenLogin(false);
    }

    const openModalRegister = () => {
        setOpenRegister(true);
    }

    const closeModalRegister = () => {
        setOpenRegister(false);
    }

    const onMenu = () => {
        setOpenMenu(!openMenu)
    }

    React.useLayoutEffect(() => {
    
        const onScroll = () => {
            const scrollPos = window.scrollY;
            if (scrollPos > 5) {
                setNavAnimate(true)
            } else {
                setNavAnimate(false)
            }
        };
    
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    return(
        <React.Fragment>
            <Desktop>
                <Nav height={data.height} animate={navAnimate} ref={navRef}>
                    <LogoLink href={"/"}><Logo src={data.logo} alt='Logo' /></LogoLink>
                    <NavUl>
                        {
                            data.items.map((item, index) =>
                                item.title === 'Productos' ?
                                item.showNav &&
                                <NavLiUl key={`MenuItem${index}`}>
                                    <Item>{item.title}<KeyboardArrowDownIcon /></Item>
                                    <NavLiUlList>
                                        <NavSubLi>
                                            <ItemSubLink exact to={item.link}>Todos</ItemSubLink>
                                        </NavSubLi>
                                        <NavSubLi>
                                            <ItemSubLink exact to="/products/novelties">Novedades</ItemSubLink>
                                        </NavSubLi>
                                        <NavSubLi>
                                            <ItemSubLink exact to="/products/outstanding">Destacados</ItemSubLink>
                                        </NavSubLi>
                                    </NavLiUlList>
                                </NavLiUl>
                                :
                                item.showNav && item.title !== 'INICIO' && item.title !== 'Destacados' && item.title !== 'Novedades' &&
                                <NavLi key={`MenuItem${index}`}><ItemLink exact to={item.link} >{item.title}</ItemLink></NavLi>
                            )
                        }
                        {/* <NavLi><StyledSearchIcon /></NavLi> */}
                        {/* <NavLi><CartIcon onClick={switchSidebarCart} /><span>{cartitems.length > 0 ? `(${cartitems.length})` : ''}</span></NavLi>
                        {
                            loggedIn ?
                            <NavLi active={profileMenu}>
                                <Profile onClick={handleProfileMenu} open={profileMenu}>
                                    <DataProfile>
                                        <strong>{user.username}</strong>
                                    </DataProfile>
                                    <img src={ImageProfile} alt='Profile' />
                                    <ProfileMenu open={profileMenu}>
                                        <ItemProfile onClick={logout}><span>Cerrar Sesión</span></ItemProfile>
                                    </ProfileMenu>
                                </Profile>
                            </NavLi>
                            :
                            <NavLi onClick={openModalLogin}><span>Acceder</span></NavLi>
                        } */}
                    </NavUl>
                    {
                        openLogin && <ModalLogin registerAccess={openModalRegister} openModal={openModalLogin} closeModal={closeModalLogin} />
                    }
                    {
                        openRegister && <ModalRegister loginAccess={openModalLogin} openModal={openModalRegister} closeModal={closeModalRegister} />
                    }
                    <NavBorder />
                </Nav>
            </Desktop>
            <Tablet>
                <Nav height={data.height} animate={navAnimate} ref={navRef}>
                <LogoLink href={"/"}><Logo src={data.logo} alt='Logo' /></LogoLink>
                    <NavUl>
                        {
                            data.items.map((item, index) =>
                                item.showNav && item.title !== 'INICIO' && 
                                <NavLi key={`MenuItem${index}`}><ItemLink exact to={item.link} >{item.title}</ItemLink></NavLi>
                            )
                        }
                        {/* <NavLi><StyledSearchIcon /></NavLi> */}
                        {/* <NavLi><CartIcon onClick={switchSidebarCart} /><span>{cartitems.length > 0 ? `(${cartitems.length})` : ''}</span></NavLi>
                        {
                            loggedIn ?
                            <NavLi active={profileMenu}>
                                <Profile onClick={handleProfileMenu} open={profileMenu}>
                                    <DataProfile>
                                        <strong>{user.username}</strong>
                                    </DataProfile>
                                    <img src={ImageProfile} alt='Profile' />
                                    <ProfileMenu open={profileMenu}>
                                        <ItemProfile onClick={logout}><span>Cerrar Sesión</span></ItemProfile>
                                    </ProfileMenu>
                                </Profile>
                            </NavLi>
                            :
                            <NavLi onClick={openModalLogin}><span>Acceder</span></NavLi>
                        } */}
                    </NavUl>
                    {
                        openLogin && <ModalLogin registerAccess={openModalRegister} openModal={openModalLogin} closeModal={closeModalLogin} />
                    }
                    {
                        openRegister && <ModalRegister loginAccess={openModalLogin} openModal={openModalRegister} closeModal={closeModalRegister} />
                    }
                </Nav>
            </Tablet>
            <Mobile>
                <Nav height={data.height} ref={navRef} padding="0 10px">
                    <LogoLink href={"/"}><Logo src={data.logo} alt='Logo' /></LogoLink>
                    <NavUl>
                        {/* <NavLi>
                            <SearchButton><StyledSearchIcon /></SearchButton>
                        </NavLi> */}
                        <NavLi>
                            <MenuButton onClick={onMenu} open={openMenu}>
                                {
                                    openMenu ?
                                        <CloseIcon />
                                    :
                                        <MenuIcon />
                                }
                            </MenuButton>
                        </NavLi>
                    </NavUl>
                    <NavUlMobile show={openMenu}>
                        {
                            data.items.map((item, index) =>
                                item.showNav && item.title !== 'INICIO' &&
                                <NavLi key={`MenuItem${index}`} lineHeight="80px">
                                    <ItemLink padding="0" fontSize="1.5rem" color="#FFF" exact to={item.link} >{item.title}</ItemLink>
                                    <Divider />
                                </NavLi>
                            )
                        }
                        {/* <NavLi><CartIcon onClick={switchSidebarCart} /><span>{cartitems.length > 0 ? `(${cartitems.length})` : ''}</span></NavLi>
                        {
                            loggedIn ?
                            <NavLi active={profileMenu}>
                                <Profile onClick={handleProfileMenu} open={profileMenu}>
                                    <DataProfile>
                                        <strong>{user.username}</strong>
                                    </DataProfile>
                                    <img src={ImageProfile} alt='Profile' />
                                    <ProfileMenu open={profileMenu}>
                                        <ItemProfile onClick={logout}><span>Cerrar Sesión</span></ItemProfile>
                                    </ProfileMenu>
                                </Profile>
                            </NavLi>
                            :
                            <NavLi onClick={openModalLogin}><span>Acceder</span></NavLi>
                        } */}
                    </NavUlMobile>
                    {
                        openLogin && <ModalLogin registerAccess={openModalRegister} openModal={openModalLogin} closeModal={closeModalLogin} />
                    }
                    {
                        openRegister && <ModalRegister loginAccess={openModalLogin} openModal={openModalRegister} closeModal={closeModalRegister} />
                    }
                    <NavBorder />
                </Nav>
            </Mobile>
        </React.Fragment>
    );
}

function mapState(state) {
    const { cartitems, open} = state.sidebarcart;
    const { loggingIn, user, loggedIn } = state.login;
    return { cartitems, open, loggingIn, user, loggedIn};
}

const actionCreators = {
    switchSidebarCart: sidebarCartActions.switchSidebarCart,
    modalLogin: loginActions.modalLogin,
    logout: loginActions.logout,
    getFields: loginActions.getFields
}

export default connect(mapState, actionCreators)(NavAnimate);